import { BooleanInput, NumberInput, FormDataConsumer } from 'react-admin';
import { Grid } from '@mui/material';



export default function VaultConnectionJobberForm({configurationKeys}) {
    return (
        <Grid hidden>
            {configurationKeys.indexOf('import_timeSheetEntries') >= 0 &&
                <Grid item lg={12} xs={12}>
                    <BooleanInput source='import_timeSheetEntries' />
                </Grid>
            }
            {configurationKeys.indexOf('auto_create_propay') >= 0 &&
                <Grid item lg={12} xs={12}>
                    <BooleanInput source='auto_create_propay' />
                    <FormDataConsumer>
                        {({ formData }) => {
                            return (<>{formData.auto_create_propay&&<NumberInput source='revenue_percent' label='Contract price percent' validate={validateRevenuePercentField}/>}</>)
                        }}
                    </FormDataConsumer>
                </Grid>
            }
        </Grid>
    )
}
const revenuPercentFieldValidation = (value, allValues) => {
    if (
        !value &&
        allValues.auto_create_propay
    ) {
        return '% of Contract Price is required';
    }
    return undefined;
};

const validateRevenuePercentField = [revenuPercentFieldValidation];
