import { ToastContainer } from 'react-toastify';
import { IconButton, Tooltip } from '@mui/material';
import { DropdownIcon } from '../../components/SvgIcons/DropdownIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { BulkAddBonusModal } from './BulkAddBonusModal';
import {
    CRUD_UPDATE,
    useMutation,
    useNotify,
} from 'react-admin';
import { USER_TYPE } from '../../utils/Constants/ConstantData';
import { PROPAY_STATUS } from '../../constants';
import { PayBonusModal } from './PayBonusModal';
import { useQueryClient } from 'react-query';

export const BonusListAction = (props: any) => {
    const { identity, onList, record, setShowDetail } = props;
    const [openAddModal, setAddModal] = React.useState(false);
    const [showPayModal, setShowModal] = React.useState(false);
    const [isBonus, setIsBonus] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [mutate] = useMutation();
    const notify = useNotify();
    const queryClient = useQueryClient();

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const getRecordData = async () => {
        await queryClient.invalidateQueries([
            'protivEmployeeBonusStatement',
            'getList',
        ]);
    };
    const markPendingBonuses = data => {
        return mutate(
            {
                type: 'update',
                resource: 'protivEmployeeBonusStatement',
                payload: {
                    ids: [data.id],
                    action: 'pendingBonusStatement',
                    data: {},
                },
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: () => {
                    notify('Successfully! Statement marked as pending');
                    getRecordData();
                },
                onFailure: error => {
                    notify(`Failure ! ${error.message}`);
                },
            }
        );
    };
    return (
        <>
            <ToastContainer />
            <div
                className={`${
                    onList ? 'on-list-dropdown card-dropdown' : 'card-dropdown'
                }`}
            >
                <Tooltip title="">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-haspopup="true"
                    >
                        <DropdownIcon />
                    </IconButton>
                </Tooltip>
                <Menu
                    anchorEl={anchorEl}
                    id="card-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    className="card-menu-budget"
                >
                    {record?.status !== PROPAY_STATUS.PENDING &&
                        identity?.user_type !== USER_TYPE.worker && (
                            <MenuItem
                                onClick={() => markPendingBonuses(record)}
                            >
                                Mark as Pending
                            </MenuItem>
                        )}
                    {record?.status !== PROPAY_STATUS.PAID &&
                        identity?.user_type !== USER_TYPE.worker && (
                            <MenuItem
                                onClick={() => {
                                    setShowModal(true);
                                }}
                            >
                                Mark as Close
                            </MenuItem>
                        )}
                    <MenuItem
                        onClick={() =>
                            setShowDetail({ open: true, id: record.id })
                        }
                    >
                        View Detail
                    </MenuItem>
                    {record?.status !== PROPAY_STATUS.PAID &&
                        identity?.user_type !== USER_TYPE.worker && (
                            <>
                                <MenuItem
                                    onClick={() => {
                                        setAddModal(true);
                                        setIsBonus(true);
                                    }}
                                >
                                    Add Bonus
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setAddModal(true);
                                        setIsBonus(false);
                                    }}
                                >
                                    Add Deduction
                                </MenuItem>
                            </>
                        )}
                </Menu>
                {openAddModal && (
                    <BulkAddBonusModal
                        isOpen={openAddModal}
                        refreshData={getRecordData}
                        onClose={() => {
                            setAddModal(false);
                        }}
                        selectedIds={[record.id]}
                        showOption={false}
                        bulkAction={isBonus ? 'bonus' : 'deduction'}
                        recordId={record.id}
                    />
                )}
                {showPayModal && (
                    <PayBonusModal
                        isOpen={showPayModal}
                        onClose={() => setShowModal(false)}
                        recordIds={[record.id]}
                        refreshData={getRecordData}
                    />
                )}
            </div>
        </>
    );
};
