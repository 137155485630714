
import CloseIcon from '@mui/icons-material/Close';
import TabPanel from '@mui/lab/TabPanel';
import {
    Box, Button, Dialog,
    DialogTitle,
    Grid, IconButton,
    Link,
    Stack, Typography, Theme, useMediaQuery, FormGroup, DialogActions
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { useEffect, useRef, useState } from 'react';
import {
    AutocompleteArrayInput,
    AutocompleteInput,
    BooleanInput, FieldTitle, FormDataConsumer,
    FunctionField, ListActions,
    NumberInput,
    RadioButtonGroupInput,
    ResourceContextProvider,
    TextInput,
    required,
    useDataProvider,
    useListContext,
    ReferenceInput,
    useRecordContext
} from 'react-admin';
import DialogForm from '../../components/DialogForm';
import { Condition, CustomReferenceArrayInputObj, MoneyInput, ReferenceArrayInputObj, StyledDatagrid } from '../../components/fields';
import { InfoLabel } from '../../components/fields/InfoLabel';
import { MaskedTimeInput } from '../../components/fields/MaskedTimeInput';
import { CustomReferenceInputObjSelect } from '../../components/fields/RefrenceInputObjReactSelect';
import { PageAlertContextProvider } from '../../components/page-alerts/PageAlerts';
import { CONSTANT_DATA, PROPAY_BUDGET_HELP_LINK, USER_TYPE } from '../../utils/Constants/ConstantData';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import {
    EmployeeCreateDialog,
    EmployeeFullNameField,
    EmployeeUpdateForm
} from '../employees/Employee';
import { EditJob, JobNameInput } from '../jobs/job';
import ButtonGroupInput from './ButtonGroupInput';
import { JobCreateDialog, PropayCreate, StyledMilstoneFormIterator } from './Propay';
import {CustomStyledMilstoneFormIterator,
    StyledCard,
    StyledGroupMilstoneFormIterator} from './NewProPayForm'

import { List } from '../../layout/List';
import { ResponsiveFilterGusser } from '../../layout/ResponsiveFilter';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControl,
    FormControlLabel,
    InputAdornment,
    OutlinedInput,
    Radio,
    RadioGroup,
    Input,
} from '@mui/material';
import _ from 'lodash';
import calculator_Frame_Icon from '../../assets/calculator-frame.svg';
import calculator_Frame_Orange_Icon from '../../assets/calculator-frame-orange.svg';
import { ALERT_ERROR_ICON, BIN_ICON, CLOSE_ICON, LOCK_ICON, PLUS_ICON, SWITCH_ICON } from '../../utils/Constants/ClassName';
import { StyledNumberInput } from '../companies/CompanySetting';
import { ArrayInput, AutoCompleteArrayInput } from '../../components/ArrayInput';
import { StyledBooleanInput } from '../payrolls/weeklyEntries';
import { convertNumToTime } from '../../utils/formatter';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import { useIdentityContext } from '../../components/identity';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { PropayCreateNewForm } from './NewProPayForm';
import { Switch } from '@mui/material';
import { useLocation } from 'react-router-dom';

interface LeadpayEmployeeWage {
    employee_id: number;
    id: number;
    lead_pay_per: number;
  }

  interface Task {
    id: number;
    name: string;
  }

  interface CostCode {
    code: string;
    id: number;
  }

  interface Milestone {
    amount: number;
    id: number;
    name: string;
    hours: number;
    budget: number;
    cost_code_ids: number[];
    budget_hours: number;
    ot_amt: number;
    estimated_hours: number;
  }

  interface PropayRecord {
    id: number;
    name: string;
    amount: number;
    manager_id: number;
    job_id: number;
    second_job_id: number;
    is_bonus_pool_enabled: boolean;
    bonus_split_type: string;
    worker_bonus_percent: number;
    old_propay_view: boolean;
    estimated_hours: number;
    leadpay_employee_wage_ids: LeadpayEmployeeWage[];
    allow_task: boolean;
    budget_option: string;
    budget: number;
    budget_hours: number;
    is_change_lead_pay: boolean;
    show_to_all: boolean;
    leadpay_amount: number;
    task_ids: Task[];
    cost_code_ids_obj: CostCode[];
    milestone_ids: Milestone[];
    can_create_unique_code: boolean;
    cost_code_ids: number[];
  }


const parsePropayRecord = (record: Partial<PropayRecord> = {}): PropayRecord => ({
    id: record?.id || 0,
    name: record.name || '',
    amount: record.amount || 0,
    manager_id: record.manager_id || 0,
    job_id: record.job_id || 0,
    second_job_id: record.second_job_id || 0,
    is_bonus_pool_enabled: record.is_bonus_pool_enabled || false,
    bonus_split_type: record.bonus_split_type || '',
    worker_bonus_percent: record.worker_bonus_percent || 0,
    old_propay_view: record.old_propay_view || false,
    estimated_hours: record.estimated_hours || 0,
    leadpay_employee_wage_ids: record.leadpay_employee_wage_ids?.map(({ employee_id, id, lead_pay_per }) => ({
      employee_id,
      id,
      lead_pay_per,
    })) || [],
    allow_task: record.allow_task || false,
    budget_option: record.budget_option || '',
    budget: record.budget || 0,
    budget_hours: record.budget_hours || 0,
    is_change_lead_pay: record.is_change_lead_pay || false,
    show_to_all: record.show_to_all || false,
    leadpay_amount: record.leadpay_amount || 0,
    task_ids: record.task_ids?.map(({ id, name }) => ({
      id,
      name,
    })) || [],
    cost_code_ids_obj: record.cost_code_ids_obj?.map(({ code, id }) => ({
      code,
      id,
    })) || [],
    milestone_ids: record.milestone_ids?.map(({ amount, id, name, hours, budget, cost_code_ids, budget_hours, ot_amt, estimated_hours }) => ({
      amount,
      id,
      name,
      hours,
      budget,
      cost_code_ids,
      budget_hours,
      ot_amt,
      estimated_hours,
    })) || [],
    can_create_unique_code: record.can_create_unique_code || false,
    cost_code_ids: record.cost_code_ids || [],
  });


const PropayCreateTab = () => {
    const identity = useIdentityContext();
    const [oldForm, setOldForm] = useState(false);
    const [remainingTime, setRemainingTime] = useState({valid:false, timeLeft:0});
    const newForm = (e) => {
        setOldForm(e?.target?.checked);
    }

    const isFeatureValid = () => {
        const lastUpdateTimestamp = new Date('2024-01-11T00:00:00').getTime();
        const currentTimestamp = new Date().getTime();
        const fifteenDaysInMilliseconds = NUMBER.FIFTEEN * NUMBER.TWENTY_FOUR * NUMBER.SIXTEY * NUMBER.SIXTEY * NUMBER.ONE_THOUSAND;
        const remainingTime = fifteenDaysInMilliseconds - (currentTimestamp - lastUpdateTimestamp);
        return {valid:remainingTime > NUMBER.ZERO, timeLeft:remainingTime};
      }

    const displayRemainingTime = (remainingTime) => {
        const days = Math.ceil(remainingTime / (NUMBER.TWENTY_FOUR * NUMBER.SIXTEY * NUMBER.SIXTEY * NUMBER.ONE_THOUSAND));
        const hours = Math.floor((remainingTime % (NUMBER.TWENTY_FOUR * NUMBER.SIXTEY * NUMBER.SIXTEY * NUMBER.ONE_THOUSAND)) / (NUMBER.SIXTEY * NUMBER.SIXTEY * NUMBER.ONE_THOUSAND));
        return {day:days, hours:hours};
    }

    useEffect(() => {
        const updateTime = isFeatureValid();
        setRemainingTime({valid: updateTime?.valid, timeLeft:updateTime?.timeLeft});
        const intervalId = setInterval(() => {
            const updateTime = isFeatureValid();
            setRemainingTime({valid: updateTime?.valid, timeLeft:updateTime?.timeLeft});
        }, NUMBER.ONE_THOUSAND*NUMBER.SIXTEY*NUMBER.THIRTY);
        return () => clearInterval(intervalId);
    }, []);

    const location = useLocation();
    const dataProvider = useDataProvider();
    const [propayRecord, setPropayRecord] = useState({});

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const duplicate = params.get('duplicate');
        const getPorpayData = async () => {
            const data:any = await dataProvider.getOne('Propay__propayRecord', {id: Number(duplicate)});
            setPropayRecord(data?.data ? parsePropayRecord(data?.data) : {})
        }
        if(duplicate){
            getPorpayData();
        }
    }, [dataProvider, location]);

    return(
        <div className='create-propay-page'>
            <PageAlertContextProvider>
                <div className='propay-switch-head'>
                    <Typography variant='h2' className='main-title main-title-mobile create-propay-mbs'>Create ProPay</Typography>
                    {!identity?.company?.is_new_company && remainingTime?.valid &&
                        <div className='propay-switch-custom'>
                        <Stack direction="row" className="create-propay-switch-case" spacing={1}>
                            <div className='create-propay-switch-head'>
                                <span className='switch-head-icon'> {SWITCH_ICON()} </span>
                                Switch to older view
                                <InfoLabel className="remove-bonus-tooltip" sx={{ height: 20 }} icon="ri:error-warning-fill" height={20}>
                                    <Typography className="bonus-tooltip-cs">
                                        Explore the classic interface for a familiar experience. If you prefer the older view, use the toggle.
                                    </Typography>
                                </InfoLabel>
                            </div>
                            <FormGroup className="custom-switch">
                                <Switch checked={oldForm} onChange={(e) => newForm(e)} />
                            </FormGroup>
                        </Stack>
                        <div className='older-view-tagline'>Older view will expire {displayRemainingTime(remainingTime?.timeLeft)?.day > 1 && 'in'} {displayRemainingTime(remainingTime?.timeLeft)?.day === NUMBER.ONE ? 'Today' : displayRemainingTime(remainingTime?.timeLeft)?.day} {displayRemainingTime(remainingTime?.timeLeft)?.day > 1 && 'days'}
                            {/* {/ and {displayRemainingTime(remainingTime?.timeLeft)?.hours} hours /} */}
                        </div>
                        </div>
                    }
                </div>
                <ResourceContextProvider value='propays'>
                    <>
                        <ToastContainer />
                        {oldForm ? <PropayCreate redirect='/propay/propay' record={{...propayRecord, employee_wage_ids:[]}} duplicate={true} /> :
                            <PropayCreateNewForm redirect='/propay/propay' record={{...propayRecord, employee_wage_ids:[]}} duplicate={true} />}
                    </>
                </ResourceContextProvider>
            </PageAlertContextProvider>
        </div>
    );
};
export default PropayCreateTab;

export const CostCodeInput = (props: any) => {
    const { type, isRequired=false, label, setIsCreate, handleCostCodeChange} = props;
    const [perCostCodePage, setCostCodePerPage] = useState(10);
    return (
        <>
            <FormDataConsumer>
                {({ formData, getSource, ...rest }) => {
                    return (
                        <CustomReferenceArrayInputObj
                            perPage={perCostCodePage}
                            setPerPage={setCostCodePerPage}
                            source="cost_code_ids"
                            reference="Costcode__DropdownList"
                            label={label}
                            //validate={[isRequired && required()]}
                            filterToQuery={searchText => {
                                const cost_filter = type === 'unique' ? {code: {_ilike: searchText}, _and: {job_id: {_eq: formData.job_id}, _or: {job_id: {_is_null: true}}}} :
                                                                        {code: {_ilike: searchText},job_id: {_is_null: true}}
                                return(
                                    cost_filter
                                )
                            }
                            }
                            handleCostCodeChange={handleCostCodeChange}
                            setIsCreate={setIsCreate}
                            formData={formData}
                            formProps={props?.formProps}
                        >
                            <AutocompleteArrayInput
                                fullWidth
                                multiple={true}
                                optionText="code"
                                optionValue="id"
                            />
                        </CustomReferenceArrayInputObj>
                    );
                }}
            </FormDataConsumer>
        </>
    );
};


export const ProPayRequiredInfo = (props) =>{
  const {
    identity,
    setIsJobCreate,
    handleJobChange,
    formProps,
    jobSearch,
    setJobSearch,
    isJobCreate,
    isExpand,
    isRequired,
    handleManagerchange,
    validateManagerEmail,
    dialogRefs,
    setManagerData,
    managerData,
    translate,
    setIsMilestones,
    BudgetChoices,
    isMilestones,
    handleCostCodeChange,
    changeCostCode,
    setIsCreate,
    defaultCostCodeIds,
    hoursPlaceholder,
    validateTimeType,
    handleAssignManager,
    handleChangegroupMilestones,
    showGroupMiletones,
    connectorList,
    handleSecondJob,
    jobObj,
    jobData,
    setjobData,
    title,
  } = props;

  const [createmanager, setCreateManager] = useState(false);
  const [showBudgetHelp, setShowBudgetHelp] = useState(false);
  const [showMileBudgetHelp, setShowMileBudgetHelp] = useState(false);
  const record = useRecordContext()
  const dataProvider = useDataProvider();

  const handleCreateManagerOpen = () => {
    setCreateManager(true);
  }
  const handleCreateManagerClose = () => {
    setCreateManager(false);
  }
  const handlebuttonAddJob = () => {
    setIsJobCreate(true)
  }
  const [perManagerPage, setManagerPerPage] = useState(10);
  const [perJobPage, setJobPerPage] = useState(10);
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const isAspireConnected = connectorList?.some(item => item?.service === "aspire");
  const hierarchyFilter = isAspireConnected
  ? { is_milestone_candidate: { _eq: false },
    _and: {
      _or: {
        has_milestone_candidates: {
          _eq: true
        },
        previous_parent_id: {
          _is_null: false
        }
      }
    }}
  : { has_children: { _eq: false } };

  const firstJobFilter = (connectorList) => {
  if (connectorList.length > 1) {
      return {
      active: { _eq: true },
      ...hierarchyFilter,
      _and: {
          protiv_connection_id: { _is_null: true },
          _or: { protiv_connection_id: { _ilike: connectorList[0]?.id } },
      },
      };
  }

  return { active: { _eq: true }, ...hierarchyFilter };
  };

  const secondJobFilter = (connectorList) => {
  return connectorList.length > 1 
      ? { 
          active: { _eq: true },
          ...hierarchyFilter,
          _and: { 
              protiv_connection_id: { _is_null: true },
              _or: { protiv_connection_id: { _ilike: connectorList[1]?.id } }
          }
      } 
      : {};
  };

    const validateDuplicateJob = async (values: any, allValues: any) => {
        if(allValues?.job_id !== jobObj.current?.id && !title?.includes('Edit')){
            jobObj.current = {id:allValues?.job_id}
        const jobResp:any = values && await dataProvider.getList('Propay__DropdownList', { pagination: { page: 1, perPage: 2 },
        sort: { field: 'create_date', order: 'DESC' }, filter:{job_id:{_eq:allValues?.job_id}} })
                     .catch(error => {
                          console.log(`Failure! ${error}`);
                      })
        jobObj.current = jobResp?.data;
        if (jobResp?.total) {
            setjobData(jobResp);
            return undefined;
        }else{
            setjobData(null);
        }
        }
        return undefined;
    };

  return(
    <>
      {identity?.company?.show_job_page && <div className='tabs-sub-title'>Select a Job</div>}
        <Grid className="form-padding" item xs={12} sm={12} md={12} lg={12}>
                <Grid container spacing={0}>
                    <Grid className="create-propay-top-wrap" item xs={12}>
                    {jobData && jobData?.total ? <div className='gp-setting-alert job-add-alert'>
                    {ALERT_ERROR_ICON()} You're about to create an additional ProPay for the job selected.
                    </div>
                     :
                    <></>}
                        <StyledCard>
                            <Grid container>
                            <FormDataConsumer>
                                {({formData,}) => {
                                return(<>
                                {identity?.company?.show_job_page &&
                                    <Stack className="create-job-row">
                                        <CustomReferenceInputObjSelect
                                            setPerPage={setJobPerPage} perPage={perJobPage}
                                            showUnderAllJobs={identity?.company?.tsheets_status === 'connected' && identity?.company?.show_job_page}
                                            buttonText={
                                            !(identity?.company?.is_jobber_service_connected ||identity?.company?.allow_salesforce_api ||
                                                identity?.company?.allow_vericlock_api || identity?.company?.allow_dataverse)
                                              ? 'Add Job' : ''}
                                            id="job_id_selected"
                                            showFooter={true}
                                            source={(connectorList.length < NUMBER.TWO && formProps?.record?.second_job_id && !formProps?.record?.job_id) ? 'second_job_id':'job_id' }
                                            validate={connectorList.length > NUMBER.ONE && formData.second_job_id ? [validateDuplicateJob]: [required(), validateDuplicateJob]}
                                            reference="Job__DropdownList"
                                            className="job-create-propay"
                                            buttonOneClick={handlebuttonAddJob}
                                            filter={firstJobFilter(connectorList)}
                                            onValueChange={handleJobChange}
                                            formProps={formProps}
                                            newId={ props?.record?.job_id }
                                            placeholder={identity?.company?.allow_tsheet_duplicate_connector || connectorList.length > 1 ? (connectorList[0]?.name ||connectorList[0]?.service)+`${connectorList.length > NUMBER.ONE && formData.second_job_id ? '':' *'}`:'Job'}
                                        >
                                            {/** TODO: what about tsheet here? */}
                                            <JobNameInput fullWidth edit={<EditJob />}/>
                                        </CustomReferenceInputObjSelect>
                                    </Stack>
                                }
                                {(identity?.company?.allow_tsheet_duplicate_connector || connectorList.length > 1) && <Stack className="create-job-row" style={{marginTop:'20px'}}>
                                <CustomReferenceInputObjSelect
                                            setPerPage={setJobPerPage} perPage={perJobPage}
                                            showUnderAllJobs={false}
                                            buttonText={''}
                                            id="second_job_id_selected"
                                            showFooter={true}
                                            source="second_job_id"
                                            reference="Job__DropdownList"
                                            className="job-create-propay"
                                            filter = {secondJobFilter(connectorList)}
                                            onChange={(val)=>handleSecondJob(val,formProps?.form)}
                                            formProps={formProps}
                                            buttonOneClick={()=>{}}
                                            validate={formData.job_id ? []:required()}
                                            placeholder={connectorList.length > 1 ? (connectorList[1]?.name || connectorList[1]?.service)+`${formData.job_id ? '':' *'}` : 'Job 2'}
                                        >
                                            <JobNameInput fullWidth/>
                                        </CustomReferenceInputObjSelect>
                                        </Stack>}
                                    </>)}}
                                    </FormDataConsumer>
                                <JobCreateDialog
                                    open={ isJobCreate } title="Add Job" onCancel={() => setIsJobCreate(false)} formProps={ formProps }
                                    contentProps={{ maxHeight: 325, height: window.innerHeight / NUMBER.TWO,}} jobSearch={ jobSearch } setJobSearch={ setJobSearch }/>
                                <Stack className='create-job-child'>
                                    {!isExpand && (
                                        <>
                                        <Stack className={`hide-job-stack create-job-stack ${!identity?.company?.show_job_page ? 'hide-job-connection':''}`}>
                                            <Stack className="create-job-icon">
                                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15 10.5L20 15.5L15 20.5" stroke="#B8C0C6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4 4.5V11.5C4 12.5609 4.42143 13.5783 5.17157 14.3284C5.92172 15.0786 6.93913 15.5 8 15.5H20" stroke="#B8C0C6"
                                                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                            </Stack>
                                            <Stack className="create-job-stack-rt">
                                                <TextInput className="title-input-propay" source="name" label="ProPay Title" validate={isRequired} fullWidth />
                                            </Stack>
                                        </Stack>
                                        {record?.parent_id_obj?.is_group_propay ? <div className='gp-setting-alert'>
                                        {LOCK_ICON()} Controlled by the GroupProPay settings.
                                        </div>:''}
                                        </>
                                    )}

                        <Grid item xs={12} className={`${record?.parent_id_obj?.is_group_propay ? 'disabled-manager-grid':''}`}>
                        <CustomReferenceInputObjSelect forEmployee={true} searchPlaceholder='Search Manager' newId={ props?.record?.manager_id } hideLabel={false} setPerPage={setManagerPerPage} perPage={perManagerPage} onValueChange={handleManagerchange} source="manager_id" placeholder='Assign Manager *'
                            buttonOneClick={handleCreateManagerOpen} buttonTwoClick={handleAssignManager} showFooter={true} buttonText={'Create Manager'} buttonTextTwo={'Assign New Manager'}
                            reference="Employee__DropdownList" label='Assign Manager' onChange={(e)=>handleManagerchange(e)} className="manager-create-propay"
                            validate={[required(),validateManagerEmail]} filter={{ user_type: { _in: ['manager','admin',],},active: {_eq: true, }, }}
                        >
                            <AutocompleteInput fullWidth />
                        </CustomReferenceInputObjSelect>
                        {createmanager && <EmployeeCreateDialog resource='employees' {...props} userRole={USER_TYPE.manager} hideRoleInput={true} formProps={formProps} onClose={handleCreateManagerClose} buttonOptions={['Save']}/>}

                        <DialogForm title="Missing User Data" ref={dialogRefs} wageClass={'add-wage-dialog'} >
                            <EmployeeUpdateForm
                                onEditSuccess={data => { setManagerData(null); dialogRefs.current.close(); }}
                                handleClose={dialogRefs?.current?.close} isLoading={false} isDashboard={false}
                                emptyFields={[managerData]} subText='To notify the user please enter missing information'
                            />
                        </DialogForm>
                        <Grid item xs={12} className="no-wages-msg">
                        {(managerData && (!managerData?.data?.email || managerData?.data?.email==='false')) && (
                            <Typography sx={{color:'#ff4842',mt:1,fontSize:'0.75rem',}}>Manager email is required
                                <Typography component="span" className="wage-blue-text" onClick={() => {dialogRefs.current.open();}}
                                    sx={{ ml: 1, cursor: 'pointer', textDecoration: 'underline', fontSize: '0.75rem' }}
                                >
                                  Click here to add
                                </Typography>
                            </Typography>
                        )}
                        </Grid>
                    </Grid>
                </Stack>
                            <Grid item xs={12}>
                            {identity?.company?.show_job_page && identity?.company?.allow_salesforce_api && (
                                <FormDataConsumer>
                                {({ formData, ...rest }) => (
                                    formData.job_id ? (
                                    <ReferenceArrayInputObj
                                        source="contractor_item_ids"
                                        reference="contractItems"
                                        filter={{ job_id: { _eq: [formData.job_id] }, active: { _eq: true } }}
                                        label={translate('resources.propays.fields.contractor_item_ids')}
                                    >
                                        <AutocompleteArrayInput
                                        optionText={(record?: any) =>
                                            record?.id ? record.id === '@@ra-create' ? record.name : `${record.name} (${record.quantity})` : ''
                                        }
                                        fullWidth
                                        multiple={false}
                                        />
                                    </ReferenceArrayInputObj>
                                    ) : <></>
                                )}
                                </FormDataConsumer>
                            )}
                            </Grid>
                            <Grid item xs={12}>
                                    <Grid container>
                                    <Grid className="create-propay-budget" item xs={12}>
                                        <Stack direction="column">
                                        <Grid container sx={{ paddingTop: 1 }} >
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Stack direction="row">
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <div className="create-right-tooltip">
                                                        <div className="create-budget-label add-budget-stack">
                                                            <FieldTitle label="Add a Budget" />
                                                            {<Button className='budget-help-button' onClick={()=>(!isMilestones ? setShowBudgetHelp(true) : setShowMileBudgetHelp(true))}>Budget Help <img src={calculator_Frame_Icon} alt='calculator icon' /></Button>}
                                                        </div>
                                                        {/* <div className='budget-parent-buttons'>
                                                        <Button className='group-milestone-button' onClick={()=>handleChangegroupMilestones(true)}>Group Milestones</Button>
                                                        </div> */}
                                                                </div>
                                                            </Grid>
                                                        </Stack>

                                                          <div className="automation-radios bonus-split-radio-group">
                                                            <div className='budget-help-row'>

                                                            <FormDataConsumer>
                                                                  {({ formData }) => {
                                                                      return (
                                                                        <>
                                                                            {!isSmall ? <FormControl>
                                                                                <RadioGroup
                                                                                    aria-labelledby="calculate-budget-radio-label"
                                                                                    name="calculate-budget-radio-group"
                                                                                    defaultValue="Hours"
                                                                                    value={formData?.budget_option}
                                                                                    onChange={(e)=>formProps?.form?.change('budget_option',e?.target?.value)}
                                                                                >
                                                                                    {BudgetChoices?.map((choice) => (
                                                                                        <div className='add-budget-radio'>
                                                                                        <FormControlLabel value={choice?.id} control={<Radio />} label={choice?.name} />
                                                                                        {choice['subText'] && <div className='bonus-split-msg'>{choice['subText']}</div>}
                                                                                    </div>
                                                                                        ))}
                                                                                </RadioGroup>
                                                                            </FormControl> :
                                                                            <FormControl className='mui-select-responsive' fullWidth>
                                                                            <Select
                                                                                labelId="mui-custom-select-label"
                                                                                id="mui-custom-select"
                                                                                value={formData?.budget_option}
                                                                                label="false"
                                                                                onChange={(e)=>formProps?.form?.change('budget_option',e?.target?.value)}
                                                                            >
                                                                                <MenuItem value={'amount'}>
                                                                                    <div className='custom-select-label'>Amount</div>
                                                                                    <div className='custom-select-value'>Labor cost without burden</div>
                                                                                </MenuItem>
                                                                                <MenuItem value={'hours'}>
                                                                                    <div className='custom-select-label'>Hours</div>
                                                                                    <div className='custom-select-value'>Total budget hours</div>
                                                                                </MenuItem>
                                                                            </Select>
                                                                            </FormControl>}
                                                                        </>
                                                                      )}}
                                                                      </FormDataConsumer>
                                                              <FormDataConsumer>
                                                                  {({ formData }) => {
                                                                      return (<ShowWagesHtml formData={formData} />)
                                                                  }}
                                                              </FormDataConsumer>
                                                              </div>
                                                          </div>

                                                        {!isMilestones && <Grid className='code-amount-row' container spacing={2}>
                                                        <Grid className={`${!identity?.company?.can_use_cost_code ? 'no-cost-code-hide-propay':''}`} item md={6} sm={6} xs={12}>
                                                                {identity
                                                                    ?.company
                                                                    ?.can_use_cost_code && (
                                                                        <Stack className={`input-cost-code-propay create-job-stack ${isMilestones ? 'hide-job-connection' : ''}`}>
                                                                            <Stack className="create-job-stack-rt">
                                                                                <FormDataConsumer>
                                                                                    {({
                                                                                        formData,
                                                                                    }) => {
                                                                                        return (
                                                                                            <>
                                                                                                {!isMilestones && identity?.company?.can_use_cost_code && <>
                                                                                                    {changeCostCode ||
                                                                                                        formData?.can_create_unique_code ? (
                                                                                                        <CostCodeInput
                                                                                                            type="unique"
                                                                                                            //isRequired={identity?.company?.is_cost_code_required}
                                                                                                            defaultValue={
                                                                                                                defaultCostCodeIds
                                                                                                            }
                                                                                                            label={
                                                                                                                identity
                                                                                                                    ?.company
                                                                                                                    ?.cost_code_label
                                                                                                            }
                                                                                                            handleCostCodeChange={handleCostCodeChange}
                                                                                                            setIsCreate={setIsCreate}
                                                                                                            formProps={formProps}
                                                                                                        />
                                                                                                    ) : (
                                                                                                        <CostCodeInput
                                                                                                            type="generic"
                                                                                                            //isRequired={identity?.company?.is_cost_code_required}
                                                                                                            label={
                                                                                                                identity
                                                                                                                    ?.company
                                                                                                                    ?.cost_code_label
                                                                                                            }
                                                                                                            handleCostCodeChange={handleCostCodeChange}
                                                                                                            setIsCreate={setIsCreate}
                                                                                                            formProps={formProps}
                                                                                                        />
                                                                                                    )}
                                                                                                </>}
                                                                                            </>
                                                                                        );
                                                                                    }}
                                                                                </FormDataConsumer>
                                                                            </Stack>
                                                                        </Stack>
                                                                    )}
                                                        </Grid>

                                                        <Grid className='milestone-hour-mobile-space' item md={6} sm={6} xs={12}>
                                                            <Condition
                                                            when="budget_option"
                                                            is="hours"
                                                        >
                                                            <Stack>
                                                                {!isMilestones &&  <MaskedTimeInput
                                                                    className="money-pay-input-create mt-zero"
                                                                    fullWidth
                                                                    label={
                                                                        false
                                                                    }
                                                                    placeholder={hoursPlaceholder}
                                                                    source="budget_hours"
                                                                    validate={[
                                                                        required(),
                                                                        validateTimeType,
                                                                    ]}
                                                                    disabled={isMilestones}
                                                                />}
                                                            </Stack>
                                                        </Condition>
                                                        <Condition
                                                            when="budget_option"
                                                            is="amount"
                                                        >
                                                            {!isMilestones &&<>  <MoneyInput
                                                                label=""
                                                                fullWidth
                                                                className="money-pay-input-create mt-zero"
                                                                placeholder='Amount'
                                                                source="amount"
                                                                validate={required()}
                                                                disabled={isMilestones}
                                                            />
                                                            </>}
                                                        </Condition>
                                                            </Grid>

                                                        </Grid>}
                                                        <FormDataConsumer>
                                                        {({ formData, ...rest }) => (
                                                             <>
                                                                {!isMilestones &&
                                                                (isSmall ? <Dialog
                                                                onClose={()=>setShowBudgetHelp(false)}
                                                                aria-labelledby="integration-connect-title"
                                                                open={showBudgetHelp}
                                                                className='common-dialog-modal budget-help-modal'
                                                            >
                                                                <DialogContent dividers>
                                                                <PropayBudgetCalculate setShowBudgetHelp={setShowBudgetHelp} showBudgetHelp={showBudgetHelp} formProps={formProps} formData={formData}/>
                                                                </DialogContent>
                                                                </Dialog> :
                                                                    <PropayBudgetCalculate setShowBudgetHelp={setShowBudgetHelp} showBudgetHelp={showBudgetHelp} formProps={formProps} formData={formData}/>
                                                                )}
                                                            </>
                                                        )}
                                                        </FormDataConsumer>

                                                        <FormDataConsumer>
                                                        {({ formData, ...rest }) => (
                                                             <>
                                                            {isMilestones &&
                                                              (isSmall ? <Dialog
                                                              onClose={()=>setShowMileBudgetHelp(false)}
                                                              aria-labelledby="integration-connect-title"
                                                              open={showMileBudgetHelp}
                                                              className='common-dialog-modal budget-help-modal'
                                                          >
                                                              <DialogContent dividers>
                                                              <PropayBudgetCalculateForMilestones setShowBudgetHelp={setShowMileBudgetHelp} showBudgetHelp={showMileBudgetHelp} formProps={formProps} formData={formData}/>
                                                              </DialogContent>
                                                              </Dialog> :
                                                                <PropayBudgetCalculateForMilestones setShowBudgetHelp={setShowMileBudgetHelp} showBudgetHelp={showMileBudgetHelp} formProps={formProps} formData={formData}/>
                                                              )}
                                                            </>
                                                        )}
                                                        </FormDataConsumer>
                                                        {/* {showGroupMiletones && <PropayGroupingMilestones {...props}/>} */}

                                                    </Grid>
                                                </Grid>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </StyledCard>
                    </Grid>
                </Grid>
        </Grid>
    </>
  )
}
const CustomAssignManagerSearcInput = ({ className, source, label, size, alwaysOn, alwaysOnMobile, ...rest }) => {
    const [state, setState] = useState('');
    const { filterValues, setFilters, displayedFilters} = useListContext();

      useEffect(()=>{
        setState(filterValues?.name?._ilike ? filterValues?.name?._ilike : '');
        if(filterValues?.name?._ilike){
        setFilters({ ...filterValues, name: {_ilike:filterValues?.name?._ilike ? filterValues?.name?._ilike : ''}}, displayedFilters, true);
        }
      },[]);

      const handleIsActiveChange = (event) => {
        const isActive = event.target.value;
        setState(isActive);
        handleDebounce(isActive);
      };

      const handleDebounce = _.debounce((isActive) => {
        const newFilters = isActive
          ? { ...filterValues, name: { _ilike: isActive } }
          : { ...filterValues, name: { _ilike: '' } };
        setFilters(newFilters, displayedFilters);
      },NUMBER.ONE_THOUSAND);

  return (
    <div className='propay-filter-search'>
      <Input
        placeholder='Search Worker'
        id={source}
        name={source}
        className='propay-search'
        value={state} onChange={(e)=> handleIsActiveChange(e)}
        {...rest}
      />
    </div>
    );
  };

export const AssignManagerModal = (props) =>{
  const {open, handleClose, formProps} = props;
  const handleManagerAssign = (record) =>{
    const { form } = formProps;
    form.change('manager_id', record?.id);
    handleClose()
  }
  return(
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="bonus-pool-title"
          aria-describedby="bonus-pool-description"
          className='new-manager-modal'
      >

          <DialogTitle id="new-manager-modal-title">
              <Stack
                  flexDirection={'row'}
                  justifyContent={'space-between'}
              >
                Assign Manager Permission
                  <IconButton
                      sx={{ top: -2, paddingLeft: 2 }}
                      color="primary"
                      aria-label="New Manager Modal"
                      onClick={handleClose}
                  >
                      <CloseIcon />
                  </IconButton>
              </Stack>
          </DialogTitle>

          <DialogContent>
              <ResourceContextProvider value='employees'>
                  <div className='manager-resource-modal'>
                    <div className='manager-modal-sub-head'>Assigning New Manager will change user permissions from worker to manager</div>
                      <List
                          {...props}
                          filters={<ResponsiveFilterGusser filters={[<CustomAssignManagerSearcInput source="name._ilike" label="Search Worker" size="medium" className="filter-search" alwaysOn alwaysOnMobile />]} />}
                          filterDefaultValues={{ active: { _eq: true }, user_type: { _eq: 'worker' } }}
                          titleActionProps={{ showCreate: false }}
                          hasCreate={false}
                          disableSyncWithLocation={CONSTANT_DATA.true}
                          className="manager-modal-list"
                          actions={<ListActions exporter={false} />}
                      >
                          <div className='manager-modal-listing-scroll'>
                              <StyledDatagrid
                                  size="medium"
                                  bulkActionButtons={false}
                                  isRowSelectable={(record) => false}
                              >
                                  <EmployeeFullNameField
                                      source="name"
                                      sortBy={'name'}
                                      sortable={true}
                                      className='mobile-worker-name'
                                  />
                                  <FunctionField
                                      label="Actions"
                                      textAlign="center"
                                      sortable={false}
                                      render={(record: any) => (<Button onClick={()=>handleManagerAssign(record)} className='assign-manager-button'>Assign new manager</Button>)}
                                  />
                              </StyledDatagrid>
                          </div>
                      </List>
                  </div>
              </ResourceContextProvider>
          </DialogContent>
      </Dialog>
  )
}

const PropayBudgetCalculate = (props) => {
    const { showBudgetHelp, setShowBudgetHelp, formData, formProps } = props;
    const identity = useIdentityContext();
    const [costType, setCostType] = useState('by_hours')
    const [avgWageFrom, setAvgWageFrom] = useState('Company')
    const [hoursBudget, setHoursBudget] = useState({labourCost:'', avgWage:'', changeWage:false});
    const [LaborCostBudget, setLaborCostBudget] = useState({labourCost:'', burdenCost:''});
    const [JobRevenueBudget, setJobRevenueBudget] = useState({revenue:'', percentOfLabor:''});
    const dataProvide = useDataProvider();

    useEffect(()=>{
        if(avgWageFrom === 'Assigned' && !hoursBudget?.changeWage){
            let assignedAvgWage = 0;
            formData?.employee_wage_ids?.map((item)=>{
                return(
                    assignedAvgWage = assignedAvgWage+(item?.base_wage ? item?.base_wage : 0))
            })
            const avgWage = avgWageFrom === 'Assigned' ? ((assignedAvgWage/formData?.employee_wage_ids?.length)*1).toFixed(2) : identity?.company?.avg_wage;
            setHoursBudget((prev)=>({...prev,avgWage: !Number.isNaN(Number(avgWage)) ? avgWage : ''}));
        }
        if(avgWageFrom !== 'Assigned'){
            if(identity?.company_id){
            const findCompanyWage = async ()=>{
                const companyData = await dataProvide.getOne('Company',{id: identity?.company_id});
                setHoursBudget((prev)=>({...prev,avgWage: companyData?.data?.employee_avg_wage ? (companyData?.data?.employee_avg_wage*1).toFixed(2) : ''}))
                }
            findCompanyWage();
            }
        }
    },[avgWageFrom,identity?.company_id]);

    const addBudget = (type, budget) => {
        if(type==='hours'){
            formProps?.form?.change('budget_option','hours')
            formProps?.form?.change('budget_hours', budget);
            setShowBudgetHelp(false);
        }else{
            formProps?.form?.change('budget_option','amount')
            formProps?.form?.change('amount', budget);
            setShowBudgetHelp(false);
        }
    }

    const handleCostType = (e) =>{
        setCostType(e?.target?.value)
    }

    const handleAvgWageFrom = (e) =>{
        setAvgWageFrom(e?.target?.value);
        setHoursBudget((previous) => ({...previous,changeWage:false}));
    }

    const handleSetHoursBudget = (e, key) =>{
        if (/^\d*\.?\d{0,2}$/.test(e.target.value) || e.target.value === '') {
            const value = e?.target?.value;
            setHoursBudget((previous) => ({...previous, [key]: value ? value : '', changeWage:true}));
          }
    }
    const handleSetLaborCostBudget = (e, key) =>{
        if (/^\d*\.?\d{0,2}$/.test(e.target.value) || e.target.value === '') {
            const value = e?.target?.value;
            setLaborCostBudget((previous) => ({...previous, [key]: value ? value : ''}));
          }
    }
    const handleSetJobRevenueBudget = (e, key) =>{
        if (/^\d*\.?\d{0,2}$/.test(e.target.value) || e.target.value === '') {
            const value = e?.target?.value;
            setJobRevenueBudget((previous) => ({...previous, [key]: value ? value : ''}));
          }
    }

    const showHoursBudget = () => {
        if(hoursBudget?.labourCost && hoursBudget?.avgWage){
            if (identity?.company?.hours_format === 'by_time') {
                const hours = Number(hoursBudget?.labourCost) / Number(hoursBudget?.avgWage);
                const hh = Math.floor(hours);
                const mm = Math.round((hours - hh) * 60);
                const formattedTime = `${String(hh).padStart(2, '0')}:${String(mm).padStart(2, '0')}`;
                return formattedTime;
            } else {
                return (Number(hoursBudget?.labourCost) / Number(hoursBudget?.avgWage)).toFixed(2);
            }
        }else{
            return 0;
        }
    }

    const showlaborBudget = () => {
        if(LaborCostBudget?.labourCost && LaborCostBudget?.burdenCost){
            return (Number(LaborCostBudget?.labourCost)-(Number(LaborCostBudget?.labourCost)*(Number(LaborCostBudget?.burdenCost)/100))).toFixed(2)
        }else{
            return 0;
        }
    }

    const showRevenueBudget = () => {
        if(JobRevenueBudget?.revenue && JobRevenueBudget?.percentOfLabor){
            return (Number(JobRevenueBudget?.revenue)*(Number(JobRevenueBudget?.percentOfLabor)/100)).toFixed(2)
        }else{
            return 0;
        }
    }
    const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    return(
    <>
        {showBudgetHelp ?
        <div className='calculate-budget'>
            <div className='calculate-budget-head'>
                <img src={calculator_Frame_Icon} alt='calculator icon' className='calculator_frame_icon' />
                <div className='calculate-budget-title'>Budget Help</div>
                <div className='calculate-budget-Subtitle'>Simply enter your job values to calculate your budget. <a href='#' onClick={()=> window.open(PROPAY_BUDGET_HELP_LINK, '_blank')}>Learn More</a>
                </div>
                <Button className='close-calculate-budget' onClick={()=>setShowBudgetHelp(false)}>
                {CLOSE_ICON()}
                </Button>
            </div>
            <div className="automation-radios calculate-budget-radio-group">
                    {!isSmall ?<FormControl>
                        <RadioGroup
                            aria-labelledby="calculate-budget-radio-label"
                            name="calculate-budget-radio-group"
                            defaultValue="Hours"
                            value={costType}
                            onChange={(e)=>handleCostType(e)}
                        >
                            <div className='bonus-split-radio'>
                                <FormControlLabel value="by_hours" control={<Radio />} label="Hours" />
                            </div>
                            <div className='bonus-split-radio'>
                                <FormControlLabel value="by_wage" control={<Radio />} label="Labor Cost" />
                            </div>
                            <div className='bonus-split-radio'>
                                <FormControlLabel value="by_percentage" control={<Radio />} label="Contract Price" />
                            </div>
                        </RadioGroup>
                    </FormControl>:
                    <FormControl className='mui-select-responsive' fullWidth>
                    <Select
                    labelId="mui-custom-select-label"
                    id="mui-custom-select"
                    value={costType}
                    onChange={(e)=>handleCostType(e)}
                    label="false"
                     >
                    <MenuItem value={'by_hours'}>
                        <div className='custom-select-label'>Hours</div>
                    </MenuItem>
                    <MenuItem value={'by_wage'}>
                        <div className='custom-select-label'>Labor Cost</div>
                    </MenuItem>
                    <MenuItem value={'by_percentage'}>
                        <div className='custom-select-label'>Contract Price</div>
                    </MenuItem>
                </Select>
                </FormControl>
                    }

                </div>

            <div className='calculate-hour-box'>
                {costType === 'by_hours' && <div className='calculate-box-tab-content'>
                    <div className='calculate-hour-box-row'>
                        <div className='calculate-hour-label'>
                            Labor Budget <InfoLabel sx={{ height: 20, }} icon="ri:error-warning-fill" height={20}>
                                <Typography>
                                Total labor of a job without any burden cost.
                                </Typography>
                            </InfoLabel>
                        </div>

                        <FormControl variant="outlined">
                            <OutlinedInput
                                id="outlined-adornment-weight"
                                onChange={(e)=>handleSetHoursBudget(e, 'labourCost')}
                                value={hoursBudget?.labourCost}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                            />
                        </FormControl>
                    </div>

                    <div className='calculate-hour-box-row avg-wage-budget-row'>
                    <div className='calculate-hour-box-row'>
                        <div className='calculate-hour-label'>
                            Avg. Wage. <InfoLabel sx={{ height: 20, }} icon="ri:error-warning-fill" height={20}>
                                <Typography>
                                Average hourly wage of either the current workers on the selected ProPay or the entire company.
                                </Typography>
                            </InfoLabel>
                            </div>
                            <FormControl variant="outlined">
                            <OutlinedInput
                                id="outlined-burden"
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                aria-describedby="outlined-weight-burden"
                                onChange={(e)=>handleSetHoursBudget(e, 'avgWage')}
                                value={hoursBudget?.avgWage}
                                inputProps={{
                                    'aria-label': 'Burden',
                                }}
                            />
                        </FormControl>
                        </div>

                        <div className="automation-radios avg-wage-automation-radios">
                            {!isSmall ? <FormControl>
                                <RadioGroup
                                    aria-labelledby="calculate-budget-avg-label"
                                    name="calculate-avg-radio-group"
                                    value={avgWageFrom}
                                    onChange={(e)=>handleAvgWageFrom(e)}
                                >
                                    <div className='bonus-split-radio'>
                                        <FormControlLabel value="Company" control={<Radio />} label="Company" />
                                    </div>
                                    <div className='bonus-split-radio'>
                                        <FormControlLabel value="Assigned" control={<Radio />} label="Assigned" />
                                    </div>
                                </RadioGroup>
                            </FormControl> :
                            <FormControl className='mui-select-responsive' fullWidth>
                            <Select
                            labelId="mui-custom-select-label"
                            id="mui-custom-select"
                            value={avgWageFrom}
                            onChange={(e)=>handleAvgWageFrom(e)}
                            label="false"
                             >
                            <MenuItem value={'Company'}>
                                <div className='custom-select-label'>Company</div>
                            </MenuItem>
                            <MenuItem value={'Assigned'}>
                                <div className='custom-select-label'>Assigned</div>
                            </MenuItem>
                        </Select>
                        </FormControl>
                            }
                        </div>

                    </div>

                    <div className='calculate-hour-box-row calculate-budget-amount-row calculate-hour-border-line'>
                        <div className='calculate-hour-label'>
                        Budget Hours <InfoLabel sx={{ height: 20, }} icon="ri:error-warning-fill" height={20}>
                                <Typography>
                                We are dividing the Labor Budget and the Avg. Wage to equal the total budget hours of the job.
                                </Typography>
                            </InfoLabel>
                        </div>
                        <div className='budget-amount-right'>
                        <strong className='no-translate'>{showHoursBudget()} Hours</strong>
                        <Button className='add-amount-milestone-btn' disabled={!showHoursBudget() || (identity?.company?.hours_format !== 'by_time' && Number.isNaN(Number(showHoursBudget())))}
                        onClick={()=>addBudget('hours', identity?.company?.hours_format !== 'by_time' ? Number(showHoursBudget()): showHoursBudget())}>Add budget</Button>
                        </div>
                    </div>
                </div>}

                <div className='calculate-box-tab-content'>
                {costType === 'by_wage' &&<>
                <div className='calculate-hour-box-row'>
                    <div className='calculate-hour-label'>
                        Labor Cost <InfoLabel sx={{ height: 20, }} icon="ri:error-warning-fill" height={20}>
                            <Typography>
                            Labor Cost is your worker wages and burden cost of a job.
                            </Typography>
                        </InfoLabel>
                    </div>

                    <FormControl variant="outlined">
                        <OutlinedInput
                            id="outlined-adornment-labor-cost"
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            aria-describedby="outlined-weight-labor-cost"
                            onChange={(e)=>handleSetLaborCostBudget(e, 'labourCost')}
                            value={LaborCostBudget?.labourCost}
                            inputProps={{
                                'aria-label': 'Labor cost',
                            }}
                        />
                    </FormControl>
                </div>

                <div className='calculate-hour-box-row'>
                    <div className='calculate-hour-label'>
                            Burden % <InfoLabel sx={{ height: 20, }} icon="ri:error-warning-fill" height={20}>
                            <Typography>
                            Is the percentage of burden used of the total Labor Cost. Burden is the additional cost to labor outside of direct wages.
                            </Typography>
                        </InfoLabel>
                    </div>

                    <FormControl variant="outlined">
                        <OutlinedInput
                            id="outlined-burden"
                            endAdornment={<InputAdornment position="end">%</InputAdornment>}
                            aria-describedby="outlined-weight-burden"
                            onChange={(e)=>handleSetLaborCostBudget(e, 'burdenCost')}
                            value={LaborCostBudget?.burdenCost}
                            inputProps={{
                                'aria-label': 'Burden',
                            }}
                        />
                    </FormControl>
                </div>
                <div className='calculate-hour-box-row calculate-budget-amount-row calculate-hour-border-line'>
                    <div className='calculate-hour-label'>
                    Budget Amount <InfoLabel sx={{ height: 20, }} icon="ri:error-warning-fill" height={20}>
                            <Typography>
                            Labor Cost - Burden Cost = Budget Amount
                            </Typography>
                        </InfoLabel>
                        <div className='calculate-hour-sub-text'>Labor Budget without Burden</div>
                    </div>
                    <div className='budget-amount-right'>
                    <strong className='no-translate'>${showlaborBudget()}</strong>
                    <Button className='add-amount-milestone-btn' disabled={!showlaborBudget() || Number.isNaN(Number(showlaborBudget()))} onClick={()=>addBudget('amount', Number(showlaborBudget()))}>Add budget</Button>
                    </div>
                </div>

                </>}

                {costType === 'by_percentage' && <>
                <div className='calculate-hour-box-row'>
                    <div className='calculate-hour-label'>
                        Contract Price <InfoLabel sx={{ height: 20, }} icon="ri:error-warning-fill" height={20}>
                            <Typography>
                            Contract Price  is the contract value that a customer pays for the job.
                            </Typography>
                        </InfoLabel>
                    </div>

                    <FormControl variant="outlined">
                        <OutlinedInput
                            id="outlined-adornment-labor-cost"
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            aria-describedby="outlined-weight-labor-cost"
                            onChange={(e)=>handleSetJobRevenueBudget(e, 'revenue')}
                            value={JobRevenueBudget?.revenue}
                            inputProps={{
                                'aria-label': 'Labor cost',
                            }}
                        />
                    </FormControl>
                </div>

                <div className='calculate-hour-box-row'>
                    <div className='calculate-hour-label'>
                            % of Labor <InfoLabel sx={{ height: 20, }} icon="ri:error-warning-fill" height={20}>
                            <Typography>
                            Percentage of labor is used to identify the percentage of the Contract Price that is allocated to just direct wages for workers.
                            </Typography>
                        </InfoLabel>
                    </div>

                    <FormControl variant="outlined">
                        <OutlinedInput
                            id="outlined-burden"
                            endAdornment={<InputAdornment position="end">%</InputAdornment>}
                            aria-describedby="outlined-weight-burden"
                            onChange={(e)=>handleSetJobRevenueBudget(e, 'percentOfLabor')}
                            value={JobRevenueBudget?.percentOfLabor}
                            inputProps={{
                                'aria-label': 'Burden',
                            }}
                        />
                    </FormControl>
                </div>
                <div className='calculate-hour-box-row calculate-budget-amount-row calculate-hour-border-line'>
                    <div className='calculate-hour-label'>
                    Budget Amount <InfoLabel sx={{ height: 20, }} icon="ri:error-warning-fill" height={20}>
                            <Typography>
                            Contract Price x Budget Amount % = Budget Amount
                            </Typography>
                        </InfoLabel>
                    </div>
                    <div className='budget-amount-right'>
                    <strong className='no-translate'>${showRevenueBudget()}</strong>
                    <Button className='add-amount-milestone-btn' disabled={!showRevenueBudget() || Number.isNaN(Number(showRevenueBudget()))} onClick={()=>addBudget('amount', Number(showRevenueBudget()))}>Add budget</Button>
                    </div>
                </div>
                </>}
                </div>

            </div>
        </div>
        :
        <></>}
    </>
    );
}
const HeaderForHoursBudget = ({costType}) => {
    if(costType === 'by_hours'){
    return (
            <TableRow>
                <TableCell>Milestone Name</TableCell>
                <TableCell>Labor Budget</TableCell>
                <TableCell>Avg. Wage</TableCell>
                <TableCell colSpan={2}>Budget Hours</TableCell>
            </TableRow>
        );
    }
    if(costType === 'by_wage'){
        return (
                <TableRow>
                    <TableCell>Milestone Name</TableCell>
                    <TableCell>Labor Cost</TableCell>
                    <TableCell>Burden %</TableCell>
                    <TableCell colSpan={2}>Budget Amount</TableCell>
                </TableRow>
            );
    }
    if(costType === 'by_percentage'){
        return (
                <TableRow>
                    <TableCell>Milestone Name</TableCell>
                    <TableCell>Contract Price</TableCell>
                    <TableCell>% of Labor</TableCell>
                    <TableCell colSpan={2}>Budget Amount</TableCell>
                </TableRow>
            );
    }
};

const PropayBudgetCalculateForMilestones = (props) => {
    const { showBudgetHelp, setShowBudgetHelp, formProps, formData } = props;
    const [costType, setCostType] = useState('by_hours');
    const identity = useIdentityContext();
    const dataProvide = useDataProvider();
    const [bonuspoolModalOpen, setBonuspoolModalOpen] = useState(false);
    const [budgetHelpData, setBudgetHelpData] = useState({data:[], type:''});
    const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const [avgWageFrom, setAvgWageFrom] = useState({from:'Company', wage:''});
    const [burdenPerForAll, setBurdenForAll] = useState('');

    const handleBurdenPercentChange = (e) =>{
        const value = e?.target?.value;
        if (/^\d*\.?\d{0,2}$/.test(value) || value === '') {
            setBurdenForAll(value ? value : '');
            formProps.form.change('milestone_by_wage', formData?.milestone_by_wage?.length ? formData?.milestone_by_wage?.map((itme)=>({...itme, burden_per: value ? value : ''})) : [])
          }
        }


    useEffect(()=>{
        if(avgWageFrom.from === 'Assigned'){
            let assignedAvgWage = 0;
            formData?.employee_wage_ids?.map((item)=>{
                return(
                    assignedAvgWage = assignedAvgWage+(item?.base_wage ? item?.base_wage : 0))
            })
            const avgWage = avgWageFrom.from === 'Assigned' ? ((assignedAvgWage/formData?.employee_wage_ids?.length)*1).toFixed(2) : identity?.company?.avg_wage;
            formProps.form.change('milestone_by_hours', formData?.milestone_by_hours?.length ? formData?.milestone_by_hours?.map((itme)=>({...itme, average_wage:!Number.isNaN(Number(avgWage)) ? avgWage : ''})) : [])
            setAvgWageFrom((prev)=>({...prev,wage: !Number.isNaN(Number(avgWage)) ? avgWage : ''}));
        }
        if(avgWageFrom.from !== 'Assigned'){
            if(identity?.company_id){
            const findCompanyWage = async ()=>{
                const companyData = await dataProvide.getOne('Company',{id: identity?.company_id});
                formProps.form.change('milestone_by_hours', formData?.milestone_by_hours?.length ? formData?.milestone_by_hours?.map((itme)=>({...itme, average_wage:companyData?.data?.employee_avg_wage ? (companyData?.data?.employee_avg_wage*1).toFixed(2) : ''})) : [])
                setAvgWageFrom((prev)=>({...prev,wage: companyData?.data?.employee_avg_wage ? (companyData?.data?.employee_avg_wage*1).toFixed(2) : ''}))
                }
                findCompanyWage();
            }
        }
    },[avgWageFrom.from,identity?.company_id]);

    const handleAvgWageFrom = (e) =>{
        setAvgWageFrom((prev)=>({...prev,from:e?.target?.value}));
    }

    const handleCostType = (e) =>{
        setCostType(e?.target?.value)
    }
    const addMilestones = (type,data) =>{
        if(type === formData?.budget_option){
            formProps?.form?.change('milestone_ids',formData?.milestone_ids?.length ? [...formData?.milestone_ids?.filter((item)=>item?.name||item[formData?.budget_option] ||item?.cost_code_ids?.length), ...data]:data)
            formProps?.form?.change('milestone_by_hours',[])
            formProps?.form?.change('milestone_by_wage',[])
            formProps?.form?.change('milestone_by_percentage',[])
            setShowBudgetHelp(false);
        }else{
            if(formData?.milestone_ids?.filter((item)=>item?.name||item[formData?.budget_option] || item?.cost_code_ids?.length)?.length){
                handleClickOpen();
                setBudgetHelpData({data:data, type:type});
            }else{
                formProps?.form?.change('milestone_ids',data)
                formProps?.form?.change('budget_option',type)
                formProps?.form?.change('milestone_by_hours',[])
                formProps?.form?.change('milestone_by_wage',[])
                formProps?.form?.change('milestone_by_percentage',[])
                setShowBudgetHelp(false);
            }
        }
    }

    const showHoursBudget = (labourCost, avgWage) => {
        if(labourCost && avgWage){
            if (identity?.company?.hours_format === 'by_time') {
                const hours = Number(labourCost) / Number(avgWage);
                const hh = Math.floor(hours);
                const mm = Math.round((hours - hh) * 60);
                const formattedTime = `${String(hh).padStart(2, '0')}:${String(mm).padStart(2, '0')}`;
                return formattedTime;
            } else {
                return (Number(labourCost) / Number(avgWage)).toFixed(2);
            }
        }else{
            return identity?.company?.hours_format === 'by_time' ? '00:00' : 0;
        }
    }

    const showlaborBudget = (labourCost,burdenCost) => {
        if(labourCost && burdenCost){
            return (Number(labourCost)-(Number(labourCost)*(Number(burdenCost)/100))).toFixed(2)
        }else{
            return 0;
        }
    }

    const showRevenueBudget = (revenue,percentOfLabor) => {
        if(revenue && percentOfLabor){
            return (Number(revenue)*(Number(percentOfLabor)/100)).toFixed(2)
        }else{
            return 0;
        }
    }
    const areAllValuesByHourPresent = (array) => {
        if(array?.length){
      for (const item of array) {
          if (!item || !item?.name || !item?.labour_budget || !item?.average_wage) {
            return false;
          }
        }
        return true;
    }else{
            return false
        }
    }

    const areAllValuesBywagePresent = (array) => {
        if(array?.length){
    for (const item of array) {
        if (!item || !item?.name || !item?.labour_cost || !item?.burden_per) {
        return false;
        }
    }
        return true;
    }else{
            return false
        }
    }

    const areAllValuesByPercentagePresent = (array) => {
        if(array?.length){
        for (const item of array) {
            if (!item || !item?.name || !item?.job_revenue || !item?.per_of_labor) {
            return false;
            }
        }
        return true;
    }else{
        return false
    }
    }
    const handleClickOpen = () => {
        setBonuspoolModalOpen(true);
    };
    const handleCloseModal = () => {
        setBonuspoolModalOpen(false);
    };

    const addAsAmount = () => {
        setBonuspoolModalOpen(false);
        formProps?.form?.change('milestone_ids',budgetHelpData?.data)
        formProps?.form?.change('budget_option',budgetHelpData?.type)
        formProps?.form?.change('milestone_by_hours',[])
        formProps?.form?.change('milestone_by_wage',[])
        formProps?.form?.change('milestone_by_percentage',[])
        setShowBudgetHelp(false);

    };
    const changeOldIntoHours = () => {
        setBonuspoolModalOpen(false);
        formProps?.form?.change('milestone_ids',[...formData?.milestone_ids?.filter((item)=>item?.name||item[formData?.budget_option] ||item?.cost_code_ids?.length), ...budgetHelpData?.data])
        formProps?.form?.change('budget_option',budgetHelpData?.type)
        formProps?.form?.change('milestone_by_hours',[])
        formProps?.form?.change('milestone_by_wage',[])
        formProps?.form?.change('milestone_by_percentage',[])
        setShowBudgetHelp(false);
    };

    const showText = ()=>budgetHelpData?.type === 'hours' ? 'hours':'amount';
    const showTextNew = ()=>budgetHelpData?.type === 'hours' ? 'amount':'hours';

    return(
    <>
    <Dialog
        open={bonuspoolModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="create-milestone-title"
        aria-describedby="create-milestone-description"
        className='create-milestone-modal'
    >
        <DialogTitle id="bonus-pool-title">
            <Stack flexDirection={'row'} justifyContent={'space-between'}> Milestones
                <IconButton sx={{ top: -2, paddingLeft: 2 }} color="primary" aria-label="Create Milestone Remove" onClick={handleCloseModal}>
                    <CloseIcon />
                </IconButton>
            </Stack>
        </DialogTitle>

        <DialogContent>
            <div className='milestone-modal-text'>
            The milestones that you previously added are set on {showTextNew()} and the new milestone is on {showText()}.
            </div>
            <div className='milestone-modal-text milestone-bold-modal-text'>
            Do you want to change the previous milestones into {showText()} or add the new as {budgetHelpData?.type === 'hours' ? 'hours' :'an amount'}?
            </div>
        </DialogContent>
        <DialogActions className='button-justified'>
            <Button onClick={()=> handleCloseModal()} className='cancel-button'>Cancel</Button>
            <div className='milestone-modal-btn-right'>
            <Button onClick={()=>addAsAmount()} className='update-button'>Add as {budgetHelpData?.type === 'hours' ? 'Hours':'an Amount'} </Button>
            <Button onClick={()=>changeOldIntoHours()} className='update-button'>Change Old Into {budgetHelpData?.type === 'hours' ? 'Hours':'Amount'}</Button>
            </div>
        </DialogActions>

    </Dialog>
        {showBudgetHelp ?
        <div className='calculate-budget'>
            <div className='calculate-budget-head'>
                <img src={calculator_Frame_Icon} alt='calculator icon' className='calculator_frame_icon' />
                <div className='calculate-budget-title'>Budget Help</div>
                <div className='calculate-budget-Subtitle'>Simply enter your job values to calculate your budget. <a href='#' onClick={()=> window.open(PROPAY_BUDGET_HELP_LINK, '_blank')}>Learn More</a>
                </div>
                <Button className='close-calculate-budget' onClick={()=>{
                    setShowBudgetHelp(false);
                    formProps?.form?.change('milestone_by_hours',[])
                    formProps?.form?.change('milestone_by_wage',[])
                    formProps?.form?.change('milestone_by_percentage',[])
                    }}>
                {CLOSE_ICON()}
                </Button>
            </div>
            <div className="automation-radios calculate-budget-radio-group">
                    {!isSmall ?<FormControl>
                        <RadioGroup
                            aria-labelledby="calculate-budget-radio-label"
                            name="calculate-budget-radio-group"
                            defaultValue="Hours"
                            value={costType}
                            onChange={(e)=>handleCostType(e)}
                        >
                            <div className='bonus-split-radio'>
                                <FormControlLabel value="by_hours" control={<Radio />} label="Hours" />
                            </div>
                            <div className='bonus-split-radio'>
                                <FormControlLabel value="by_wage" control={<Radio />} label="Labor Cost" />
                            </div>
                            <div className='bonus-split-radio'>
                                <FormControlLabel value="by_percentage" control={<Radio />} label="Contract Price" />
                            </div>
                        </RadioGroup>
                    </FormControl>:
                    <FormControl className='mui-select-responsive' fullWidth>
                    <Select
                    labelId="mui-custom-select-label"
                    id="mui-custom-select"
                    value={costType}
                    onChange={(e)=>handleCostType(e)}
                    label="false"
                     >
                    <MenuItem value={'by_hours'}>
                        <div className='custom-select-label'>Hours</div>
                    </MenuItem>
                    <MenuItem value={'by_wage'}>
                        <div className='custom-select-label'>Labor Cost</div>
                    </MenuItem>
                    <MenuItem value={'by_percentage'}>
                        <div className='custom-select-label'>Contract Price</div>
                    </MenuItem>
                </Select>
                </FormControl>
                    }

                </div>


                    {costType === 'by_hours' && <div className='milestone-avg-wage-row'>
                        <div className='milestone-avg-wage-label'>
                            Avg. Wage. <InfoLabel sx={{ height: 20, }} icon="ri:error-warning-fill" height={20}>
                                <Typography>
                                Average hourly wage of either the current workers on the selected ProPay or the entire company.
                                </Typography>
                            </InfoLabel>
                        </div>

                        <div className="automation-radios avg-wage-automation-radios avg-wage-milestone-row">
                            {!isSmall ? <FormControl>
                                <RadioGroup
                                    aria-labelledby="calculate-budget-avg-label"
                                    name="calculate-avg-radio-group"
                                value={avgWageFrom.from}
                                onChange={(e)=>handleAvgWageFrom(e)}
                                >
                                    <div className='bonus-split-radio'>
                                        <FormControlLabel value="Company" control={<Radio />} label="Company" />
                                    </div>
                                    <div className='bonus-split-radio'>
                                        <FormControlLabel value="Assigned" control={<Radio />} label="Assigned" />
                                    </div>
                                </RadioGroup>
                            </FormControl> :
                                <FormControl className='mui-select-responsive' fullWidth>
                                    <Select
                                        labelId="mui-custom-select-label"
                                        id="mui-custom-select"
                                        value={avgWageFrom.from}
                                        onChange={(e)=>handleAvgWageFrom(e)}
                                        label="false"
                                    >
                                        <MenuItem value={'Company'}>
                                            <div className='custom-select-label'>Company</div>
                                        </MenuItem>
                                        <MenuItem value={'Assigned'}>
                                            <div className='custom-select-label'>Assigned</div>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            }
                        </div>
                    </div>}

                    {costType === 'by_wage' && <div className='milestone-avg-wage-row'>
                        <div className="automation-radios avg-wage-automation-radios avg-wage-milestone-row">
                        <div className='milestone-avg-wage-label milestone-burden-wage-label'>
                            Burden: <InfoLabel sx={{ height: 20, }} icon="ri:error-warning-fill" height={20}>
                                <Typography>
                                Burden.
                                </Typography>
                            </InfoLabel>
                        </div>
                            <FormControl variant="outlined" className='avg-wage-input-right burden-wage-input-right'>
                                <OutlinedInput
                                    id="outlined-adornment-weight"
                                    onChange={(e)=>handleBurdenPercentChange(e)}
                                    value={burdenPerForAll}
                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                />
                            </FormControl>
                        </div>
                    </div>}
                <div className='milestone-budget-table'>
                                {costType === 'by_hours' && <FormDataConsumer>
                                    {({ formData, getSource, scopedFormData, ...rest }) => {
                                        return (
                                            <>
                                                <ArrayInput source="milestone_by_hours" label={false}>
                                                    <CustomStyledMilstoneFormIterator addValue={{avgWage:avgWageFrom.wage, allowAdd:true, source:'average_wage'}} disableReordering headerHtml={<HeaderForHoursBudget costType={costType}/>}>
                                                        <TextInput fullWidth variant="outlined" source="name" data-title='Milestone Name' className='budget-milestone-name' label={false} size="small" validate={[validateMilestoneName,required()]}/>
                                                        <NumberInput
                                                        step={ 0.01 } fullWidth variant="outlined" source="labour_budget" data-title='Labor Budget'
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position='start'>
                                                                    $
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                         label={false} size="small"
                                                         />
                                                        <NumberInput
                                                            step={ 0.01 } fullWidth variant="outlined" source="average_wage" data-title='Avg. Wage' label={false} size="small"
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position='start'>
                                                                        $
                                                                    </InputAdornment>
                                                                ),
                                                            }} />
                                                        <FormDataConsumer>
                                                        {({ formData, getSource, scopedFormData, ...rest }) => {
                                                        return (
                                                            <Typography className='no-translate' variant="caption" data-title='Budget Hours'>{showHoursBudget(scopedFormData?.labour_budget,scopedFormData?.average_wage)}</Typography>
                                                            );
                                                        }}
                                                        </FormDataConsumer>
                                                    </CustomStyledMilstoneFormIterator>
                                                </ArrayInput>
                                                <TableFooter>
                                                    <TableCell colSpan={5}>
                                                        <Button
                                                        disabled={!areAllValuesByHourPresent(formData?.milestone_by_hours) || !formData?.milestone_by_hours?.length }
                                                        className='budget-milestone-add-btn'
                                                        onClick={()=>addMilestones('hours',formData?.milestone_by_hours?.map((item)=>({name:item?.name, budget_hours:showHoursBudget(item?.labour_budget,item?.average_wage)})))}
                                                        >Create Milestones
                                                        </Button>
                                                    </TableCell>
                                                </TableFooter>
                                            </>
                                        );
                                    }}
                                </FormDataConsumer>}
                                {costType === 'by_wage' && <FormDataConsumer>
                                    {({ formData, getSource, scopedFormData, ...rest }) => {
                                        return (
                                            <>
                                                <ArrayInput source="milestone_by_wage" label={false}>
                                                    <CustomStyledMilstoneFormIterator addValue={{avgWage:burdenPerForAll, allowAdd:true, source:'burden_per'}} disableReordering headerHtml={<HeaderForHoursBudget costType={costType}/>}>
                                                        <TextInput fullWidth variant="outlined" className='budget-milestone-name' source="name" label={false} data-title='Milestone Name' size="small" validate={[validateMilestoneName,required()]}/>
                                                        <NumberInput step={ 0.01 } fullWidth variant="outlined" source="labour_cost" data-title='Labor Cost' label={false} size="small"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position='start'>
                                                                    $
                                                                </InputAdornment>
                                                            ),
                                                        }} />
                                                        <FormDataConsumer>
                                                        {({ scopedFormData }) => {
                                                            return (<>{scopedFormData?.burden_per &&
                                                            <Typography variant="caption" className='labour-cost-budget-amount burden-percent-text' data-title='Burden %'>
                                                            {scopedFormData?.burden_per} %
                                                            </Typography>}
                                                            </>
                                                          )
                                                        }}
                                                        </FormDataConsumer>
                                                        <FormDataConsumer>
                                                        {({ formData, getSource, scopedFormData, ...rest }) => {
                                                        return (
                                                            <Typography variant="caption" data-title='Budget Amount' className='labour-cost-budget-amount no-translate'>${showlaborBudget(scopedFormData?.labour_cost,scopedFormData?.burden_per)}</Typography>
                                                            );
                                                        }}
                                                        </FormDataConsumer>

                                                    </CustomStyledMilstoneFormIterator>
                                                </ArrayInput>
                                                <TableFooter>
                                                    <TableCell colSpan={5}>
                                                        <Button disabled={!areAllValuesBywagePresent(formData?.milestone_by_wage) || !formData?.milestone_by_wage?.length }
                                                        className='budget-milestone-add-btn'
                                                        onClick={()=>addMilestones('amount',formData?.milestone_by_wage?.map((item)=>({name:item?.name, amount:showlaborBudget(item?.labour_cost,item?.burden_per)})))}
                                                        >Create Milestones</Button>
                                                    </TableCell>
                                                </TableFooter>
                                            </>
                                        );
                                    }}
                                </FormDataConsumer>}
                                {costType === 'by_percentage' && <FormDataConsumer>
                                    {({ formData, getSource, scopedFormData, ...rest }) => {
                                        return (
                                            <>
                                                <ArrayInput source="milestone_by_percentage" label={false}>
                                                    <CustomStyledMilstoneFormIterator disableReordering headerHtml={<HeaderForHoursBudget costType={costType}/>}>
                                                        <TextInput fullWidth variant="outlined" source="name" label={false} size="small"
                                                        className='budget-milestone-name' data-title='Milestone Name' validate={[validateMilestoneName,required()]}/>
                                                        <NumberInput step={ 0.01 } fullWidth variant="outlined" source="job_revenue" data-title='Labor Budget' label={false} size="small"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position='start'>
                                                                    $
                                                                </InputAdornment>
                                                            ),
                                                        }} />
                                                        <NumberInput step={ 0.01 } fullWidth variant="outlined" source="per_of_labor" data-title='% of Labor' label={false} size="small"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position='start'>
                                                                    %
                                                                </InputAdornment>
                                                            ),
                                                        }} />
                                                        <FormDataConsumer>
                                                        {({ formData, getSource, scopedFormData, ...rest }) => {
                                                        return (
                                                            <Typography variant="caption" className='labour-cost-budget-amount no-translate' data-title='Budget Amount'>${showRevenueBudget(scopedFormData?.job_revenue,scopedFormData?.per_of_labor)}</Typography>
                                                            );
                                                        }}
                                                        </FormDataConsumer>
                                                    </CustomStyledMilstoneFormIterator>
                                                </ArrayInput>
                                                <TableFooter>
                                                    <TableCell colSpan={5}>
                                                        <Button disabled={!areAllValuesByPercentagePresent(formData?.milestone_by_percentage) || !formData?.milestone_by_percentage?.length }
                                                        className='budget-milestone-add-btn'
                                                        onClick={()=>addMilestones('amount',formData?.milestone_by_percentage?.map((item)=>({name:item?.name, amount:showRevenueBudget(item?.job_revenue,item?.per_of_labor)})))}
                                                        >Create Milestones</Button>
                                                    </TableCell>
                                                </TableFooter>
                                            </>
                                        );
                                    }}
                                </FormDataConsumer>}
                </div>
        </div>
        :
        <></>}
    </>
    );
}

const PropayGroupingMilestones = (props) => {
    const {
        identity,
        setIsJobCreate,
        handleJobChange,
        formProps,
        jobSearch,
        setJobSearch,
        isJobCreate,
        isExpand,
        isRequired,
        handleManagerchange,
        validateManagerEmail,
        dialogRefs,
        setManagerData,
        managerData,
        translate,
        setIsMilestones,
        BudgetChoices,
        isMilestones,
        handleCostCodeChange,
        changeCostCode,
        setIsCreate,
        defaultCostCodeIds,
        hoursPlaceholder,
        validateTimeType,
        handleAssignManager,
        handleChangegroupMilestones,
        showGroupMiletones,
    } = props;

    return (<>
        <div className='grouping-milestones'>
            <div className='grouping-milestones-head'>
                <div className='grouping-milestones-title'>Grouping Milestones</div>
                <Button className='budget-help-button'>Budget Help</Button>
                <Button className='milestone-cancel-button'>Cancel</Button>
            </div>
            <div className='group-selection-search'>
                <TextInput source="title" label='Milestones Group 1 Name *' size='small' fullWidth />
                <Button className='group-selection-btn'>Group Selection</Button>
            </div>



            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid className="milestone-section milestone-group-section" item xs={12}>
                    <div className="create-budget-label">
                        <FieldTitle label="Milestones" />
                    </div>
                    <div className="milestone-subtitle">
                        Break the job by phases,
                        per trade, and/or parts
                    </div>

                    <Grid className="milestone-row add-more-milestone" container spacing={0}>
                        <FormDataConsumer>
                            {({ formData, getSource, scopedFormData, ...rest }) => {
                                return (
                                    <>
                                        <ArrayInput source="milestone_ids" label={false}>
                                            <StyledGroupMilstoneFormIterator disableReordering>
                                                <TextInput source="id" disabled hidden className='hide-milestone-id' />
                                                <TextInput fullWidth variant="outlined" source="name" className='group-milestone-name' label="Milestone Name" size="small" validate={[validateMilestoneName,required()]}/>
                                                {identity?.company?.can_use_cost_code &&  <ReferenceArrayInputObj source="cost_code_ids" reference="costcodes" className="cost-code-ref-input"
                                                    filter={ (formData.unique_cost_code || formData.can_create_unique_code) ? { job_id: { _eq: formData.job_id }, _or: {job_id: {_is_null: true}} } : { job_id: { _is_null: true } }}
                                                    label={identity?.company?.cost_code_label}
                                                    filterToQuery={searchText => ({
                                                        _and: {code: {_ilike: searchText}}
                                                    })}
                                                >
                                                    <AutoCompleteArrayInput fullWidth optionText="code" optionValue="id"/>
                                                </ReferenceArrayInputObj>}

                                                {formData.budget_option === 'amount' ? (
                                                    <StyledNumberInput step={ 0.01 } fullWidth variant="outlined" source="amount" label="Amount" size="small"/>
                                                ) : (
                                                    <MaskedTimeInput className="money-pay-input-create" fullWidth label={ false } source="budget_hours"
                                                    placeholder={hoursPlaceholder} validate={[validateTimeType,]}
                                                    />
                                                )}
                                            </StyledGroupMilstoneFormIterator>
                                        </ArrayInput>
                                        {formData?.milestone_ids?.length > NUMBER.ZERO &&
                                            <Grid className="milestone-amount-grid" container spacing={0}>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <div className="milestone-amount">
                                                        Total{' '}
                                                        {formData.budget_option === 'amount' ? 'Amount' : 'Hours'}{' '}
                                                        <strong>
                                                            {getSumOfBudgetOption(formData,formProps.form, setIsMilestones, identity)}
                                                        </strong>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        }
                                    </>
                                );
                            }}
                        </FormDataConsumer>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    </>
    );
}

export const validateMilestoneName = (values: any, allValues: any) => {
    let result = [];

    if (allValues?.milestone_ids?.length > 0 && values) {
        result = allValues?.milestone_ids?.filter(item => item?.name?.toLowerCase() === values?.toLowerCase())
    }
    return result?.length > 1 ? 'Duplicate milestone name not allowed.' : undefined
}
const getSum = (total, num) => {
    return total + parseFloat(num?.amount ? num?.amount : 0);
};

export const getSumOfBudgetOption = (budgetOptionData: any, form: any, setIsMilestones, identity) => {
    budgetOptionData?.milestone_ids?.length > 0 && setIsMilestones(true);
    if (budgetOptionData?.budget_option === 'amount') {
        const sum =
            budgetOptionData?.milestone_ids?.length > 0
                ? budgetOptionData?.milestone_ids.reduce(getSum, 0)
                : 0;
        const result = sum > 0 ? sum.toFixed(2) : '00.00';
        form.change('amount', Number(result));
        return result;
    }
    else {
        const hoursArr = budgetOptionData?.milestone_ids?.map(
            item => item?.budget_hours
        );
        const sum = identity?.company?.hours_format === 'by_time' ? sumTimeFuc(hoursArr) : sumTimeDecimalFuc(hoursArr);
        const resultHrs = sum.length > 1 ? sum : '00:00';
        form.change('budget_hours', resultHrs);
        return resultHrs;
    }
};

export const sumTimeFuc = (timeArray: string[]) => {
    let totalHours = NUMBER.ZERO;
    let totalMinutes =  NUMBER.ZERO;

    // Iterate over each time value in the array
    for (const time of timeArray) {
        let result;
        if (time && typeof time == 'number'){
             result = convertNumToTime(time as number);

        } else if(time && typeof time == 'string'){
            result = time;
        }
        if(result){
            const [hours, minutes] = result.includes(':') ? result.split(':').map(Number) : [Number(result),NUMBER.ZERO];
            totalHours += hours;
            totalMinutes += minutes;
        }
    }

    // Adjust the total hours and minutes
    totalHours += Math.floor(totalMinutes / NUMBER.SIXTEY);
    totalMinutes = totalMinutes % NUMBER.SIXTEY;

    // Format the result as "hh:mm"
    const resultHours = String(totalHours).padStart(NUMBER.TWO, '0');
    const resultMinutes = String(totalMinutes).padStart(NUMBER.TWO, '0');

    return `${isNaN(totalHours) ? '00' :resultHours}:${isNaN(totalMinutes) ? '00': resultMinutes}`;
};
export const sumTimeDecimalFuc = (timeArray: string[]) => {
    let totalHours = 0;
    for (const time of timeArray) {
        let result;
        if (time && typeof time === 'number') {
            result = convertNumToTime(time as number);
        } else if (time && typeof time === 'string') {
            result = time;
        }
        if (result) {
            const [hours, minutes] = result.includes(':') ? result.split(':').map(Number) : [Number(result), 0];
            totalHours += hours + minutes / 60;
        }
    }

    const resultHours = totalHours.toFixed(2);

    return isNaN(totalHours) ? '0.00' : resultHours;
};


const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    });

export const ShowWagesHtml = ({formData}) => {
    const dataProvide = useDataProvider();
    const identity = useIdentityContext();
    const [companyAvgWage, setCompanyAvgWage] = useState(0);
    const [selectedEmployeeWage, setSelectedEmployeeWage] = useState(0);
    const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    useEffect(()=>{
        if(identity?.company_id){
        const findCompanyWage = async ()=>{
            const companyData = await dataProvide.getOne('Company',{id: identity?.company_id});
            setCompanyAvgWage(companyData?.data?.employee_avg_wage ? companyData?.data?.employee_avg_wage : 0)
        }
        findCompanyWage();
    }
    },[identity?.company_id])

    useEffect(()=>{
        if(formData?.employee_wage_ids?.length){
            let assignedAvgWage = 0;
            const filterData = formData?.employee_wage_ids?.filter((user)=>user?.base_wage);
            const findWage = filterData?.map((item)=>{
                return(
                    assignedAvgWage = assignedAvgWage+(item?.base_wage ? item?.base_wage : 0))
            })
            const avgWage = ((assignedAvgWage/filterData?.length)*1).toFixed(2);
            setSelectedEmployeeWage(Number(avgWage));
        }
    },[formData?.employee_wage_ids,formData?.employee_wage_ids?.length]);

    return (
        <>

      {!isSmall ?
        <div className='avg-budget-alert avg-budget-left-align'>
            <div className='avg-budget-alert-row'>
                <strong>Company Avg. Wage:</strong>
                <div className='no-translate translate-avg-budget'>
                {formatter.format(Number.isNaN(Number(companyAvgWage)) ? 0 : companyAvgWage)}/hr
                </div>
                <InfoLabel
                    sx={{
                        height: 20,
                    }}
                    icon="ri:error-warning-fill"
                    height={
                        20
                    }
                >
                    <Typography>
                        Average wage taken from all active users that have a wage listed.
                    </Typography>
                </InfoLabel>
            </div>
            {formData?.employee_wage_ids?.length ? <div className='avg-budget-alert-row'>
                <strong>Selected Avg. Wage:</strong>
                    <div className='no-translate translate-avg-budget'>
                    {formatter.format(Number.isNaN(Number(selectedEmployeeWage)) ? 0 : selectedEmployeeWage)}/hr
                    </div>
                <InfoLabel
                    sx={{
                        height: 20,
                    }}
                    icon="ri:error-warning-fill"
                    height={
                        20
                    }
                >
                    <Typography>
                        Average wage of the workers selected to this ProPay.
                    </Typography>
                </InfoLabel>
            </div>:''}
        </div> :
        <Accordion className='view-avg-accordion'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="ViewAvgWage-content"
          id="ViewAvgWage-header"
        >
          View Avg. Wage
        </AccordionSummary>
        <AccordionDetails>
        <div className='avg-budget-alert avg-budget-left-align'>
            <div className='avg-budget-alert-row'>
                <strong>Company Avg. Wage:</strong>
                <div className='no-translate translate-avg-budget'>
                {formatter.format(Number.isNaN(Number(companyAvgWage)) ? 0 : companyAvgWage)}/hr
                </div>
                <InfoLabel
                    sx={{
                        height: 20,
                    }}
                    icon="ri:error-warning-fill"
                    height={
                        20
                    }
                >
                    <Typography>
                        Average wage taken from all active users that have a wage listed.
                    </Typography>
                </InfoLabel>
            </div>
            {formData?.employee_wage_ids?.length ? <div className='avg-budget-alert-row'>
                <strong>Selected Avg. Wage:</strong>
                <div className='no-translate translate-avg-budget'>
                {formatter.format(Number.isNaN(Number(selectedEmployeeWage)) ? 0 : selectedEmployeeWage)}/hr
                </div>
                <InfoLabel
                    sx={{
                        height: 20,
                    }}
                    icon="ri:error-warning-fill"
                    height={
                        20
                    }
                >
                    <Typography>
                        Average wage of the workers selected to this ProPay.
                    </Typography>
                </InfoLabel>
            </div>:''}
        </div>
        </AccordionDetails>
      </Accordion>
      }
        </>
    )
}