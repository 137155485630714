import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useState } from 'react';
import { ResourceContextProvider, useDataProvider, useTranslate } from 'react-admin';
import TimeIcon from '../../assets/time-icon.png';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ALERT_ERROR, BONUS_POOL_ARROW, BUDGET_ARROW, DOWNLOAD_ICON_02, OVERVIEW_CALENDER_ICON, OVERVIEW_LIST_ICON } from '../../utils/Constants/ClassName';
import { convertNumber } from '../../components/fields';
import BonusCalculatorDetailPage, { CalculatorSlider } from '../../dashboard/BonusCalculatorDetailPage';
import { FORECAST_TAB_ICON, OVERVIEW_TAB_ICON } from '../../utils/Constants/ClassName';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { Button, Grid, Link, Menu, MenuItem, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material';
import TeamIcon from '../../assets/team-icon.png';
import PersonalIcon from '../../assets/personal-icon.png';
import { GetJobsNames, GroupPropayBonusView, HandleModalOpen, MobileCharts } from './propaydetailoverview';
import AttendanceList from '../attendances/Attendance';
import BudgetCharts from './groupPropay/BudgetCharts';
import moment from 'moment';
import { Avatar } from '@mui/material';
import { Carousel } from 'rsuite';
import OverviewIcon from '../../assets/overview-icon.png';
import { HorizontalGroupedStackedBarChart } from './groupPropay/groupStacks';
import { useNavigate } from 'react-router';

const PropayActualAndTotalData = (props: any) => {
    const {maxHours, record, identity, workerPreview, setShowTime, sliderValue, setSliderValue, personalInfoRef, setChangeWorker, selectedWorkerToPreview} = props;
    const translate = useTranslate();
    const navigate = useNavigate();
    const employeeId = () => workerPreview.show ? workerPreview.employee_id : identity?.employee_id;
    const [openModalBonus, setOpenModalBonus] = React.useState(false);
    const [tab, setTab] = useState('Overview');
    const [budgetToggle, setBudgetToggle] = React.useState(false);
    const [openNegativeBalance, setOpenNegativeBalance] = React.useState(false);
    const [openModalActual, setOpenModalActual] = React.useState(false);
    const [goToBudget, setGoToBudget] = React.useState(0);
    const [openPositiveBalance, setOpenPositiveBalance] = React.useState(false);
    const [openModalBudget, setOpenModalBudget] = React.useState(false);
    const [chartData, setChartData] = React.useState({ data: { budget: [], hours: [] }, categories: [] });
    const [toggleChart, setToggleChart] = React.useState('table')
    const [openModalVariance, setOpenModalVariance] = React.useState(false);
    const [unassignedEntry, setUnassignedEntry] = React.useState(0);
    const [totalTimeEntries, setTotalTimeEntries] = React.useState(0);
    const [organized, setOrganized] = React.useState({ measureBy: 'earnings', sortBy: 'mostRecent' });
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [value, setValue] = useState('Team' );
    const [personalDetail,setPersonalDetail] = useState((identity?.user_type === 'worker' || workerPreview.show ) ? record?.employee_wage_ids?.filter((item)=> item.employee_id === employeeId())[NUMBER.ZERO]
    : {performance_bonus:NUMBER.ZERO, attendance_earning:NUMBER.ZERO, hours:NUMBER.ZERO});

    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );
       const open = Boolean(anchorEl);
       const touchStartX = React.useRef(null);
    const dataProvider = useDataProvider();
    useEffect(()=>{
        if(workerPreview?.show || identity?.user_type === 'worker'){
            setPersonalDetail(record?.employee_wage_ids?.filter((item)=> item.employee_id === employeeId())[NUMBER.ZERO]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[identity?.user_type,workerPreview?.show,workerPreview?.employee_id]);

    React.useEffect(() => {
        if(tab === 'Forecast') {
                const filter = {
                    job_id: record?.job_id && record?.second_job_id ?  {_in: [record?.job_id, record?.second_job_id]} : record?.job_id ? {_eq:record?.job_id} : {_eq:record?.second_job_id},
                    propay_id: { _is_null: true },
                    type: {_neq: "is_performance_bonus"}
                };
                dataProvider.getList('attendances', {
                    filter,
                    pagination: { page: 1, perPage: 1 },
                    sort: { field: 'id', order: 'DESC' }}).then((data) => {
                        setUnassignedEntry(data?.total);
                    })
        }
        const filter = {
            type:{_in:['regular','manual']},
            job_id: record?.job_id && record?.second_job_id ?  {_in: [record?.job_id, record?.second_job_id]} : record?.job_id ? {_eq:record?.job_id} : {_eq:record?.second_job_id},
          };
          dataProvider.getList('attendances', {
              filter,
              pagination: { page: 1, perPage: 1 },
              sort: { field: 'id', order: 'DESC' }}).then((data) => {
                setTotalTimeEntries(data?.total);
              })
      }, [tab, record]);

      React.useEffect(() => {
        if(record?.employee_wage_ids && record?.employee_wage_ids.length > 0) {
            const categoryData = record.employee_wage_ids.map(employee => employee.employee_id_obj?.display_name);
            const updatedChartValues = {
                categories: categoryData,
                data: {
                    budget: record.employee_wage_ids.map(employee => ({
                        value: Number(employee?.attendance_earning?.toFixed(NUMBER.TWO)),
                        name: employee?.employee_id_obj?.display_name
                    })),
                      hours: record.employee_wage_ids.map(employee => ({
                        value: Number(employee?.hours?.toFixed(NUMBER.TWO)),
                        name: employee?.employee_id_obj?.display_name
                    }))
                    }
              };

              setChartData(updatedChartValues);
        }
       },[record]);

       React.useEffect(() => {
        if(organized.sortBy === 'highToLow') {
            if(organized.measureBy === 'earnings') {
                setChartData(prevData => ({ ...prevData, earnings: chartData.data.budget.sort((a,b) => b.value - a.value) }))
            } else if (organized.measureBy === 'hours') {
                setChartData(prevData => ({ ...prevData, earnings: chartData.data.hours.sort((a,b) => b.value - a.value) }))
            }
        } else if (organized.sortBy === 'lowToHigh') {
            if(organized.measureBy === 'earnings') {
                setChartData(prevData => ({ ...prevData, earnings: chartData.data.budget.sort((a,b) => a.value - b.value) }))
            } else if (organized.measureBy === 'hours') {
                setChartData(prevData => ({ ...prevData, earnings: chartData.data.hours.sort((a,b) => a.value - b.value) }))
            }
        }
    },[organized])


    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        });
        const calculateBasepay = () => {
            let bonusOnlyHours = NUMBER.ZERO;
            record?.wage_ids?.map((workerData) => {
                if(!workerData.is_remove_bonus)
                {
                 bonusOnlyHours = bonusOnlyHours + workerData.hours;
                }
            });
            return bonusOnlyHours;
        }

    const actualHoursForcalculation = calculateBasepay();
    const budget = record?.budget;
    const budgetHours = Number((maxHours*NUMBER.ONE).toFixed(NUMBER.TWO));
    const actualHours = Number((record?.hours*NUMBER.ONE).toFixed(NUMBER.TWO));
    const varianceAmount = ((record?.remaining_amount)*NUMBER.ONE).toFixed(NUMBER.TWO);
    const varianceHours = ((record?.remaining_hours)*NUMBER.ONE).toFixed(NUMBER.TWO);
    const actualAmount = record?.attendance_earning+Number(record?.ot_amt);
    const approvedBonus = record?.performance_bonus;
    const actualPercentComplete = `${(NUMBER.HUNDRED * (actualAmount / budget )) > NUMBER.ZERO ? (NUMBER.HUNDRED * (actualAmount / budget)).toFixed(NUMBER.TWO) : NUMBER.ZERO}%`
    const PercenatgeChange = (record.performance_bonus/actualAmount)* NUMBER.HUNDRED;
    const PerhourChange = actualHoursForcalculation ? record.performance_bonus/actualHoursForcalculation : NUMBER.ZERO;

    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        setShowTime(newValue);
        setTab(newValue);
    };

    function getArrayLength(arr) {
        return Array.isArray(arr) ? arr.length : 0;
    }

    const handleChangegotobudget = (event: SelectChangeEvent) => {
        setGoToBudget(Number(event.target.value));
        setSliderValue('');
      };
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
        if(newValue === 'Personal'){
        setSliderValue('');
        setGoToBudget(0);
        }
    };
    const personalBonus = Number((personalDetail?.performance_bonus*NUMBER.ONE).toFixed(NUMBER.TWO));
    const personalActualBalance = Number((personalDetail?.attendance_earning*NUMBER.ONE).toFixed(NUMBER.TWO));
    const personalActualHours = Number((personalDetail?.hours*NUMBER.ONE).toFixed(NUMBER.TWO));
    const personalPercenatgeChange = Number(((personalDetail?.performance_bonus/personalDetail?.attendance_earning)* NUMBER.HUNDRED).toFixed(NUMBER.TWO));
    const baseWage = personalDetail?.base_wage && Number((personalDetail?.base_wage).toFixed(NUMBER.TWO));
    const personalPerhourChange = personalDetail?.hours ? Number((personalDetail?.performance_bonus/personalDetail?.hours).toFixed(NUMBER.TWO)) : NUMBER.ZERO;
    const personalBalance = Number((personalDetail?.reserve_amount*NUMBER.ONE).toFixed(NUMBER.TWO));
    const negativeBalance = record?.deficit_amount;
    const positiveBalance = record?.reserve_amount;


     const workerPoolPercent = (record?.worker_bonus_percent*NUMBER.ONE).toFixed(NUMBER.TWO);
     const companyPoolPercent = (record?.company_bonus_percent*NUMBER.ONE).toFixed(NUMBER.TWO);

    const handleCloseBudget = () => {
        setOpenModalBudget(false);
    };
    const handleCloseActual = () => {
        setOpenModalActual(false);
    };
    const handleCloseVariance = () => {
        setOpenModalVariance(false);
    };
    const handleCloseBonus = () => {
        setOpenModalBonus(false);
    };
    const handleClosePositiveBalance = () => {
        setOpenPositiveBalance(false);
    };
    const handleCloseNegativeBalance = () => {
        setOpenNegativeBalance(false);
    };

    const CommonTdValue = ({value, identity}) => {
        return(
            <div className={`${value < 0 ? 'red-text font-weight-600':''} ${value > NUMBER.ZERO ? 'green-text font-weight-600':''}`}>{convertNumber((value), identity)}</div>
        )
    }
    const CommonTdAmount = ({amount}) => {
        return(
            <div className={`${amount < 0 ? 'red-text font-weight-600':''} ${amount > NUMBER.ZERO ? 'green-text font-weight-600':''}`}>{formatter.format(amount)}</div>
        )
    }

       const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
         setAnchorEl(event.currentTarget);
       };
       const handleClose = () => {
         setAnchorEl(null);
       };
       const handleInputChange = (event) => {
        let inputValue = event.target.value;
        const regex = /^\d*(\.\d{0,1})?$/;
        if (regex.test(inputValue)) {
            if (inputValue !== '' && Number(inputValue) <= NUMBER.HUNDRED) {
                setSliderValue(Number(inputValue));
                if(workerPreview?.show) {
                    setValue('Team')
                }
                setGoToBudget(3);
            }
        }
        if (inputValue === '') {
            setSliderValue('');
            if(workerPreview?.show) {
                setValue('Team')
            }
            setGoToBudget(3);
        }
    };

    const segments = [
        { value: 20, color: '#0391BA', label: 'Budget' },
        { value: 10, color: '#FC6E45', label: 'Actuals' },
      ];

    const handleCalSliderChange = (event, newValue) => {
        setSliderValue(newValue);
        if(workerPreview?.show) {
            setValue('Team')
        }
        setGoToBudget(3);
    };

       const handleTouchStart = (event) => {
        touchStartX.current = event.touches[0].clientX;
      };

      // Function to handle touch end event
      const handleTouchEnd = (event) => {
        const touchEndX = event.changedTouches[0].clientX;
        const deltaX = touchEndX - touchStartX.current;
        const sensitivity = 50; // Adjust this value as needed

        if (deltaX > sensitivity && goToBudget > 0) {
          setGoToBudget(goToBudget - 1);
          setSliderValue('');
        } else if (deltaX < -sensitivity && goToBudget < 3) { // Update 2 with the total number of slides - 1
          setGoToBudget(goToBudget + 1);
          setSliderValue('');
        }
      };

      const scrollToDiv = () => {
        if (personalInfoRef.current) {
            personalInfoRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

      const getSelectedText = (id) => {
        if(id === 0) return 'Budget'
        if(id === 1) return 'Bonus Pool'
        if(id === 2) return 'Balance'
        if(id === 3) return 'Goals'
      }

    const isCompanyPoolHidden = identity?.company?.hide_company_pool;
    const isUserWorker = identity?.user_type === 'worker' || selectedWorkerToPreview?.type === 'worker';
    const shouldShowCompanyPool = !isCompanyPoolHidden || !isUserWorker;
    
    return(
        <>
        <Box sx={{ width: '100%', typography: 'body1' }} className='overview-new-tabs'>
        <TabContext value={tab}>
            <TabList aria-label="Overview Forecast tabs example" onChange={handleChangeTab} className='overview-tabs-row details-tabbing'>
                <Tab icon={<img src={OverviewIcon} alt='' />} label="Overview" value="Overview" />
                    <Tab icon={<img src={TimeIcon} alt='' />} label="Time" value="Forecast"  />
            </TabList>
               <TabPanel value="Overview">
                    <TabContext value={value}>
                     <div className='overview-budget-box overview-new'>
                    {record?.employee_wage_ids?.length > 0 &&
                    <div className='overview-list-grid'>
                            <div className='overview-list-icon-group'>
                            <Button onClick={() => setToggleChart('table')} className={toggleChart === 'table' ? 'overview-list-icon overview-list-active' : 'overview-list-icon'}>{OVERVIEW_CALENDER_ICON()}</Button>
                            <Button onClick={() => setToggleChart('chart')} className={toggleChart === 'chart' ? 'overview-list-icon overview-list-active' : 'overview-list-icon'}>{OVERVIEW_LIST_ICON()}</Button>
                            </div>
                            {toggleChart === 'chart' &&
                            <>
                            {isSmall ? (
                                <>
                                <div className="card-dropdown organized-dropdown">
                                <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    sx={{ ml: 2 }}
                                    aria-controls={open ? 'card-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                <Avatar>
                                    <svg
                                        width="4"
                                        height="18"
                                        viewBox="0 0 4 18"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M2 4a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                                            fill="#BDBDBD"
                                            fill-rule="evenodd"
                                        />
                                    </svg>
                                </Avatar>
                                </IconButton>
                                <Menu
                                    id="organized-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                    'aria-labelledby': 'organized-button',
                                    }}
                                >
                                    <OrganizedFilter organized={organized} handleClose={handleClose} isSmall={isSmall} setOrganized={setOrganized} />
                                </Menu>
                                </div>
                                </>
                                )
                                : <></>
                                }

                            {!isSmall ? (
                            <>
                            <OrganizedFilter organized={organized} setOrganized={setOrganized} />
                            </>
                            )
                            : <></>
                            }

                            </>
                            }
                        </div>}
                        {/* Organized Toolbar */}

                    {
                    toggleChart === 'chart' ?
                    <>
                        { isSmall ? <MobileCharts organizedBy={organized?.measureBy} data={organized?.measureBy === 'earnings' ? chartData?.data?.budget : chartData?.data?.hours} segments={segments} /> : <HorizontalGroupedStackedBarChart isMilestone={false} xAxisPrefix={organized?.measureBy === 'earnings' ? '$' : ''} yAxisTitle={'Workers'} xAxisTitle={organized?.measureBy === 'earnings' ? 'Earnings' : 'Hours'} data={organized?.measureBy === 'earnings' ? chartData?.data?.budget : chartData?.data?.hours} />}
                    </>
                    :
                    <>
                    <Box className='overview-new-tabs-row'>
                        {/* {(identity?.user_type === 'worker' || workerPreview.show)  && <TabList onChange={handleChange} aria-label="Personal Team tabs example" className='team-personal-tabs'>
                            <Tab icon={<img src={TeamIcon} alt='' />} label="Team" value="Team" />
                            <Tab icon={<img src={PersonalIcon} alt='' />} label="Personal" value="Personal" />
                        </TabList>} */}
                    </Box>
                    {record?.parent_id_obj?.is_group_propay ? <div className='alert-group'>
                    {ALERT_ERROR()} Please go to the group to view all bonuses
                    <Link className='alert-group-link' onClick={()=>navigate(`/show/${record?.parent_id_obj?.id}/propay`)}>View group</Link>
                    </div>:'' }
                    <div className='grid-structure-full'>
                    <TabPanel value="Personal" className='grid-fifty'>
                    <Grid container spacing={2}>
                        <Grid item md={12} sm={12} xs={12}>
                        <div className="budget-detail-heading">Budget Details</div>
                        <div className="budget-detail-box budget-detail-single-box">
                               <table className="table">
                                       <thead>
                                           <tr>
                                               <th scope="col">
                                               <div className="budget-detail-th">Base Pay</div>
                                               </th>
                                               <th scope="col">
                                               <div className="budget-detail-th">Hours</div>
                                               </th>
                                               <th scope="col">
                                               <div className="budget-detail-th">Wage</div>
                                                </th>
                                           </tr>
                                       </thead>
                                       <tbody>
                                           <tr>
                                               <td>{formatter.format(personalActualBalance)}</td>
                                               <td>{personalActualHours}</td>
                                               <td>{personalDetail?.hours ? formatter.format(personalActualBalance/personalActualHours) : formatter.format(baseWage)}</td>
                                           </tr>
                                       </tbody>
                               </table>
                        </div>
                        <div className="budget-detail-sec budget-new-overview remove-hyper-linking">
                        <div className="budget-detail-box budget-approve-table budget-single-table">
                        <table className="table">
                                <tbody>
                                <tr>
                                        <td className="budget-headers">
                                        <div className='approve-bonus-flex'>
                                        <div onClick={()=> setOpenModalBonus(true)} className='budget-detail-th'>Approved Bonuses</div>
                                        {record?.bonus_split_type && <Tooltip arrow placement="top" title={translate(`resources.propays.bonus_choices.bonus_split_type.${record.bonus_split_type}.info`!)}>
                                            <IconButton>
                                            Split: {translate(`resources.propays.bonus_choices.bonus_split_type.${record.bonus_split_type}.label`!)}
                                            </IconButton>
                                        </Tooltip>}
                                        </div>
                                        </td>
                                <td className={`${Number(approvedBonus) < 0 ? 'red-text font-weight-600':''} ${Number(approvedBonus) > NUMBER.ZERO ? 'green-text font-weight-600':''}`}>
                                <div className='approve-bonus-mobile'>
                                    {formatter.format(Number(approvedBonus))}
                                <span className='approve-bonus-value'>
                                    {record.bonus_split_type === 'by_hours' && <span className='text-green font-weight-600'>
                                    ({record.performance_bonus ? formatter.format(PerhourChange): formatter.format(NUMBER.ZERO)}/hr)</span>}
                                {record.bonus_split_type === 'by_wage' && <span className='approve-bonus-text'>
                                    ({record.performance_bonus ? parseFloat(PercenatgeChange.toString()).toFixed(NUMBER.TWO): '0'}%)
                                    {record.performance_bonus ? <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15" fill="none">
                                    <path d="M1 6.5L6 1.5M6 1.5L11 6.5M6 1.5V13.5" stroke="#4FCA4D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg> : ''}
                                    </span>}
                                </span>
                                </div>
                                </td>
                                </tr>
                                </tbody>
                        </table>
                        </div>
                        </div>
                        </Grid>
                    </Grid>

                    </TabPanel>
                    <TabPanel value="Team" className='grid-fifty'>
                    <Grid container spacing={2}>
                        <Grid item md={12} sm={12} xs={12}>
                        {!isSmall ? (
                               <>
                               <div className='bd-actual-flex'>
                               <div className='budget-detail-heading'>Budget Details</div>
                               {(workerPreview.show || identity?.user_type === 'worker') &&
                                <Button onClick={() => {
                                setChangeWorker('your-info')
                                scrollToDiv()
                                    }
                                } className='view-personal-info-link'>
                                View personal info
                                </Button>}
                                </div>

                               {!budgetToggle &&
                               <>
                               <div className="budget-detail-box">
                               <table className="table">
                                       <thead>
                                           <tr>
                                               <th scope="col"></th>
                                               <th scope="col"><div className='budget-detail-th'>Budget</div>
                                               {record?.budget_option === 'amount' ? <LabelWithTooltip
                                            tooltipTitle='Completion is tracked against amount instead of hours. Hours will fluctuate based on the worker hours and their wage.'
                                             label='Fixed Amount'/> :
                                            <LabelWithTooltip
                                            tooltipTitle='Completion is tracked against hours instead of amount. Amount will fluctuate based on the worker hours and their wage.'
                                             label='Fixed Hours'/>}
                                               </th>
                                               <th scope="col"><div onClick={()=> setOpenModalActual(true)} className='budget-detail-th'>Actuals</div></th>
                                               <th scope="col"><div className='budget-detail-th'>Variance</div></th>
                                           </tr>
                                       </thead>
                                       <tbody>
                                           <tr>
                                               <td>Hours</td>
                                               <td>{convertNumber((budgetHours), identity)}</td>
                                               <td><div className='actual-hour-value'>{convertNumber((actualHours),identity)}{record?.budget_option === 'hours' && <span className='hrs-overview-text' >({isFinite(parseFloat(actualPercentComplete)) ? actualPercentComplete : '0%'} spent)</span>}</div></td>
                                               <td className='green-text font-weight-600'><CommonTdValue value={Number(varianceHours)} identity={identity}/></td>
                                           </tr>
                                           <tr>
                                               <td>Amount</td>
                                               <td>{formatter.format(budget)}</td>
                                               <td><div className='actual-hour-value'>{formatter.format(actualAmount ? actualAmount : 0)}{record?.budget_option === 'amount' && <span className='hrs-overview-text' >({isFinite(parseFloat(actualPercentComplete)) ? actualPercentComplete : '0%'} spent)</span>}</div></td>
                                               <td className='green-text font-weight-600'><CommonTdAmount amount={Number(varianceAmount)}/></td>
                                           </tr>
                                       </tbody>
                               </table>
                               </div>
                               { record?.is_bonus_pool_enabled && <div className='budget-dot-row'>
                                   <div className='budget-dot-left'>
                                   <Button className='budget-dot budget-dot-active'>1</Button>
                                   <Button className='budget-dot'>2</Button>
                                   </div>
                                   <Button onClick={() => setBudgetToggle(true)} className='budget-bonus-link'>Bonus Pool
                                   {BONUS_POOL_ARROW()}
                                   </Button>
                               </div>}
                               </>
                               }
                               {
                                budgetToggle &&
                                <>
                                <div className="budget-detail-box budget-bonus-pool">
                               <table className="table">
                                       <thead>
                                           <tr>
                                               <th scope="col" colSpan={2}>
                                                <div className='budget-bonus-th'>Bonus Pool <div className='budget-bonus-text'>{`(${formatter.format(record?.remaining_amount.toFixed(2))})`}</div></div>
                                                </th>
                                           </tr>
                                       </thead>
                                       <tbody>
                                           <tr>
                                               <td><strong>{identity?.company?.worker_pool_label ? identity?.company?.worker_pool_label : 'ProPay Pool'}</strong></td>
                                               <td>
                                                {shouldShowCompanyPool &&
                                                    <Tooltip placement="top" className='bonus-company-tooltip' title={identity?.company?.company_pool_label ? identity?.company?.company_pool_label : 'Company Pool'} arrow>
                                                    <strong>{identity?.company?.company_pool_label ? identity?.company?.company_pool_label : 'Company Pool'}</strong>
                                                    </Tooltip>
                                                }
                                               </td>
                                           </tr>
                                           <tr>
                                               <td>
                                               <div className='bonus-worker-text'>
                                               <div data-title={identity?.company?.worker_pool_label ? identity?.company?.worker_pool_label : 'ProPay Pool'}>{formatter.format(Number(record?.worker_pool.toFixed(2)))} 
                                                {shouldShowCompanyPool && <span className='worker-grey-text'>({workerPoolPercent}%)</span>}
                                                </div>
                                                </div>
                                                <div className='worker-grey-text'>(For ProPay bonuses)</div>
                                               </td>
                                               {shouldShowCompanyPool &&
                                               <td>
                                                    <div className='bonus-worker-text'>
                                                    <div data-title={identity?.company?.company_pool_label ? identity?.company?.company_pool_label : 'Company Pool'}>{formatter.format(Number(record?.company_pool))} <span className='worker-grey-text'>({companyPoolPercent}%)</span></div>
                                                    </div>
                                                </td>
                                               }
                                           </tr>
                                       </tbody>
                               </table>
                               </div>

                               <div className='budget-dot-row bonus-pool-dot-row'>
                                   <Button onClick={() => setBudgetToggle(false)} className='budget-bonus-link'>{BONUS_POOL_ARROW()} Budget</Button>
                                   <div className='budget-dot-left'>
                                   <Button className='budget-dot'>2</Button>
                                   <Button className='budget-dot budget-dot-active'>1</Button>
                                   </div>
                               </div>
                                </>
                               }
                               <div className="budget-detail-sec budget-new-overview remove-hyper-linking">
                               <div className="budget-detail-box budget-approve-table">
                               <table className="table">
                                       <tbody>
                                       <tr>
                                               <td className="budget-headers">
                                               <div className='approve-bonus-flex'>
                                               <div onClick={()=> setOpenModalBonus(true)} className='budget-detail-th'>Approved Bonuses</div>
                                               {record?.bonus_split_type && <Tooltip arrow placement="top" title={translate(`resources.propays.bonus_choices.bonus_split_type.${record.bonus_split_type}.info`!)}>
                                                   <IconButton>
                                                   Split: {translate(`resources.propays.bonus_choices.bonus_split_type.${record.bonus_split_type}.label`!)}
                                                   </IconButton>
                                               </Tooltip>}
                                               </div>
                                               </td>
                                               <td className={`${Number(approvedBonus) < 0 ? 'red-text font-weight-600':''} ${Number(approvedBonus) > NUMBER.ZERO ? 'green-text font-weight-600':''}`}>
                                        <div className='approve-bonus-mobile'>
                                            {formatter.format(Number(approvedBonus))}
                                        <span className='approve-bonus-value'>
                                            {record.bonus_split_type === 'by_hours' && <span className='text-green font-weight-600'>
                                            ({record.performance_bonus ? formatter.format(PerhourChange): formatter.format(NUMBER.ZERO)}/hr)</span>}
                                        {record.bonus_split_type === 'by_wage' && <span className='approve-bonus-text'>
                                            ({record.performance_bonus ? parseFloat(PercenatgeChange.toString()).toFixed(NUMBER.TWO): '0'}%)
                                            {record.performance_bonus ? <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15" fill="none">
                                            <path d="M1 6.5L6 1.5M6 1.5L11 6.5M6 1.5V13.5" stroke="#4FCA4D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg> : ''}
                                            </span>}
                                        </span>
                                        </div>
                                        </td>
                                       </tr>
                                        {record.overage ? <tr>
                                         <td colSpan={2} className='budget-headers hidden-td-mobile'>Overage</td>
                                         <td data-title='Overage'>{formatter.format(record.overage)}</td>
                                        </tr>:''}
                                       </tbody>
                               </table>
                               </div>
                               </div>
                               </>
                             ) : (
                               <>
                               {goToBudget !== 3 && <div className='budget-detail-heading'>Budget Details</div>}

                                <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
                                <Carousel className='budget-carousel' autoplay={false} activeIndex={goToBudget}
                                onSelect={(index)=>{
                                    setGoToBudget(index)
                                    setSliderValue('');
                                    }}
                                >

                                <div className='budget-card-panel budget-detail-first'>
                                <div className='budget-card'>
                                    <div onClick={()=> setOpenModalBudget(true)} className='budget-card-head'>
                                    Budget
                                    {record?.budget_option === 'amount' ? <LabelWithTooltip
                                            tooltipTitle='Completion is tracked against amount instead of hours. Hours will fluctuate based on the worker hours and their wage.'
                                             label='Fixed Amount'/> :
                                            <LabelWithTooltip
                                            tooltipTitle='Completion is tracked against hours instead of amount. Amount will fluctuate based on the worker hours and their wage.'
                                             label='Fixed Hours'/>}
                                    </div>
                                    <div className='budget-card-body'>
                                        <div className='budget-card-label'>
                                        <label>Hours</label>
                                        <div className='budget-card-value'>
                                        {convertNumber((budgetHours), identity)}
                                        </div>
                                        </div>

                                        <div className='budget-card-label'>
                                        <label>Amount</label>
                                        <div className='budget-card-value'>
                                        {formatter.format(budget)}
                                        </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='budget-card budget-card-actual'>
                                    <div className='budget-card-head'>
                                    Actuals
                                    </div>
                                    <div className='budget-card-body'>
                                        <div className='budget-card-label'>
                                        <label>Hours</label>
                                        <div className='budget-card-value'>
                                        {convertNumber((actualHours),identity)}
                                        </div>
                                        </div>

                                        <div className='budget-card-label'>
                                        <label>Amount</label>
                                        <div className='budget-card-value'>
                                        {formatter.format(actualAmount ? actualAmount : 0)} {record?.budget_option === 'amount' && <span className='hrs-overview-text' >({isFinite(parseFloat(actualPercentComplete)) ? actualPercentComplete : '0%'} spent)</span>}

                                        </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='budget-card budget-card-variance'>
                                    <div onClick={()=> setOpenModalVariance(true)} className='budget-card-head'>
                                    Variance
                                    </div>
                                    <div className='budget-card-body'>
                                        <div className='budget-card-label'>
                                        <label>Hours</label>
                                        <div className='budget-card-value green-value'>
                                        <CommonTdValue value={Number(varianceHours)} identity={identity}/>
                                        </div>
                                        </div>

                                        <div className='budget-card-label'>
                                        <label>Amount</label>
                                        <div className='budget-card-value green-value'>
                                        <CommonTdAmount amount={Number(varianceAmount)}/>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                               </div>
                               <div className='budget-card-panel'>
                                     <div className='budget-card'>
                                    <div onClick={()=> setOpenModalBudget(true)} className='budget-card-head'>
                                    Bonus Pool
                                    <div className='budget-bonus-text'>{`(${formatter.format(record?.available_bonus.toFixed(2))})`}</div>
                                    </div>
                                    <div className='budget-card-body'>
                                        <div className='budget-card-label'>
                                        <label>{identity?.company?.worker_pool_label ? identity?.company?.worker_pool_label : 'ProPay Pool'}</label>
                                        <div className='budget-card-value'>
                                            {formatter.format(Number(record?.worker_pool))} 
                                            {shouldShowCompanyPool && <span className='worker-grey-text'>({workerPoolPercent}%)</span>}
                                        </div>
                                        </div>
                                        {shouldShowCompanyPool&& 
                                            <div className='budget-card-label'>
                                                <label>{identity?.company?.company_pool_label ? identity?.company?.company_pool_label : 'Company Pool'}</label>
                                                <div className='budget-card-value'>
                                                    {formatter.format(Number(record?.company_pool))} <span className='worker-grey-text'>({companyPoolPercent}%)</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                </div>
                                <div className='budget-card-panel'>
                                     <div className='budget-card'>
                                    <div className='budget-card-body'>
                                        <div className='budget-card-label'>
                                        <label>
                                        <div className='budget-headers'>
                                        Approved Bonuses
                                   {record?.bonus_split_type && <Tooltip arrow placement="top" title={translate(`resources.propays.bonus_choices.bonus_split_type.${record.bonus_split_type}.info`!)}>
                                    <IconButton>
                                    Split: {translate(`resources.propays.bonus_choices.bonus_split_type.${record.bonus_split_type}.label`!)}
                                    </IconButton>
                                    </Tooltip>}
                                    </div>
                                        </label>
                                        <div className='budget-card-value'>
                                        <div className={`${Number(approvedBonus) < 0 ? 'red-text font-weight-600':''} ${Number(approvedBonus) > NUMBER.ZERO ? 'green-text font-weight-600':''}`}>
                                        <div className='approve-bonus-mobile'>
                                            {formatter.format(Number(approvedBonus))}
                                        <span className='approve-bonus-value'>
                                            {record.bonus_split_type === 'by_hours' && <span className='text-green font-weight-600'>
                                            ({record.performance_bonus ? formatter.format(PerhourChange): formatter.format(NUMBER.ZERO)}/hr)</span>}
                                        {record.bonus_split_type === 'by_wage' && <span className='approve-bonus-text'>
                                            ({record.performance_bonus ? parseFloat(PercenatgeChange.toString()).toFixed(NUMBER.TWO): '0'}%)
                                            {record.performance_bonus ? <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15" fill="none">
                                            <path d="M1 6.5L6 1.5M6 1.5L11 6.5M6 1.5V13.5" stroke="#4FCA4D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg> : ''}
                                            </span>}
                                        </span>
                                        </div>
                                        </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <Grid item md={6} sm={12} xs={12}>
                                {record?.parent_id_obj?.is_group_propay ? <GroupPropayBonusView /> :<BonusCalculatorDetailPage record={record} maxHours={maxHours} workerPreview={workerPreview}/>}
                                </Grid>
                                </Carousel>
                                </div>
                               <div className='budget-dot-row'>
                                   <div className='budget-dot-right'>
                                    <div className='budget-dot-btn'>{getSelectedText(goToBudget)}</div>
                                    {BUDGET_ARROW()}
                                <FormControl variant="standard" className='go-to-menu' sx={{ minWidth: 70 }}>
                                    <Select
                                    value={JSON.stringify(goToBudget)}
                                    onChange={handleChangegotobudget}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                    <MenuItem value={0} className='go-to-menu-item'>Budget</MenuItem>
                                    <MenuItem value={1} className='go-to-menu-item'>Bonus Pool</MenuItem>
                                    <MenuItem value={2} className='go-to-menu-item'>Balance</MenuItem>
                                    <MenuItem value={3} className='go-to-menu-item'>Goals</MenuItem>
                                    </Select>
                                </FormControl>

                                   </div>
                               </div>
                               </>
                            )}
                        </Grid>
                    </Grid>

                    </TabPanel>

                    <div className='grid-fifty bonus-goal-fifty'>
                    {!isSmall && <>{record?.parent_id_obj?.is_group_propay ? <GroupPropayBonusView /> :<BonusCalculatorDetailPage record={record} maxHours={maxHours} workerPreview={workerPreview}/>}</>}
                    </div>
                    </div>
                    </>}

                    </div>

                </TabContext>

               </TabPanel>
               <TabPanel value="Forecast">
               <div className='all-entries-job'><GetJobsNames record={record}/></div>
               <div className='group-time-scroll'>
               <div className='group-time-row group-time-five-box'>
                    <div className='group-time-item'>
                        <strong className='group-time-strong'>
                        {getArrayLength(record?.wage_ids)}
                        </strong>
                        <span>Workers</span>
                    </div>
                    <div className='group-time-item'>
                        <strong className='group-time-strong'>
                        {record?.hours.toFixed(NUMBER.TWO)}
                        </strong>
                        <span>Total Hours</span>
                    </div>
                    <div className='group-time-item'>
                        <strong className='group-time-strong'>
                        {totalTimeEntries}
                        </strong>
                        <span>Total Entries</span>
                    </div>
                    <div className='group-time-item'>
                        <strong className='group-time-strong'>
                        {unassignedEntry}
                        </strong>
                        <span>Unassigned Entries</span>
                    </div>
                   {record?.status !== 'pending' &&
                   <div className='group-time-item'>
                        <div className='group-time-start'>Started on: <strong>{moment(record?.from_date).format('MMM DD, YYYY')}</strong></div>
                        <div className='group-time-start'>End on: <strong>{moment(record?.to_date).format('MMM DD, YYYY')}</strong></div>
                    </div>
                    }
               </div>
               </div>
                <ResourceContextProvider value='attendances'>
                <AttendanceList budgetPropayId={record?.job_id && record?.second_job_id ?  {_in: [record?.job_id, record?.second_job_id]} : record?.job_id ? {_eq:record?.job_id} : {_eq:record?.second_job_id}} propayBool={true}
                record={record} />
                </ResourceContextProvider>
            </TabPanel>
        </TabContext>
        </Box>
        {(goToBudget !== 3 && isSmall && tab === 'Overview' && !record?.parent_id_obj?.is_group_propay) && <CalculatorSlider value={sliderValue} numericValue={0}  handleInputChange={handleInputChange} handleSliderChange={handleCalSliderChange} setGoalbutton={false} closeSliderButton={false} />}
        <HandleModalOpen identity={identity} record={record} openModalActual={openModalActual} openModalBonus={openModalBonus} openModalBudget={openModalBudget} openModalVariance={openModalVariance}
            handleCloseActual={handleCloseActual} handleCloseBonus={handleCloseBonus} handleCloseBudget={handleCloseBudget} handleCloseVariance={handleCloseVariance}
            openPositiveBalance={openPositiveBalance} openNegativeBalance={openNegativeBalance}
            handleClosePositiveBalance={handleClosePositiveBalance} handleCloseNegativeBalance={handleCloseNegativeBalance}
            />
        </>
    )
}
export default PropayActualAndTotalData;

type LabelWithTooltipProps = {
    label: string;
    tooltipTitle: string;
  }

export const LabelWithTooltip = (props:LabelWithTooltipProps) => {
    const {label, tooltipTitle,} = props;
    return(
        <Tooltip id='unique-tooltip' className='unique-tooltip' title={tooltipTitle} placement="bottom" arrow>
        <span className='fix-account'>{label}</span>
        </Tooltip>
    );
}

export const OrganizedFilter =(props:any)=>{

    const {organized, setOrganized, handleClose, isSmall} = props;
    const organizedhandleChange = (event: SelectChangeEvent) => {
        if(isSmall) {
            handleClose()
        }
        setOrganized(prevState => ({ ...prevState, measureBy: event.target.value  }));
    };
    const organizedTypeChange = (event: SelectChangeEvent) => {
        if(isSmall) {
            handleClose()
        }
        setOrganized(prevState => ({ ...prevState, sortBy: event.target.value  }));
    }
    return (
        <>
         <div className='organized-list'>
            <div className='organized-list-by'>
                <label className='organized-label'>Organized by:</label>
                <div>Worker</div>
            </div>
            <div className='organized-list-by'>
                <label className='organized-label'>Measure by:</label>
                <FormControl sx={{ minWidth: 90 }} size="small">
                <Select
                labelId="organized-select-label"
                id="organized-select"
                inputProps={{ 'aria-label': 'Without label' }}
                value={organized?.measureBy}
                placeholder="Labor Cost"
                defaultValue='Labor Cost'
                onChange={organizedhandleChange}
                >
                <MenuItem value={'earnings'}>Earnings</MenuItem>
                <MenuItem value={'hours'}>Hours</MenuItem>
                </Select>
            </FormControl>
            </div>
            <div className='organized-list-by'>
                <label className='organized-label'>Sort by:</label>
                <FormControl sx={{ minWidth: 90 }} size="small">
                <Select
                labelId="organized-select-label"
                id="organized-select"
                inputProps={{ 'aria-label': 'Without label' }}
                value={organized?.sortBy}
                placeholder="High to Low"
                defaultValue='High to Low'
                onChange={organizedTypeChange}
                >
                <MenuItem value={'mostRecent'}>Most Recent Started</MenuItem>
                <MenuItem value={'highToLow'}>High to Low</MenuItem>
                <MenuItem value={'lowToHigh'}>Low to High</MenuItem>
                </Select>
            </FormControl>
            </div>

        </div>
        </>
    )
}
