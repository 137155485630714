import { Box, FormControl, FormControlLabel, FormLabel, Grid, IconButton, MenuItem, Radio, RadioGroup, Theme, Toolbar, Typography, useMediaQuery,Button,Menu, ListItemIcon ,Snackbar} from '@mui/material';
import { styled } from '@mui/material/styles';
import createDecorator from 'final-form-calculate';
import _ from 'lodash';
import moment from 'moment';
import { AutocompleteArrayInput, FunctionField, TextInput } from 'ra-ui-materialui';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
    AutocompleteInput as RaAutocompleteInput, Create, Edit, FormDataConsumer, FormWithRedirect, ListActions, Record, ReferenceField, ReferenceInput,
    required, ResourceContextProvider, SaveButton, SelectArrayInput, SelectInput,SimpleForm, TextField, BooleanInput, BulkActionProps, useCreate, useRefresh, useNotify, useGetList, useDataProvider, useListContext
} from 'react-admin';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import { DialogTitleForRecord } from '../../components/dialog-form';
import { AttendanceStatusLabelField, EditableDatagridWithPermission, FormatTimeField, FormatTimeFieldResposive, getDuplicateConnectorList, getParsedConnectorName, MoneyField, ReferenceArrayInputObj, ReferenceInputObj, RestrictedTag, StyledEditableDatagrid } from '../../components/fields';
import { DateField } from '../../components/fields/DateField';
import { DateRangeInputFilter } from '../../components/fields/DateRangeInputFilter';
import { DateTimePickerField } from '../../components/fields/DateTimePickerField';
import { InfoLabel } from '../../components/fields/InfoLabel';
import { HoursInput, MinutesInput } from '../../components/fields/InputMask';
import { MaskedTimeInput, validateTime, validateTimeinDecimal } from '../../components/fields/MaskedTimeInput';
import { AutocompleteInput, DatePickerInput, HoursFormatInput } from '../../components/fields/inputs';
import { useIdentityContext, usePermissionsOptimized } from '../../components/identity';
import AddTimePage from '../../layout/AddTimePage';
import { List } from '../../layout/List';
import { ResponsiveFilterGusser } from '../../layout/ResponsiveFilter';
import AutoSaveInlineForm from '../../ra-editable-datagrid/AutoSaveInlineForm';
import { canAccess, usePermissions } from '../../ra-rbac';
import { CONSTANT_DATA, isMobile, PROPAY_COLUMN_NAME, truncatePropayName , USER_TYPE} from '../../utils/Constants/ConstantData';
import { ATTENDANCE_COLUMNS, NUMBER } from '../../utils/Constants/MagicNumber';
import { StyledReferenceArrayInput } from '../payrolls/Payrolls';
import { Filter, StyleToolbar, StyledStack, parseTime } from '../payrolls/weeklyEntries';
import { StyleDateTimeField } from '../propays';
import { StyledCreate, formStyle } from '../propays/Propay';
import AttendanceFilterModal, { AddTimeButton, CustomAttendanceSearcInput, FilterModalButton } from './AttendanceFilterModal';
import AttendanceTeamDropdown from './AttendanceTeamDropdown';
import AttendenceCardListing from './AttendenceCardListing';
import BulkActionDropdown from './BulkActionDropdown';
import ImportDone from './ImportDonePage';
import { getHours } from './getHours';
import { useAttendance } from './useAttendance';
import HandleAttendanceImport from './FileImport';
import { BulkDeleteWithConfirmButton } from './BulkDeleteWithConfirmButton';
import { CustomReferenceInputObj } from '../../components/fields/CustomReferenceInputObj';
import { Tooltip } from '@mui/material';
import { useQueryClient } from 'react-query';
import { EditColumnsOpenDrawer } from '../../layout/ColumnDrawer';
import { EmployeeUpdateForm, NotificationIcon } from '../employees/Employee';
import DialogForm from '../../components/DialogForm';
import EditableField from '../../ra-editable-datagrid/EditableField';
import { FieldFormAttendance } from '../../ra-editable-datagrid/FieldForm';
import { BIN_ICON, CLOSE_ICON, NO_ATTENDANCE_ICON } from '../../utils/Constants/ClassName';
import { getPropayName } from '../reports/TotalBonusReport';
import UserAvtarAndName from '../../layout/UserAvtarAndName';
import ExportAttendance from './ExportAttendance';
import CustomMobileViewFilter from './CustomMobileViewFilter';
import TimeActions from './TimeActions';
import TimeTickIcon from '../../assets/propay-tick-icon.svg';
import CloseIcon from '@mui/icons-material/Close';
import NewAttendanceCardList from './NewAttendanceCardList';
import { useAttendanceConnector } from './useAttendanceConnector';
import { CustomJobReferenceInput } from '../../components/fields/CustomJobReferenceInput';
import { CustomPropayInput } from '../../components/fields/CustomPropayInput';
import Empty from '../../layout/Empty';

export const EmployeeFullNameField = ({ record }: any) => (
    <span style={{ color: '#231F20' }}>
        {record.first_name} {record.last_name}
    </span>
);
export const NameField = ({ record }: any) => <span>{record.name}</span>;

export const StyledSelectInput = styled(SelectInput)({
    'min-width': '150px',
});

export const StyledReferenceInput = styled(ReferenceInput)({
    'min-width': '150px',
});
export const StyledAutocompleteInputLarge = styled(AutocompleteInput)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        'min-width': 'unset',
    },
    [theme.breakpoints.up('sm')]: {
        'min-width': '200px',
    }
}));

export const StyledReferenceField = styled(ReferenceField)({
    'min-width': '150px',
});

export const StyledSelectArrayInput = styled(SelectArrayInput)({
    'min-width':'120px'
});

export const StyledDatePickerInput = styled(DatePickerInput)({
   minWidth:100
});



export const ATTENDANCE_STATUS = [
    { id: 'pending', name: 'resources.attendances.choices2.status.pending' },
    { id: 'paid', name: 'resources.attendances.choices2.status.paid' },
];

/*List of filters for mobile view */
export const AttendanceFilter = [
    <TextInput className='filter-search' source='employee_id_obj.name._ilike' label='Search' alwaysOn alwaysOnMobile size='medium' />,
    <StyledReferenceArrayInput
        size='medium'
        source='employee_id._in'
        reference='Employee__DropdownList'
        filter={{active: {_eq: false}, _or: {active: {_eq: true}}}}
        label='Worker'
        alwaysOn
    >
        <AutocompleteArrayInput source='name' />
    </StyledReferenceArrayInput>,
        <StyledSelectInput
        size='medium'
        source='status._eq'
        label='Status'
        alwaysOn
        choices={ATTENDANCE_STATUS}
        />,
    <DateRangeInputFilter source='start' alwaysOn startText={'From'} endText={'To'} />,
    <StyledReferenceInput
    source='propay_id._eq'
    reference='Propay__DropdownList'
    label={'ProPay'}
    size='medium'
    allowEmpty={false}
    >
     <AutocompleteArrayInput source="name" optionText={record => getPropayName(record)} />
    </StyledReferenceInput>,
    <StyledReferenceInput
        source='job_id._eq'
        reference='Job__DropdownList'
        label={'Job'}
        size='medium'
    >
        <StyledAutocompleteInputLarge optionText='full_name' />
    </StyledReferenceInput>,
    <StyledReferenceArrayInput
        source='period_id._in'
        reference='Period__DropdownList'
        label='Period'
        sort={{ field: 'start_date', order: 'DESC' }}
        filter={{ start_date: { _lte: moment().format('YYYY-MM-DD') } }}
    >
        <AutocompleteArrayInput source='name' />
    </StyledReferenceArrayInput>,
    <BooleanInput source="propay_id._is_null" className='checkbox-boolean'
    label={
    <div className='entry-filter-req-line'>
    Entries Require Attentions
    <InfoLabel sx={{ height: 10 }} icon='ri:error-warning-fill' height={12}>
    <Typography sx={{ fontSize: 10 }}>
    Check box will show all jobs with ProPays that are not yet matched.
    </Typography>
  </InfoLabel>
    </div>
    } alwaysOn />
];

/*List of filters for web view */
export const AttendanceFilterWeb = [
    <CustomAttendanceSearcInput className='filter-search' source='employee_id_obj.name._ilike' label='Search' alwaysOn alwaysOnMobile size='medium' />,
    <StyledReferenceArrayInput
        size='medium'
        source='employee_id._in'
        reference='Employee__DropdownList'
        filter={{active: {_eq: false}, _or: {active: {_eq: true}}}}
        label='Worker'
        alwaysOn
    >
        <AutocompleteArrayInput source='name' />
    </StyledReferenceArrayInput>,
        <StyledSelectInput
        size='medium'
        source='status._eq'
        label='Status'
        alwaysOn
        choices={ATTENDANCE_STATUS}
        />,
        <StyledReferenceArrayInput
            source='propay_id._eq'
            reference='Propay__DropdownList'
            label={'ProPay'}
            size='medium'
            alwaysOn
            allowEmpty={false}
        >
        <AutocompleteArrayInput source="name" optionText={record => getPropayName(record)} />
        </StyledReferenceArrayInput>,
        <StyledReferenceInput
                source='job_id._eq'
                reference='Job__DropdownList'
                label={'Job'}
                size='medium'
                alwaysOn
            >
          <StyledAutocompleteInputLarge optionText='full_name' />
       </StyledReferenceInput>
];

export const CustomTimeFilters = [<CustomAttendanceSearcInput className='filter-search' source='employee_id_obj.name._ilike' label='Search'
alwaysOn alwaysOnMobile size='medium' />,
<DateRangeInputFilter
className="date-input-filter"
source="start"
pageType= 'time'
alwaysOn
/>]

export const CustomPropayDetailTimeFilters = [<CustomAttendanceSearcInput className='filter-search' source='employee_id_obj.name._ilike' label='Search'
alwaysOn alwaysOnMobile size='medium' />,
<DateRangeInputFilter
className="date-input-filter"
source="start"
pageType= 'propayDetailTime'
alwaysOn
/>]

const StyledRowForm = styled(AutoSaveInlineForm)({
    ...formStyle,
});

const onChangeStartAndEnd = (fieldValue:any, name:string, allValues:any):any => {
    return getHours(allValues);
};

const validateEnd = (values: any,allValues:any) => {
    if (allValues.hours <= 0) {
        return 'End Date Must be greater than start date'
    };
    return undefined;
};

const onChangeJob = async (fieldValue:any, name:string, allValues:any) => {
    const result:any = {};
    if (fieldValue=== undefined){
        result['job_id'] = 0
    };
    return result;
};
// export const PropayInput = (props: any) => {
//     const identity = useIdentityContext();
//     // const { state } = useLocation();

//     return <FormDataConsumer >
//     {({ formData,getSource, ...rest }) => {
//         const filter: Filter = {
//             status: { _in: ["pending", "approved"] },
//             _and: {
//               show_to_all: { _eq: true },
//               _or: {
//                 budget: { _gt: 0 }, _or:{budget_hours: { _gt: 0 }}
//               },
//             }
//           };

//           if (formData?.job_id) {
//             filter.job_id = { _eq: formData.job_id };
//           }
//           if(identity?.company?.is_started_status){
//             filter.status = { _in: ["pending", "approved", 'started']};
//           }
//           if(identity?.company?.is_completed_status){
//             filter.status = { _in: ["pending", "approved", 'started', 'completed']};
//           }

//         return(
//             <CustomReferenceInputObj source='propay_id' allowEmpty filter={filter} reference='Propay__TimePageDropdownList' {...props} jobId={formData?.job_id}>
//                 <AutocompleteInput fullWidth  />
//             </CustomReferenceInputObj>
//             )
//     }}
//     </FormDataConsumer>
// };

export const PropayInput = (props: any) => {
    const identity = useIdentityContext();
    const [propayPerPage,setPropayPerPage]=useState(10)
    // const { state } = useLocation();

    return <FormDataConsumer >
    {({ formData,getSource, ...rest }) => {
        const filter: Filter = {
            parent_id: { _is_null: true },
            status: { _in: ["pending", "approved"] },
            _and: {
              show_to_all: { _eq: true },
              _or: {
                budget: { _gt: 0 }, _or:{budget_hours: { _gt: 0 }}
              },
            }
          };

          if (formData?.job_id) {
            filter.job_ids = { _in: [formData.job_id] };
          }
          if(identity?.company?.is_started_status){
            filter.status = { _in: ["pending", "approved", 'started']};
          }
          if(identity?.company?.is_completed_status){
            filter.status = { _in: ["pending", "approved", 'started', 'completed']};
          }

        return(
            <CustomPropayInput customClass="time-edit-jb-dn" source='propay_id' perPage={propayPerPage} filter={filter} setPerPage={setPropayPerPage}  reference='Propay__TimePageDropdownList' {...props} >
                <AutocompleteInput fullWidth  />
            </CustomPropayInput>
            )
    }}
    </FormDataConsumer>
};


export const CostCodeInputField = (props:any)=>{
    const {identity} = props
    return <FormDataConsumer >
    {({ formData }) => {
     return ( <StyledReferenceInput
         source="cost_code_id"
         reference="Costcode__DropdownList"
         filter={
            formData?.job_id && formData?.job_id_obj?.unique_cost_code
                 ? { job_id: { _eq: formData.job_id } }
                 : { job_id: { _is_null: true } }
         }
        //  validate={[identity?.company?.is_cost_code_required && required()]}
         filterToQuery={searchText => ({
             code: { _ilike: searchText },
         })}
     >
         <AutocompleteInput
             fullWidth
             optionText="code"
             optionValue="id"
         />
     </StyledReferenceInput>)
    }}
    </FormDataConsumer>
}
const JobInput = (props: any) => {
    return <StyledReferenceInput source='job_id' emptyValue={0} filter={{ has_propay: { _eq: true },propay_ids:{status:{_in:['pending','started','approved','completed']}},active: {_eq:true},has_children: {_eq:false} }} allowEmpty reference='Job__DropdownList' {...props} >
       <AutocompleteInput fullWidth optionText='full_name' />
    </StyledReferenceInput>
};

const JobCustomInput = (props: any) => {
    const [perJobPage,setJobPerPage]=useState(10);
    return <CustomJobReferenceInput
    setPerPage={setJobPerPage} perPage={perJobPage}
    source={'job_id' }
    reference="Job__DropdownList"
    className="job-create-propay"
    hideLabel={true}
    customClass="time-edit-jb-dn"
    filter={{ has_propay: { _eq: true },propay_ids:{status:{_in:['pending','started','approved','completed']}},active: {_eq:true},has_children: {_eq:false} }}

>
    <AutocompleteInput fullWidth/>
</CustomJobReferenceInput>
};

const todayDateOnly = moment().format('YYYY-MM-DD');

const ManualLabel=()=>{
    return <Typography>Manual</Typography>;
};
const ManualLabelResponsive=()=>{
    return <Typography className='manual-responsive'><label>End</label>Manual</Typography>;
};
const StartField = (props: any) => {
    const {isManual} = useAttendance(props);
    return  isManual ? <DateField className='attendance-date' source='date' isLocal={false}/>:<StartFieldByPayroll {...props} />;
};
const StartFieldResponsive = (props: any) => {
    const {isManual} = useAttendance(props);
    return  isManual ? <Typography className='manual-responsive'><label>Start</label>
    <DateField className='attendance-date' source='date' isLocal={false}/>
    </Typography>
    : <StartFieldByPayrollResponsive {...props} />;
};
const EndField = (props: any) => {
    const {isManual} = useAttendance(props);
    return isManual ? <ManualLabel/>:<EndFieldByPayroll {...props} />;
};
const EndFieldResponsive = (props: any) => {
    const {isManual} = useAttendance(props);
    return isManual ? <ManualLabelResponsive/>:<EndFieldPayrollResponsive {...props} />;
};

const EndFieldPayrollResponsive = (props: any) => {
    const {isPayPeriod} = useAttendance(props);
    return(
        <>
         {isPayPeriod ? <Typography className='manual-responsive'><label>End</label>By Payroll</Typography> :
         <Typography className='manual-responsive'><label>End</label><StyleDateTimeField className='attendance-date' source='end' /></Typography> }
        </>
    );
};

const AttendanceDateFieldInput=(props:any)=>{
    return <StyledDatePickerInput className='attendance-date' {...props} source='date' />;
};

const AttendanceCheckInFieldInput=(props:any)=>{
    const todayDateTime = moment().set('second', 0).format('YYYY-MM-DD HH:mm:ss');
    return <DateTimePickerField className='attendance-date' source='start' validate={required()} {...props} defaultValue={todayDateTime}/>;
};

const StartInput = (props:any) =>{
    const {isManual} = useAttendance(props);
    return isManual? <AttendanceDateFieldInput {...props}/>:<StartInputByPayroll {...props}/>;
};

const StartInputByPayroll = (props: any) => {
    const {isPayPeriod} = useAttendance(props);
    return(
        <>
        {isPayPeriod ? <ReferenceInputObj size='medium' source='period_id' reference='Period__DropdownList' label='Payroll period' className='payroll-period-time'>
        <StyledAutocompleteInputLarge source='name' />
        </ReferenceInputObj>: <AttendanceCheckInFieldInput {...props}/>}
        </>
    );
};
const StartFieldByPayroll = (props: any) => {
    const {isPayPeriod} = useAttendance(props);
    return(
        <>
         {isPayPeriod ? <DateField className='attendance-date' source='period_start_date' isLocal={false}/> : <StyleDateTimeField className='attendance-date' source='start' />}
        </>
    );
};
const StartFieldByPayrollResponsive = (props: any) => {
    const {isPayPeriod} = useAttendance(props);
    return(
        <>
         {isPayPeriod ? <Typography className='manual-responsive'><label>Start</label>
         <DateField className='attendance-date attendance-date-Typography' label='Start' source='period_start_date' isLocal={false}/>
         </Typography>
         : <Typography className='manual-responsive'><label>Start</label><StyleDateTimeField className='attendance-date' source='start' /></Typography>}
        </>
    );
};
const EndInputByPayroll = (props:any) => {
    const {isPayPeriod} = useAttendance(props);
    const todayDateTime = moment().set('second', 0).format('YYYY-MM-DD HH:mm:ss');
    return(
        <>
         {isPayPeriod ? <Typography className='manual-responsive'>By Payroll</Typography>:
         <DateTimePickerField className='attendance-date' {...props} source='end' validate={[required(), validateEnd]} defaultValue={todayDateTime}/> }
        </>
    );
};
const EndFieldByPayroll = (props: any) => {
    const {isPayPeriod} = useAttendance(props);
    return(
        <>
         {isPayPeriod ? <DateField className='attendance-date' source='period_end_date' isLocal={false}/> :
         <StyleDateTimeField className='attendance-date' source='end' />}
        </>
    );
};

const HoursInputs = (props: any) => {
    const {isManual, isPayPeriod} = useAttendance(props);
    const identity = useIdentityContext();
    const validateTimeType = identity?.company?.hours_format === 'by_time' ? validateTime : validateTimeinDecimal;
    return isManual || isPayPeriod ?
        <MaskedTimeInput source={props.source} style={{ width: 55 }} variant='standard' label='' placeholder='Hours' validate={[required(),validateTimeType]} />
        : <HoursFormatInput source={props.source} style={{ width: 55 }} variant='standard' label='' disabled={props.disabled} />;
};

const BreakHoursInput = (props: any) => {
    // const {isManual, isPayPeriod} = useAttendance(props);
    const identity = useIdentityContext();
    const validateTimeType = identity?.company?.hours_format === 'by_time' ? validateTime : validateTimeinDecimal;
    return <MaskedTimeInput source={props.source} style={{ width: 55 }} variant='standard' label='' placeholder='Hours' validate={[required(),validateTimeType]} />
};


const HoursInputResponsive = (props: any) => {
    const {isManual, isPayPeriod} = useAttendance(props);
    const identity = useIdentityContext();
    const validateTimeType = identity?.company?.hours_format === 'by_time' ? validateTime : validateTimeinDecimal;
    return isManual || isPayPeriod ?
        <MaskedTimeInput source='hours' style={{ width: 55 }} variant='standard' label='Hours' validate={[required(),validateTimeType]}/>
        : <HoursFormatInput source='hours' style={{ width: 55 }} variant='standard' label='Hours' disabled={true} />;
};

const BreakHoursInputResponsive = (props: any) => {
    const {isManual, isPayPeriod} = useAttendance(props);
    const identity = useIdentityContext();
    const validateTimeType = identity?.company?.hours_format === 'by_time' ? validateTime : validateTimeinDecimal;
    return isManual || isPayPeriod ?
        <MaskedTimeInput source='break_hours' style={{ width: 55 }} variant='standard' label='Break' validate={[required(),validateTimeType]}/>
        : <HoursFormatInput source='break_hours' style={{ width: 55 }} variant='standard' label='Break' disabled={true} />;
};

const EndInput = (props:any) =>{
    const {isManual} = useAttendance(props);
    return isManual?<ManualLabel/>: <EndInputByPayroll {...props}/>;

};

const EndInputResponsive = (props:any) =>{
    const {isManual} = useAttendance(props);
    return isManual?<ManualLabelResponsive/>:< EndInputByPayroll {...props}/>;
};

const EmployeeField = (props:any)=>{
    const {record} = props;
    return  (<FunctionField render={()=>(<>{record?.employee_id_obj?.display_name}</>)} {...props}/>);
};
const ZapierList = (props:any) => {
    const { columns,identity ,IsVisible,getLabel} = props;
    const calculator = useMemo(() => {
        return [
            createDecorator(
                {
                    field: 'start',
                    updates: onChangeStartAndEnd,
                },
                {
                    field: 'end',
                    updates: onChangeStartAndEnd,
                },
                {
                    field:'job_id',
                    updates: onChangeJob,
                }
                            ),
        ];
    }, []);

    const transform = (data:any) =>{
        _.set(data, 'hours',parseTime(data.hours) || 0.0);
        _.set(data, 'break_hours',parseTime(data.break_hours) || 0.0);
        if(data?.period_id_obj?.start_date){
            _.set(data,'date', data?.period_id_obj?.start_date)
            _.unset(data, 'period_id')
        }
        return data;
    };
    return (
        <StyledRowForm {...props} decorators={calculator} transform={transform}>
            {IsVisible(ATTENDANCE_COLUMNS.name) && <EmployeeField {...props}/>}
           {IsVisible(ATTENDANCE_COLUMNS.startDate) && <StartInput variant='standard' label='Start' source='start'/>}
            {IsVisible(ATTENDANCE_COLUMNS.endDate) &&<EndInput variant='standard' label='End' source='end'/>}
            {IsVisible(ATTENDANCE_COLUMNS.hours) &&<HoursInputs source='hours'  style={{ width: 50 }}  variant='standard' label='' disabled={true}/>}
            {IsVisible(ATTENDANCE_COLUMNS.break) &&<BreakHoursInput source='break_hours' style={{ width: 50 }}  variant='standard' label='' disabled={true}/>}
           {IsVisible(ATTENDANCE_COLUMNS.job) &&  <JobInput variant='standard' label='' source='job_id'/>}
           {IsVisible(ATTENDANCE_COLUMNS.propay) &&  <PropayInput source='propay_id' variant='standard' label=''  classToAdd='time-page-listing'/>}
           {(IsVisible(ATTENDANCE_COLUMNS.costCode) && identity?.company?.can_use_cost_code) &&  <CostCodeInputField identity={identity} source='cost_code_id' /> }
           {IsVisible(ATTENDANCE_COLUMNS.wage) && <MoneyField source="base_wage" label={getLabel(ATTENDANCE_COLUMNS.wage)}/> }
           { IsVisible(ATTENDANCE_COLUMNS.earnings) && <MoneyField source="earning" label={getLabel(ATTENDANCE_COLUMNS.earnings)} /> }
           {IsVisible(ATTENDANCE_COLUMNS.status) && <AttendanceStatusLabelField resource='attendances' source='status' colors={{'paid':'success'}}/>}

        </StyledRowForm>
    );
};

const IntegratedCompanyList = (props: any) => {
    const { identity,IsVisible,getLabel} = props;
    return (
        <>
        <StyledRowForm {...props} >
        {IsVisible(ATTENDANCE_COLUMNS.name) && <EmployeeField label='Worker' {...props}/>}
          {IsVisible(ATTENDANCE_COLUMNS.startDate) &&  <StartField source='start' label='Start' />}
           {IsVisible(ATTENDANCE_COLUMNS.endDate) &&  <EndField source='end' label='End' />}
          {IsVisible(ATTENDANCE_COLUMNS.hours) &&  <FormatTimeField source='hours' />}
          {IsVisible(ATTENDANCE_COLUMNS.break) &&  <FormatTimeField source='break_hours' />}
           {IsVisible(ATTENDANCE_COLUMNS.job) &&  <StyledReferenceField source='job_id' reference='Job__DropdownList' link={false}>
                <TextField source='full_name' />
            </StyledReferenceField>}
           {IsVisible(ATTENDANCE_COLUMNS.propay) && <PropayInput variant='standard' label='' source='propay_id' classToAdd='time-page-listing'/>}
           {(IsVisible(ATTENDANCE_COLUMNS.costCode) && identity?.company?.can_use_cost_code) &&  <CostCodeInputField identity={identity} source='cost_code_id'/> }
           {IsVisible(ATTENDANCE_COLUMNS.wage) && <MoneyField source="base_wage" label={getLabel(ATTENDANCE_COLUMNS.wage)}/> }
           { IsVisible(ATTENDANCE_COLUMNS.earnings) && <MoneyField source="earning" label={getLabel(ATTENDANCE_COLUMNS.earnings)} /> }
           {IsVisible(ATTENDANCE_COLUMNS.status) &&  <AttendanceStatusLabelField resource='attendances' source='status' colors={{ 'paid': 'success' }} />}
        </StyledRowForm>
        </>
    );
};

export const AttendanceCreateRowForm = (props: any) => {
    const identity = useIdentityContext();
        return getIntegratedNonIntegrated(identity) ?
        <ZapierList identity={identity} {...props}/> :
        <IntegratedCompanyList {...props}  />
};
const AttendanceEditSpecificColumn = ({ FieldOne, FieldTwo,canEditEntries}) => {
    const identity = useIdentityContext();
    return canEditEntries ? FieldOne : FieldTwo;
  };


const StyledEditableDatagridWithPermission = styled(EditableDatagridWithPermission)({
    '.column-status' : {width: '0%'}
});

const AttendanceList = (props: any) => {
    const { pathname } = useLocation();
    const { propayBool, budgetPropayId, record, isGroupPropay, totalTimeEntries } = props;
    const { permissions } = usePermissionsOptimized();
    const identity = useIdentityContext();
    const dataProvider = useDataProvider();
    const [userFieldsData, setUserFileds] = useState([]);
    const isMobileDevice = isMobile();
    const [connectorList,setConnectorList] = useState([]);
    const checkInOutAddtime = canAccessCheckInOut({
        permissions,
        resource: 'feature_check_in_out',
    });
    const isExternallyAttendanceImported = false;//useAttendanceConnector();

    useEffect(() => {
        const dashboardApiCalls = async () => {
            const userFields = await dataProvider.getList('userFields',{pagination: { page: NUMBER.ONE, perPage: NUMBER.FIFTY },
            sort:{field: 'field_id', order: 'ASC'},filter:{model: {_eq: 'protiv.attendance'}}});
            userFields?.data && userFields?.data?.forEach((item,i)=>{
                if( item.field_id === ATTENDANCE_COLUMNS.name ){
                    userFields?.data?.splice(i, NUMBER.ONE);
                    userFields?.data?.unshift(item);
                }
                });

            setUserFileds(_.cloneDeepWith(userFields?.data));
        }
        dashboardApiCalls();
    }, [dataProvider]);

    useEffect(()=>{
        const getConnectorList = async ()=>{
            const data = await getDuplicateConnectorList();
            setConnectorList(!data.length ? []: data)
        }
        getConnectorList();
    },[])


    const dataFilterd = userFieldsData && userFieldsData?.filter(item=> item.field_id !== ATTENDANCE_COLUMNS.manager )
    const updatedDataFilter = identity?.user_type !== USER_TYPE.worker ? dataFilterd : dataFilterd && dataFilterd?.filter(ele=> ele.field_id !== ATTENDANCE_COLUMNS.action && ele.field_id !== ATTENDANCE_COLUMNS.manager)
    return (
        <>
        { (pathname === '/attendances' || propayBool) && (isMobileDevice ? <AttednadnceResponsive totalTimeEntries={totalTimeEntries} isGroupPropay={isGroupPropay} budgetPropayId={budgetPropayId} record={record} propayBool={propayBool} connectorList={connectorList} showFilter={false} {...props} setUserFileds={setUserFileds} userFields={_.cloneDeepWith(updatedDataFilter)} columns={_.cloneDeepWith(updatedDataFilter)} /> :
               <AttendanceListWithResponsive totalTimeEntries={totalTimeEntries} isGroupPropay={isGroupPropay} propayBool={propayBool} propayRecord={record} isExternallyAttendanceImported={isExternallyAttendanceImported} budgetPropayId={budgetPropayId} connectorList={connectorList} showFilter={false} {...props} setUserFileds={setUserFileds} userFields={_.cloneDeepWith(updatedDataFilter)} columns={_.cloneDeepWith(updatedDataFilter)}/>) }
        <Routes>
        <Route path='create' element={
            checkInOutAddtime && !propayBool ?
            (<ResourceContextProvider value='attendances'>
                 <AttendanceCreateDialog />
             </ResourceContextProvider>) :
             (<AddTimePage/>)
         }/>
         <Route path=':id/edit' element={
             <ResourceContextProvider value='attendances'>
                 <AttendanceEditDialog columns={updatedDataFilter} />
             </ResourceContextProvider>
         }/>
        </Routes>
        </>
    );
};

export const ImportDoneTab = () => {
    const navigate = useNavigate();
    const identity = useIdentityContext();
    if(!getIntegratedNonIntegrated(identity)){
        navigate('/attendances');
    }
    return(
        <>
          <ResourceContextProvider value='attendances'>
            <ImportDone />
          </ResourceContextProvider>
        </>
    );
}
export const ImportTab = () => {
    const navigate = useNavigate();
    const identity = useIdentityContext();
    if(!getIntegratedNonIntegrated(identity)){
        navigate('/attendances');
    }
    return(
        <>
          <ResourceContextProvider value='attendances'>
            <HandleAttendanceImport />
          </ResourceContextProvider>
        </>
    )
}

export default AttendanceList;

const AttednadnceResponsive = (props: any) => {
    const { columns,connectorList=[], propayBool,budgetPropayId, record,isExternallyAttendanceImported, totalTimeEntries, isGroupPropay,setUserFileds } = props;
    const identity = useIdentityContext();
    const canEditEntries = true;//isExternallyAttendanceImported ? isExternallyAttendanceImported && identity?.company.allow_import_from_file  : true ;
    const [bulkAction,setBulkAction]= useState('');
    const [selectedIds, setSelectedIds] = useState([]);
    const [hideAction,setHideAction]= useState(true);
    const { loading, permissions } = usePermissions();
    const showCreate = !loading && canEditEntries && (canAccess({
        permissions,
        resource: 'attendances',
        action: 'create',
    }) && identity?.allow_to_add_time)
    const [timeFilter,setTimeFilter]=useState({});

    const transform = (data:any) =>{
        _.set(data, 'hours',parseTime(data.hours) || 0.0);
        _.set(data, 'break_hours',parseTime(data.break_hours) || 0.0);
        if(data?.period_id_obj?.start_date){
            _.set(data,'date', data?.period_id_obj?.start_date)
            _.unset(data, 'period_id')
        }
        return data;
    };
    const calculator = useMemo(() => {
        return [
            createDecorator(
                {
                    field: 'start',
                    updates: onChangeStartAndEnd,
                },
                {
                    field: 'end',
                    updates: onChangeStartAndEnd,
                },
                {
                    field:'job_id',
                    updates: onChangeJob,
                }),
        ];
    }, []);

    return(

        <div className='attendance-wrapper'>
        {!propayBool && <h2 className='MuiTypography-root MuiTypography-h2 main-title main-title-mobile'>Time</h2>}
        <List
            {...props}
            empty={false}
            hideTitle={propayBool ? true : false}
            title={'Attendance'}
            showPagination={(isGroupPropay && totalTimeEntries == 0) ? false : true}
            titleActionProps={propayBool ? {} : { label:'Add Time',showCreate: showCreate}}
            filterDefaultValues={{type:{_in:['regular','manual']}, ...(budgetPropayId ? {job_id: budgetPropayId} : {})}}
            actions={<ListActions exporter={false} />}
            //filters={<ResponsiveFilterGusser filters={AttendanceFilter}/>}
            sort={{ field: 'create_date', order: 'DESC' }}
            className='attendance-page propay-listing'
            emptyWhileLoading
            disableSyncWithLocation={CONSTANT_DATA.true}
            filter={timeFilter}

        >

            <div className='time-propay-mobile-row'>
            <CustomMobileViewFilter setUserFileds={setUserFileds} selectedIds={selectedIds} setSelectedIds={setSelectedIds} connectorList={connectorList} columns={columns} record={record} identity={identity} setTimeFilter={setTimeFilter} timeFilter={timeFilter} propayBool={propayBool}/>
            {!propayBool &&
            <div className='time-card-export'>
            <ExportAttendance columnOptions={columns} identity={identity} attendanceFilter={timeFilter}/>
            </div>
            }
          {!propayBool && <EditColumnsOpenDrawer propayBool={propayBool} {...props} options={columns} showTimeFilter={false}/>}
            </div>
            {propayBool ?
            <NewAttendanceCardList totalTimeEntries={totalTimeEntries} isGroupPropay={isGroupPropay} transform={transform} calculator={calculator} selectedIds={selectedIds} setSelectedIds={setSelectedIds} budgetPropayId={budgetPropayId} connectorList={connectorList} identity={identity} columns={columns} attendanceFilter={timeFilter}/>
            :
            <AttendenceCardListing connectorList={connectorList} identity={identity} columns={columns} />
            }

            {/*Propay Detail Time Tab Responsive Card Design */}

        </List>
        </div>
    );
};
const BulkActionButtons = (props: BulkActionProps) => (
        <BulkDeleteWithConfirmButton {...props} mutationMode ='pessimistic' />
);
export const PropayNameWithMilestone = (props) => {
    const { record } = props;
    return (
    <>
        {record?.parent_id ? <span className='time-propay-border'>
            <NameWithTootip propayName={record?.parent_id_obj?.display_name?.toString()} />
            <NameWithTootip propayName={` > ${record?.name?.toString()}`} /></span> :
            <NameWithTootip propayName={record?.name?.toString()} propayClass='time-propay-border'/>}
    </>
    )
};

export const NameWithTootip = (props)=> {
    const {propayName,propayClass} = props;
    return(
      <>
      {propayName?.length > NUMBER.FIFTY ? <Tooltip title={propayName} placement="bottom" arrow>
          <span className={`${propayClass ? propayClass :''}`}>{truncatePropayName(propayName)}</span>
          </Tooltip>
          :
          <span className={`${propayClass ? propayClass :''}`}>{propayName}</span>
          }
      </>
    );
  };

const AttendanceListWithResponsive = (props:any) => {
    const { columns,connectorList=[], propayBool, budgetPropayId,isExternallyAttendanceImported,propayRecord, isGroupPropay, totalTimeEntries } = props;
    const identity = useIdentityContext();
   const { loading, permissions } = usePermissions();
    const [showBulkClose,setShowBulkClose] = useState(false);
    const [bulkIds,setBulkIds]=useState(null);
    const [attendanceFilter,setAttendanceFilter]=useState({});
    const [bulkAction,setBulkAction]= useState('');
    const [hideAction,setHideAction]= useState(true);
    const prevSort = JSON.parse(localStorage.getItem(propayBool ? 'propayDetailTimeSetting' : 'timePageSetting'));
    const timeSort = prevSort ? JSON.parse(prevSort.sort) : { field: 'create_date', order: 'DESC' };
    const [scrollCl, setScrollClass] = useState('');
    const canEditEntries = true;//isExternallyAttendanceImported ? isExternallyAttendanceImported && identity?.company.allow_import_from_file  : true ;
    const customCursorClass = canEditEntries ? 'time-cursor-pointer':'';
    const borderClass = canEditEntries ? 'time-propay-border':'';
    const showManualTag = isExternallyAttendanceImported && identity?.company.allow_import_from_file
    const showCreate = !loading && canEditEntries && (canAccess({
        permissions,
        resource: 'attendances',
        action: 'create',
    }) && identity?.allow_to_add_time)
    const allowDeletable = (record:Record) => {
        return record.status==='pending' && (identity?.user_type !== 'worker' || identity?.allow_to_add_time) &&  !record?.propay_id_obj?.is_restricted && (showManualTag && !getParsedConnectorName(record?.job_id_obj?.connected_name) && connectorList.length > NUMBER.ZERO)
    };
    const allowEditable = (record:Record) => {
        return record.status==='pending' && (identity?.user_type !== 'worker' || identity?.allow_to_add_time) &&  !record?.propay_id_obj?.is_restricted
    };
    const [filterCount, setFilterCount] = useState(NUMBER.ZERO);
    const [anchorEl, setAnchorEl] = useState(null);

    const showModal = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const propayDetailColumns = columns.filter(item => item.field_id_obj.name !== PROPAY_COLUMN_NAME.ACTION);
    const isMobileDevice = isMobile();
    const IsVisible = (id)=>(propayBool ? propayDetailColumns : columns) && (propayBool ? propayDetailColumns?.filter(item=> item.field_id === id)[0]?.is_visible : columns?.filter(item=> item.field_id === id)[0]?.is_visible);
    const getLabel = (id)=> (columns) && columns?.filter(item=> item.field_id === id)[0]?.field_id_obj?.name;
    const queryClient = useQueryClient();
    const ApiCallAfterEditSuccess  = ()=>{
        queryClient.invalidateQueries(['attendances', 'getList']);
    }
    const transform = (data:any) =>{
        _.set(data, 'hours',parseTime(data.hours) || 0.0);
        _.set(data, 'break_hours',parseTime(data.break_hours) || 0.0);
        if(data?.period_id_obj?.start_date){
            _.set(data,'date', data?.period_id_obj?.start_date)
            _.unset(data, 'period_id')
        }
        return data;
    };
    const calculator = useMemo(() => {
        return [
            createDecorator(
                {
                    field: 'start',
                    updates: onChangeStartAndEnd,
                },
                {
                    field: 'end',
                    updates: onChangeStartAndEnd,
                },
                {
                    field:'job_id',
                    updates: onChangeJob,
                }),
        ];
    }, []);
    useEffect(() => {
        const handleScroll = () => {
          const currentPosition = window.scrollY || window.pageYOffset;
          const isAtBottom = Math.ceil(currentPosition + window.innerHeight) >= document.body.scrollHeight;
          if (isAtBottom) {
            setScrollClass('bulk-listing-table');
          }else{
            setScrollClass('');
          }
        };
        window.addEventListener('scroll', handleScroll);
        return(()=>{ window.removeEventListener('scroll', handleScroll);})
      }, []);

    return (
        <>
        <div className='attendance-wrapper'>
        {!propayBool && <h2 className='MuiTypography-root MuiTypography-h2 main-title main-title-mobile'>Time</h2>}
        {/* <FilterModalButton handleShow={handleClick} filterCount={filterCount} {...props}/> */}
        {showCreate && !propayBool && <AddTimeButton />}
        <div className={`${(identity?.user_type === USER_TYPE.worker && !identity?.allow_to_add_time) ? 'worker-time-filter-toolbar':''} time-filter-toolbar`}>
        <List
            {...props}
            hideTitle={propayBool ? true : false}
            showPagination={(isGroupPropay && totalTimeEntries == 0) ? false : true}
            title={'Attendance'}
            empty={false}
            titleActionProps={{ showCreate: false }}
            actions={(isMobileDevice || (identity?.user_type === USER_TYPE.worker && !identity?.allow_to_add_time)) ? <ListActions exporter={false}/>
            : <AttendanceTeamDropdown propayBool={propayBool} propayRecord={propayRecord} isExternallyAttendanceImported={isExternallyAttendanceImported} columns={columns} setBulkAction={setBulkAction} setHideAction={setHideAction} setShowBulkClose={setShowBulkClose} attendanceFilter={attendanceFilter} identity={identity}/>}
            filters={<ResponsiveFilterGusser filters={propayBool ? CustomPropayDetailTimeFilters : CustomTimeFilters}/>}
            sort={timeSort}
            filter={attendanceFilter}
            className='attendance-page propay-listing propay-listing-columns'
            disableSyncWithLocation={CONSTANT_DATA.true}
            filterDefaultValues={{type:{_in:['regular','manual']}, ...(budgetPropayId ? { job_id: budgetPropayId}  : {})}}
        >
            <>
            <EditColumnsOpenDrawer propayRecord={propayRecord} {...props} setAttendanceFilter={setAttendanceFilter} options={columns} propayBool={propayBool} showTimeFilter={true} attendanceFilter={attendanceFilter} showTimeExport={(identity?.user_type === 'worker' && !identity?.allow_to_add_time)}/>
            {(bulkAction && hideAction) && <div className='close-statement-toolbar'>
                <div className='close-bulk-action-text'>
               <IconButton onClick={()=>{setBulkAction('');setHideAction(true)}} size="medium" className='cross-statement'>
                    <CLOSE_ICON />
                </IconButton>
                Select entries to {bulkAction}
                <Button className='bulk-approve-button' disabled>{bulkAction}</Button>
               </div>
               </div>}
                <div className='time-listing-scroll common-listing-scroll'>
                <StyledEditableDatagrid
                    data-testid='store-datagrid'
                    isRowDeletable={(record: any) => canEditEntries && allowDeletable(record) && IsVisible(ATTENDANCE_COLUMNS.action )}
                    isRowEditable = {(record: any) =>allowEditable(record)}
                    isRowSelectable={record => (record.status !== 'paid' && !record.propay_id_obj.is_restricted) && (identity?.user_type !== USER_TYPE.worker || identity?.allow_to_add_time)}
                    size='small'
                    noDelete={propayBool}
                    bulkActionButtons={bulkAction ? <TimeActions propayBool={propayBool || isExternallyAttendanceImported} setHideAction={setHideAction} hideAction={hideAction} setBulkAction={setBulkAction} bulkAction={bulkAction} /> :<BulkActionDropdown propayBool={propayBool || isExternallyAttendanceImported} setShowBulkClose={setShowBulkClose} setBulkIds={setBulkIds} identity={identity} />}
                    onEditSuccess={()=>ApiCallAfterEditSuccess()}
                    empty={
                        propayBool ? <div className='no-attendance-grouped'>
                            {NO_ATTENDANCE_ICON()}
                            {'No attendance assigned'} <br />
                            {propayRecord?.is_group_propay ? 'to this GroupProPay' : 'to this ProPay'}
                        </div> : <Empty />
                    }
                    defaultTitle={null}
                    resource='attendances'
                    stickyHeader
                    className={`data-grid-listing ${!IsVisible(ATTENDANCE_COLUMNS.action || (identity?.user_type === USER_TYPE.worker && !identity?.allow_to_add_time)) ? 'hide-action-time':''}`}
                >
                    {IsVisible(ATTENDANCE_COLUMNS.name) &&
                    <FunctionField
                      textAlign="right"
                      source='name'
                      label="Name"
                      data-title="Name"
                      sortBy='employee_id'
                      render={(record: any) => {
                        return(<><div><p className='time-name-flex'><UserAvtarAndName  hideAvatar={true} record={record.employee_id_obj}/></p>
                        {record.propay_id_obj.is_restricted ? <RestrictedTag/>:''}
                        {connectorList.length === NUMBER.TWO && getParsedConnectorName(record?.job_id_obj?.connected_name) ? <div className='zapier-tag'>{getParsedConnectorName(record.job_id_obj.connected_name)}</div>: (showManualTag && connectorList.length === NUMBER.TWO) ? <div className='zapier-tag'>Manual</div>:''}
                        {showManualTag && !getParsedConnectorName(record?.job_id_obj?.connected_name) && connectorList.length !== NUMBER.TWO ?  <div className='zapier-tag'>Manual</div>:''}
                         </div></>)
                         }}/>
                    }
                    {IsVisible(ATTENDANCE_COLUMNS.startDate) &&
                    <EditableField source='start' label={getLabel(ATTENDANCE_COLUMNS.startDate)} noSaveButton form={<FieldFormAttendance transform={transform} decorators={calculator}>
                        <FunctionField source='start' render={(record)=>(
                        <AttendanceEditSpecificColumn canEditEntries={canEditEntries} FieldOne={<StartInput variant='standard' label='Start' source='start'record={record}/>} FieldTwo={<StartField source='start' label='Start' record={record}/>} />
                        )}/>
                        </FieldFormAttendance>}>
                    <StartField source='start' label={getLabel(ATTENDANCE_COLUMNS.startDate)}  />
                     </EditableField>
                    }
                    {IsVisible(ATTENDANCE_COLUMNS.endDate) &&
                    <EditableField source='end' label={getLabel(ATTENDANCE_COLUMNS.endDate)} noSaveButton form={<FieldFormAttendance transform={transform} decorators={calculator}>
                        <FunctionField source='end' render={(record)=>(
                        <AttendanceEditSpecificColumn canEditEntries={canEditEntries} FieldOne={<EndInput variant='standard' label='End' source='end'record={record}/>} FieldTwo={<EndField source='end' label='End' record={record}/>} />
                        )}/>
                        </FieldFormAttendance>}>
                    <EndField source='end' label={getLabel(ATTENDANCE_COLUMNS.endDate)} className={`no-translate ${customCursorClass}`}/>
                    </EditableField>
                    }
                   { IsVisible(ATTENDANCE_COLUMNS.hours) &&
                   <EditableField source='hours' label={getLabel(ATTENDANCE_COLUMNS.hours)} noSaveButton form={<FieldFormAttendance transform={transform}>
                    <FunctionField source='hours' render={(record)=>(
                    <AttendanceEditSpecificColumn canEditEntries={canEditEntries} FieldOne={<HoursInputs source='hours'  style={{ width: 50 }}  variant='standard' label='' disabled={true} record={record}/>} FieldTwo={<FormatTimeField source='hours' record={record}/>} />
                    )}/>
                    </FieldFormAttendance>}>
                   <FormatTimeField source='hours' label={getLabel(ATTENDANCE_COLUMNS.hours)} className={`no-translate ${customCursorClass}`}/>
                   </EditableField>
                   }
                   { IsVisible(ATTENDANCE_COLUMNS.break) &&
                   <EditableField source='break_hours' label={getLabel(ATTENDANCE_COLUMNS.break)} noSaveButton form={<FieldFormAttendance transform={transform} decorators={calculator}>
                    <FunctionField source='break_hours' render={(record)=>(
                    <AttendanceEditSpecificColumn canEditEntries={canEditEntries} FieldOne={<BreakHoursInput source='break_hours' style={{ width: 50 }}  variant='standard' label='' disabled={true} record={record}/>} FieldTwo={<FormatTimeField source='break_hours' record={record}/>} />
                    )}/>
                    </FieldFormAttendance>}>
                   <FormatTimeField source='break_hours' label={getLabel(ATTENDANCE_COLUMNS.break)} className={`no-translate ${customCursorClass}`}/>
                   </EditableField>
                   }
                    { IsVisible(ATTENDANCE_COLUMNS.job) &&
                    <EditableField source='job_id' label={getLabel(ATTENDANCE_COLUMNS.job)} noSaveButton form={<FieldFormAttendance transform={transform} decorators={calculator}>
                                        <FunctionField source='job_id' className={`no-translate ${customCursorClass}`} render={(record)=>(
                        <AttendanceEditSpecificColumn canEditEntries={canEditEntries} FieldOne={<JobCustomInput variant='standard' label='' source='job_id' {...props} record={record} />} FieldTwo={<FunctionField source='job_id' label={getLabel(ATTENDANCE_COLUMNS.job)} render={(record: any) => {
                      return(<>{record?.job_id ? <span className={`no-translate ${borderClass}`}>{record?.job_id_obj?.full_name}</span> : <span className={borderClass}></span> }</>)
                       }}/>} />
                       )}/>
                        </FieldFormAttendance>}>
                    <FunctionField source='job_id' label={getLabel(ATTENDANCE_COLUMNS.job)} className={`no-translate ${customCursorClass}`} render={(record: any) => {
                      return(<>{record?.job_id ? <span className={`no-translate ${borderClass}`}>{record?.job_id_obj?.full_name}</span> : <span className={borderClass}></span> }</>)
                       }}/>
                    </EditableField>

                    }
                    { IsVisible(ATTENDANCE_COLUMNS.propay) &&
                        <EditableField source='propay_id' label="ProPay" className='no-translate time-cursor-pointer' noSaveButton form={<FieldFormAttendance transform={transform} decorators={calculator}>
                          <FunctionField source='propay_id' render={(record)=>(
                            <AttendanceEditSpecificColumn canEditEntries={canEditEntries} FieldOne={<PropayInput source='propay_id' variant='standard' label=''  classToAdd='time-page-listing' record={record}/>}
                                    FieldTwo={<PropayInput source='propay_id' variant='standard' label=''  classToAdd='time-page-listing' record={record}/>}/>
                                    )}/>
                            </FieldFormAttendance>}>
                            <FunctionField source='propay_id' label="ProPay" render={(record: any) => {
                                return(<>{record?.propay_id ? <PropayNameWithMilestone record={record?.propay_id_obj}/> : <span className="time-propay-border"></span>}</>)
                                    }}/>
                        </EditableField>
                    }
                    {(IsVisible(ATTENDANCE_COLUMNS.costCode) && identity?.company?.can_use_cost_code) &&
                        <EditableField source='cost_code_id' className={`no-translate ${customCursorClass}`} label="Cost Codes" noSaveButton form={<FieldFormAttendance transform={transform} decorators={calculator}>
                          <FunctionField source='cost_code_id' render={(record)=>(
                            <AttendanceEditSpecificColumn canEditEntries={canEditEntries} FieldOne={<CostCodeInputField identity={identity} source='cost_code_id' record={record}/>} FieldTwo={<FunctionField source='cost_code_id' label="Cost Codes" render={(record: any) => {
                                return(<>{record?.cost_code_id ? <span>{record?.cost_code_id_obj?.code}</span> : <span className={borderClass}></span>}</>)
                            }}/>} />
                            )}/>
                            </FieldFormAttendance>}>
                            <FunctionField source='cost_code_id' label="Cost Codes" render={(record: any) => {
                                return(<>{record?.cost_code_id ? <span>{record?.cost_code_id_obj?.code}</span> : <span className={borderClass}></span>}</>)
                            }}/>
                        </EditableField>
                    }
                    {IsVisible(ATTENDANCE_COLUMNS.wage) && <MoneyField className='no-translate' source="base_wage" label={getLabel(ATTENDANCE_COLUMNS.wage)}/> }
                    { IsVisible(ATTENDANCE_COLUMNS.earnings) && <MoneyField className='no-translate' source="earning" label={getLabel(ATTENDANCE_COLUMNS.earnings)} /> }
                   {IsVisible(ATTENDANCE_COLUMNS.status) && <AttendanceStatusLabelField className='no-translate' source='status' label={getLabel(ATTENDANCE_COLUMNS.status)} colors={{'paid':'success'}}/>  }
                </StyledEditableDatagrid>
                </div>
            </>
        </List>
        </div>
        </div>
        </>
    );
};

export const AttendanceCreateDialog = (props: any) => {
    const navigate = useNavigate();
    const identity = useIdentityContext();

    if(!getIntegratedNonIntegrated(identity) && !identity?.allow_to_add_time){
        navigate('/attendances');
    }

    return (
        <Create  {...props} mutationMode={'pessimistic'} component='div' title={<DialogTitleForRecord title='Add Time' />} >
            <AttendanceDialogForm {...props}/>
        </Create>
    );
};


const AttendanceEditDialog = (props: any) => {
    const transform = (data:any) =>{
        _.set(data, 'hours',parseTime(data.hours) || 0.0);
        _.set(data, 'break_hours',parseTime(data.break_hours) || 0.0);
        if(data?.period_id_obj?.start_date){
            _.set(data,'date', data?.period_id_obj?.start_date)
            _.unset(data, 'period_id')
        }
        return data;
    };

    return (
        <Edit component='div' {...props} title=' ' mutationMode='pessimistic' transform={transform}>
            <AttendanceEditDailogForm {...props}/>
        </Edit>
    );
};

const AttendanceEditDailogForm = (props: any) => {
    const identity = useIdentityContext();
    return getIntegratedNonIntegrated(identity) ?
        <ZappierListResponsive {...props} identity={identity}/> :
        <IntegratedCompanyResponsive {...props} />;
};

const ZappierListResponsive = (props) => {
    const navigate = useNavigate();
    const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const { columns,identity } = props;
    const location = useLocation();
    const { state } = location;
    const isVisible = (id)=>columns && columns?.filter(item=> item.field_id === id)[0]?.is_visible;
    if(!isSmall) {
        navigate('/attendances')
    };
    const calculator = useMemo(() => {
        return [
            createDecorator(
                {
                    field: 'start',
                    updates: onChangeStartAndEnd,
                },
                {
                    field: 'end',
                    updates: onChangeStartAndEnd,
                },
                {
                    field:'job_id',
                    updates: onChangeJob,
                },
            ),
        ];
    }, []);

    const transform = (data:any) =>{
        _.set(data, 'hours',parseTime(data.hours) || 0.0);
        _.set(data, 'break_hours',parseTime(data.break_hours) || 0.0);
        if(data?.period_id_obj?.start_date){
            _.set(data,'date', data?.period_id_obj?.start_date)
            _.unset(data, 'period_id')
        }
        return data;
    };
    const [employeeData, setEmployeeData] = useState(null);
    const dataProvider = useDataProvider();
    const dialogRefs: any = useRef();
    const notify = useNotify();

    const updateAttendance = async (data,formProps) => {
        try {
            const attendanceUpdate = await dataProvider.update('attendance', {id:data?.id, data: transform(data),previousData:formProps?.record });
            if(attendanceUpdate?.data?.id){
                dialogRefs?.current?.close();
                if(state?.path) {
                    navigate(state.path, { state: { tab: 'Time' } });
                } else {
                    navigate('/attendances');
                }
            }
        } catch (error) {
            notify('Unable to update:', error);
        }
    }
    const handleSave = async (data,formProps) => {
        if (!data.base_wage && data?.propay_id) {
            const employeeRecord:any = await dataProvider.getOne('employees',{id:data?.employee_id});
               setEmployeeData({employeeRecord,data,formProps});
               dialogRefs.current.open();
        } else {
            updateAttendance(data,formProps);
        }
    };
    return (
        <>
        <DialogForm
            title="Missing User Data"
            ref={dialogRefs}
            wageClass={'add-wage-dialog'}
            iconBootun={true}
        >
         <EmployeeUpdateForm
            onEditSuccess={()=>{
                dialogRefs?.current?.close();
                updateAttendance(employeeData?.data,employeeData?.formProps)}}
            handleClose={dialogRefs?.current?.close}
            emptyFields={[{id:employeeData?.employeeRecord?.data?.id,name:employeeData?.employeeRecord?.data?.display_name,
                email:employeeData?.employeeRecord?.data?.email ? employeeData?.employeeRecord?.data?.email :'',base_wage:employeeData?.employeeRecord?.data?.base_wage ? employeeData?.employeeRecord?.data?.base_wage :'',
                mobile_number:employeeData?.employeeRecord?.data?.mobile_number ? employeeData?.employeeRecord?.data?.mobile_number :''}]}
            hideMobile={true}
            isLoading={employeeData?.isLoading}
            isDashboard={false}

        />
        </DialogForm>
        <div className='attendance-create-page'>
        <Typography variant='h2' className='main-title main-title-mobile'>
        <span className='back-button-attendance' onClick={()=> state?.path ? navigate(state.path, { state: { tab: 'Time' } }) : navigate(-NUMBER.ONE)}>Back</span>
        Edit Attendance</Typography>
        <div className='attendance-create-wrapper'>
        <FormWithRedirect
        {...props}
        decorators={calculator}
        transform={transform}
        render={(formProps: any) => {
                    return (
                        <Grid container>

                        {isVisible(ATTENDANCE_COLUMNS.name) && <Grid item xs={12}>
                            <ReferenceInput source='employee_id' reference='Employee__DropdownList' label='Worker'>
                                <RaAutocompleteInput fullWidth label={false} disabled={props.record.id}/>
                            </ReferenceInput>
                        </Grid>}

                        {isVisible(ATTENDANCE_COLUMNS.job) && <Grid item xs={12}>
                            <JobInput fullWidth label='Job' />
                            </Grid>}

                        {isVisible(ATTENDANCE_COLUMNS.propay) && <Grid item xs={12}>
                            <PropayInput fullWidth label='ProPay'/>
                        </Grid>}
                        {(isVisible(ATTENDANCE_COLUMNS.costCode) && identity?.company?.can_use_cost_code)&& <Grid item xs={12}>
                            <CostCodeInputField identity={identity} />
                        </Grid>}

                            {isVisible(ATTENDANCE_COLUMNS.startDate) && <Grid className='add-job-date-field add-job-date-field-modal' item xs={12}>
                            <StartInput variant='standard' label='Start' {...props}/>
                            </Grid>}

                            {isVisible(ATTENDANCE_COLUMNS.endDate) &&<Grid className='add-job-date-field add-job-date-field-modal' item xs={12}>
                            <EndInputResponsive variant='standard' label='End' {...props}/>
                            </Grid>}
                            {isVisible(ATTENDANCE_COLUMNS.hours) && <Grid className='add-job-date-field add-job-date-field-modal' item xs={12}>
                            <HoursInputResponsive source='hours' variant='standard' label='' disabled={true} {...props}/>
                            </Grid>}
                            {isVisible(ATTENDANCE_COLUMNS.break) && <Grid className='add-job-date-field add-job-date-field-modal' item xs={12}>
                            <BreakHoursInputResponsive sourcHours='break_hours' variant='standard' label='' disabled={true} {...props}/>
                            </Grid>}

                            <StyleToolbar
                            className='add-time-footer'
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                            saving={formProps.saving}
                            basePath={''}
                            >
                            <SaveButton
                                saving={formProps.saving}
                                onSave={(data)=>handleSave(data, formProps)}
                            />
                        </StyleToolbar>

                        </Grid>)
                }}
            />
            </div>
        </div>
        </>
    );
};

const IntegratedCompanyResponsive = (props: any) => {
            const navigate = useNavigate();
            const [employeeData, setEmployeeData] = useState(null);
            const dataProvider = useDataProvider();
            const dialogRefs: any = useRef();
            const notify = useNotify();

            const updateAttendance = async (data,formProps) => {
                try {
                    const attendanceUpdate = await dataProvider.update('attendance', {id:data?.id, data: data,previousData:formProps?.record });
                    if(attendanceUpdate?.data?.id){
                        dialogRefs?.current?.close();
                        navigate('/attendances');
                    }
                } catch (error) {
                    notify('Unable to update:', error);
                }
            }
            const handleSave = async (data,formProps) => {
                if (!data.base_wage && data?.propay_id) {
                    const employeeRecord:any = await dataProvider.getOne('employees',{id:data?.employee_id});
                       setEmployeeData({employeeRecord,data,formProps});
                       dialogRefs.current.open();
                } else {
                    updateAttendance(data,formProps);
                }
            };

               return (
                <>
                <DialogForm
                    title="Missing User Data"
                    ref={dialogRefs}
                    wageClass={'add-wage-dialog'}
                    iconBootun={true}
                >
                 <EmployeeUpdateForm
                    onEditSuccess={()=>{
                        dialogRefs?.current?.close();
                        updateAttendance(employeeData?.data,employeeData?.formProps)}}
                    handleClose={dialogRefs?.current?.close}
                    emptyFields={[{id:employeeData?.employeeRecord?.data?.id,name:employeeData?.employeeRecord?.data?.display_name,
                        email:employeeData?.employeeRecord?.data?.email ? employeeData?.employeeRecord?.data?.email :'',base_wage:employeeData?.employeeRecord?.data?.base_wage ? employeeData?.employeeRecord?.data?.base_wage :'',
                        mobile_number:employeeData?.employeeRecord?.data?.mobile_number ? employeeData?.employeeRecord?.data?.mobile_number :''}]}
                    hideMobile={true}
                    isLoading={employeeData?.isLoading}
                    isDashboard={false}
                />
                </DialogForm>

                <div className='attendance-create-page'>
                <Typography variant='h2' className='main-title main-title-mobile'>
                <span className='back-button-attendance' onClick={()=> navigate(-NUMBER.ONE)}>Back</span>
                    Edit Attendance
                </Typography>
                <div className='attendance-create-wrapper'>
            <FormWithRedirect {...props}
                render={(formProps: any) => {
                    return (

                    <Grid container>

                    <Grid item xs={12}>
                        <ReferenceInput source='employee_id' reference='Employee__DropdownList' label='Worker'>
                            <RaAutocompleteInput fullWidth label={false} disabled={props.record.id}/>
                        </ReferenceInput>
                    </Grid>

                    <Grid item xs={12}>
                        <JobInput fullWidth label='Job' />
                        </Grid>

                    <Grid item xs={12}>
                        <PropayInput fullWidth label='ProPay'/>
                    </Grid>

                        <Grid className='add-job-date-field add-job-date-field-modal' item xs={12}>
                        <StartFieldResponsive source='start' label='Start' {...props}/>
                        </Grid>

                        <Grid className='add-job-date-field add-job-date-field-modal' item xs={12}>
                        <EndFieldResponsive source='end' label='End' {...props}/>
                        </Grid>
                        <Grid className='add-job-date-field add-job-date-field-modal' item xs={12}>
                        <FormatTimeFieldResposive source='hours' {...props}/>
                        </Grid>
                        <Grid className='add-job-date-field add-job-date-field-modal' item xs={12}>
                        <FormatTimeFieldResposive source='break_hours' {...props}/>
                        </Grid>
                        <StyleToolbar
                            className='add-time-footer'
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                            saving={formProps.saving}
                            basePath={''}
                            >
                            <SaveButton
                                saving={formProps.saving}
                                onSave={(data)=>handleSave(data, formProps)}
                            />
                        </StyleToolbar>
                    </Grid>
                  )
                }}
            />
            </div>
            </div>
            </>
            );
    };

const AddtimeFormDataTransform = (formData) => {
        const { hours, minutes } = formData;
        const timeHours = parseFloat(hours);
        const timeMinutes = parseFloat(minutes);
        if (timeMinutes) {
            let strminutes = timeMinutes/NUMBER.SIXTEY || 0.0;
            return timeHours + strminutes;
        } else {
            return timeHours;
        }
};

const AttendanceDialogForm = (props: any) => {
const navigate = useNavigate();
const [employeeId, setEmployeeId] = useState(0);
const {state} = useLocation();
const identity = useIdentityContext()
const onChangeEmployeeId = async (fieldValue:any, name:string, allValues:any) => {
    const result:any = {};
    setEmployeeId(fieldValue);
    return result;
};
const oncangeDecorator = useMemo(() => {
    return [
        createDecorator(

            {
                field: 'start',
                updates: onChangeStartAndEnd,
            },
            {
                field: 'end',
                updates: onChangeStartAndEnd,
            },
            {
                field:'job_id',
                updates: onChangeJob,
            },
            {
                field:'employee_id',
                updates: onChangeEmployeeId,
            },
        )
    ];
}, []);

const employeeFilter = {active: {_eq: true,}};
const [addTimeType, setAddTimeType] = useState('TIMEINOUT');

const dialogRef: any = useRef();
dialogRef.current = addTimeType;

const handleChange = (event: React.ChangeEvent<HTMLInputElement>, formProps) => {
    dialogRef.current = event.target.value;
    setAddTimeType(event.target.value);
    formProps?.form?.change('hours', null);
  };

const transform = (data:any) =>{
    let result= {};
    if(dialogRef.current !== 'TIMEINOUT'){
        delete data.start;
        delete data.end;
        result={date:data?.date};
    }else{
        result={...result,start:data?.start, end:data?.end};
        delete data.date;
    }
    if(dialogRef.current === 'DURATION'){
        _.set(data, 'hours',AddtimeFormDataTransform(data) || 0.0);
    };
    delete data.minutes;
    result = {...result,propay_id: data?.propay_id,job_id: data?.job_id,employee_id: data?.employee_id,hours: data?.hours}
    return result;
};
const [create] = useCreate();
const queryClient = useQueryClient();
const notify = useNotify();
const dialogRefs: any = useRef();
const [employeeData, setEmployeeData] = useState(null);
const dataProvider = useDataProvider();

const callAction = (props:any) => {
    const {onSuccess,data,resource} = props;
    create(
        resource,
        {data:data},
        {
            onSuccess: (result: any) => {
                onSuccess()
                queryClient.setQueryData([resource, 'getOne', String(result.id)],result);
            },
            onError: (error: any) => {
                notify(`Element Failed Updated ${error.message}`);
            },
        }
    );
};
const onSave = async (data:any) => {
    const transformedData:any = transform(data);
     const employeeRecord:any = await dataProvider.getOne('employees',{id:transformedData?.employee_id});
     if(!employeeRecord?.data?.base_wage){
        setEmployeeData({employeeRecord,transformedData,saveExit:false});
        dialogRefs.current.open();
     }else{
        callAction({ data: transformedData,onSuccess:() => {
            notify('Time has been added sucessfully!');
            if(state?.path) {
                navigate(state.path, { state: { tab: 'Time' } })
            }
        },resource:'attendances'})
     }
};
const onSaveAndExit = async (data:any) => {
    const transformedData:any = transform(data);
     const employeeRecord:any = await dataProvider.getOne('employees',{id:transformedData?.employee_id});
     if(!employeeRecord?.data?.base_wage){
        setEmployeeData({employeeRecord,transformedData,saveExit:true});
        dialogRefs.current.open();
     }else{
        callAction({ data: transformedData,onSuccess:() => {
            notify('Time has been added sucessfully!');
            if(state?.path) {
                navigate(state.path, { state: { tab: 'Time' } })
            } else {
                navigate('/attendances')
            }
        },resource:'attendances'})
     }
};
const singleEmployee = identity?.allow_to_add_time && identity?.user_type === 'worker' ? {id: {_eq:identity?.employee_id}} : {};

return (
    <>
    <DialogForm
            title={(!employeeData?.employeeRecord?.data?.base_wage) ? 'Missing User Data' : 'User Contact Information Missing'}
            ref={dialogRefs}
            wageClass={'add-wage-dialog'}
            iconBootun={true}
        >
         <EmployeeUpdateForm
            onEditSuccess={() => {
                dialogRefs.current.close();
                callAction({ data: employeeData?.transformedData,onSuccess:() => {
                    notify('Time has been added sucessfully!');
                    if(employeeData?.saveExit) {
                        if(state?.path) {
                            navigate(state.path, { state: { tab: 'Time' } })
                        } else {
                            navigate('/attendances')
                        }
                    }
                },resource:'attendances'})
            }}
            handleClose={(!employeeData?.employeeRecord?.data?.base_wage) ? dialogRefs?.current?.close : () => {
                dialogRefs.current.close();
                callAction({ data: employeeData?.transformedData,onSuccess:() => {
                    notify('Time has been added sucessfully!');
                    if(employeeData?.saveExit) {
                        if(state?.path) {
                            navigate(state.path, { state: { tab: 'Time' } })
                        } else {
                            navigate('/attendances')
                        }
                    }
                },resource:'attendances'})
            }}
            emptyFields={[{id:employeeData?.employeeRecord?.data?.id,name:employeeData?.employeeRecord?.data?.display_name,email:employeeData?.employeeRecord?.data?.email ? employeeData?.employeeRecord?.data?.email :'',base_wage:employeeData?.employeeRecord?.data?.base_wage ? employeeData?.employeeRecord?.data?.base_wage :'',mobile_number:employeeData?.employeeRecord?.data?.mobile_number ? employeeData?.employeeRecord?.data?.mobile_number :''}]}
            hideMobile={true}
            isLoading={employeeData?.isLoading}
            buttonText={(!employeeData?.employeeRecord?.data?.base_wage) ? '' : 'Later'}
            isDashboard={false}
            subtext={(!employeeData?.employeeRecord?.data?.base_wage) ? '': 'To notify the user please enter missing information'}
        />
        </DialogForm>
    <div className='attendance-create-page'>
    {(!props?.propayId?.id || state?.record?.id) && <Typography variant='h2' className='main-title main-title-mobile'>
    <span className='back-button-attendance' onClick={()=> state?.path ? navigate(state.path, { state: { tab: 'Time' } }) : navigate(-NUMBER.ONE)}>Back</span>
        Add Time
    </Typography>}
    <div className='attendance-create-wrapper attendance-create-add-time'>
    <StyledCreate component='div' title=' ' transform={transform}>
    <FormWithRedirect
        decorators={oncangeDecorator}
        initialValues={{ propay_id: props?.propayId?.id || state?.record?.id, job_id: props?.propayId?.job_id || state?.record?.job_id}}
        redirect='list'
        render={(formProps: any) => {
            return (
                <>
                <Grid className='add-time-grid-cont' spacing={4} container>

                <Grid item lg={6} xs={12} className='left-add-time'>

                <Grid item lg={12} xs={12}>
                    <FormLabel className='add-time-label hidden-add-time'>&nbsp;</FormLabel>
                    <ReferenceInputObj perPage={300} source='job_id' disabled={props?.propayId?.job_id || state?.record?.job_id} emptyValue={0}
                        filter={{ has_propay: { _eq: true },propay_ids:{status:{_in:['pending','started','approved','completed']}},active: {_eq:true},has_children: {_eq:false} }} allowEmpty reference='Job__DropdownList' {...props} >
                        <AutocompleteInput optionText='full_name'/>
                    </ReferenceInputObj>
                </Grid>

                <Grid item lg={12} xs={12} className='attendance-propay-mbs-zero'>
                    <PropayInput fullWidth label='ProPay' {...props}/>
                </Grid>

                <Grid item lg={12} xs={12}>
                <FormDataConsumer label='resources.weekEntryLines.workers'>
                  {({ formData, getSource, scopedFormData }) => {
                    return (
                    <ReferenceInput perPage={300} source='employee_id' reference='Employee__TimeDropdownList' label='Worker' validate={required()} filter={{...employeeFilter, ...singleEmployee}}>
                        <RaAutocompleteInput fullWidth
                            optionText={(record?: Record) => {
                                const propayIds = _.flatMap(record?.employee_wage_ids, 'propay_id');
                                const parentId = formData?.propay_id_obj?.parent_id ? formData?.propay_id_obj?.parent_id : formData?.propay_id
                                        return (`${record?.display_name} ${propayIds?.includes(parentId) ? '(Assigned)' : '' }`)
                                    }}
                                    {...props}
                                    label={false}

                        />
                    </ReferenceInput>
                    )
                       }}
                     </FormDataConsumer>

                </Grid>

                </Grid>

                <Grid item lg={6} xs={12} className='right-add-time'>

                <Grid className='add-time-type' item lg={12} md={12} sm={12} xs={12}>
                            <FormControl>
                                <FormLabel className='add-time-label'>Type</FormLabel>
                            <RadioGroup
                                    row
                                    aria-labelledby='radio-buttons-type-group-label'
                                    name='row-radio-buttons-group'
                                    value={addTimeType}
                                    onChange={(e) => handleChange(e, formProps)}
                                >
                                    <FormControlLabel value='TIMEINOUT' control={<Radio />} label='Time In/Out' />
                                    <FormControlLabel value='DURATION' control={<Radio />} label='Duration' />
                                </RadioGroup>
                                </FormControl>
                </Grid>

                 {addTimeType === 'TIMEINOUT' && <>
                    <Grid className='add-job-date-field' item lg={12} xs={12}>
                    <StartInput />
                    </Grid>

                    <Grid className='add-job-date-field' item lg={12} xs={12}>
                    <EndInput />
                    </Grid>
                    </>
                    }

                {addTimeType === 'DURATION' && <>
                    <Grid className='add-job-date-field' item lg={12} xs={12}>
                    <StyledDatePickerInput className='attendance-date' {...props} source='date' defaultValue={todayDateOnly}/>
                    </Grid>
                    <Grid className='add-time-hour-field' item lg={12} xs={12}>
                        {identity?.company?.hours_format === 'by_time' ? <StyledStack className='' direction='row'>
                        <HoursInput variant='standard' source='hours' label='HH' validate={required()}/>
                        <MinutesInput variant='standard' source='minutes' label='MM'/>
                        </StyledStack> :
                        <MaskedTimeInput
                        className="money-pay-input-create hours-full-width"
                        fullWidth
                        label={false}
                        placeholder='Hours'
                        source='hours'
                        validate={[required(),validateTimeinDecimal]}
                    />}
                    </Grid>
                    </> }

                </Grid>

                </Grid>
                 <Box className='add-time-footer' display='flex' justifyContent='space-between' width='100%'>
                 <Grid className='padding_toolbar' item lg={12} md={12} sm={12} xs={12} sx={{ marginTop: 0 }}>
                 <StyleToolbar
                            record={formProps.record}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                            saving={formProps.saving}
                            basePath={props.redirect}
                        >
                            <SaveButton className='save-exit-button' label='Save & Exit'
                                saving={false} disabled={!employeeId} onSave={onSaveAndExit}/>
                               <SaveButton className='add-time-next-btn' label='Save'
                                saving={false} disabled={!employeeId} onSave={onSave} />

                        </StyleToolbar>
                         </Grid>
                 </Box>
                </>
                )
        }}
    />
    </StyledCreate>
    </div>
    </div>
    </>
    );
};

/*function to find out if company is integrated or not */
export const getIntegratedNonIntegrated = (companyData: any) => {
    if(companyData?.company?.allow_zapier_api){
        return true;
    }
    else if(companyData?.company?.allow_dataverse){
        return false;
    }else if((companyData?.company?.is_jobber_service_connected || companyData?.company?.allow_salesforce_api || companyData?.company?.allow_vericlock_api ||
               companyData?.company?.tsheets_status === 'connected') && !companyData?.company?.allow_zapier_api){
        return false;
    }else{
        return true;
    }
};

/*function to find out if company can access check- in/out add time interface or not*/
export const canAccessCheckInOut = ({
    permissions,
    resource,
}: {
    permissions: any;
    resource: string;
}): boolean => {

    const featureFlag = permissions.filter((flag) => flag.resource === resource);
    if(featureFlag?.length > NUMBER.ZERO){
            if(featureFlag[NUMBER.ZERO]?.resource === resource && featureFlag[NUMBER.ZERO]?.type === 'allow'){
                return true;
            }else{
                return false;
            }
    }else{
        return false;
    }
};
