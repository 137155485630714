import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { alpha, styled } from '@mui/material/styles';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ConfirmClasses,useNotify,useDataProvider } from 'react-admin';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Stack } from '@mui/material';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { useQueryClient } from 'react-query';
import { CustomFilterContext } from '../../layout/CustomFilterContext';
import { POST } from '../../services/HttpService';
import { LoadingButton } from '@mui/lab'
import { useIdentityContext } from '../../components/identity';
import { PROPAY_BULK_ACTION_API } from '../../utils/Constants/ConstantData';
import { useSnackbar } from 'notistack';
import { GENERAL_INFO } from '../../constants';

export const PropayBulkActionModal = (props) => {
    const {
        isOpen = false,
        onClose,
        action,
        selectedIds,
        unselectAll,
        payload={
            propay_ids:selectedIds
        },
        confirmMessage=''
    } = props;
    const apiPayload = ['Delete', 'Cancel']?.includes(action) ? {...payload, action_name: action?.toLocaleLowerCase() } : payload;
    const identity = useIdentityContext();
    const propayContext = useContext(CustomFilterContext);
    const notify = useNotify();
    const [isLoading,setIsLoading] = useState(false);
    const queryClient = useQueryClient();
    const url = PROPAY_BULK_ACTION_API[action];
    const dataProvider = useDataProvider();
    const syncActions = ['Approve','Update Budget','Update Bonus Pool','Pending', 'Delete', 'Cancel']
    const messageObj ={
        'Delete': 'deleted',
        'Cancel': 'cancelled',
        'Approve':'approved',
        'Update Budget':'budget updated',
        'Update Bonus Pool':'bonus pool updated',
        'Pending':'marked pending and started'
    }
  const {enqueueSnackbar} =  useSnackbar()
    useEffect(()=>{},[propayContext?.showActionAlert,propayContext?.alertMessage])
    const handleConfirm = ()=>{
       setIsLoading(true);

      POST(url,apiPayload).then((response:any)=>{
          if(syncActions.includes(action)){
            localStorage.setItem('propayBulkId',response?.bulk_sync_info_id);
            getStatus();
            return;
          }
           propayContext?.setBulkActionResponse(response);
            propayContext?.setShowActionAlert(true);
            queryClient.invalidateQueries(['propays', 'getList']);
            let successCount = NUMBER.ZERO;
            let cancelledCount = NUMBER.ZERO;
            let successList = [];
            if(response[NUMBER.ZERO].hasOwnProperty('is_success')){
             successList = response.filter(el=>el.is_success);
             successCount = successList.length;
            }else{
            successCount = selectedIds.length
            }
            setIsLoading(false);
            let alertMessage=''
            if(['Approve','Pending'].includes(action)){
                alertMessage =`${successCount}/${selectedIds.length} ${successCount > NUMBER.ONE ?'ProPays were':'ProPay was'}  ${action === 'Approve' ? `approved`:identity?.company?.is_started_status ? 'marked pending and started':'marked pending'}`;
            }else if(['Complete'].includes(action)){
                alertMessage = `${successCount}/${selectedIds.length} ${successCount > NUMBER.ONE ?'ProPays were':'ProPay was'}  ${'Completed'}`;
            }else{
              let cancelList = successList.filter(el=>el.propay_status === 'cancelled');
              cancelledCount = cancelList.length;
              let deleteCount =  Math.abs(successCount - cancelledCount);
              alertMessage =    successCount ? `${deleteCount ? `${deleteCount}/${selectedIds.length} ${deleteCount > NUMBER.ONE ?'ProPays were deleted ':'ProPay was deleted'}`:''} ${cancelledCount && deleteCount ? '& ' : ''}
              ${cancelledCount ? `${cancelledCount}/${selectedIds.length} ${cancelledCount > NUMBER.ONE ?'ProPays were cancelled ':'ProPay was cancelled'}`:''}` :`${successCount}/${selectedIds.length} ProPay was deleted`;
            }
            propayContext?.setAlertMessage(alertMessage);
            enqueueSnackbar(alertMessage, {variant:'success',key:'propay',autoHideDuration:6000});

        }).catch(error=>{
            setIsLoading(false);
            notify(`Failure ! ${error.message}`);
        })
        notify(GENERAL_INFO.soonNotifiied);
        unselectAll('propays');
        onClose();
    }

    const handleClick = useCallback(e => {
        e.stopPropagation();
    }, []);

    const getSyncId = ()=>{
        const bulkId = localStorage.getItem('propayBulkId');
        return bulkId
    }
    const getStatus = ()=>{
      const interval = setInterval(() => {
                if(getSyncId()){
                dataProvider.getOne('protivBulkSyncInfo', { id: getSyncId() }).then((res)=>{
                    if(res?.data?.status === 'success' && getSyncId()){
                        const result = JSON.parse(res?.data?.result);
                        propayContext?.setBulkActionResponse(result);
                        propayContext?.setShowActionAlert(true);
                        let message =`${res?.data?.success_count}/${res?.data?.success_count+res?.data?.failure_count} ProPays ${res?.data?.success_count > NUMBER.ONE && action === 'Approve' ?'were':''} ${messageObj[action]}`;
                        propayContext?.setAlertMessage(message);
                        enqueueSnackbar(message,{variant:'success',key:'propay',autoHideDuration:6000});
                        queryClient.invalidateQueries(['propays', 'getList']);
                        localStorage.removeItem('propayBulkId');
                        clearInterval(interval);
                    }
                })
            }
            }, 2000);
    }

    return (
        <StyledDialog
            open={isOpen}
            onClose={onClose}
            onClick={handleClick}
            aria-labelledby="alert-dialog-title"
            className='common-dialog-modal alert-modal-confirmed'
        >

        <DialogTitle id="alert-dialog-title">
            <Stack flexDirection={'row'} justifyContent={'flex-end'}>
                <IconButton
                    sx={{top:-2,paddingLeft:2}}
                    color="primary"
                    aria-label="Alert Modal Confirmed"
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            </Stack>
            </DialogTitle>
            <DialogContent>
              <div className='alert-modal-strong'>
               {!confirmMessage ? `Are you sure you want to "${(identity?.company?.is_started_status && action === 'Pending') ? `${action} or Start` : action}" the selected (${selectedIds.length}) ${selectedIds.length > NUMBER.ONE ?'ProPays':'ProPay'} ?`:confirmMessage}
               </div>
            </DialogContent>
            <DialogActions className='button-justified'>
            <Button className='cancel-button' onClick={()=>onClose()}>Cancel</Button>
            <LoadingButton  className='update-button' onClick={()=>handleConfirm()}>{'Confirm'}</LoadingButton>
            </DialogActions>

        </StyledDialog>
    );
};

const PREFIX = 'RaConfirm';

const StyledDialog = styled(Dialog, { name: PREFIX })(({ theme }) => ({
    [`& .${ConfirmClasses.confirmPrimary}`]: {
        color: theme.palette.primary.main,
    },

    [`& .${ConfirmClasses.confirmWarning}`]: {
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: alpha(theme.palette.error.main, NUMBER.ZERO_POINT_ONE_TWO),
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },

    [`& .${ConfirmClasses.iconPaddingStyle}`]: {
        paddingRight: '0.5em',
    },
}));
