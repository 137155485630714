import { useState, useEffect, useContext, useCallback } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    Stack,
    DialogTitle,
    IconButton,
    Badge,
} from '@mui/material';
import { EMPLOYEE_FILTER_OPTION, SEARCH_PLACEHOLDER } from '../../utils/Constants/ConstantData';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import _ from 'lodash';
import { DateRangeInputFilter } from '../../components/fields/DateRangeInputFilter';
import CloseIcon from '@mui/icons-material/Close';
import FilterAlt from '@mui/icons-material/FilterAlt';
import { Input } from '@mui/material';
import { getTeamCustomFilterQuery } from '../../components/fields';
import { Form } from 'react-final-form';
import { CustomFilterContext } from '../../layout/CustomFilterContext';
import { TagPicker } from 'rsuite';
import {
    CustomConditionInput,
    CustomDateFilter,
    CustomTagInput,
} from '../protivEmployeeBonusStatement/MobileViewFiter';
import { RoleOptions, StatusOptions } from './CustomEmployeeFilters';
import { getSearchTeamQuery } from '../propays/propay_utils';
import { getFilterCount } from '../../utils/Constants/CommonFunctions';
import debounce from 'lodash/debounce';
export const TeamStatusOptions= [
    { name: 'Invite Sent', id: 'invited', group: 'Active' },
    { name: 'Not yet Invited', id: 'draft', group: 'Active' },
    { name: 'Inactive', id: 'in_active', group: '' },
  ];

const EmployeeMobileViewFilter = ({ setEmployeeFilter, employeeFilter }) => {
    const [open, setOpen] = useState(false);
    const teamContext = useContext(CustomFilterContext);
    const [search, setSearch] = useState('');
    const [dateRange, setDateRange] = useState(null);
    const [filterCount, setFilterCount] = useState(0);
    const [customFilter, setCustomFilter] = useState([]);
    const initialFilter = [
        { tag: EMPLOYEE_FILTER_OPTION.status, selected_ids: ['active'] },
    ];
    const teamStore = 'teamAppliedFilterMobile';
    const teamSearchStore = 'teamSearch';
    const teamDateStore = 'teamFromDateFilter';


    useEffect(() => {
        const prevAppliedFilters = localStorage.getItem(teamStore);
        const getPrevSearch = localStorage.getItem(teamSearchStore);
        const prevSearch = getPrevSearch || '';
        const prevDateFilter = localStorage.getItem(teamDateStore);
        const dateFilter = prevDateFilter
            ? { write_date: JSON.parse(prevDateFilter) }
            : {};
        if (prevAppliedFilters === null) {
            let query = prevSearch
                ? {  ...getSearchTeamQuery(prevSearch), ...dateFilter }
                : {  ...dateFilter };
            setEmployeeFilter(query);
        } else {
            const parsedPrevFilters = JSON.parse(prevAppliedFilters);
            setCustomFilter(parsedPrevFilters);
            const prvQyery = getTeamCustomFilterQuery(parsedPrevFilters);
            setEmployeeFilter(
                prevSearch
                    ? {
                          ...prvQyery,
                          ...getSearchTeamQuery(prevSearch),
                          ...dateFilter,
                      }
                    : { ...prvQyery, ...dateFilter }
            );
        }
        setDateRange(prevDateFilter ? JSON.parse(prevDateFilter) : null);
        setSearch(prevSearch);
    }, []);

    const hasDate = date => {
        return date ? date['_gte'] || date['_lte'] : false;
    };

    const applyFilter = (searchText: string) => {
        const filteredData = customFilter.filter(
            item =>
                item.selected_ids !== null ||
                item.selected_ids.length > NUMBER.ZERO
        );
        let filterQuery = getTeamCustomFilterQuery(filteredData);
        const newFilters = hasDate(dateRange)
            ? { ...filterQuery, write_date: dateRange }
            : filterQuery;
        setEmployeeFilter(
            searchText
                ? { ...newFilters, ...getSearchTeamQuery(searchText) }
                : newFilters
        );
        localStorage.setItem(teamStore, JSON.stringify(filteredData));
        hasDate(dateRange) &&
            localStorage.setItem(teamDateStore, JSON.stringify(dateRange));
        setOpen(false);
    };

    const handleSubmit = (dates: any) => {
        setDateRange(dates);
    };

    const handleSearch = (text: string) => {
        setSearch(text);
        localStorage.setItem('teamSearch', text);
        handleDebounce(text);
    };

    const handleDebounce = useCallback(debounce((searchText) => {
        setEmployeeFilter({ ...employeeFilter, ...getSearchTeamQuery(searchText) });
    },NUMBER.ONE_THOUSAND), []);


    const handleClose = () => {
        setOpen(false);
        const prevAppliedFilters = localStorage.getItem(teamStore);
        setCustomFilter(
            prevAppliedFilters ? JSON.parse(prevAppliedFilters) : initialFilter
        );
    };

    const prevTeamAppliedFilters  = JSON.parse(localStorage.getItem(teamStore));
useEffect(() => {
    const count = prevTeamAppliedFilters?.filter((item)=> item.selected_ids.filter((item)=>item)?.length)?.length
    setFilterCount(count);
}, [prevTeamAppliedFilters]);

    return (
        <>
            <div className="filter-mobile-search-row">
                <div className="propay-filter-search">
                    <Input
                        type="text"
                        value={search}
                        onChange={e => handleSearch(e.target.value)}
                        placeholder={SEARCH_PLACEHOLDER.team}
                        className="propay-search"
                    />
                </div>
                <IconButton
                    className={`filter-mobile-btn ${filterCount > 0 ? 'filter-badge':''}`} onClick={()=>{setOpen(true)}}
                >
                    <FilterAlt color="primary" />
                    {filterCount > 0 && <Badge className='custom-badge-btn' color='primary' badgeContent={getFilterCount(filterCount)} max={NUMBER.FIVE}></Badge>}
                </IconButton>
            </div>
            <Dialog
                className="filter-modal-mobile"
                open={open}
                onClose={() => handleClose()}
            >
                <DialogTitle sx={{ p: 2 }}>
                    <Stack
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                    >
                        Filters
                        <IconButton
                            color="primary"
                            onClick={() => handleClose()}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <CustomGroupTagInput
                        name={EMPLOYEE_FILTER_OPTION.status}
                        customFilter={customFilter}
                        setCustomFilter={setCustomFilter}
                        renderData={TeamStatusOptions}
                    />
                    <CustomTagInput
                        customFilter={customFilter}
                        setCustomFilter={setCustomFilter}
                        name={EMPLOYEE_FILTER_OPTION.name}
                        resource={'Employee__FilterList'}
                        filterQuery={{ show_in_list: { _eq: true } }}
                        label={'display_name'}
                        value={'id'}
                        renderData={teamContext?.allWorkers}
                        setRenderData={teamContext?.setAllWorkers}
                        sortQry={{ field: 'name', order: 'ASC' }}
                    />

                    <CustomTagInput
                        customFilter={customFilter}
                        setCustomFilter={setCustomFilter}
                        name={EMPLOYEE_FILTER_OPTION.email}
                        resource={'Employee__EmailFilterList'}
                        filterQuery={{
                            show_in_list: { _eq: true },
                            email: { _is_null: false, _neq: '' },
                        }}
                        label={'email'}
                        value={'id'}
                        renderData={teamContext?.allTeamEmails}
                        setRenderData={teamContext?.setAllTeamEmails}
                        sortQry={{ field: 'email', order: 'ASC' }}
                    />

                    <CustomTagInput
                        customFilter={customFilter}
                        setCustomFilter={setCustomFilter}
                        name={EMPLOYEE_FILTER_OPTION.mobile}
                        resource={'Employee__EmailFilterList'}
                        filterQuery={{
                            show_in_list: { _eq: true },
                            mobile_number: { _is_null: false, _neq: '' },
                        }}
                        label={'mobile_number'}
                        value={'id'}
                        renderData={teamContext?.allTeamMobile}
                        setRenderData={teamContext?.setAllTeamMobile}
                        sortQry={{ field: 'name', order: 'ASC' }}
                    />
                    <CustomStaticTagInput
                        name={EMPLOYEE_FILTER_OPTION.role}
                        customFilter={customFilter}
                        setCustomFilter={setCustomFilter}
                        renderData={RoleOptions}
                    />
                    <CustomConditionInput
                        name={EMPLOYEE_FILTER_OPTION.wage}
                        customFilter={customFilter}
                        setCustomFilter={setCustomFilter}
                    />
                    <CustomDateFilter
                        name={EMPLOYEE_FILTER_OPTION.create_date}
                        customFilter={customFilter}
                        setCustomFilter={setCustomFilter}
                    />

                    <Form
                        onSubmit={() => {}}
                        render={() => (
                            <DateRangeInputFilter
                                className="date-input-filter"
                                source="write_date"
                                pageType="team"
                                onChange={handleSubmit}
                                alwaysOn
                            />
                        )}
                    ></Form>
                </DialogContent>
                <div className="responsive-apply-filter-div">
                    <Button
                        className="responsive-apply-filter-btn"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => applyFilter(search)}
                    >
                        Apply Filter
                    </Button>
                </div>
            </Dialog>
        </>
    );
};

export default EmployeeMobileViewFilter;

export const CustomStaticTagInput = ({
    name,
    customFilter,
    setCustomFilter,
    renderData,
}) => {
    let prevSelectedValue = customFilter.filter(item => item.tag === name);
    const [defaultValue, setDefaultValue] = useState(
        prevSelectedValue.length > 0 ? prevSelectedValue[0].selected_ids : []
    );

    const updateSelectedValue = value => {
        let update = customFilter.filter(item => item.tag !== name);
        setCustomFilter(_.concat(update, [{ tag: name, selected_ids: value }]));
    };

    return (
        <>
            <div className="filter-label-title">{name}</div>
            <TagPicker
                onOpen={() => document.body.classList.add('tag-picker-body')}
                onClose={() =>
                    document.body.classList.remove('tag-picker-body')
                }
                menuClassName="mobile-filter-tag-picker mobile-filter-tag-tp-zero"
                placeholder={`Select ${name}`}
                searchable={true}
                data={renderData}
                valueKey={'id'}
                labelKey={'name'}
                onChange={e => updateSelectedValue(e)}
                defaultValue={defaultValue}
                style={{ width: 300 }}
            />
        </>
    );
};

export const CustomGroupTagInput = ({
    name,
    customFilter,
    setCustomFilter,
    renderData,
}) => {
    let prevSelectedValue = customFilter.filter(item => item.tag === name);
    const [defaultValue, setDefaultValue] = useState(
        prevSelectedValue.length > 0 ? prevSelectedValue[0].selected_ids : []
    );

    const updateSelectedValue = value => {
        let filterIds = value.filter(val=> val !== 'active')
        let update = customFilter.filter(item => item.tag !== name);
        setCustomFilter(_.concat(update, [{ tag: name, selected_ids: filterIds }]));
    };

    return (
        <>
            <div className="filter-label-title">{name}</div>
            <TagPicker
                onOpen={() => document.body.classList.add('tag-picker-body')}
                onClose={() =>
                    document.body.classList.remove('tag-picker-body')
                }
                menuClassName="mobile-filter-tag-picker mobile-filter-tag-tp-zero"
                placeholder={`Select ${name}`}
                searchable={true}
                data={renderData}
                valueKey={'id'}
                labelKey={'name'}
                groupBy='group'
                
                onChange={e => updateSelectedValue(e)}
                defaultValue={defaultValue}
                style={{ width: 300 }}
            />
        </>
    );
};