import { Auth } from '@aws-amplify/auth';
import { useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Amplify, I18n } from 'aws-amplify';
import { useEffect } from 'react';
import { useLogin } from 'react-admin';
import { useLocation } from 'react-router';
import awsExports from '../aws-exports';
import { LoginWrapper } from '../layout/LoginWrapper';
import ThemeWrapper from '../layout/ThemeWrapper';
import CustomLogin from './CustomLogin';
import './styles.scss';
import './header.scss';
import './onboarding.scss';
import './calculator.scss';
import './popup-modal.scss';
import './createpropay.scss';
import './old-create-propay.scss';
import './dashboard.scss';
import './propay-list.scss';
import './import-milestone.scss';
import './setting.scss';
import './bonus-statement-modal.scss';
import './import.scss';
import './propay-budget-detail.scss';
import './propay-detail.scss';
import './group-propay.scss';
import './automation.scss';
import './attendance.scss';
import './milestone.scss';
import './milestone-modal.scss';
import './bonus-page.scss';
import './notification.scss';
import './drawer.scss';
import './integrations.scss';
import './team.scss';
import './policies.scss';
import './filter.scss';
import './bonus-statement-modal.scss';
import './modal.scss';
import './team.scss';
import './automation.scss';
import './custom.scss';
import './setting.scss';
import './download-app.scss';

Amplify.configure({
    ...awsExports,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
});
I18n.putVocabulariesForLanguage('en', {});

const AwsLogin = (props: any) => {
    const login = useLogin();
    const { user, route, signOut } = useAuthenticator(context => [
        context.user,
        context.route,
    ]);

    const search = useLocation().search;
    const redirectUrl = new URLSearchParams(search).get('redirect');
    useEffect(() => {
        if (route === 'authenticated') {
            //call Odoo login
            Auth.currentSession().then(cognitoUserSession => {
                const accessToken = cognitoUserSession.getAccessToken();
                const jwt = accessToken.getJwtToken();
                //You can print them to see the full objects
                const username = accessToken.payload.username;
                login({ username, password: jwt }, redirectUrl)
                    .then(() => {})
                    .catch(() => {
                        signOut();
                    });
            });
        }
    }, [signOut, login, user, route, redirectUrl]);

    return (
        <>
            <CustomLogin />
        </>
    );
};

const Login = (props: any) => {
    return (
        <LoginWrapper>
            <AwsLogin {...props} />
        </LoginWrapper>
    );
};

const LoginWithTheme = (props: any) => {
    return (
        <ThemeWrapper>
            <Login {...props} />
        </ThemeWrapper>
    );
};
export default LoginWithTheme;
