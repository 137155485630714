import { AutocompleteInput, required } from 'react-admin';
import {
    Grid,
    CircularProgress,
    Box,
    Typography,
    Container,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import { INFO_ICON_OUTLINED } from '../../utils/Constants/ClassName';

const StyledContainer = styled(Container)(({ theme }) => ({
    justifyContent: 'center',
    alignSelf: 'stretch',
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    maxWidth: '633px',
    flexDirection: 'column',
    padding: '28px 20px',
}));

const SettingsWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginTop: '20px',
    flexDirection: 'column',
    fontSize: '14px',
    color: theme.palette.text.primary,
    fontWeight: 400,
    lineHeight: '143%',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
        paddingRight: '20px',
    },
}));

const SettingsContent = styled(Box)(({ theme }) => ({
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
    },
}));

const SettingsRow = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    gap: '8px',
    [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
    },
}));

const SettingsLabel = styled(Typography)({
    fontFamily: 'Poppins, sans-serif',
    alignSelf: 'stretch',
    margin: 'auto 0',
    fontWeight: 600,
});

const ImgLogo = styled('img')({
    aspectRatio: '6',
    objectFit: 'cover',
    objectPosition: 'center',
    width: '300px',
    maxWidth: '100%',
});

export default function JobtreadSettings({
    logo,
    connectionId,
    onSettingsChange,
}) {
    const [jobTypeList, setJobTypeList] = useState([]);
    const [organizationsList, setOrganizationsList] = useState([]);
    const [settings, setSettings] = useState({
        crew_lead_id: '',
        job_status_id: '',
        organizations: '',
        job_type_id: '',
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchConfiguration = async () => {
            try {
                const connectionsResponse = await fetch(
                    '/api/vault/connections',
                    {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ jsonrpc: '2.0', id: null }),
                    }
                );

                const connectionsResult = await connectionsResponse.json();
                const currentConnection = connectionsResult.result.find(
                    conn => conn.id === connectionId
                );

                if (!currentConnection) {
                    console.error('Connection does not exist');
                    setLoading(false);
                    return;
                }

                const configurationResponse = await fetch(
                    '/api/connector/configuration',
                    {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            jsonrpc: '2.0',
                            params: { connection_id: connectionId },
                        }),
                    }
                );

                if (!configurationResponse.ok) {
                    throw new Error('Error fetching custom fields');
                }

                const configurationResult = await configurationResponse.json();
                setJobTypeList(
                    configurationResult.result.org_customfields || []
                );
                setOrganizationsList(
                    configurationResult.result.organizations || []
                );

                const { job_type_id } =
                    currentConnection.configuration.customfields_selected ||
                    {};
                setSettings({
                    organizations: currentConnection.configuration.org_id || '',
                    crew_lead_id: '',
                    job_status_id: '',
                    job_type_id: job_type_id?.value || '',
                });
            } catch (error) {
                console.error('Error fetching configuration:', error);
            } finally {
                setLoading(false);
            }
        };

        if (connectionId) {
            fetchConfiguration();
        } else {
            console.error('No connection ID provided');
            setLoading(false);
        }
    }, [connectionId]);

    useEffect(() => {
        const { organizations, job_type_id } = settings;
    
        if (organizations) {
            const selectedOrganization = organizationsList.find(org => org.id === organizations);
    
            if (selectedOrganization) {
                const updatedSettings = {
                    ...settings,
                    org_id: selectedOrganization.id,
                    org_name: selectedOrganization.name,
                };
    
                if (job_type_id) {
                    updatedSettings.job_type_id = job_type_id;
                }
    
                onSettingsChange(updatedSettings);
            }
        }
    }, [settings, onSettingsChange, organizationsList]);

    const handleChange = async (field, newValue) => {
        const newSettings = { ...settings, [field]: newValue };
        setSettings(newSettings);

        if (field === 'organizations' && newValue) {
            const selectedOrganization = organizationsList.find(org => org.id === newValue);

            if (selectedOrganization) {
                await updateOrganizationConfiguration(selectedOrganization.id, selectedOrganization.name);
                await fetchUpdatedConfiguration();
            }
        }
    };

    const updateOrganizationConfiguration = async (orgId, orgName) => {
        const body = {
            jsonrpc: "2.0",
            params: {
                number: 1,
                service_id: "jobtread",
                authorization_url: "",
                configuration: {
                    org_id: orgId,
                    org_name: orgName,
                },
            },
        };

        try {
            const response = await fetch(`/api/vault/connection/${connectionId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });

            if (!response.ok) {
                throw new Error('Error updating organization configuration');
            }

            console.log('Organization configuration updated successfully');
        } catch (error) {
            console.error('Error updating organization configuration:', error);
        }
    };

    const fetchUpdatedConfiguration = async () => {
        try {
            const response = await fetch(
                '/api/connector/configuration',
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        jsonrpc: '2.0',
                        params: { connection_id: connectionId },
                    }),
                }
            );

            if (!response.ok) {
                throw new Error('Error fetching updated configuration');
            }

            const result = await response.json();
            setJobTypeList(result.result.org_customfields || []);
        } catch (error) {
            console.error('Error fetching updated configuration:', error);
        }
    };

    if (loading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="30vh"
            >
                <CircularProgress />
            </Box>
        );
    }

    const organizationChoices = Array.isArray(organizationsList) ? 
        (organizationsList.length > 0 ? organizationsList.map(option => ({
            id: option.id,
            name: option.name
        })) : [])
        : [];

    const JobTypeChoices = Array.isArray(jobTypeList) ? 
        (jobTypeList.length > 0 ? jobTypeList.map(option => ({
            id: option.id,
            name: option.name + ' - ' + option.targetType,
        })) : [])
        : [];

    return (
        <StyledContainer>
            <ImgLogo src={logo} alt="Job Settings Logo" />
            <SettingsWrapper>
                <SettingsContent>
                    <SettingsRow>
                        <SettingsLabel>
                            Select the company you wish to connect
                        </SettingsLabel>
                    </SettingsRow>
                    <Box sx={{ mt: 2 }} className="aspire-multi-select-row">
                        <Grid container spacing={2}>
                            <Grid item lg={12} xs={12}>
                                <AutocompleteInput
                                    source="organizations"
                                    label="Organization"
                                    choices={organizationChoices}
                                    fullWidth
                                    onChange={newValue =>
                                        handleChange('organizations', newValue)
                                    }
                                    validate={required()}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </SettingsContent>
                <SettingsContent>
                    <SettingsRow>
                        <SettingsLabel>
                            Select the custom field associated to your Job Types
                        </SettingsLabel>
                    </SettingsRow>
                    <Box sx={{ mt: 2 }} className="aspire-multi-select-row">
                        <Grid container spacing={2}>
                            <Grid item lg={12} xs={12}>
                                <AutocompleteInput
                                    source="job_type_id"
                                    label="Job Type (Optional)"
                                    choices={JobTypeChoices}
                                    fullWidth
                                    onChange={newValue =>
                                        handleChange('job_type_id', newValue)
                                    }
                                    disabled={!settings.organizations}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <div className="info-alert-tagline">
                        {INFO_ICON_OUTLINED()} Job Types are used to create and filter jobs for automation feature
                    </div>
                </SettingsContent>
            </SettingsWrapper>
        </StyledContainer>
    );
}
