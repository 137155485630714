import moment from 'moment';
import {
    ReferenceField,
    ResourceContextProvider,
    TextField,
    useTranslate,
    AutocompleteArrayInput,
    TopToolbar,
    FunctionField,
} from 'react-admin';
import { Datagrid } from '../../components/datagrid';
import { MoneyField } from '../../components/fields/fields';
import { useIdentityContext } from '../../components/identity';
import { ListGroup } from '../../layout/List';
import { Title } from '../../layout/Title';
import UserAvtarAndName from '../../layout/UserAvtarAndName';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { PropayNameWithMilestone } from '../attendances/Attendance';
import { StyledReferenceArrayInput } from '../payrolls/Payrolls';
import { GroupByExportButton } from './GroupByExportButton';
import { ProPayBonusExporter } from './ProPayBonusExporter';
import { PropayBonusStatementExporter } from './PropayBonusStatementExporter';
import { formatter } from '../../utils/Constants/ConstantData';

const ReportFilter = [
    <StyledReferenceArrayInput
        source="paid_period_id._in"
        reference="Period__DropdownList"
        label="resources.PropayBonusReport.paid_period"
        alwaysOn
        sort={{ field: 'start_date', order: 'DESC' }}
        perPage={NUMBER.HUNDRED}
    >
        <AutocompleteArrayInput source="name" />
    </StyledReferenceArrayInput>,
    <StyledReferenceArrayInput
        source="period_id._in"
        reference="Period__DropdownList"
        label="resources.PropayBonusReport.work_period"
        alwaysOn
        sort={{ field: 'start_date', order: 'DESC' }}
        filter={{
            start_date: { _lte: moment().format('YYYY-MM-DD') },
            payroll_ids: { performance_bonus: { _gt: 0 } },
        }}
    >
        <AutocompleteArrayInput source="name" />
    </StyledReferenceArrayInput>,
    <StyledReferenceArrayInput
        size="medium"
        source="employee_id._in"
        reference="Employee__DropdownList"
        label="resources.PropayBonusReport.worker"
        alwaysOn
    >
        <AutocompleteArrayInput source="name" />
    </StyledReferenceArrayInput>,
];
const StatementReportFilter = [
    <StyledReferenceArrayInput
        source="paid_period_id._in"
        reference="Period__DropdownList"
        label="resources.PropayBonusReport.paid_period"
        alwaysOn
        sort={{ field: 'start_date', order: 'DESC' }}
        perPage={NUMBER.HUNDRED}
    >
        <AutocompleteArrayInput source="name" />
    </StyledReferenceArrayInput>,
    <StyledReferenceArrayInput
        source="statement_period_id._in"
        reference="protivStatementPeriods"
        label="resources.PropayBonusReport.fields.statement_period_id"
        alwaysOn
        sort={{ field: 'start_date', order: 'DESC' }}
        filter={{
            start_date: { _lte: moment().format('YYYY-MM-DD') }
        }}
    >
        <AutocompleteArrayInput source="name" />
    </StyledReferenceArrayInput>,
    <StyledReferenceArrayInput
        size="medium"
        source="employee_id._in"
        reference="Employee__DropdownList"
        label="resources.PropayBonusReport.worker"
        alwaysOn
    >
        <AutocompleteArrayInput source="name" />
    </StyledReferenceArrayInput>,
];
const ListActions = (props: any) => (
    <TopToolbar>
        <GroupByExportButton labelResource="PropayBonusReport" />
    </TopToolbar>
);
export const PropayBonusList = (props: any) => {
    const translate = useTranslate();
    const identity = useIdentityContext();
    const details = {
              source: 'protivPropayBonusStatement',
              resource: 'ProtivPropayBonusStatement__PropayBonusReport',
              groupByFields: [
                  'employee_id',
                  'propay_id',
                  'paid_period_id',
                  "employee_statement_id",
                  'is_lead_pay_statement',
                  'statement_period_id',
              ],
              fields: [
                  'employee_id',
                  'propay_id',
                  'paid_period_id',
                  'statement_period_id',
                  "employee_statement_id",
                  'bonus_earning',
                  'status',
                  'deduction_allocation',
                  'is_lead_pay_statement',
                  'total_balance',
                  'net_bonus',
                  'net_bonus_ot_diff_amt'
              ],
              filterQuery: {
                  status: { _eq: 'paid' },
                  is_void:{ _eq: false},
                  bonus_earning: { _gt: NUMBER.ZERO },
              },
              bonusSource: 'bonus_earning',
          }


    return (
        <ResourceContextProvider value={details.source}>
            <ListGroup
                title={<Title title="ProPay Bonus Report" />}
                lazy={false}
                filters={StatementReportFilter}
                resource={details.resource}
                disableSyncWithLocation={true}
                exporter={PropayBonusStatementExporter(translate)}
                actions={<ListActions />}
                className="reports-filter"
                groupBy={details.groupByFields}
                filter={details.filterQuery}
                fields={details.fields}
            >
                <div className="wage-report-scroll">
                    <Datagrid stickyHeader bulkActionButtons={false} showFooter>
                        <ReferenceField
                            reference="Employee__allEmployeespropayDetailList"
                            source="employee_id"
                            label="resources.PropayBonusReport.fields.employee_id"
                            link={false}
                        >
                            <UserAvtarAndName hideAvatar={true} />
                        </ReferenceField>
                        <ReferenceField
                            reference="Propay__DropdownList"
                            source="propay_id"
                            label="resources.PropayBonusReport.fields.propay_id"
                            link={false}
                        >
                            <PropayNameWithMilestone />
                        </ReferenceField>
                        <FunctionField
                            source="paid_period_id"
                            label="resources.PropayBonusReport.fields.paid_period_id"
                            render={rec => rec?.paid_period_id_obj?.name || ''}
                        />

                            <FunctionField
                                source="statement_period_id"
                                label="resources.PropayBonusReport.fields.statement_period_id"
                                render={record =>
                                    record?.statement_period_id_obj?.name || ''
                                }
                            />
                        <MoneyField
                            source={details.bonusSource}
                            label="resources.PropayBonusReport.fields.performance_bonus"
                            className="no-translate"
                            groupBy
                        />

                            <MoneyField
                                    source="deduction_allocation"
                                    label="Deduction"
                                    className="no-translate"
                                    groupBy
                                />
                               
                                 <FunctionField
                                 className="no-translate"
                                 label={'Balance'}
                                 render={(record) => (<> {formatter.format(record.net_bonus + record.net_bonus_ot_diff_amt)} </>)}
                             />

                    </Datagrid>
                </div>
            </ListGroup>
        </ResourceContextProvider>
    );
};
