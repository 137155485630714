import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    OutlinedInput,
    Stack,
    TextField,
    Theme,
    Typography,
    useMediaQuery
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import createDecorator from 'final-form-calculate';
import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    AutocompleteInput,
    BooleanInput,
    FormDataConsumer,
    FormWithRedirect,
    NumberInput,
    maxValue,
    minValue,
    required,
    useTranslate
} from 'react-admin';
import DatePicker from 'react-datepicker';
import { Field } from 'react-final-form';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
    ArrayInput
} from '../../components/ArrayInput';
import { MoneyInput } from '../../components/fields';
import { InfoLabel } from '../../components/fields/InfoLabel';
import { CustomReferenceInputObjSelect } from '../../components/fields/RefrenceInputObjReactSelect';
import { useIdentityContext } from '../../components/identity';
import { handleErrorAndSuccessToast, handleStartAutomation } from '../../layout/AutomationListActions';
import MultiSelectInputWithInfiniteScroll from '../../layout/CustomMultiSelectInputWithInfiniteScroll';
import SelectInputWithInfiniteScroll from '../../layout/SelectInputWithInfiniteScroll';
import {
    ALERT_ERROR_AUTOMATION,
    ARROW_CORNER_ICON,
    CHEVRON_UP_DOWN_ICON,
    GROUPED_ICON,
    INFO_ICON_OUTLINED,
    MILESTONE_ARROW_ICON,
    SELECT_JOB_ARROW,
    TOAST_INFO_ICON,
    VISIT_CALENDER_ICON
} from '../../utils/Constants/ClassName';
import { COMMON_DAYS, PROPAY_LEADPAY_ARTICLE_LINK, capitalizeFirstLetter, dayToNumber, formatter } from '../../utils/Constants/ConstantData';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { CustomStyledMilstoneFormIterator, GetLeadpayTotal, HeaderForAddLeadpayMember, StyledCard, StyledPercentInput } from '../propays/NewProPayForm';
import { ShowWagesHtml } from '../propays/PropayCreateTab';
import CustomisedDraggableTagPicker from './CustomisedDraggableTagPicker.';
import { devideBonusByOptions, distributeBudgetOptions, groupAutomationRepeatType, groupPropayTitleOptions, jobTypeOperator } from './SelectConstatntOptions';
import { SingleSelect } from './SingleSelect';
import { handleCreateAutomationApiCall, handleUpdateAutomationApiCall, selectedManagerVal } from './automations';
import { ToastContainer, toast } from 'react-toastify';
import { findGroupAutomationFormError } from '../../utils/Constants/CommonFunctions';
import moment from 'moment';
import { post } from '../onboard/PaymentProvider';
import { SelectPicker } from 'rsuite';
import { Checkbox } from '@mui/material';

const GroupPropayAutomationSteps = [
    { label: 'Setup', value: 1 },
    { label: 'Settings', value: 2 },
    { label: 'Schedule', value: 3 },
    { label: 'Confirm', value: 4 }
]

const conditionFilterField = [
    {
        job_selection_option: 'job_type',
        operator: '',
        value: []
    }
];

const CustomToast = ({ errors }) => {
    const allErrors = [
      'Job',
      'ProPay Title',
      'Leadpay member not added.',
      'Manager',
      'Cost Codes',
      'budget',
      'Leadpay amount',
    ]
    const multiError = ['is missing required fields.']

    if(errors?.length > 1){
      errors.map((error) => {
        const matchingError = allErrors.find((keyword) => error.includes(keyword));

        if (matchingError) {
          const index = errors.indexOf(error);
          errors.splice(index, 1, capitalizeFirstLetter(matchingError));
        }

        return error;
      });
      return (
        <div>
      {errors.length > 0 && (
        <>
          <div>Please fix the required fields:</div>
          {errors.map((field, index) => {
            return(
            <div key={index}>{multiError.find((keyword) => field?.includes(keyword))
              ? field.replace(new RegExp(multiError.join('|'), 'g'), '')
              : field}</div>
          )})}
          <div>Once complete, you can move to next step.</div>
        </>
      )}
    </div>
      );
    }
    const singleError = ['Add Worker row', 'Lead pay row', 'Milestone ']
    return (
      <div>
        {errors?.length && errors.map((error, index) => (
          <div key={index}>{
            singleError.find((keyword) => error?.includes(keyword)) ? `${error} is missing required fields.` : error}</div>
        ))}
      </div>
    );
};

const WizardFormStepsView = ({ activeTab, editMode, setCreateGroupTab }) => {
    const applyClass = (check, className) => check ? className : ''
    const handleTabClick = (step) => {
        if ((step <= activeTab) || editMode) {
            setCreateGroupTab(step);
        }
    };

    return (
        <div className="calc-m-tabs gp-am-tabs">
            {
                GroupPropayAutomationSteps.map((item) => {
                    return (
                        <>
                            <Button
                                className={`calc-m-tab-item ${applyClass(activeTab >= item.value, 'active-calc-m-tab-item')}`}
                                onClick={() => handleTabClick(item.value)}
                                disabled={editMode ? false : activeTab < item.value}
                            >
                                {item.label}
                            </Button>
                            {item.value !== 4 && <div className={`calc-m-arrow ${applyClass(((activeTab - 1) >= item.value), 'active-calc-m-arrow')}`}>
                                {MILESTONE_ARROW_ICON()}
                            </div>}
                        </>
                    )
                })
            }
        </div>
    )
}

export const GroupProPaysModal = (props) => {
    const { editMode } = props;
    const [CreateGroupTab, setCreateGroupTab] = React.useState(1);
    const [groupAutomationId, setGroupAutomationId] = useState('');
    const [automationNameChanged, setAutomationNameChanged] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [filterConditions, setFiltersCondiition] = React.useState(conditionFilterField);
    const [formFields, setFormFields] = useState({
        type: 'group_propay',
        connector_id:'',
        title:'',
        crew_lead_id:'',
        divide_bonus_by:'',
        distribute_bonus_rate:'',
        repeat_value: 1,
        repeat_type: '',
        schedule_end_type: 'never',
        name_format: [],
        filters: [
            {
                job_selection_option: 'job_type',
                operator: '',
                value: []
            }
        ],
        leadpay_employee_wage_ids: [],
        start_date: '',
        is_bonus_pool_enabled: '',
        worker_bonus_percent: '',
        is_change_lead_pay: '',
        leadpay_amount: '',
        repeat_week_day: '',
        after_occurrence: '',
        end_date: '',
        skip_days:[],
        manager_id:'',
        interval_input:false,
        exclude_days:false,
    });
    const [connectorList,setConnectorList]= React.useState([]);
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const allFormData = useRef<any>();
    const identity = useIdentityContext();
    const [managerAndLead,setManagerAndLead]= React.useState({manager:selectedManagerVal, crewLead:selectedManagerVal});

    const handleChangeFormFields = (fieldName, value) => {
        if (fieldName === 'skip_days') {
            let updatedSkipDays = formFields?.skip_days ? [...formFields.skip_days] : [];
            if (updatedSkipDays.includes(value)) {
                updatedSkipDays = updatedSkipDays.filter(item => item !== value);
            } else {
                if(updatedSkipDays?.length < 6){
                updatedSkipDays.push(value);
                }else{
                    handleErrorAndSuccessToast('error', 'You can not exclude All Days.')
                }
            }
            setFormFields(previous => ({ ...previous, [fieldName]: updatedSkipDays }));
            }else{
            setFormFields(previous => ({ ...previous, [fieldName]: value}));
        }
    };

    React.useEffect(()=>{
        if(identity?.company?.is_integrated){
            const getConnection = async()=>{
                const data= {
                    jsonrpc: '2.0',
                    params: {},
                }
                const res = (await post('/api/protiv/connections',data)) as any;
                if (res?.length > NUMBER.ZERO) {
                    const modifiedResponse = res.map((item)=>({...item,label: item?.name ? item?.name : capitalizeFirstLetter(item?.service),
                        value: item?.id, name: item?.name ? item?.name : capitalizeFirstLetter(item?.service)}))
                    setConnectorList(modifiedResponse);
                    if(res?.length === 1 && _.isEmpty(props?.record)){
                        setFormFields((prev)=>({...prev, connector_id:res[0]?.id}));
                    }
                } else {
                    handleErrorAndSuccessToast('error', 'Unable to fetch connector list.')
                }
            }
            getConnection();
        }
    },[identity?.company?.is_integrated]);


    const findLabel = (value) => {
        const field = groupPropayTitleOptions?.find(item => item.value === value);
        return field ? field.label : value;
    };

    React.useEffect(() => {
        if (!_.isEmpty(props?.record)) {
            const { record } = props;
            setGroupAutomationId(props.record.uuid);
            let cleanedString = record?.filters !== 'false' ? record?.filters : conditionFilterField;
            setFiltersCondiition(JSON.parse(cleanedString));
            setManagerAndLead({manager:{label:record.manager_id_obj?.display_name, value:record.manager_id, status:record.manager_id_obj?.status, class: 'rsuite-menu-parent'},
                crewLead:{label:record.crew_lead_id_obj?.display_name, value:record.crew_lead_id, status:record.crew_lead_id_obj?.status, class: 'rsuite-menu-parent'}});
            const groupName = JSON.parse(record?.name_format);
            const generatedOptions = groupName?.length ? groupName?.map(field => ({
                label: findLabel(field),
                value: field
            })): [];
            setFormFields((prev)=>({connector_id:record?.connection_id, ...record,leadpay_employee_wage_ids:JSON.parse(record?.leadpay_employee_wage_ids),
                interval_input:record?.repeat_value>1 ? true : false,
                // exclude_days:record?.skip_days?.length ? true:false,
                filters:JSON.parse(cleanedString), name_format:generatedOptions, after_occurrence: record?.after_occurrence ? record?.after_occurrence : ''}));
        }
    }, [props?.record?.company_id]);

    // Coomneting this code as we currently do not need this
    // useEffect(() => {
    //     if(!automationNameChanged && CreateGroupTab === 3){
    //         setFormFields((prev)=>({...prev, title:`${prev?.title} - ${formFields?.schedule_end_type}`}))
    //     }
    // }, [formFields?.schedule_end_type, automationNameChanged, CreateGroupTab])

    const findFormErrors = (formData) => {
        return new Promise(resolve => {
            findGroupAutomationFormError(formData, CreateGroupTab).then((allErrors:any) => {
                allErrors?.length && toast.error(<CustomToast errors={allErrors} />, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
                resolve(allErrors);
            });
        });
    };


    const handleClickNext = async () => {
        const apiPayload = getFilteredFormFieldsByStep(CreateGroupTab);
        const getError:any = await findFormErrors(apiPayload)
        setisLoading(true);
        if(getError?.length){
            setisLoading(false);
        }else{
            if (groupAutomationId) {
                const resp = await handleUpdateAutomationApiCall(apiPayload, groupAutomationId);
                handleApiResponse(resp);
            } else {
                const resp = await handleCreateAutomationApiCall(apiPayload, true);
                handleApiResponse(resp);
            }
        }
    }

    const handleApiResponse = (resp) => {
        setisLoading(false);
        if (resp?.status === 'success') {
            handleErrorAndSuccessToast('success', `Step ${CreateGroupTab} submitted successfully!`);
            setGroupAutomationId(resp.id);
            setCreateGroupTab((prev) => prev+1);
        } else {
            handleErrorAndSuccessToast('error', resp?.error);
        }
    };

    const getFilteredFormFieldsByStep = (step) => {
        switch (step) {
            case 1:
                return {
                    type: formFields?.type,
                    title: formFields?.title,
                    crew_lead_id: formFields?.crew_lead_id,
                    filters: formFields?.filters,
                    name_format: formFields?.name_format?.length ? formFields?.name_format?.map((el)=> el?.value) : [],
                    connection_id: formFields?.connector_id
                };
            case 2:
                return {
                    divide_bonus_by: formFields?.divide_bonus_by,
                    distribute_bonus_rate: formFields?.distribute_bonus_rate !== 'false' ? formFields?.distribute_bonus_rate : '',
                    leadpay_employee_wage_ids: allFormData.current?.leadpay_employee_wage_ids?.length ? allFormData.current?.leadpay_employee_wage_ids?.map(item => ({employee_id: item?.employee_id,lead_pay_per:Number(item?.lead_pay_per)})) : [],
                    is_bonus_pool_enabled: allFormData.current?.is_bonus_pool_enabled,
                    worker_bonus_percent: allFormData.current?.worker_bonus_percent,
                    is_change_lead_pay: allFormData.current?.is_change_lead_pay,
                    leadpay_amount: allFormData.current?.leadpay_amount,
                    manager_id: allFormData.current?.manager_id
                };
            case 3:
                return {
                    repeat_value: formFields?.repeat_value,
                    repeat_type: formFields?.repeat_type,
                    schedule_end_type: formFields?.schedule_end_type,
                    start_date: formFields?.start_date ? moment(formFields?.start_date).format('YYYY-MM-DD') : formFields?.start_date,
                    repeat_week_day: formFields?.repeat_week_day,
                    // Commenting this for now as we do not have backend support for this field
                    // skip_days:formFields?.skip_days,
                    /* Commenting this for now as we will release this in Version 2 */
                    // after_occurrence: formFields?.after_occurrence,
                    // end_date: formFields?.end_date ? moment(formFields?.end_date).format('YYYY-MM-DD') : formFields?.end_date
                };
            default:
                return {};
        }
    };

    const handleCloseGroupAutomationModal = (checkApiCall?:boolean) => {
        if(checkApiCall){
            if(formFields?.title){
                handleUpdateAutomationApiCall({title: formFields?.title}, groupAutomationId);
                navigate('/propayAutomations');
                queryClient.invalidateQueries(['propayAutomations', 'getList']);
            }else{
                handleErrorAndSuccessToast('error', 'Group automation name is required.')
            }
        }else{
            navigate('/propayAutomations');
            queryClient.invalidateQueries(['propayAutomations', 'getList']);
        }
    };

    return (
        <>
        <ToastContainer />
            <Dialog
                open={true}
                onClose={handleCloseGroupAutomationModal}
                aria-labelledby="group-propay-dialog-title"
                aria-describedby="group-propay-dialog-description"
                className="group-propay-modal"
            >
                <DialogTitle id="group-propay-dialog-title">
                    <Stack className="group-propay-title" flexDirection={'row'} justifyContent={'space-between'}>
                        New GroupProPay Automation
                        <IconButton sx={{ top: -2, padding: 0 }} color="primary" aria-label="automation modal" onClick={()=>handleCloseGroupAutomationModal()}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="group-propay-dialog-description">

                        <WizardFormStepsView setCreateGroupTab={setCreateGroupTab} editMode={editMode} activeTab={CreateGroupTab} />

                        {CreateGroupTab === 1 && <GroupPropayAutomationSetupStep managerAndLead={managerAndLead} setManagerAndLead={setManagerAndLead} connectorList={connectorList} handleChangeFormFields={handleChangeFormFields} formFields={formFields} automationNameChanged={automationNameChanged}
                        props={props} setFiltersCondiition={setFiltersCondiition} filterConditions={filterConditions} setFormFields={setFormFields} setAutomationNameChanged={setAutomationNameChanged} />}

                        {CreateGroupTab === 2 && <GroupPropayAutomationSettingsStep props={props} allFormData={allFormData} handleChangeFormFields={handleChangeFormFields} formFields={formFields} />}

                        {CreateGroupTab === 3 && <GroupPropayAutomationScheduleStep formFields={formFields} handleChangeFormFields={handleChangeFormFields} setFormFields={setFormFields} />}

                        {CreateGroupTab === 4 && <GroupPropayAutomationConfirmStep  formFields={formFields} handleChangeFormFields={handleChangeFormFields} setCreateGroupTab={setCreateGroupTab} setAutomationNameChanged={setAutomationNameChanged} />}

                    </DialogContentText>
                </DialogContent>
                <DialogActions className="button-justified">
                    <Button className="cancel-button" onClick={()=>CreateGroupTab > 1 ? setCreateGroupTab((prev) => prev-1) : handleCloseGroupAutomationModal()}>{CreateGroupTab > 1 ? 'Previous' : 'Cancel'}</Button>
                    <div className="automation-button-right">
                        {CreateGroupTab === 4 && <Button className="save-draft-button" onClick={()=>handleCloseGroupAutomationModal(true)}>
                            Start Later
                        </Button>}
                        <LoadingButton loading={isLoading} className="update-button" onClick={()=> CreateGroupTab ===4 ? handleStartAutomation(groupAutomationId, queryClient,navigate, true) : handleClickNext()}>{CreateGroupTab === 4 ? 'Start Now' : 'Next'}</LoadingButton>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
};

const GroupPropayAutomationSetupStep = ({managerAndLead, setManagerAndLead, connectorList, formFields, handleChangeFormFields, props, setFiltersCondiition, filterConditions, setFormFields, setAutomationNameChanged, automationNameChanged}) => {
    const handleChangeConditionFilters = async (fieldName, value) => {
        setFiltersCondiition(previousFilters => {
            const updatedFilters = [...previousFilters];
            const filterToUpdate = updatedFilters.find(filter => filter?.job_selection_option === 'job_type');

            if (filterToUpdate) {
                if(fieldName === 'operator'){
                    filterToUpdate.operator = value
                }else{
                    filterToUpdate.value = value;
                }
            } else {
                    updatedFilters.push({ job_selection_option: fieldName, value });
            }
            handleChangeFormFields('filters', updatedFilters)
            return updatedFilters;
        });
    };

    return (
        <>
            <div className="gp-am-steps">
                <div className="group-propay-head">
                    {GROUPED_ICON()}
                    Group ProPays
                </div>
                <div className="group-propay-sub-head">
                    Choose the parameters to create the group as you prefer
                </div>
                {connectorList?.length > 1 ?
                <div className="automation-job-to-row am-gp-row">
                    <span className="am-text-space">
                    Select Cew Lead for
                    </span>
                    <SelectPicker
                        classPrefix="propay-select-picker automation-select-picker no-translate"
                        menuClassName="propay-select-dropdown am-job-s-picker automation-suite-select no-translate"
                        data={connectorList}
                        value={formFields?.connector_id}
                        searchable={false}
                        cleanable={false}
                        renderMenu={(menu)=>{
                            return <>{menu}</>
                        }}
                        renderMenuItem={(label) =>{
                                return (
                                  <div className={`rs-picker-option rsuite-menu-parent`}>
                                    <span>{label}</span>
                                  </div>
                                );
                        }}
                        onSelect={(value) => handleChangeFormFields('connector_id',value)}
                        onClean={() => handleChangeFormFields('connector_id', '')}
                        style={{ width: 224 }}
                        placeholder="Select Connector"
                        placement='bottomStart'
                    />

                </div>
                : <></>}
                <div className="automation-job-to-row am-gp-row">
                    <span className="am-text-space">
                        Group ProPays by using Crew Lead
                    </span>
                        <SelectInputWithInfiniteScroll automationNameChanged={!automationNameChanged} hideLabel={true} setManagerAndLead={setManagerAndLead} useControllerApiCall={false} selectedUser={managerAndLead?.crewLead} userTypeFilter={['worker', 'manager', 'admin', 'crew_lead']} handleChangeFormFields={handleChangeFormFields} selectedValue={formFields?.crew_lead_id} setPrevious={true} fieldName={'crew_lead_id'} />
                </div>
                {filterConditions?.map((item)=>filterConditionHtml(props, item, formFields, handleChangeConditionFilters, handleChangeFormFields))}

                <div className="automation-job-to-row am-gp-row">
                    <span className="am-text-space">
                        Automatically generate the group title using these values
                    </span>
                    <CustomisedDraggableTagPicker options={groupPropayTitleOptions} setSelected={setFormFields} selectedValue={formFields?.name_format} setPrevious={true} fieldName={'name_format'} />
                </div>
                <TextField
                    label="Group Automation Name"
                    value={formFields.title}
                    onChange={(e)=>{
                        const formattedValue = e.target.value.replace(/^\s+/, '');
                        setAutomationNameChanged(true);
                        handleChangeFormFields('title', formattedValue)
                    }}
                    fullWidth
                    size="medium"
                    className="group-textfield"
                />
            </div>
        </>
    )
}

const filterConditionHtml = (props, optionType, formFields, handleChangeConditionFilters, handleChangeFormFields) => {

    let cleanedString = props?.record?.filters && props?.record?.filters !== 'false' ? props?.record?.filters : conditionFilterField;
    cleanedString = props?.record?.filters ? JSON.parse(cleanedString) : conditionFilterField;

    const value = cleanedString?.filter((item) => item?.job_selection_option === optionType?.job_selection_option)[0];

    switch (optionType?.job_selection_option) {
        case 'job_type': {
            return (
                <div className="automation-job-action-row gp-am-action">
                    {SELECT_JOB_ARROW()}{' '}
                    <span className="color-text-orange">
                        AND
                    </span>{' '}
                    <span className="optional-text">
                        (optional)
                    </span>
                    Job Type
                    <SingleSelect options={jobTypeOperator} handleChange={handleChangeConditionFilters} value={optionType?.operator} fieldName={'operator'} />
                    <MultiSelectInputWithInfiniteScroll resource={'JobType'} hideLabel={true} editMode={!_.isEmpty(props?.record)} selectedUser={{}} handleChangeFormFields={handleChangeConditionFilters} selectedValue={value?.value} val={optionType?.value} setPrevious={true} fieldName={'job_type'} />
                    when creating the Automation
                </div>
            );
        }
        default:
            return <></>;
    }
}

const GroupPropayAutomationSettingsStep = ({ props, allFormData, handleChangeFormFields, formFields }) => {
    const [perLeadpayPage, setLeadpayPerPage] = useState(10);
    const [perManagerPage, setManagerPerPage] = useState(10);
    const wageChangeRefByEmp: any = useRef({});
    const [changeLeadPay, setChangeLeadPay] = useState(
        props?.record?.is_change_lead_pay
    );

    useEffect(()=>{
        if(!_.isEmpty(props?.record)){
        setChangeLeadPay(props?.record?.is_change_lead_pay);
        }
    },[props?.record?.is_change_lead_pay]);

    useEffect(()=>{
        if(_.isEmpty(props?.record)){
        setChangeLeadPay(allFormData?.current?.is_change_lead_pay);
        }
    },[allFormData?.current?.is_change_lead_pay, props?.record?.is_change_lead_pay]);

    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );
    const identity = useIdentityContext();
    const translate = useTranslate();

    const getFilterForLeadpayEmployee = (formData, scopedFormData) => {
        return {
            id: {
                _nin: formData?.leadpay_employee_wage_ids?.some((item) => item?.employee_id)
                    ? formData?.leadpay_employee_wage_ids
                        ?.filter((item) => item?.employee_id)
                        ?.map((item) => item?.employee_id)
                    : [],
            },
        };
    };

    const changeInLeadPayIndex = useRef({changeIndex:-1, isChangeLeadpayPercent:false});
    const onChangeLeadPayEmployee = (value: any, field: string, allValues?: Object, prevValues?: Object) => {
        if (field === 'leadpay_employee_wage_ids' && Array.isArray(allValues[field]) && Array.isArray(prevValues[field])) {
            if (allValues[field].length !== prevValues[field].length) {
                return allValues;
            }
            const changedIndex = allValues[field].findIndex(
                (item, index) =>
                    item?.employee_id !== prevValues[field][index]?.employee_id ||
                    (item?.employee_id === null && prevValues[field][index]?.employee_id !== null) ||
                    (item?.employee_id !== null && prevValues[field][index]?.employee_id === null) ||
                    item?.lead_pay_per !== prevValues[field][index]?.lead_pay_per
            );

            if (changedIndex !== -1) {
                const hasEmployeeIdChanged =
                    allValues[field][changedIndex]?.employee_id !== prevValues[field][changedIndex]?.employee_id;
                const hasLeadpayPercentChanged = allValues[field][changedIndex]?.lead_pay_per !== prevValues[field][changedIndex]?.lead_pay_per;
                changeInLeadPayIndex.current = {changeIndex:changedIndex, isChangeLeadpayPercent:hasLeadpayPercentChanged !== changeInLeadPayIndex.current.isChangeLeadpayPercent ? hasLeadpayPercentChanged : changeInLeadPayIndex.current.isChangeLeadpayPercent};

                if (hasEmployeeIdChanged) {
                    const updatedObject = {
                        ...allValues[field][changedIndex],
                        lead_pay_per: allValues[field][changedIndex]?.lead_pay_per ? allValues[field][changedIndex]?.lead_pay_per : ''
                    };
                    allValues[field][changedIndex] = updatedObject;
                    return allValues;
                }
            }
            if(changeInLeadPayIndex?.current?.changeIndex !== -1 && !changeInLeadPayIndex?.current?.isChangeLeadpayPercent){
                const totalEmployees = allValues[field]?.length;
                const equalLeadPayPercent = Number(((NUMBER.HUNDRED / totalEmployees) * NUMBER.ONE).toFixed(NUMBER.TWO));
                const remainingPercent = NUMBER.HUNDRED - (equalLeadPayPercent * (totalEmployees - NUMBER.ONE));
                const addAutoLeadPay = Array.from({ length: totalEmployees }, (item:any, index) => ({...item,
                    employee_id: allValues[field][index]?.employee_id,
                    lead_pay_per: index === totalEmployees - NUMBER.ONE ? Number(remainingPercent.toFixed(NUMBER.TWO)) : Number(equalLeadPayPercent.toFixed(NUMBER.TWO))
                }));
                allValues[field] = addAutoLeadPay;
                return allValues;
            }
        }
        return allValues;
    };

    const decorator = useMemo(() => {
        return [
            createDecorator(
                {
                    field: 'leadpay_employee_wage_ids',
                    updates:onChangeLeadPayEmployee
                },
            ),
        ];
    }, []);

    return (
        <>
            <div className="gp-am-steps">
            <div className='divide-bonus-head'>Divide Bonus</div>
                <div className="automation-job-to-row">
                    <span className="am-text-space">
                        Divide Bonus by
                    </span>
                    <SingleSelect
                                options={devideBonusByOptions}
                                handleChange={handleChangeFormFields}
                                value={formFields?.divide_bonus_by}
                                fieldName={'divide_bonus_by'}
                            />
                    {formFields.divide_bonus_by === 'hours_worked' &&
                           <>
                                    <span className="am-text-space">Distribute bonus rate</span>
                                    <SingleSelect
                                        options={distributeBudgetOptions}
                                        handleChange={handleChangeFormFields}
                                        value={formFields?.distribute_bonus_rate}
                                        fieldName={'distribute_bonus_rate'}
                                    />

                            </>
                    }
                </div>

                <FormWithRedirect
                    {...props}
                    record={!_.isEmpty(props?.record) && !allFormData?.current ? {...props?.record, leadpay_employee_wage_ids:props?.record?.leadpay_employee_wage_ids ? JSON.parse(props?.record?.leadpay_employee_wage_ids) : []} : {...allFormData.current}}
                    initialValues={{
                        worker_bonus_percent:identity?.company?.worker_bonus_percent,
                        is_bonus_pool_enabled:identity?.company?.is_bonus_pool_enabled,
                    }}
                    decorators={decorator}
                    render={(formProps: any) => {
                        return (
                            <>
                                <FormDataConsumer>
                                    {({ formData }) => {
                                        allFormData.current = formData;
                                        return (<></>)
                                    }}
                                </FormDataConsumer>
                                <div className='divide-bonus-head'>
                                    Assign Manager
                                </div>
                                <CustomReferenceInputObjSelect
                                        forEmployee={true} searchPlaceholder='Search Manager' newId={ props?.manager_id } hideLabel={false}
                                        setPerPage={setManagerPerPage} perPage={perManagerPage} onValueChange={()=>{}} source="manager_id" placeholder='Manager'
                                        showFooter={true} buttonText={''} buttonTextTwo={''}
                                        reference="Employee__DropdownList" label='Manager' className="manager-create-propay"
                                        filter={{ user_type: { _in: ['manager','admin',],},active: {_eq: true, }, }}
                                >
                                    <AutocompleteInput fullWidth />
                                </CustomReferenceInputObjSelect>
                                {identity?.company?.is_bonus_pool_enabled && (
                                    <FormDataConsumer>
                                        {({ formData }) => {
                                            const workerPercentVal = formData?.worker_bonus_percent
                                                ? formData?.worker_bonus_percent
                                                : 0;
                                            const abc =
                                                workerPercentVal <=
                                                    NUMBER.HUNDRED
                                                    ? workerPercentVal
                                                    : NUMBER.HUNDRED;
                                            const companyPercent = (
                                                NUMBER.HUNDRED - abc
                                            ).toFixed(2);

                                            return (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className="old-create-right-panel bonus-pool-propay"
                                                >
                                                    <Box className='g-m-search-m'>
                                                        <Stack
                                                            direction="row"
                                                            spacing={1}
                                                            className='g-am-b-pool'
                                                        >
                                                            <BooleanInput
                                                                source="is_bonus_pool_enabled"
                                                                helperText={
                                                                    false
                                                                }
                                                                className="toggle-accordion"
                                                                label="Bonus Pool"
                                                            />
                                                            <div className="create-right-tooltip">
                                                                <InfoLabel
                                                                    className="remove-bonus-tooltip"
                                                                    sx={{
                                                                        height: 20,
                                                                    }}
                                                                    icon="ri:error-warning-fill"
                                                                    height={20}
                                                                >
                                                                    <Typography className="bonus-tooltip-cs">
                                                                        Variance split between workers and the company from the ProPay creates a pool of money to distribute.
                                                                    </Typography>
                                                                </InfoLabel>
                                                            </div>
                                                        </Stack>
                                                        {formData?.is_bonus_pool_enabled && (
                                                            <>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <div className="maintenance-sub-text">
                                                                        Set
                                                                        split of
                                                                        the
                                                                        budget
                                                                        variance
                                                                        between
                                                                        the
                                                                        company
                                                                        and
                                                                        workers
                                                                    </div>
                                                                </Grid>
                                                                <Stack className="bonus-pool-stack">
                                                                    <NumberInput
                                                                        fullWidth
                                                                        source="worker_bonus_percent"
                                                                        label="ProPay Pool %"
                                                                    validate={[
                                                                        required(),
                                                                        maxValue(
                                                                            100
                                                                        ),
                                                                        minValue(
                                                                            1
                                                                        ),
                                                                    ]}
                                                                    />
                                                                    <span className="bonus-pool-slash">
                                                                        /
                                                                    </span>
                                                                    <FormControl
                                                                        fullWidth
                                                                        variant="outlined"
                                                                        className="input-payroll-period"
                                                                    >
                                                                        <InputLabel htmlFor="worker-bonus-pool">
                                                                            Company
                                                                            %
                                                                        </InputLabel>
                                                                        <OutlinedInput
                                                                            id="worker-bonus-pool"
                                                                            type="text"
                                                                            label="Worker %"
                                                                            value={
                                                                                companyPercent
                                                                            }
                                                                            disabled
                                                                        />
                                                                    </FormControl>
                                                                </Stack>
                                                            </>
                                                        )}
                                                    </Box>
                                                </Grid>
                                            );
                                        }}
                                    </FormDataConsumer>
                                )}

                                <Grid
                                    item
                                    xs={12}
                                    className="create-right-panel add-lead-pay"
                                >
                                    <Box>
                                        <Stack
                                            direction="row"
                                            className="toggle-leadpay-head bonus-pool-heading"
                                            spacing={1}
                                        >
                                            <div className="bonus-pool-head-text">
                                                LeadPay Budget
                                                <InfoLabel
                                                    className="remove-bonus-tooltip"
                                                    sx={{
                                                        height: 20,
                                                    }}
                                                    icon="ri:error-warning-fill"
                                                    height={20}
                                                >
                                                    <Typography className="bonus-tooltip-cs">
                                                        {translate(
                                                            'resources.propays.leadpay.info_text'
                                                        )}
                                                    </Typography>
                                                </InfoLabel>
                                                <Typography
                                                    className="read-article-link"
                                                    onClick={() =>
                                                        window.open(
                                                            PROPAY_LEADPAY_ARTICLE_LINK,
                                                            '_blank'
                                                        )
                                                    }
                                                >
                                                    Learn More
                                                </Typography>
                                            </div>
                                            <BooleanInput
                                                source="is_change_lead_pay"
                                                label=""
                                                helperText={false}
                                                not
                                                deleted
                                                commented
                                                code
                                                onChange={e => {
                                                    if (
                                                        !props?.title?.includes(
                                                            'Edit'
                                                        )
                                                    ) {
                                                        formProps?.form.change(
                                                            'leadpay_employee_wage_ids',
                                                            [undefined]
                                                        );
                                                    }
                                                    setChangeLeadPay(e);
                                                }}
                                                className="toggle-accordion"
                                            />
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            className="bonus-pool-sub-heading"
                                        >
                                            Additional bonus for management
                                        </Stack>
                                        {changeLeadPay &&(
                                                <div className="lead-pay-enable-toast lead-pay-budget-toast">
                                                    <span className="lead-pay-enable-icon">
                                                        {TOAST_INFO_ICON()}
                                                    </span>
                                                    <strong>
                                                        LeadPay is enabled:
                                                    </strong>{' '}
                                                    Add amount, user, and percentage
                                                </div>
                                            )}
                                        <StyledCard className="leadpay-clearfix">
                                            {changeLeadPay && (
                                                <Stack
                                                    className="lead-pay-stack"
                                                    direction="row"
                                                >
                                                    <MoneyInput
                                                        fullWidth
                                                        source="leadpay_amount"
                                                    // validate={required()}
                                                    />
                                                </Stack>
                                            )}
                                        </StyledCard>
                                    </Box>
                                </Grid>

                                <div className="assign-team-table">
                                    {changeLeadPay && (
                                            <Grid
                                                className="add-more-leadpay"
                                                container
                                                spacing={0}
                                            >
                                                <div className="bonus-worker-message-text">
                                                    Select any user and divide the
                                                    LeadPay budget
                                                </div>
                                                {isSmall && (
                                                    <FormDataConsumer>
                                                        {({ formData }) => {
                                                            return (
                                                                <ShowWagesHtml
                                                                    formData={
                                                                        formData
                                                                    }
                                                                />
                                                            );
                                                        }}
                                                    </FormDataConsumer>
                                                )}
                                                <FormDataConsumer>
                                                    {({
                                                        formData,
                                                        getSource,
                                                        scopedFormData,
                                                        ...rest
                                                    }) => {
                                                        return (
                                                            <>
                                                                <ArrayInput
                                                                    source="leadpay_employee_wage_ids"
                                                                    label={true}
                                                                >
                                                                    <CustomStyledMilstoneFormIterator
                                                                        disableReordering
                                                                        className='am-leadpay-table'
                                                                        ShowTotal={
                                                                            GetLeadpayTotal
                                                                        }
                                                                        formData={
                                                                            formData
                                                                        }
                                                                        headerHtml={
                                                                            <HeaderForAddLeadpayMember
                                                                                LeadPayAmount={
                                                                                    formData?.leadpay_amount
                                                                                        ? formData?.leadpay_amount
                                                                                        : 0
                                                                                }
                                                                            />
                                                                        }
                                                                    >
                                                                        <FormDataConsumer label="Assign team">
                                                                            {({
                                                                                formData,
                                                                                getSource,
                                                                                scopedFormData,
                                                                                ...rest
                                                                            }) => {
                                                                                return (
                                                                                    <CustomReferenceInputObjSelect
                                                                                        forEmployee={
                                                                                            true
                                                                                        }
                                                                                        setPerPage={
                                                                                            setLeadpayPerPage
                                                                                        }
                                                                                        perPage={
                                                                                            perLeadpayPage
                                                                                        }
                                                                                        showFooter={
                                                                                            true
                                                                                        }
                                                                                        className="assign-team-worker-select"
                                                                                        {...rest}
                                                                                        label={
                                                                                            ''
                                                                                        }
                                                                                        hideLabel={
                                                                                            true
                                                                                        }
                                                                                        source={getSource(
                                                                                            'employee_id'
                                                                                        )}
                                                                                        variant="outlined"
                                                                                        searchPlaceholder="Search Worker"
                                                                                        reference="Employee__DropdownList"
                                                                                        size="small"
                                                                                        validate={required()}
                                                                                        filter={getFilterForLeadpayEmployee(
                                                                                            formData,
                                                                                            scopedFormData
                                                                                        )}
                                                                                    >
                                                                                        <AutocompleteInput
                                                                                            size="small"
                                                                                            fullWidth
                                                                                            optionText={record => {
                                                                                                return `${record?.display_name}`;
                                                                                            }}
                                                                                            {...props}
                                                                                        />
                                                                                    </CustomReferenceInputObjSelect>
                                                                                );
                                                                            }}
                                                                        </FormDataConsumer>
                                                                        <FormDataConsumer>
                                                                            {({
                                                                                formData,
                                                                                getSource,
                                                                                scopedFormData,
                                                                                ...rest
                                                                            }) => {
                                                                                return (
                                                                                    <Field
                                                                                        name={getSource(
                                                                                            'base_wage'
                                                                                        )}
                                                                                    >
                                                                                        {({
                                                                                            input: {
                                                                                                onChange,
                                                                                            },
                                                                                        }) => {
                                                                                            wageChangeRefByEmp.current[
                                                                                                `${scopedFormData?.employee_id}`
                                                                                            ] = onChange;
                                                                                            return (
                                                                                                <StyledPercentInput
                                                                                                    className="lead-pay-amount-res no-translate"
                                                                                                    data-title={`% (${formatter.format(
                                                                                                        formData?.leadpay_amount
                                                                                                            ? formData?.leadpay_amount
                                                                                                            : 0
                                                                                                    )})`}
                                                                                                    variant="outlined"
                                                                                                    fullWidth
                                                                                                    source={getSource(
                                                                                                        'lead_pay_per'
                                                                                                    )}
                                                                                                    size="small"
                                                                                                    validate={[
                                                                                                        required(),
                                                                                                    ]}
                                                                                                    label={
                                                                                                        ''
                                                                                                    }
                                                                                                />
                                                                                            );
                                                                                        }}
                                                                                    </Field>
                                                                                );
                                                                            }}
                                                                        </FormDataConsumer>
                                                                    </CustomStyledMilstoneFormIterator>
                                                                </ArrayInput>
                                                            </>
                                                        );
                                                    }}
                                                </FormDataConsumer>
                                            </Grid>
                                        )}
                                </div>
                            </>
                        );
                    }}
                />

                <div className="info-alert-tagline g-m-i-a-t">
                    {INFO_ICON_OUTLINED()} All the group ProPay's need to have the same ProPay settings.
                </div>

            </div>
        </>
    )
}

const GroupPropayAutomationScheduleStep = ({ formFields, handleChangeFormFields, setFormFields }) => {

    return (
        <>
            <div className='gp-am-steps'>
                <div className="group-recurrence-sec">
                    <div className="group-recurrence-head">
                        {VISIT_CALENDER_ICON()}
                        Create a schedule to automatically generate Group ProPays.
                    </div>
                    <div className="group-repeat-div group-repeat-every">
                        <div className="group-repeat-label">
                            Repeat every
                        </div>
                        <SingleSelect
                        value={formFields?.repeat_type}
                        options={groupAutomationRepeatType}
                        fieldName={'repeat_type'}
                        handleChange={handleChangeFormFields}
                        />
                    </div>
                    {formFields?.repeat_type === 'week' && <div className="group-repeat-div group-repeat-week">
                    <div className="group-repeat-label">Repeat on</div>
                    <div className='repeat-week'>
                        {COMMON_DAYS?.map((day)=> (
                            <Button onClick={()=>handleChangeFormFields('repeat_week_day',Number(dayToNumber(day)))} className={`repeat-week-btn ${formFields?.repeat_week_day === Number(dayToNumber(day)) ? 'active-repeat-week-btn' : ''}`}>{day}</Button>
                        ))}
                    </div>
                    </div>}

                    <div className='am-r-i-row'>
                    {formFields?.repeat_type && formFields?.repeat_type !== 'false' && <div className='am-r-i-row-head'>
                    {ARROW_CORNER_ICON()}
                    <Checkbox checked={formFields?.interval_input} onChange={(e)=>handleChangeFormFields('interval_input', e?.target?.checked)} />
                    Add repeat intervals
                    </div>}
                    {formFields?.interval_input && <div className='am-r-i-row-body'>
                    Interval
                        <CustomNumberInput
                            value={formFields?.repeat_value}
                            setFormFields={setFormFields}
                            disabled={!formFields?.repeat_type}
                            fieldName={'repeat_value'}
                            className='repeat-textfield no-translate'
                            variant='standard'
                        />
                    </div>}
                    </div>

                    {/* Commented this code as we are not releasing this in v1 */}
                    {/* <div className='am-r-i-row'>
                    {formFields?.repeat_type === 'day' && <div className='am-r-i-row-head'>
                    {ARROW_CORNER_ICON()}
                    <Checkbox checked={formFields?.exclude_days} onChange={(e)=>handleChangeFormFields('exclude_days', e?.target?.checked)} />
                    Exclude Specific Days
                    </div>}
                    {formFields?.exclude_days && formFields?.repeat_type === 'day' &&
                    <div className='am-r-i-row-body'>
                    <div className="group-repeat-div group-repeat-week">
                    <div className='repeat-week'>
                        {COMMON_DAYS?.map((day)=> (
                            <Button onClick={()=>handleChangeFormFields('skip_days',Number(dayToNumber(day)))} className={`repeat-week-btn ${formFields?.skip_days?.includes(Number(dayToNumber(day))) ? 'active-repeat-week-btn' : ''}`}>{day}</Button>
                        ))}
                    </div>
                    </div>
                    </div>}
                    </div> */}

                    {/* Commenting this for now as we will release this in Version 2 */}
                    {/* <div className="group-repeat-div group-end-repeat">
                        <div className="group-repeat-label">Ends</div>
                        <FormControl className="group-end-radio">
                            <RadioGroup
                                aria-labelledby="group-recurrence-radio-buttons-group-label"
                                defaultValue="Never"
                                name="group-recurrence-radio-buttons-group"
                                value={formFields?.schedule_end_type}
                                onChange={(e)=>handleChangeFormFields('schedule_end_type',e?.target?.value)}
                            >
                                <FormControlLabel
                                    value="never"
                                    control={<Radio />}
                                    label="Never"
                                    className='gp-end-never'
                                />
                                <FormControlLabel
                                    value="on"
                                    control={<Radio />}
                                    label="On"
                                    className='m-w-on-label'
                                />
                                <DatePicker
                                    selected={formFields?.end_date ? new Date(formFields?.end_date) : formFields?.end_date}
                                    disabled={formFields?.schedule_end_type !== 'on'}
                                    onChange={e => handleChangeFormFields('end_date', e)}
                                    placeholderText="Date"
                                    className='gp-am-date-picker'
                                />
                                <FormControlLabel
                                    value="after"
                                    control={<Radio />}
                                    label="After"
                                    className='m-w-on-label'
                                />
                                <CustomNumberInput
                                    value={formFields?.after_occurrence}
                                    setFormFields={setFormFields}
                                    disabled={formFields?.schedule_end_type !== 'after'}
                                    fieldName={'after_occurrence'}
                                />
                                Ocurrences
                            </RadioGroup>
                        </FormControl>
                    </div> */}
                </div>
                <div className="labour-budget-notification info-budget-notification">
                    {ALERT_ERROR_AUTOMATION()}
                    Group triggered first by scheduled visits start date, then clock-in start date
                </div>
                <div className='group-repeat-label'>Please select the date you would like to start the automation</div>
                <DatePicker
                    selected={formFields?.start_date ? new Date(formFields?.start_date) : formFields?.start_date}
                    onChange={e => handleChangeFormFields('start_date', e)}
                    placeholderText="Add Starting Date"
                    className='gp-am-date-picker fw-gp-am-d-picker'
                />
                <div className='am-gp-text'>
                    Group will only be created if their are two or more ProPays.
                </div>

            </div>
        </>
    )
}

export const CustomNumberInput = ({ value, fieldName, setFormFields, disabled, className, variant }) => {
    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue === "" || (/^\d*$/.test(inputValue) && parseInt(inputValue) >= 1)) {
            setFormFields(previous => ({
                ...previous,
                [fieldName]: inputValue === "" ? "" : parseInt(inputValue)
            }));
        }
    };

    return (
        <TextField
            id="budget-percent-input"
            type="number"
            value={value}
            onChange={handleInputChange}
            label=""
            variant={variant ? variant : 'outlined'}
            size="small"
            className={className ? className : 'group-end-input no-translate'}
            inputProps={{
                min: 1
            }}
            disabled={disabled}
            InputProps={{
                endAdornment: <InputAdornment position="end">{CHEVRON_UP_DOWN_ICON()}</InputAdornment>,
              }}
        />
    )
}

const GroupPropayAutomationConfirmStep = ({formFields, handleChangeFormFields, setCreateGroupTab, setAutomationNameChanged}) => {
    return (
        <>
            <div className='gp-am-steps'>

                <div className='confirm-gp-box'>
                    <div className="group-propay-head">
                        {GROUPED_ICON()}
                        Group ProPays
                        <Link onClick={()=>setCreateGroupTab(1)}>Edit</Link>
                    </div>
                    <Typography className='gp-am-paragraph' variant="body1" gutterBottom>
                        Group Automation Name: <strong>{formFields?.title}</strong>
                    </Typography>
                    {formFields?.filters?.length && formFields?.filters[0]?.value?.length ?
                        <>
                            <Typography className='gp-am-paragraph' variant="body1" gutterBottom>
                                Group ProPays by using <strong>Job Type</strong>
                            </Typography>
                            <div className="gp-am-and-alert">
                                {SELECT_JOB_ARROW()}{' '}
                                <span className="color-text-orange">AND</span>{' '}
                                Job Type <strong>Is</strong> Included in Automation
                            </div>
                        </> : <></>
                    }
                    <Typography className='gp-am-paragraph' variant="body1" gutterBottom>
                        Automatically generate the group title using these values <strong>{formFields?.name_format?.map((el)=>el?.label)?.join(', ')}</strong>
                    </Typography>
                </div>

                <div className='confirm-gp-box'>
                    <div className="group-propay-head gap-am-calender-head">
                        {VISIT_CALENDER_ICON()}
                        Create a schedule to automatically generate Group ProPays.
                        <Link onClick={()=>setCreateGroupTab(3)}>Edit</Link>
                    </div>
                    <Typography className='gp-am-paragraph' variant="body1" gutterBottom>
                        Repeat every <strong>{formFields?.repeat_value} {capitalizeFirstLetter(formFields?.repeat_type)}</strong>
                    </Typography>
                    {/* Commenting this for now as we will release this in Version 2 */}
                    {/* <Typography className='gp-am-paragraph' variant="body1" gutterBottom>
                        Ends <strong>{formFields?.schedule_end_type}</strong>
                    </Typography> */}
                </div>
                <TextField
                    id="outlined-basic"
                    label="Group Automation Name"
                    value={formFields?.title}
                    onChange={(e)=> {
                        const formattedValue = e.target.value.replace(/^\s+/, '');
                        setAutomationNameChanged(true);
                        handleChangeFormFields('title', formattedValue)
                    }}
                    variant="outlined"
                    fullWidth
                />
            </div>
        </>
    )
}
