import { IconButton, Tooltip } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { CRUD_UPDATE, useMutation, useNotify, useResourceContext, useTranslate } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { useGetIdentityOptimized, usePermissionsOptimized } from '../components/identity';
import { DropdownIcon } from '../components/SvgIcons/DropdownIcon';
import { canAccess } from '../ra-rbac';
import { getIntegratedNonIntegrated } from '../resources/attendances/Attendance';
import { HasBackendNotConnected } from '../resources/company/company';
import { NUMBER } from '../utils/Constants/MagicNumber';
import ApprovePropayAction from './CradActions/ApprovePropayAction';
import PropayCancelAction from './CradActions/PropayCancelAction';
import PropayDeleteAction from './CradActions/PropayDeleteAction';
import UserDetailsModal from './UserDetailsModal';
import { ConfirmModal } from '../components/ConfirmModal';
import MilestonesSetStatusModal from '../resources/propays/MilestonesSetStatusModal';
import DialogForm from '../components/DialogForm';
import { EmployeeUpdateForm } from '../resources/employees/Employee';
import { GroupApproveModal, ProPayPaidAlert } from '../resources/propays';
import { usePageAlertsContext } from '../components/page-alerts/usePageAlerts';
import { useQueryClient } from 'react-query';
import { updateCache } from '../hooks/updateCache';
import { ASSIGN_USER_ICON, BIN_ICON_ACTIONS, CLOSE_ICON_ACTIONS, DUPLICATE_ICON, EDIT_ICON_ACTIONS, FILLED_PLUS_ICON, PREVIEW_AS_WORKER_SEARCH_ICON, PROPAY_PENDING_ACTION, TEAM_PLUS_ICON, UNGROUP_ICON, VISIT_CALENDER_ICON } from '../utils/Constants/ClassName';
import { manageActions } from '../resources/propays/ActionButtons';
import { USER_TYPE, capitalizeFirstLetter } from '../utils/Constants/ConstantData';
import AssignWorkers from '../resources/propays/AssignWorkers';
import ScheduledVisitsModal from '../resources/propays/ScheduledVisitsModal';
import CreateGroupPropay from '../resources/propays/groupPropay/CreateGroupPropay';
import { CustomFilterContext } from './CustomFilterContext';
import { Button } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material';
import ExportPropay from '../resources/propays/ExportPropay';
import { EditColumnsOpenDrawer } from './ColumnDrawer';
import UpdateListDataDialog from './UpdateListData';
import { ToastContainer } from 'react-toastify';


// / Action for listing of cards view and list view /
const CardListActions = (props: any )=> {
    const { record, onList, onShowPage, refresh, onCard, workerPreview, showWorkerPreview } = props;
    const navigate = useNavigate();
    const translate = useTranslate();
    const [showModal, setShowModal] = React.useState(false);
    const [milestoneNames, setmilestoneNames] = React.useState('');
    const [showStatusModal, setShowStatusModal] = React.useState(false);
    const [employeeData, setEmployeeData] = React.useState({employeeRecord:null, propayRecord:null});
    const dialogRefs: any = React.useRef();
    const { showAlert } = usePageAlertsContext();
    const groupContext = React.useContext(CustomFilterContext);
    const [mutate] = useMutation();
    const notify = useNotify();
    const resource =  useResourceContext();
    const queryClient = useQueryClient();
    const { permissions } = usePermissionsOptimized();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const isSmall = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down('sm')
  );
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const [openModal, setOpenModal] = React.useState(false);
    const [deletePropay, seDeletePropay] = React.useState(false);
    const [cancelPropay, seCancelPropay] = React.useState(false);
    const [actionName, setActionName] = React.useState('');
    const { identity } = useGetIdentityOptimized();
    const [budgetUpdatePropays, setBudgetUpdatePropays] = React.useState([]);

    const [wageIds, setWageIds] = React.useState([]);

    const [propayId, setPropayId] = React.useState<number>();
    const [openAssignWorkerModal, setOpenAssignWorkerModal] = React.useState(false);
    const [showScheduleVisit,setShowScheduleVisit]=React.useState(false);
    const [showGroupApprove, setShowGroupApprove] = React.useState(false);
    const [showEditGroup, setShowEditGroup] = React.useState(false);
    const [groupName, setGroupName] = React.useState('');
    const [openBudgetupdate, setOpenBudgetupdate] = React.useState(false);
    const handleCloseBudgetupdate = () => {
      setOpenBudgetupdate(false);
    };

    React.useEffect(()=>{
      if(record?.is_group_propay){
        setGroupName(record?.name)
      }

    },[record?.name])

    const handleOpenWorkerDetails = (wageId, id: number) => {
      setPropayId(id);
      const arr = [];
      wageId && wageId.forEach((item)=> arr.push(item.id));
      setOpenModal(true);
      setWageIds(arr);
    };

    const editPropay = (PropayId: number) => navigate(`/edit/${PropayId}/propay`);
    const showPropay = (PropayId: any) => {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.delete('filter');
      if(queryParams.has('page')){
        queryParams.set('page', '1');
      }
      const newUrl = `/show/${PropayId}/propay?${queryParams.toString()}`;
      queryParams.has('page') ? navigate(newUrl, { replace: true, state: { _scrollToTop: true } }) :
      navigate(`/show/${PropayId}/propay`,{state: { _scrollToTop: true }})
    }
    const canAction =  canAccess({ permissions, resource: 'propays', action: 'cancel_propay' });
    const userCanEdit =  canAccess({ permissions, resource: 'propays', action: 'edit' });
    const handleConfirmChange = () => {
      setShowModal(false);
  }
  const handleApprovePropay = (propayRecord, forceUpdate?:boolean, showGroupModal?:boolean) => {
    const mutationAction = propayRecord?.mutationAction;
    setActionName(propayRecord?.actionName ? propayRecord?.actionName : 'Approve')
    const pendingMilestones = propayRecord?.milestone_ids.filter(milestone => (milestone.status === "pending" || milestone.status === "started" || milestone.status === "completed"));
    const milestoneNames = pendingMilestones.map(milestone => milestone.name).join(', ');
    const workerWithoutWage = propayRecord?.employee_wage_ids?.filter((workerRec)=> !workerRec?.base_wage && workerRec?.hours)
    const propayWithoutBudget = propayRecord?.is_group_propay ? propayRecord?.grouped_propay_ids?.filter((idsObj)=> !idsObj?.budget && !idsObj?.budget_hours) : [];
    const status = propayRecord.status;
    if((propayRecord?.status === 'pending' || propayRecord?.status === 'started' || status === "completed") && pendingMilestones?.length && identity?.user_type !== USER_TYPE.crew_lead && !propayRecord?.is_group_propay){
      setShowModal(true);
      setmilestoneNames(milestoneNames);
      return
    }
    if(['pending', 'started', 'completed']?.includes(propayRecord?.status) && propayRecord?.is_group_propay && propayWithoutBudget?.length){
      setBudgetUpdatePropays(propayWithoutBudget.map((item)=>({name:item?.name, id:item?.id, budget_option:item?.budget_option,status:item?.status, budget:'', hours:item?.hours})));
      setOpenBudgetupdate(true);
      return;
    }
    if((propayRecord?.status === 'pending' || propayRecord?.status === 'started' || status === "completed") && propayRecord?.is_group_propay && (!propayRecord?.actionName || propayRecord?.actionName === "Approve")){
      const propayZeroHours = propayRecord?.grouped_propay_ids?.filter(item=>item?.hours === 0)
      if(!showGroupModal && !forceUpdate && propayRecord?.is_group_propay && propayZeroHours.length > 0) {
        setShowGroupApprove(true)
        return
      } else {
        setShowGroupApprove(false)
      }
    }
    if (workerWithoutWage?.length && !forceUpdate && (!propayRecord?.actionName || propayRecord?.actionName === "Approve")) {
        setEmployeeData((prev)=>({...prev,employeeRecord:workerWithoutWage,propayRecord:propayRecord}));
        dialogRefs?.current?.open();
        return
    }
    // const bonusPendingMilestones = record.milestone_ids.filter(ml=> ml.status === 'approved');
    // if(status !=='pending' && bonusPendingMilestones.length > NUMBER.ZERO && record.has_milestones){
    //         setShowStatusModal(true);
    //         return ;
    //     }
    return mutate(
      {
          type: 'update',
          resource: 'propays',
          payload: {id: propayRecord.id, action: mutationAction ? mutationAction : 'approvePropay', data: {  } }
      },
      {
          mutationMode: 'pessimistic',
          action: CRUD_UPDATE,
          onSuccess: (
              data: any,
              variables: any = {}
          ) => {
              data = data.data;
              if (propayRecord.status === 'pending' && data.status === 'paid') {
                  showAlert({
                      key: 'propay-paid-alert',
                      body: <ProPayPaidAlert name={propayRecord.name} />
                  });
              };
              refresh && refresh();
              // queryClient.invalidateQueries([onCard ? 'Propay__listing' : 'propays', 'getList']);
              queryClient.refetchQueries();
              updateCache({queryClient, resource, id:data.id, data});
          },
          onFailure: error => {
              notify(`Failure ! ${error.message}`);
          }
      }
      );
};

const handleUngroup = (propayRecord) => {

  return mutate(
    {
        type: 'update',
        resource: 'propays',
        payload: {id: propayRecord.id, action: 'unGroupPropay', data: {  } }
    },
    {
        mutationMode: 'pessimistic',
        action: CRUD_UPDATE,
        onSuccess: (
            data: any,
            variables: any = {}
        ) => {
            groupContext.setCustomMessage('Ungrouped GroupProPay Successfully!');
            groupContext.setShowToast(true);
            data = data.data;
            refresh && refresh();
            updateCache({queryClient, resource, id:data.id, data});
        },
        onFailure: error => {
            notify(`Failure ! ${error.message}`);
        }
    }
    );
}

const duplicatePropay = () => navigate(`/create/propay?duplicate=${record?.id}`,{state: { _scrollToTop: true }});
const hasTimeEntries = (record?.hours > NUMBER.ZERO && (record?.budget || record?.budget_hours))

    return(
        <>
        <DialogForm
            title="Missing User Data"
            ref={dialogRefs}
            wageClass={'add-wage-dialog'}
            iconBootun={true}
        >
         <EmployeeUpdateForm
            onEditSuccess={()=>{
              dialogRefs?.current?.close();
              setEmployeeData({employeeRecord:null, propayRecord:null});
              handleApprovePropay(employeeData?.propayRecord, true);
            }}
            handleClose={dialogRefs?.current?.close}
            emptyFields={employeeData?.employeeRecord?.map((item)=>({id:item?.employee_id_obj?.id,name:item?.employee_id_obj?.display_name,
                email:(item?.employee_id_obj?.email && item?.employee_id_obj?.email !== 'false') ? item?.employee_id_obj?.email :'',base_wage:item?.employee_id_obj?.base_wage ? item?.employee_id_obj?.base_wage :'',
                mobile_number:(item?.employee_id_obj?.mobile_number&& item?.employee_id_obj?.mobile_number !== 'false') ? item?.employee_id_obj?.mobile_number :''}))}
            hideMobile={true}
            isLoading={false}
            isDashboard={false}

        />
        </DialogForm>
              <div className={`${onList ? 'on-list-dropdown card-dropdown' : 'card-dropdown'}`}>
        <Tooltip title=''>
          <IconButton
            onClick={handleClick}
            size='small'
            sx={{ ml: 2 }}
            aria-controls={open ? 'card-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
          >
            <DropdownIcon/>
          </IconButton>
        </Tooltip>
        <Menu
            anchorEl={anchorEl}
            className='propay-action-menu'
            id='card-menu'
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <>
            {!record?.is_group_propay && <MenuItem className='duplicate-propay-item' onClick={()=>duplicatePropay()}>
              <ListItemIcon>
              {DUPLICATE_ICON()}
              </ListItemIcon>
              Duplicate ProPay
            </MenuItem>}
            {
              record?.parent_id_obj?.is_group_propay && userCanEdit ?  <MenuItem onClick={() => editPropay(record.id)} disabled={!userCanEdit}>
              <ListItemIcon>
              {EDIT_ICON_ACTIONS()}
              </ListItemIcon>
              {record?.is_group_propay ? translate('resources.propays.groupActions.edit_propay') : translate('resources.propays.actions.edit_propay')}
            </MenuItem>:
            <>
           {userCanEdit && !['paid', 'approved']?.includes(record?.status) && identity?.user_type !== USER_TYPE.crew_lead  &&
           <ApprovePropayAction record={record} refresh={refresh} setShowModal={setShowModal}
            setmilestoneNames={setmilestoneNames} setShowStatusModal={setShowStatusModal} handleApprovePropay={handleApprovePropay}/>}
              <MenuItem className='sch-visit-menu-item' onClick={() => setShowScheduleVisit(true)}>
                <ListItemIcon>
                  {VISIT_CALENDER_ICON()}
                </ListItemIcon>
                Scheduled Visit
              </MenuItem>
            { (identity?.user_type !== 'worker' && !workerPreview?.show && record?.wage_ids?.length > 0) &&
            <MenuItem className='preview-as-worker' onClick={()=>showWorkerPreview()}>
                <ListItemIcon>
                    {PREVIEW_AS_WORKER_SEARCH_ICON()}
                </ListItemIcon>
                Preview as a Worker
            </MenuItem>
                                        }
            {!['completed', 'pending']?.includes(record?.status) && identity?.company?.is_completed_status &&  <MenuItem onClick={()=>handleApprovePropay({...record, mutationAction:'markCompleted', actionName:'Complete'})}>
              <ListItemIcon>
              <svg width='22' height='22' viewBox='0 0 22 22'>
                <path d='M11 1.833C5.94 1.833 1.833 5.94 1.833 11S5.94 20.167 11 20.167 20.167 16.06 20.167 11 16.06 1.833 11
                1.833zm-1.833 13.75L4.583 11l1.293-1.293 3.29 3.282 6.958-6.957 1.293 1.301-8.25 8.25z'></path>
            </svg>
              </ListItemIcon>
              Complete ProPay
            </MenuItem>}
            {/* <MenuItem className='assign-worker-menu-item' onClick={()=>setOpenAssignWorkerModal(true)}>
              <ListItemIcon>
              {ASSIGN_USER_ICON()}
              </ListItemIcon>
              Assign
            </MenuItem> */}
            {!['started', 'pending']?.includes(record?.status) && (identity?.user_type === USER_TYPE.crew_lead ? identity?.company?.is_started_status : true) &&  <MenuItem onClick={()=>handleApprovePropay({...record, mutationAction:'markPendingPropay', actionName:identity?.company?.is_started_status ? 'Start' : 'Pending'})}>
              <ListItemIcon>
              {PROPAY_PENDING_ACTION()}
              </ListItemIcon>
              {identity?.company?.is_started_status ? (record?.is_group_propay ? 'Start Group' : 'Start ProPay') : 'Pending ProPay'}
            </MenuItem>}
            {/* <MenuItem className='bonus-pool-menu-item'>
              <ListItemIcon>
              {EDIT_ICON_ACTIONS()}
              </ListItemIcon>
              Bonus Pool (Wkr. 50%  / Co. 50%)
            </MenuItem>
           */}
            {userCanEdit &&
            <MenuItem onClick={record?.is_group_propay ? () => setShowEditGroup(true)  : () => editPropay(record.id)} disabled={!userCanEdit}>
              <ListItemIcon>
              {EDIT_ICON_ACTIONS()}
              </ListItemIcon>
              {record?.is_group_propay ? translate('resources.propays.groupActions.edit_propay') : translate('resources.propays.actions.edit_propay')}
            </MenuItem>}
             {getIntegratedNonIntegrated(identity) &&
             canAccess({ permissions, resource: 'attendances', action: 'create'}) && !onShowPage &&
             <HasBackendNotConnected>
             <MenuItem onClick={() => navigate('/attendances/create', { state: { record } })}>
              <ListItemIcon>
              {EDIT_ICON_ACTIONS()}
              </ListItemIcon>
              {translate('resources.propays.actions.add_time')}
            </MenuItem>
            </HasBackendNotConnected>
            }

            {record?.is_group_propay &&
            <MenuItem onClick={() => handleUngroup(record)}>
              <ListItemIcon>
              {UNGROUP_ICON()}
              </ListItemIcon>
              Un Group
            </MenuItem>}
            {!onShowPage && <MenuItem onClick={() => showPropay(record.id)}>
              <ListItemIcon>
            <svg width='22' height='22' viewBox='0 0 22 22' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M17.417 2.75H4.583A1.839 1.839 0 0 0 2.75 4.583v12.834c0 1.008.825 1.833 1.833 1.833h12.834a1.839 1.839 0 0 0 1.833-1.833V4.583a1.839
                    1.839 0 0 0-1.833-1.833zm-4.584 12.833H6.417V13.75h6.416v1.833zm2.75-3.666H6.417v-1.834h9.166v1.834zm0-3.667H6.417V6.417h9.166V8.25z' />
            </svg>
              </ListItemIcon>
              {record?.is_group_propay ? translate('resources.propays.groupActions.view_propay_details') : translate('resources.propays.actions.view_propay_details')}
            </MenuItem>}
            {(!onShowPage && record?.hours) ? <MenuItem onClick={() => handleOpenWorkerDetails(record.employee_wage_ids, record.id) }>
              <ListItemIcon>
              <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M21 5v14h2V5h-2zm-4 14h2V5h-2v14zM14 5H2c-.55 0-1 .45-1 1v12c0 .55.45 1 1
                  1h12c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1zM8 7.75c1.24 0 2.25 1.01 2.25 2.25S9.24 12.25 8 12.25 5.75 11.24 5.75 10 6.76 7.75
                  8 7.75zM12.5 17h-9v-.75c0-1.5 3-2.25 4.5-2.25s4.5.75 4.5 2.25V17z' />
               </svg>
              </ListItemIcon>
              {translate('resources.propays.actions.user_working_details')}
            </MenuItem>: ''}
            { canAction && record.status !=='cancelled' ? <MenuItem onClick={() => seCancelPropay(true)} disabled={!canAction}>
              <ListItemIcon>
              {CLOSE_ICON_ACTIONS()}
              </ListItemIcon>
              {record?.is_group_propay ? translate('resources.propays.groupActions.cancel_propay') : translate('resources.propays.actions.cancel_propay')}
            </MenuItem>:''}
            { canAction ? <MenuItem onClick={() =>seDeletePropay(true)} disabled={!canAction}>
              <ListItemIcon>
            <svg width='22' height='22' viewBox='0 0 22 22' xmlns='http://www.w3.org/2000/svg'>
            <path d='M5.5 17.417c0 1.008.825 1.833 1.833 1.833h7.334a1.839 1.839 0 0 0
            1.833-1.833v-11h-11v11zm11.917-13.75h-3.209l-.916-.917H8.708l-.916.917H4.583V5.5h12.834V3.667z' />
            </svg>
              </ListItemIcon>
              {record?.is_group_propay ? translate('resources.propays.groupActions.delete_propay') : translate('resources.propays.actions.delete_propay')}
            </MenuItem>:''}
            {/* modal open PropayEventModal already created PropayEventModal.tsx */ }

            {/* <MenuItem>
              <ListItemIcon>
              {BIN_ICON_ACTIONS()}
              </ListItemIcon>
              Delete Group
            </MenuItem> */}
            </>
            }
            </>
          </Menu>
          </div>
          {showEditGroup && <CreateGroupPropay
                    isEditing={true}
                    record={record}
                    setGroupName={setGroupName}
                    selectedListData={[record]}
                    groupName={groupName}
                    open={showEditGroup}
                    onClose={() => setShowEditGroup(false)}
                    />}
          {wageIds.length > NUMBER.ZERO  && <UserDetailsModal openModal={openModal} close={setOpenModal} wageId={wageIds} propayId={propayId} />}
          {cancelPropay && <PropayCancelAction cancelPropay={cancelPropay} cancelClose={seCancelPropay} route={false} {...props}/>}
          {deletePropay && <PropayDeleteAction deletePropay={deletePropay} DeleteClose={seDeletePropay} route={onShowPage ? true : false} {...props}/>}
          <ConfirmModal
                isOpen={showModal}
                loading={false}
                title={`ProPay ${actionName ?capitalizeFirstLetter(actionName) : 'Approve'} Alert`}
                content={`You can not ${actionName ? actionName : 'approve'} this ProPay as these "${milestoneNames}" milestones are still in ${returnMessage(identity, actionName)} state`}
                ButtonOneText='No'
                buttonTwoText='Ok'
                hideButton={true}
                onClose={() => setShowModal(false)}
                onConfirm={handleConfirmChange}
            />
             {showStatusModal && <MilestonesSetStatusModal isOpen={showStatusModal}  onClose={() => setShowStatusModal(false)}
             record={record} refresh={refresh}/>}
           {openAssignWorkerModal &&  <AssignWorkers open={openAssignWorkerModal} setOpen={setOpenAssignWorkerModal}/>}
           <ScheduledVisitsModal openModal={showScheduleVisit} close={()=>setShowScheduleVisit(false)}/>
           <GroupApproveModal record={record} handleClick={handleApprovePropay} setShowActionModal={setShowModal} selectedGroupData={record} isOpen={showGroupApprove} onClose={() => setShowGroupApprove(false)} />
          {openBudgetupdate &&
            <UpdateListDataDialog open={openBudgetupdate} actionName={actionName} record={record} handleClick={handleApprovePropay} handleClose={handleCloseBudgetupdate} apiData={budgetUpdatePropays} />
          }
        </>
    );
};
export default CardListActions;
const returnMessage = (identity, action) => {
  if(identity?.company?.is_completed_status && identity?.company?.is_started_status && action !== 'Complete'){
      return 'pending, started or completed';
  }else if(identity?.company?.is_started_status){
      return 'pending or started';
  }else if(identity?.company?.is_completed_status && action !== 'Complete'){
      return 'pending or completed';
  }else{
      return 'pending';
  }

}

export const CardListActionsProPayWithoutBudget = (props: any )=> {
  const { record, onList, onShowPage ,refresh, onCard} = props;
  const navigate = useNavigate();
  const { permissions } = usePermissionsOptimized();
  const { identity } = useGetIdentityOptimized();
  const [openBudgetupdate, setOpenBudgetupdate] = React.useState(false);
  const handleCloseBudgetupdate = () => {
    setOpenBudgetupdate(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [mutate] = useMutation();
  const queryClient = useQueryClient();
    const notify = useNotify();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [deletePropay, seDeletePropay] = React.useState(false);
  const [cancelPropay,setCancelPropay] = React.useState(false);


  const editPropay = (PropayId: number) => navigate(`/edit/${PropayId}/propay`);
  const canAction =  canAccess({ permissions, resource: 'propays', action: 'cancel_propay' });
  const userCanEdit =  canAccess({ permissions, resource: 'propays', action: 'edit' });
  const hasTimeEntries = ((record?.hours > NUMBER.ZERO || record?.attendance_earning > NUMBER.ZERO) && (record?.budget || record?.budget_hours))

  const handleMarkPendingPropay = (propayRecord) => {
    return mutate(
      {
          type: 'update',
          resource: 'propays',
          payload: {id: propayRecord.id, action: 'markPendingPropay', data: {  } }
      },
      {
          mutationMode: 'pessimistic',
          action: CRUD_UPDATE,
          onSuccess: (
              data: any,
              variables: any = {}
          ) => {
              data = data.data;
              refresh && refresh();
              queryClient.invalidateQueries([onCard ? 'Propay__listing' : 'propays', 'getList']);
          },
          onFailure: error => {
              notify(`Failure ! ${error.message}`);
          }
      }
      );
  }

  const propayWithoutBudget = record?.is_group_propay ? record?.grouped_propay_ids?.filter((idsObj)=> !idsObj?.budget && !idsObj?.budget_hours) : [{name:record?.name, id:record?.id, budget_option:record?.budget_option,status:record?.status, budget:'', hours:record?.hours}];

  return(
    <>
    <ToastContainer />
      <div className={`${onList ? 'on-list-dropdown card-dropdown' : 'card-dropdown'}`}>
      <Tooltip title=''>
        <IconButton
          onClick={handleClick}
          size='small'
          sx={{ ml: 2 }}
          aria-controls={open ? 'card-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
        >
          <DropdownIcon/>
        </IconButton>
      </Tooltip>
      <Menu
          anchorEl={anchorEl}
          id='card-menu'
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          className='card-menu-budget'
        >
          {(!['started', 'pending']?.includes(record?.status)) && (identity?.user_type === USER_TYPE.crew_lead ? identity?.company?.is_started_status : true) &&  <MenuItem onClick={()=>handleMarkPendingPropay(record)}>
              <ListItemIcon>
              {PROPAY_PENDING_ACTION()}
              </ListItemIcon>
              {identity?.company?.is_started_status ? (record?.is_group_propay ? 'Start Group' : 'Start ProPay') : 'Pending ProPay'}
            </MenuItem>}
          {userCanEdit && <MenuItem onClick={() => setOpenBudgetupdate(true)} disabled={!userCanEdit}>
            <ListItemIcon>
            <svg width='22' height='22' viewBox='0 0 22 22' xmlns='http://www.w3.org/2000/svg'>
            <path d='M2.75 15.813v3.437h3.438L16.325 9.112l-3.438-3.438L2.75 15.813zm16.234-9.36a.913.913 0 0 0 0-1.292L16.84
                  3.016a.913.913 0 0 0-1.292 0l-1.678 1.677 3.438 3.438 1.677-1.678z' />
          </svg>
            </ListItemIcon>
            Add Budget
          </MenuItem>}

          {(canAction && record.status !=='cancelled') ? <MenuItem onClick={()=>setCancelPropay(true)} disabled={!canAction}>
              <ListItemIcon>
              {CLOSE_ICON_ACTIONS()}
              </ListItemIcon>
              {record?.is_group_propay ? 'Cancel Group' : 'Cancel ProPay'}
            </MenuItem>:''}

          {(canAction) ? <MenuItem onClick={() =>seDeletePropay(true)} disabled={!canAction}>
            <ListItemIcon>
            <svg width='22' height='22' viewBox='0 0 22 22' xmlns='http://www.w3.org/2000/svg'>
          <path d='M5.5 17.417c0 1.008.825 1.833 1.833 1.833h7.334a1.839 1.839 0 0 0
          1.833-1.833v-11h-11v11zm11.917-13.75h-3.209l-.916-.917H8.708l-.916.917H4.583V5.5h12.834V3.667z' />
          </svg>
            </ListItemIcon>
            {record?.is_group_propay ? 'Delete Group' : 'Delete ProPay'}
          </MenuItem>:''}
        </Menu>
        </div>
        {deletePropay && <PropayDeleteAction deletePropay={deletePropay} DeleteClose={seDeletePropay} route={onShowPage ? true : false} {...props}/>}
        {cancelPropay && <PropayCancelAction cancelPropay={cancelPropay} cancelClose={setCancelPropay} route={false} {...props}/>}
        {openBudgetupdate &&
            <UpdateListDataDialog open={openBudgetupdate} actionName={''} record={{}} handleClick={()=>{}} handleClose={handleCloseBudgetupdate} apiData={propayWithoutBudget.map((item)=>({name:item?.name, id:item?.id, budget_option:item?.budget_option,status:item?.status, budget:'', hours:item?.hours}))} />
          }
        </>
  );
};
export const ResponsivePropayAction = (props)=>{
  const {propayFilter,columnOptions,identity,setUserFileds}=props;
  const { permissions } = usePermissionsOptimized();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = event => {
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
      setAnchorEl(null);
  };
  return(
      <div
      className='card-dropdown p-action-res'
  >
    <Tooltip title="">
          <IconButton
              onClick={handleClick}
              sx={{ ml: 2 }}
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
          >
              <DropdownIcon />
          </IconButton>
      </Tooltip>
  <Menu
      onClose={handleClose}
      anchorEl={anchorEl}
      id="basic-menu"
      //className='propay-action-menu'
      open={open}
      className="bonus-action-menu team-menu"
  >
           {canAccess({ permissions, resource: 'propays', action: 'create' }) && <MenuItem className='team-edit-item' onClick={()=>{navigate('/create/propay');handleClose()}} >
            {TEAM_PLUS_ICON()}
            Create ProPay
            </MenuItem>}
            <MenuItem className="team-export-item">
            <ExportPropay propayFilter={propayFilter} columnOptions={columnOptions} identity={identity}/>
            </MenuItem>
            <MenuItem className='user-disable-item'><EditColumnsOpenDrawer userFields={columnOptions} options={columnOptions} setUserFileds={setUserFileds} /></MenuItem>
  </Menu>
  </div>)
}