import React, { cloneElement, ReactNode } from 'react';
import {
    Button,
    InputAdornment,
    Stack,
    TableCell,
    TableRow,
    Typography,
    Tooltip,
    IconButton,
    Box,
    TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { sentenceCase } from 'change-case';
import { convertNumToTime } from '../../utils/formatter';
import {get} from 'lodash';
import {
    Datagrid as RaDatagrid,
    DatagridProps,
    NumberField as RaNumberField,
    NumberInput,
    useTranslate,
    FunctionField,
    Pagination,
    useLocale,
    DatagridBody,
    DatagridRow,
    DatagridBodyProps,
    ListBase,
    useResourceContext,
    useListSortContext,
    useListPaginationContext,
    useRecordContext,
    ResourceContextProvider,
    NumberInputProps,
    NumberFieldProps as RaNumberFieldProps,
    TextInputProps,
    email,
    TextInput,
    useGetIdentity,
    required,
} from 'react-admin';
import IMask from 'imask';
import Empty from '../../layout/Empty';
import { Field } from 'react-final-form';
import {
    EditableDatagrid,
    EditableDatagridProps,
    EditableDatagridRow
} from '../../ra-editable-datagrid';
import Label from '../Label';
import { HeadlessDatagrid, HeadlessDatagridBody, HeadlessDatagridProps } from '../datagrid/HeadlessDatagrid';
import { EditableRowProps } from '../../ra-editable-datagrid/EditableDatagridRow';
import { canAccess } from '../../ra-rbac';
import { DateField } from './DateField';
import { useIdentityContext, usePermissionsOptimized } from '../identity';
import _ from 'lodash';
import { InfoLabel } from './InfoLabel';
import { useAttendance } from '../../resources/attendances/useAttendance';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { ATTENDANCE_FILTER_OTIONS, AUTOMATION_FILTER_OPTIONS, EMPLOYEE_FILTER_OPTION, FILTER_OPTIONS, STATEMENT_FILTER_OPTION } from '../../utils/Constants/ConstantData';
import { USER_TYPE } from '../../utils/Constants/ConstantData';
import { post } from '../../resources/onboard/PaymentProvider';
import moment from 'moment';
import { findpropayWithoutBudget } from '../../utils/Constants/CommonFunctions';
import { ALERT_ICON_WHITE } from '../../utils/Constants/ClassName';

export const sanitizeCustomPublicFieldRestProps: (props: any) => any = ({
    groupBy,
    ...props
}) => props;

export interface CustomPublicFieldProps{
    groupBy?: boolean | ReactNode;
};

export interface NumberFieldProps
    extends RaNumberFieldProps, CustomPublicFieldProps {
};

export const NumberField = (props: NumberFieldProps) => (
    <RaNumberField {...sanitizeCustomPublicFieldRestProps(props)}/>
);

export const PercentInput = (props: NumberInputProps) => (
    <NumberInput
        format={v => _.round(v * NUMBER.HUNDRED,NUMBER.TWO)}
        parse={v => parseFloat(v) / NUMBER.HUNDRED}
        InputProps={{
            endAdornment: (
                <InputAdornment position='end'>
                    %
                </InputAdornment>
            ),
        }}
        {...props}
    />
);

export const LeadPayPercentInput = (props: NumberInputProps) => (
    <NumberInput
        format={v => Number(v)}
        parse={v => Number(v)}
        InputProps={{
            endAdornment: (
                <InputAdornment position='end'>
                    %
                </InputAdornment>
            ),
        }}
        {...props}
    />
);

export const EmailInput = (props: TextInputProps) => {
    const {userType} = props;
    const identity = useIdentityContext();
    const validateEmail = email();
    const validate = (userType === USER_TYPE.manager) ? [required(),validateEmail]:[validateEmail];
    return <TextInput fullWidth  validate={validate} {...props} />
};

export const PercentField = (props: NumberFieldProps) => (
    <NumberField
        options={{ maximumFractionDigits: 2,style: 'percent' }}
        {...props}
    />
);

interface MoneyFieldProps extends NumberFieldProps {
    fromPropay?: boolean;
}

const CommonMoneyField = (props:NumberFieldProps) => {
    return(
        <NumberField
            options={{
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'USD',
            }}
            emptyText='US$0.00'
            {...props}
        />
    );
};

export const MoneyField:React.FC<MoneyFieldProps> = ({ fromPropay, ...props }) => {
    switch(fromPropay){
        case true:
            if (findpropayWithoutBudget(props?.record) && props?.source === 'budget') {
                return <div className='add-budget-alert'>{ALERT_ICON_WHITE()} Add Budget</div>;
            } else if (findpropayWithoutBudget(props?.record) && props?.source !== 'budget') {
                return props?.source ==='attendance_earning' && props?.record?.attendance_earning ?  <CommonMoneyField  {...props}/>: <div className='add-budget-alert'>~</div>;
            } else {
                return <CommonMoneyField  {...props}/>
            }
        default:
            return <CommonMoneyField  {...props}/>
    }
}

export const DescriptionsInput = ({handleChangeDescription,description}) => (
    <TextField
        label='Description'
        onChange={(e)=>handleChangeDescription(e)}
        value={description}
        size="small"
        fullWidth
    />
);

export const MoneyUSField = (props: any) => {
    const { value,newClass, uniqueClass } = props;
    const formatter = new Intl.NumberFormat('en-US');
    return(
    <FunctionField
    textAlign='right'
    align='right'
    className={uniqueClass ? uniqueClass : ''}
    render={() => (
        <>
       { value ?  <span style={{ textAlign: 'right' }} className={`${Math.sign(value) === NUMBER.NEGATIVE_ONE ? 'red-text': newClass}`}>
           {(Math.sign(value) === NUMBER.NEGATIVE_ONE || newClass) ? '$ ('+Math.abs(value).toFixed(NUMBER.TWO)+')':'$'+Math.abs(value).toFixed(NUMBER.TWO)}
        </span>: <span className='span-seventy-px' style={{ textAlign: 'center', width: '70px' }} > - </span>}
        </>
    )}
/>
);}
export const convertNumber = (record,identity) => {
    const NumberInput = record?.toString();
    if(identity?.company?.hours_format == 'by_time' ){
        return convertNumToTime(NumberInput)
    } else{
        return parseFloat(NumberInput|| 0).toFixed(NUMBER.TWO)
    };
};
export const NumberToTimeField = (props: any) => {
    return (
        <FunctionField
            textAlign='right'
            align='right'
            {...sanitizeCustomPublicFieldRestProps(props)}
            render={() => <CommonNumberToTimeField {...props}/>}
        />
    );
};

const CommonNumberToTimeField = ({ fromPropay, ...props }) => {
    const record = useRecordContext(props);
    const { source, suffix_label,identity } = props;
    switch(fromPropay){
        case true:
            if (findpropayWithoutBudget(props?.record) && props?.source === 'estimated_hours') {
                return <div className='add-budget-alert'>{ALERT_ICON_WHITE()} Add Budget</div>;
            } else if (findpropayWithoutBudget(props?.record) && !['estimated_hours','hours'].includes(props?.source)) {
                return <div className='add-budget-alert'>~</div>;
            } else {
                return (
                    <>
                {(findpropayWithoutBudget(props?.record) && source === 'hours' && !record['hours']) ? <div className='add-budget-alert'>~</div> : <span style={{ textAlign: 'right' }}>
                { identity?.company?.hours_format == 'by_time' ?  <>{convertNumToTime(record[source])} {suffix_label}</> :
                parseFloat(record[source] || NUMBER.ZERO).toFixed(NUMBER.TWO)}
               </span>}
               </>
               )
            }
        default:
            return (<span style={{ textAlign: 'right' }}>
            { identity?.company?.hours_format == 'by_time' ?  <>{convertNumToTime(record[source])} {suffix_label}</> :
            parseFloat(record[source] || NUMBER.ZERO).toFixed(NUMBER.TWO)}
           </span>)
    }
}

export const FormatTimeField = (props: any) => {
    const record = useRecordContext(props);
    const { loaded, identity } = useGetIdentity();
    if (!loaded) {
      return <span style={{ textAlign: 'right' }}>00:00</span>
    }
    const { source, suffix_label} = props;
    return  (
        <FunctionField
            textAlign='right'
            align='right'
            {...sanitizeCustomPublicFieldRestProps(props)}
            render={() => {
                return (
                    <span style={{ textAlign: 'right' }}>
                        {convertNumber(record[source], identity)} {suffix_label}
                    </span>
                )
            }}
        />
    );
};
export const FormatTimeFieldResposive = (props: any) => {
    const record = useRecordContext(props);
    const { loaded, identity } = useGetIdentity();
    if (!loaded) {
        return null;
    }
    const { source, suffix_label} = props;
    return  (
        <FunctionField
            className='date-MuiTypography'
            textAlign='right'
            align='right'
            {...sanitizeCustomPublicFieldRestProps(props)}
            render={() => {
                return (
                    <Typography className='manual-responsive'><label>Hours</label>{convertNumber(record[source], identity)} {suffix_label}</Typography>
                );
            }}
        />
    );
};


export const NumberToTimeFieldSign = (props: any) => {
    const record = useRecordContext(props);
    const { source, suffix_label } = props;
    return (
        <FunctionField
            textAlign='right'
            align='right'
            {...sanitizeCustomPublicFieldRestProps(props)}
            render={() => {
                const value = record[source]
                return <span style={{ textAlign: 'right' }}>
                { value< 0 && '-'}{convertNumToTime(value)} {suffix_label}
            </span>
            }
            }
        />
    );
};


export const Condition = ({ when, is, children } :any) => (
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value } }) => (value === is ? children : null)}
    </Field>
);

export const DefaultDatagrid = (props: DatagridProps) => {
    return (
        <Datagrid
            size='medium'
            empty={<Empty />}
            {...props}
        />
    )
}

export const DefaultEditableDatagrid = (props: EditableDatagridProps) => {
    return (
        <EditableDatagrid
            empty={props?.empty ? props.empty : <Empty />}
            {...props}
        />
    )
};

export const StyledEditableDatagrid = styled(DefaultEditableDatagrid)({
    '.MuiInput-root.MuiInput-underline.MuiInputBase-root': {
        'margin-top': '0px',
    },
    '.MuiFormControl-root.MuiFormControl-marginDense.MuiTextField-root': {
        'margin-top': '0px',
        'margin-bottom': '0px',
    },
    '.MuiTableCell-root:last-of-type': {
       'padding-right': '0px'
        // width: '90px',
        // whiteSpace: 'nowrap'

    },
    '.RaDatagrid-headerCell': {
        'background-color': '#F4F6F8',
    },
});
export const StyledDatagrid = styled(DefaultDatagrid)({
    '.MuiInput-root.MuiInput-underline.MuiInputBase-root': {
        'margin-top': '0px',
    },
    '.MuiFormControl-root.MuiFormControl-marginDense.MuiTextField-root': {
        'margin-top': '0px',
        'margin-bottom': '0px',
    },
    '.MuiTableCell-root:last-of-type': {
        'padding-right': '0px'
        // width: '90px',
    },
    '.RaDatagrid-headerCell': {
        'background-color': '#F4F6F8',
    },
});


const hasPermission = (all_permissions: any,  resource: string, access_permissions: []) => {
    for (const action of access_permissions) {
        if(canAccess({
            permissions: all_permissions,
            resource: resource,
            action: action,
        })){
            return true;
        }
    }
    return false;
}

export const EditableDatagridWithPermission = (props:any) => {
    const { permissions } = usePermissionsOptimized();
    const {rowClick, bulkActionButtons, noDelete, noEditButton, isRowEditable, editPermissions = ['edit'],deletePermissions = ['delete'],allowEdit, ...rest}  = props;

    const has_edit_permission = ((rowClick === 'edit' &&  hasPermission(permissions, props.resource,editPermissions)) || allowEdit);
    const has_delete_permission = !noDelete  && deletePermissions && hasPermission(permissions, props.resource,deletePermissions);

    return <StyledEditableDatagrid rowClick={rowClick} noEditButton={has_edit_permission ? noEditButton : true}
    bulkActionButtons={ (has_delete_permission || has_edit_permission) && bulkActionButtons ? cloneElement(bulkActionButtons, {has_delete_permission,has_edit_permission})   : false} noDelete={has_delete_permission ? noDelete : true}
    isRowEditable={has_edit_permission ? isRowEditable : (record:any) =>  {return false}} {...rest} />;
}

export const DateTimeField =  React.memo((props: any) => {
    const identity = useIdentityContext()
    return (<DateField
        showTime
        locales='en-US'
        timeFormat={identity?.time_format === '12' ? 'LT' : 'HH:mm'}
        {...props}
    />)
});

export const DateRangeField = (props:any) => {
    const locale = useLocale();
    const { record, from_field = 'from_date', to_field = 'to_date' }: any = props;
    const from_date = get(record,from_field);
    let to_date = get(record,to_field);
    to_date = from_date===to_date?null:to_date;
    return (
        (from_date || to_date)
        &&<>
            {from_date&&<DateField {...props} source={from_field} locales={locale}  />}
            {(from_date&&to_date)&&<>~</>}{to_date&&<DateField {...props}  source={to_field} locales={locale} />}
        </>
    );
};



export const StatusLabelField = ({ source, record, colors, resource }: any) => {
    const translate = useTranslate();
    const value = get(record, source);
    const color = get(colors, value);
    return (
        <Label
            variant='ghost'
            color={color || 'warning'}
        >
            <p className='no-translate'>{sentenceCase(translate(`resources.${resource}.choices.${source}.${value}`!))}</p>
        </Label>
    );
};

export const AttendanceStatusLabelField = ({ source, record, resource }: any) => {
    const translate = useTranslate();
    const value = get(record, source);
    const {statusInfo} = useAttendance({record});
    const findString = resource?.indexOf("__");

    const partBeforeDoubleUnderscore = (findString !== NUMBER.NEGATIVE_ONE)
      ? `${resource?.substring(NUMBER.ZERO, findString)}s`.toLocaleLowerCase()
      : resource;

    return (
        <Tooltip title={statusInfo} arrow>
        <Stack flexDirection={'row'} alignItems='center'>
            <Button variant='contained' className={`${record?.status === 'paid' ? 'yellow-status-btn list-status-btn' : 'list-status-btn'} no-translate`} >
            {sentenceCase(translate(`resources.${partBeforeDoubleUnderscore}.choices2.${source}.${value}`!))}
            </Button>
        </Stack>
        </Tooltip>
    );
};

export const DatagridBodyExpandHeadless = (props: DatagridBodyProps) => {
    return (
        <DatagridBody {...props} row={<DatagridRow headlessExpand />} />
    );
}



export const EmptyColumn = (props : any) => (null);
export const NoHeader = (props: any) => (<></>);


export const ListGroupItemPagination = (props: any) => {
    const {
        isLoading,
        perPage,
        total,
    } = useListPaginationContext(props);
    return !isLoading && total > perPage && (
        <TableRow>
            <TableCell colSpan={props.colSpan}>
                <Pagination />
            </TableCell>
        </TableRow>
    )
}

export const MaskField = ({mask, ...rest}:any) => {
    const value = get(rest.record, rest.source);
    if(!value){
        return <>{rest.emptyText}</>
    }
    const masked = IMask.createMask({
        mask: mask,
    });
    return (<span>{masked.resolve(value)}</span>)
}

export const PhoneField = (props:any) => {
    return (<MaskField mask='000-000-0000' {...props}/>)
}

export const ListGroupEditableRow = (props: EditableRowProps) => {
    return (
        <EditableDatagridRow {...props} />
    );
}
export const HeadlessBodyForEditable = (props: any) =>{
    return (
        <HeadlessDatagridBody {...props} row={<ListGroupEditableRow form={props.editForm}/>} />
    );
}

export const ListGroupItemsField = ({record, children, ...rest}: any) => {
    const resource = useResourceContext(rest);
    const filter = { domain : record.group_by_domain };
    const { currentSort } = useListSortContext();
    return (
        <ListBase disableSyncWithLocation sort={currentSort} resource={resource} perPage={100} filter={filter}>
            {children}
        </ListBase>
    );
}
export const HeadlessBodyForEditableNode = (props: any) =>{
    return (
        <HeadlessBodyForEditable {...props} />
    );
}

export const HeadlessDatagridEditable = ({children,...rest}: HeadlessDatagridProps&{editForm: ReactNode})=>{
    const resource = useResourceContext(rest);
    return (
        <HeadlessDatagrid bulkActionButtons={false} header={<NoHeader/>} body={HeadlessBodyForEditableNode} rowClick='edit' resource={resource} {...rest}>
            {children}
        </HeadlessDatagrid>
    );
}


export const Datagrid = ({headlessExpand, ...rest}: DatagridProps&{headlessExpand?: ReactNode}) => {
    const extraProps = headlessExpand ? { rowClick: 'expand', body:DatagridBodyExpandHeadless, expand: headlessExpand}: { } as any;
    return (
        <RaDatagrid  {...extraProps} {...rest}/>
    );
}

export const ReferenceListBase = (props:any) => {
    const {filter,reference, source, children, ...rest} = props;
    const record = useRecordContext(props);
    const {
        perPage = NUMBER.ONE_THOUSAND,
    } = props;

    //TODO: needs better fix:: this is hack to not return any records from getList api call.
    const ids = get(record, source) || [0];
    const filterBase = _.merge({id:{_in:ids}},filter);
    return (
        <ResourceContextProvider value={reference}>
            <ListBase perPage={perPage} {...rest} filter={filterBase}>
            {children}
            </ListBase>
        </ResourceContextProvider>
    );
};
export const RestrictedTag = ()=>{
    return ( <Tooltip placement="top" describeChild className="restricted-tooltip" title="Restricted removes the ability to add time to milestone" arrow>
    <Box><IconButton><svg width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path d="M8.25 5.25V3.375C8.25 2.77826 8.01295 2.20597 7.59099 1.78401C7.16903 1.36205 6.59674 1.125 6 1.125C5.40326 1.125 4.83097 1.36205 4.40901 1.78401C3.98705 2.20597 3.75 2.77826 3.75 3.375V5.25M3.375 10.875H8.625C8.92337 10.875 9.20952 10.7565 9.4205 10.5455C9.63147 10.3345 9.75 10.0484 9.75 9.75V6.375C9.75 6.07663 9.63147 5.79048 9.4205 5.5795C9.20952 5.36853 8.92337 5.25 8.625 5.25H3.375C3.07663 5.25 2.79048 5.36853 2.5795 5.5795C2.36853 5.79048 2.25 6.07663 2.25 6.375V9.75C2.25 10.0484 2.36853 10.3345 2.5795 10.5455C2.79048 10.7565 3.07663 10.875 3.375 10.875Z" stroke="#FF0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
     </svg></IconButton>Restricted</Box>
     </Tooltip>
     )
}
const getInBetweenQuery = (condition,values,dynamicKey)=>{
    if(condition === '_in'){
        let obj = {};
        obj[dynamicKey]={_lt: values[NUMBER.ONE], _gt: values[NUMBER.ZERO]};
      return  obj;
    }else{
        return {[dynamicKey]:{_lte:values[NUMBER.ZERO]},_or:{[dynamicKey]:{_gte:values[NUMBER.ONE]}}};
    }
}

const getInBetweenDateQuery = (condition,values,dynamicKey)=>{
    return {[dynamicKey]:{_gte:values[NUMBER.ZERO],_lte:values[NUMBER.ONE]}};
}

export const getCustomFilterQuery = (filterTag,connectorList=[],showLimitedFilter)=>{
    const query=Object.create({});
    const inBetween = ['_nin','_in'];
    const dateFilter =showLimitedFilter ? null : localStorage.getItem('propayFromDateFilter');
    if (dateFilter !== null) {
        const { _gte, _lte } = JSON.parse(dateFilter);
        Object.assign(query, { from_date: {_gte : _gte ? _gte : ''}, to_date: {_lte : _lte ? _lte : ''} });
    }
    if(filterTag.length > NUMBER.ZERO){
     filterTag.forEach(element => {
        switch(element.tag){
        case FILTER_OPTIONS.propay_no_bonus:
            Object.assign(query,{automation_id: {_is_null: false}, budget: {_eq: NUMBER.ZERO}, _or: {budget: {_is_null: true}}});
            break;
        case FILTER_OPTIONS.propay:
            element.selected_ids.length > NUMBER.ZERO && Object.assign(query,{id: {_in: element.selected_ids}});
            break;

        case FILTER_OPTIONS.status:
             let selectedStatus = element.selected_ids;
             let statusObj = element.selected_ids.includes('open') ? _.concat(selectedStatus,['approved','pending']):selectedStatus;
             statusObj.length > NUMBER.ZERO && Object.assign(query,{status: {_in:statusObj}});
            break;

        case FILTER_OPTIONS.cost_code:
            element.selected_ids.length > NUMBER.ZERO && Object.assign(query,{cost_code_ids: {_in: element.selected_ids}});
            break;

        case FILTER_OPTIONS.worker:
            element.selected_ids.length > NUMBER.ZERO && Object.assign(query,{employee_wage_ids:{employee_id:{_in:element.selected_ids}}});
            break;

        case FILTER_OPTIONS.manager:
            element.selected_ids.length > NUMBER.ZERO && Object.assign(query, { job_id_obj: { job_type_id_obj: { id: { _in: element.selected_ids } } } });
            break;

        case FILTER_OPTIONS.job:
            element.selected_ids.length > NUMBER.ZERO && Object.assign(query,{job_ids:{_in:element.selected_ids}});
            if(connectorList.length === NUMBER.TWO && element.selected_ids.length > NUMBER.ZERO){
                Object.assign(query,{second_job_id:{_in:element.selected_ids}});
            }
            break;
          case FILTER_OPTIONS.job_type:
            element.selected_ids.length > NUMBER.ZERO && Object.assign(query, {job_id_obj: {job_type_id_obj: {id: {_in: element.selected_ids}}}});
            break;
        case FILTER_OPTIONS.invoice_type:
            element.selected_ids.length > NUMBER.ZERO && Object.assign(query, {invoice_type_ids: {_in: element.selected_ids}});
            break;
        case FILTER_OPTIONS.branches:
            element.selected_ids.length > NUMBER.ZERO && Object.assign(query,{job_id_obj: {branch_id: {_in: element.selected_ids}  }});
            break;
        case FILTER_OPTIONS.division:
            element.selected_ids.length > NUMBER.ZERO && Object.assign(query,{job_id_obj: {division_id: {_in: element.selected_ids}  }});
            break;
        case FILTER_OPTIONS.budget:
            if(!inBetween.includes(element.value)){
            let obj = {};
            obj[element.value]=element.selected_ids;
             Object.assign(query,{budget:obj});
        }else{
             Object.assign(query,getInBetweenQuery(element.value,element.selected_ids,'budget'));
        }
            break;
        case FILTER_OPTIONS.variance_amount:
            if(!inBetween.includes(element.value)){
                let obj = {};
                obj[element.value]=element.selected_ids;
                    Object.assign(query,{remaining_amount:obj});
            }else{
                    Object.assign(query,getInBetweenQuery(element.value,element.selected_ids,'remaining_amount'));
            }
            break;
        case FILTER_OPTIONS.variance_hours:
            if(!inBetween.includes(element.value) && !isNaN(element.selected_ids) && element.value){
            let obj = {};
            obj[element.value]=element.selected_ids;
                Object.assign(query,{remaining_hours:obj});
        }else{
            (!isNaN(element.selected_ids) && element.value) && Object.assign(query,getInBetweenQuery(element.value,element.selected_ids,'remaining_hours'));
        }
            break;

        case FILTER_OPTIONS.hours_worked:
            if(!inBetween.includes(element.value)){
            let hrObj = {};
            hrObj[element.value]=element.selected_ids;
            Object.assign(query,{hours:hrObj});
            }else{
                Object.assign(query,getInBetweenQuery(element.value,element.selected_ids,'hours'));
           }
            break;

        case FILTER_OPTIONS.budget_hours:
            if(element.value){
            if(!inBetween.includes(element.value)){
            let obj1 = {};
            obj1[element.value]=element.selected_ids;
            Object.assign(query,{budget_hours:obj1});
            }else{
                Object.assign(query,getInBetweenQuery(element.value,element.selected_ids,'budget_hours'));
            }
            }
            break;
            case FILTER_OPTIONS.started_on:
                if(!inBetween.includes(element.value)){
                    let obj1 = {};
                    obj1[element.value]=element.selected_ids;
                    Object.assign(query,{from_date: obj1});
                }else{
                     Object.assign(query,getInBetweenDateQuery(element.value,element.selected_ids,'from_date'));
                }
                break;
          case FILTER_OPTIONS.schedule_visit:
            if (!inBetween.includes(element.value)) {
              let obj1 = {};
              obj1[element.value] = element.selected_ids;
              Object.assign(query, { schedule_visit_ids_obj: { schedule_date: obj1 } });
            } else {
              Object.assign(query, { schedule_visit_ids_obj: getInBetweenDateQuery(element.value, element.selected_ids, 'schedule_date') });
            }
            break;
            case FILTER_OPTIONS.ended_on:
                if(!inBetween.includes(element.value)){
                    let obj1 = {};
                    obj1[element.value]=element.selected_ids;
                    Object.assign(query,{to_date: obj1});
                }else{
                     Object.assign(query,getInBetweenDateQuery(element.value,element.selected_ids,'to_date'));
                }
                break;
            }
        });
    }
    return query;
}

export const getCustomTimeFilterQuery = (filterTag, propayBool)=>{
    const query=Object.create({});
    const inBetween = ['_nin','_in'];
    const dateFilter = localStorage.getItem(propayBool ? 'propayDetailTimeFromDateFilter' : 'timeFromDateFilter');
    if(dateFilter !== null){
        Object.assign(query,{start:JSON.parse(dateFilter)});
    }
    if(filterTag.length > NUMBER.ZERO){
     filterTag.forEach(element => {
        switch(element.tag){
        case ATTENDANCE_FILTER_OTIONS.propay:
            element.selected_ids.length > NUMBER.ZERO && Object.assign(query,{propay_id: {_in: element.selected_ids}});
            break;

        case ATTENDANCE_FILTER_OTIONS.status:
            element.selected_ids.length > NUMBER.ZERO && Object.assign(query,{status: {_in:element.selected_ids}});
            break;

        case ATTENDANCE_FILTER_OTIONS.cost_code:
            element.selected_ids.length > NUMBER.ZERO && Object.assign(query,{cost_code_id: {_in: element.selected_ids}});
            break;

        case ATTENDANCE_FILTER_OTIONS.worker:
            element.selected_ids.length > NUMBER.ZERO && Object.assign(query,{employee_id:{_in:element.selected_ids}});
            break;

        case ATTENDANCE_FILTER_OTIONS.job:
            element.selected_ids.length > NUMBER.ZERO && Object.assign(query,{job_id:{_in:element.selected_ids}});
            break;
        case ATTENDANCE_FILTER_OTIONS.period:
            element.selected_ids.length > NUMBER.ZERO && Object.assign(query,{period_id:{_in:element.selected_ids}});
            break;

        case ATTENDANCE_FILTER_OTIONS.wages:
            if(element.value){
            if(!inBetween.includes(element.value)){
            let obj = {};
             obj[element.value]=element.selected_ids;
             Object.assign(query,{base_wage:obj});
             }else{
             Object.assign(query,getInBetweenQuery(element.value,element.selected_ids,'base_wage'));
             }
            }
            break;
        case ATTENDANCE_FILTER_OTIONS.earning:
               if(element.value){
                if(!inBetween.includes(element.value)){
                let obj = {};
                obj[element.value]=element.selected_ids;
                 Object.assign(query,{earning:obj});
                 }else{
                 Object.assign(query,getInBetweenQuery(element.value,element.selected_ids,'earning'));
                 }
                }
        break;
        case ATTENDANCE_FILTER_OTIONS.hours:
            if(element.value){
            if(!inBetween.includes(element.value)){
            let obj = {};
            obj[element.value]=element.selected_ids;
             Object.assign(query,{hours:obj});
             }else{
             Object.assign(query,getInBetweenQuery(element.value,element.selected_ids,'hours'));
             }
            }
        break;
        case ATTENDANCE_FILTER_OTIONS.entries:
            Object.assign(query,{propay_id: {_is_null: true}});
            break;
        case ATTENDANCE_FILTER_OTIONS.branches:
            element.selected_ids.length > NUMBER.ZERO && Object.assign(query,{job_id_obj: {branch_id: {_in: element.selected_ids}  }});
            break;
        case ATTENDANCE_FILTER_OTIONS.division:
            element.selected_ids.length > NUMBER.ZERO && Object.assign(query,{job_id_obj: {division_id: {_in: element.selected_ids}  }});
            break;
            }
        });
    }
    return query;
}

export const getCustomAutomationFilterQuery = (filterTag=[])=>{
    const query=Object.create({});
    if(filterTag.length > NUMBER.ZERO){
     filterTag.forEach(element => {
        switch(element.tag){

        case FILTER_OPTIONS.status:
             let selectedStatus = element.selected_ids;
             selectedStatus.length > NUMBER.ZERO && Object.assign(query,{status: {_in:selectedStatus}});
            break;

        case AUTOMATION_FILTER_OPTIONS.automation_type:
            let selectedType = element.selected_ids;
            selectedType.length > NUMBER.ZERO && Object.assign(query,{type: {_in:selectedType}});
            break;

        case FILTER_OPTIONS.manager:
            element.selected_ids.length > NUMBER.ZERO && Object.assign(query,{manager_id:{_in:element.selected_ids}});
            break;

        case STATEMENT_FILTER_OPTION.created_date:
            if(element.selected_ids.length > 0){
                element.selected_ids[0] && Object.assign(query,
                {create_date:{ _gte:moment(element.selected_ids[0]).format(),_lte:moment(element.selected_ids[1]).format()}});
            }
        }
        });
    }
    return query;
}

export const getParsedConnectorName = (name)=>{
    if(!name || name === '[]' || name?.includes('False')){
        return '';
    }
    const parsedName = JSON.parse(name.replace(/'/g, "\""));
    return parsedName.length > NUMBER.ONE ? parsedName.join(',') : parsedName[NUMBER.ZERO];
}

export const getDuplicateConnectorList = async()=>{
    const data= {
        jsonrpc: '2.0',
        params: {},
    }
    const res = (await post('/api/protiv/connections',data)) as any;
    return res
}
export const getBonusCustomFilterQuery = (filterTag)=>{
    const query=Object.create({});
    query['parent_id']={_is_null:true}
    const inBetween = ['_nin','_in'];
    const dateFilter = localStorage.getItem('bonusFromDateFilter');
    if(dateFilter !== null){
        Object.assign(query,{statement_period_id_obj:{start_date:JSON.parse(dateFilter)}});
    }
    if(filterTag.length > NUMBER.ZERO){
     filterTag.forEach(element => {
        switch(element.tag){
        case STATEMENT_FILTER_OPTION.propay:
            element.selected_ids.length > NUMBER.ZERO && Object.assign(query,{propay_bonus_statement_ids:{propay_id: {_in: element.selected_ids}},
                _or:{child_bonus_statement_ids:{propay_bonus_statement_ids:{propay_id: {_in: element.selected_ids}}}}});
            break;

        case STATEMENT_FILTER_OPTION.status:
             element.selected_ids.length > NUMBER.ZERO && Object.assign(query,{status: {_in:element.selected_ids}});
            break;

        case STATEMENT_FILTER_OPTION.worker:
            element.selected_ids.length > NUMBER.ZERO && Object.assign(query,{employee_id:{_in:element.selected_ids}});
            break;

        // case STATEMENT_FILTER_OPTION.manager:
        //     element.selected_ids.length > NUMBER.ZERO && Object.assign(query,{propay_bonus_statement_ids:{propay_id_obj:{manager_id:{_in:element.selected_ids}}}});
        //     break;

        case STATEMENT_FILTER_OPTION.job:
            element.selected_ids.length > NUMBER.ZERO && Object.assign(query,{propay_bonus_statement_ids:{propay_id_obj:{job_id:{_in:element.selected_ids}}}})
            break;
        case STATEMENT_FILTER_OPTION.paid_period:
            element.selected_ids.length > NUMBER.ZERO && Object.assign(query,{paid_period_id:{_in:element.selected_ids}})
            break;
        case STATEMENT_FILTER_OPTION.statement_period:
            element.selected_ids.length > NUMBER.ZERO && Object.assign(query,{statement_period_id:{_in:element.selected_ids}})
            break;

        case STATEMENT_FILTER_OPTION.reference:
            if(!inBetween.includes(element.value)){
            let obj = {};
            obj[element.value]=element.selected_ids;
             Object.assign(query,{number:obj});
        }else{
             Object.assign(query,getInBetweenQuery(element.value,element.selected_ids,'number'));
        }
            break;
        case STATEMENT_FILTER_OPTION.created_date:
                if(element.selected_ids.length > NUMBER.ZERO){
                    const fromDate = element.selected_ids[NUMBER.ZERO] ? element.selected_ids[0]: null;
                    const toDate = element.selected_ids[NUMBER.ONE] ? element.selected_ids[1]:null;
                   (fromDate && toDate) && Object.assign(query,{propay_bonus_statement_ids: {propay_id_obj:
                    {bonus_statement_approved_date:{_gte:fromDate,_lte:toDate}}}});
                }
                break;
     case STATEMENT_FILTER_OPTION.earnings:
            if(!inBetween.includes(element.value)){
            let obj = {};
            obj[element.value]=element.selected_ids;
             Object.assign(query,{total_earning:obj});
        }else{
             Object.assign(query,getInBetweenQuery(element.value,element.selected_ids,'total_earning'));
        }
            break;
            case STATEMENT_FILTER_OPTION.close_date:
                if(element.selected_ids.length > NUMBER.ZERO){
                    const fromDate = element.selected_ids[NUMBER.ZERO] ? element.selected_ids[0]: null;
                    const toDate = element.selected_ids[NUMBER.ONE] ? element.selected_ids[1]:null;
                   (fromDate && toDate) && Object.assign(query,{closed_statement_date: {_gte:fromDate,_lte:toDate}});
                }
                break;
            case STATEMENT_FILTER_OPTION.branches:
                element.selected_ids.length > NUMBER.ZERO && Object.assign(query,{employee_id_obj: {branch_id: {_in: element.selected_ids}  }});
                break;
        }
        });
    }
    return query;
}

export const getFormattedDate = (date)=> date !== 'false' &&  date ? moment(date).format('MMM DD, YYYY'):'';

export const getPropayManagerName = (data)=>{
   return data.parent_id ? data.parent_id_obj.manager_id_obj.display_name : data.manager_id_obj.display_name
}

const getTeamStatus = (arr) => {
    const hasInactive = arr.includes('in_active');
    const hasActive = arr.includes('active');
    const filteredArr = arr.filter(item => item !== 'in_active' && item !== 'active');
    if (hasInactive && hasActive) {
        return filteredArr.length > 0 ? {status: {_in: filteredArr}} : {};
    }
    if (hasInactive) {
        return filteredArr.length > 0 ? {status: {_in: filteredArr}, active: {_eq: false}} : {active: {_eq: false}};
    }
    return {status: {_in: arr},active: {_eq:true}};
}
export const getTeamCustomFilterQuery = (filterTag)=>{
    const query=Object.create({});
    const inBetween = ['_nin','_in'];
    const dateFilter =localStorage.getItem('teamFromDateFilter');
    if (dateFilter !== null) {
        const { _gte, _lte } = JSON.parse(dateFilter);
        Object.assign(query, { create_date: {_gte : _gte ? _gte : '',_lte : _lte ? _lte : '' }});
    }
    if(filterTag.length > NUMBER.ZERO){
        filterTag.forEach(element => {
           switch(element.tag){
           case EMPLOYEE_FILTER_OPTION.name:
               element.selected_ids.length > NUMBER.ZERO && Object.assign(query,{id:{_in: element.selected_ids}});
               break;

           case EMPLOYEE_FILTER_OPTION.status:
                element.selected_ids.length > NUMBER.ZERO && Object.assign(query, getTeamStatus(element.selected_ids));
               break;

           case EMPLOYEE_FILTER_OPTION.role:
               element.selected_ids.length > NUMBER.ZERO && Object.assign(query,{user_type:{_in:element.selected_ids}});
               break;

           case EMPLOYEE_FILTER_OPTION.email:
               element.selected_ids.length > NUMBER.ZERO && Object.assign(query,{id:{_in:element.selected_ids}})
               break;
           case EMPLOYEE_FILTER_OPTION.mobile:
               element.selected_ids.length > NUMBER.ZERO && Object.assign(query,{id:{_in:element.selected_ids}})
               break;

           case EMPLOYEE_FILTER_OPTION.wage:
               if(!inBetween.includes(element.value)){
               let obj = {};
               obj[element.value]=element.selected_ids;
                Object.assign(query,{number:obj});
           }else{
                Object.assign(query,getInBetweenQuery(element.value,element.selected_ids,'base_wage'));
           }
               break;
           case EMPLOYEE_FILTER_OPTION.create_date:
                   if(element.selected_ids.length > NUMBER.ZERO){
                       const fromDate = element.selected_ids[NUMBER.ZERO] ? moment(element.selected_ids[0]).format(): null;
                       const toDate = element.selected_ids[NUMBER.ONE] ? moment(element.selected_ids[1]).format():null;
                      (fromDate && toDate) && Object.assign(query,{create_date: { _gte:fromDate,_lte:toDate}});
                   }
                   break;
            case EMPLOYEE_FILTER_OPTION.branches:
                element.selected_ids.length > NUMBER.ZERO && Object.assign(query,{branch_id: {_in: element.selected_ids}  });
                break;
            case EMPLOYEE_FILTER_OPTION.division:
                element.selected_ids.length > NUMBER.ZERO && Object.assign(query,{division_id: {_in: element.selected_ids}  });
                break;
           }
           });
       }
       return query;
}