import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    AutocompleteInput, BooleanInput,
    FormDataConsumer, FormWithRedirect, NumberInput, ReferenceInput, SaveButton, SelectInput, TextInput, required, useDataProvider, useNotify,
    useRecordContext,
    useTranslate, useUpdate
} from 'react-admin';
import { Edit } from '../../layout/Edit';
import { HasPermission } from '../payrolls/Payrolls';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Stack,
    Typography,
    TextField,
    Input
} from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import createDecorator from 'final-form-calculate';
import get from 'lodash/get';
import moment from 'moment';
import { maxValue, minValue, useGetIdentity, useGetList } from 'ra-core';
import { FunctionField, RadioButtonGroupInput } from 'ra-ui-materialui';
import CardTitle from '../../components/CardTitle';
import DialogForm from '../../components/DialogForm';
import { Condition,getDuplicateConnectorList } from '../../components/fields';
import { InfoLabel } from '../../components/fields/InfoLabel';
import { useIdentityContext ,usePermissionsOptimized} from '../../components/identity';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { getIntegratedNonIntegrated } from '../attendances/Attendance';
import { HasBackendNotConnected, useGetBackend } from '../company/company';
import { EmployeeUpdateForm, bonuspoolSelection, propayTypeSelection } from '../employees/Employee';
import { StyleToolbar } from '../payrolls/weeklyEntries';
import { BudgetChoices, bonusSplitTypeSelection } from '../propays/Propay';
import AdditionalOtSettings from './AdditionalOtSettings';
import { PayrollExportDataGrid } from './PayrollReportDataGrid';
import {
    ADD_TIME_INTERFACE,
    BONUS_STATEMENT,
    RATE,
    SCHEDULE,
    TIMEZONES,
    TIME_FORMAT,
    WEEKDAY
} from './SelectionInput';
import { ALERT_ERROR_AUTOMATION, COST_CODE_EDIT_ICON, SELECT_JOB_ARROW } from '../../utils/Constants/ClassName';
import { canAccess } from '../../ra-rbac';
import { post } from '../onboard/PaymentProvider';
import { RadioGroup } from '@mui/material';
import { Select } from '@mui/material';
import { SelectPicker } from 'rsuite';
import { useGetConnectorSyncInfo } from '../../layout/useGetConnectorSyncInfo';
import MultiSelectInputWithInfiniteScroll from '../../layout/CustomMultiSelectInputWithInfiniteScroll';
import CustomMuiSelect from '../../layout/CustomMuiSelect';
import { useAttendanceConnector } from '../attendances/useAttendanceConnector';


export const StyledSaveButton = styled(SaveButton)({
    '.MuiCircularProgress-root': {
        color: '#F4F6F8',
    },
});

export const StyledNumberInput = styled(NumberInput)({
    '.MuiFormHelperText-root': {
        display: 'none',
    },
    '.MuiInputLabel-root': {
        display: 'none',
    },
    'label+.MuiInput-root': {
        marginTop: 0,
    },
});

const HOURS_PER_DAY_SELECTION = [
    { id: '6', name: '6' },
    { id: '8', name: '8' },
    { id: '10', name: '10' },
    { id: '12', name: '12' },
];


const HOURS_FORMAT = [
    {
        id: 'by_time',
        name: 'HH:MM (4:45)',
    },
    {
        id: 'by_decimal',
        name: 'Decimal (4.75)',
    },
];
const ARBORGOLD_SERVICE_TYPE=[{label:'Recurring',value:'2'},{label:'Ongoing',value:'3'},{label:'Standard',value:'1'},{label:'Ongoing Indefinitely',value:'4'}];

export const HasFeature = ({
    enabled = true,
    feature,
    children,
    ...rest
}: any) => {
    const identity = useIdentityContext();
    const has_feature = get(identity?.company, feature);
    return has_feature === enabled
        ? React.Children.map(children, child => {
              if (React.isValidElement(child)) {
                  return React.cloneElement(child);
              }
              return child;
          })
        : null;
};

export const StyledSelectInput = styled(SelectInput)({
    '.MuiFormHelperText-root': {
        display: 'none',
    },
  '.MuiInput-input': {
    color: '#0391BA',
  }
});

const OvertimeConfigLine = ({ prefix, order, source, source2 }) => {
    const translate = useTranslate();
    return (
        <Stack
            className="setting-overtime-box-card-stack"
            flexDirection={'row'}
            justifyContent="space-end"
            alignItems="center"
            alignContent="center"
            sx={{ pt: 1, pb: 1, ml: 4 }}
        >
            <Typography variant="body2">{prefix}</Typography>
            <StyledNumberInput
                source={source}
                format={v => (!v ? null : v)}
                label={false}
                variant="standard"
                sx={{ width: '64px', pl: 1, pr: 1, mt: 0 }}
                textAlign="center"
                align="center"
                inputProps={{ style: { textAlign: 'center', marginTop: 0 } }}
            />
            <Typography variant="body2">
                {translate('resources.companies.settings.overtime.hours_for_a')}
            </Typography>
            <StyledSelectInput
                source={source2}
                label={false}
                allowEmpty
                variant="standard"
                sx={{ pl: 1, pr: 1, minWidth: '64px' }}
                choices={RATE}
            />
            <Typography variant="body2">
                {translate('resources.companies.settings.overtime.multiple')}
            </Typography>
        </Stack>
    );
};


const CompanySettingForm = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissionsOptimized();
    const [open, setOpen] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const notify = useNotify();
    const [update, { isLoading }] = useUpdate();
    const [show_job_page, setShowJobPage] = useState(
        props.record.show_job_page
    );
    const has_backend = useGetBackend();
    const record = useRecordContext();
    const { loaded, identity } = useGetIdentity();
    const {isExternallyAttendanceImported,isCostCodeImported} = useAttendanceConnector();
    const [isCostCodeEdit,setIsCostCodeEdit]=useState(false);
    const [renameCostCode,setRenameCostCode]=useState('');

    const [isPoolFieldEdit,setPoolFieldEdit]=useState({worker:false,company:false});
    const [renamePoolFieldLabel,setRenamePoolFieldLabel]=useState({worker:'',company:''});
    const [bonusType, setBonusType] = useState(props?.record?.default_bonus_split_type);

    const [bonusDay,setBonusDay]=useState(record.statement_period_week_day !== 'false' ? record.statement_period_week_day : '0');
    const [periodWeekDay,setPeriodWeekDay] =useState(record.payroll_week_day);
    const [managerData, setManagerData] = useState(null);
    const dialogRefs: any = useRef();
    const dataProvider = useDataProvider();
    const showConnectorsJob = canAccess({permissions,resource: 'allow-integrations',action: '*',})
    const [connectorList,setConnectorList]= useState([]);
    const [connectorDetail,setConnectorDetail]= useState({name:'',id:''});
    const [connectorName,setConnectorName] = useState({});
    const [disableCostCode,setDisableCostCode]= useState(false);
    const [selectedJobServices,setSelectedJobServices]= useState(record.subjob_excluded_services_ids || []);
    const {data} = useGetConnectorSyncInfo(identity?.company_id);
    const { data: scheduleVisits } = useGetList('ScheduleVisit', {
      filter: {},
      pagination: { page: 1, perPage: 1 },
      sort: { field: 'id', order: 'DESC' },
    });

    useMemo(()=>{
        if(data?.connectors.length > 0 && !isCostCodeImported){
            setDisableCostCode(true)
        }else{
            setDisableCostCode(false)
        }
  },[isCostCodeImported])
    useEffect(()=>{

        if(showConnectorsJob){
            const getConnection = async()=>{
                const res = await getDuplicateConnectorList();
                setConnectorList(res)
            }
            getConnection();
        }
    },[showConnectorsJob])

    const arraysAreEqual=(arr1, arr2)=> {
        if (arr1?.length !== arr2?.length) {
            return false;
        }
        for (let i = 0; i < arr1.length; i++) {
            if (arr1.includes(arr2[i])) {
                return false;
            }
        }
        return true;
    }

    useMemo(()=>{
        if(!arraysAreEqual(selectedJobServices,record?.subjob_excluded_services_ids)){
            setSelectedJobServices(record.subjob_excluded_services_ids);
        }
    },[record?.subjob_excluded_services_ids])

    const validateManagerEmail = async (values: any, allValues: any) => {
        const employee:any = values && await dataProvider.getOne('employee', { id: values })
                     .catch(error => {
                          notify(`Failure! ${error}`);
                      })
        if (!employee?.data?.email || employee?.data?.email === 'false') {
            setManagerData({
                name: employee?.data?.name,
                base_wage: employee?.data?.base_wage ? employee?.data?.base_wage:'',
                mobile_number: (employee?.data?.mobile_number && employee?.data?.mobile_number !== 'false') ? employee?.data?.mobile_number :'',
                id: employee?.data?.id,
                ask_email:true,
                user_type:'manager',
                email:(employee?.data?.email && employee?.data?.email !== 'false') ? employee?.data?.email : null,
            });
            return undefined;
        }else{
            setManagerData(null);
        }
        return undefined;
    };
    const handleManagerchange = (e) => {
        if(!e){
        setManagerData(null);
        }
    }
const showArborgoldSetting = (connectorList)=>{
   return connectorList.some( item => item?.service_id === 'arborgold' )
}
    useEffect(()=>{
        if(showConnectorsJob){
            const getConnection = async()=>{
                const data= {
                    jsonrpc: '2.0',
                    params: {},
                }
                const res = (await post('/api/protiv/connections',data)) as any;
                setConnectorList(res)
            }
            getConnection();
        }
    },[showConnectorsJob])
    useEffect(()=>{},[bonusDay,periodWeekDay,connectorDetail,connectorList])
    const onChangeIncludeOtFromSpentTotal = (
        fieldValue: any,
        name: string,
        allValues: any
    ): any => {
        const result: any = {};
        if (fieldValue === true && allValues?.remove_ot_diff === false) {
            result['remove_ot_diff'] = true;
        }
        return result;
    };

    const oncangeDecorator = useMemo(() => {
        return [
            createDecorator({
                field: 'include_ot_from_spent_total',
                updates: onChangeIncludeOtFromSpentTotal,
            }),
        ];
    }, []);

    if (!loaded) {
        return null;
    }
    const reload = (data, flag) => {
        if (get(data, flag) !== get(record, flag)) {
            window.location.reload();
        }
    };

    const onSave = (values: any, redirect: any) => {
        const connectorIds = Object.keys(connectorName)
        if(connectorIds.length > NUMBER.ZERO){
            connectorIds.forEach(conId =>{
                const param = {
                    company_id:identity?.company_id,
                    name:connectorName[conId].name,
                    service:connectorName[conId].service,
                    status:connectorName[conId].status
                }
            post(`/api/protiv/connection/${conId}`, { jsonrpc: '2.0',params: param})
            })
        }
        if (!values.show_job_page) {
            values.can_use_cost_code = false;
            values.is_cost_code_required = false;
        }
        if (!values.is_bonus_pool_enabled) {
            values.worker_bonus_percent = props?.record?.worker_bonus_percent;
            values.bonus_pool_apply_method = props?.record?.bonus_pool_apply_method;
            values.company_pool_label= props?.record?.company_pool_label;
            values.worker_pool_label= props?.record?.worker_pool_label;
        }
        if (!values.can_use_cost_code) {
            values.is_cost_code_required = false;
        }

        if(!renamePoolFieldLabel?.worker){
            values.worker_pool_label= props?.record?.worker_pool_label;
        }
        if(!renamePoolFieldLabel?.company){
            values.company_pool_label= props?.record?.company_pool_label;
        }

        if(managerData){
            dialogRefs.current.open();
        }else{
            if(values.create_recurring_services_as_subjobs){
                values['subjob_excluded_services_ids']=selectedJobServices;
                if(!selectedJobServices.length){
                    values['create_recurring_services_as_subjobs']=false;
                }
            }
            update(
            'company',
            { id: props.record.id, data: values, previousData: props.record },
            {
                mutationMode: 'pessimistic',
                onSuccess: (data: any) => {
                    notify('Element Updated');
                    reload(data, 'allow_all_workers_to_add_time');
                    reload(data, 'add_time_interface');
                    reload(data, 'show_job_page');
                    reload(data, 'allow_job_position');
                    reload(data, 'can_use_cost_code');
                    reload(data, 'cost_code_label');
                },
                onError: (error: any) => {
                    notify(`Element Failed Updated ${error.message}`);
                },
            }
        );}
    };
    const maxDate = moment().subtract(1, 'days');
    const minDate = moment().subtract(NUMBER.TWO, 'weeks').startOf('week');
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    interface ExpandMoreProps extends IconButtonProps {
        expand: boolean;
      }

    const ExpandMore = styled((props: ExpandMoreProps) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
      })(({ theme, expand }) => ({
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      }));

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const requiredType = (message = 'Required') => value => {
        return value !== 'false' ? undefined : message;
    }

    const validateBonuspoolApplyOn = (value, allValues) => {
        if (allValues?.is_bonus_pool_enabled && (value === 'false' || !value)) {
            return 'Required';
        }
        return undefined;
    };
    const validateCharLimit = (value, allValues) => {
        if (allValues?.is_bonus_pool_enabled && value && value?.length <2) {
            return 'Field name should be at least 2 characters long.';
        }
        return undefined;
    };

    const handleChangeSplitType = async (e,form) =>{
        setBonusType(e?.target?.value)
        form?.change('default_bonus_split_type', e?.target?.value)
    }

    const minValueCheck = (message = 'Required') => value => !value ? message : undefined;
    const minValueCheckZero = (message = 'Required') => value => (!value && value !== 0) ? message : undefined;
    return (
        <FormWithRedirect
            {...props}
            redirect="/setting"
            decorators={oncangeDecorator}
            save={onSave}
            render={formProps => {
                const { form } = formProps;
                const formState = form?.getState();
                const formData = formState?.values;
                return (
                    <Grid container spacing={2} className="setting-page">
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Grid container spacing={2}>
                                <Grid item lg={12} xs={12}>
                                    <Card
                                        sx={{ p: 3 }}
                                        className="setting-overtime-box-card"
                                    >
                                        <CardTitle
                                            title={translate(
                                                'resources.companies.settings.overtime.title'
                                            )}
                                        />
                                        <Stack
                                            flexDirection={'row'}
                                            alignItems="left"
                                        >
                                            <Typography variant="body2">
                                                <b>
                                                    {translate(
                                                        'resources.companies.settings.overtime.weekly_overtime'
                                                    )}
                                                </b>{' '}
                                            </Typography>
                                        </Stack>
                                        <OvertimeConfigLine
                                            prefix={translate(
                                                'resources.companies.settings.overtime.first_threshold_at'
                                            )}
                                            order="First"
                                            source="ot_weekly_first_threshold"
                                            source2="ot_weekly_first_threshold_rate"
                                        />
                                        <OvertimeConfigLine
                                            prefix={translate(
                                                'resources.companies.settings.overtime.second_threshold_at'
                                            )}
                                            order="Second"
                                            source="ot_weekly_sec_threshold"
                                            source2="ot_weekly_sec_threshold_rate"
                                        />
                                        <Stack
                                            flexDirection={'row'}
                                            alignItems="left"
                                        >
                                            <Typography variant="body2">
                                                <b>
                                                    {translate(
                                                        'resources.companies.settings.overtime.daily_overtime'
                                                    )}
                                                </b>{' '}
                                            </Typography>
                                        </Stack>
                                        <OvertimeConfigLine
                                            prefix={translate(
                                                'resources.companies.settings.overtime.first_threshold_at'
                                            )}
                                            order="First"
                                            source="ot_daily_first_threshold"
                                            source2="ot_daily_first_threshold_rate"
                                        />
                                        <OvertimeConfigLine
                                            prefix={translate(
                                                'resources.companies.settings.overtime.second_threshold_at'
                                            )}
                                            order="Second"
                                            source="ot_daily_sec_threshold"
                                            source2="ot_daily_sec_threshold_rate"
                                        />
                                        <Stack
                                            flexDirection={'row'}
                                            alignItems="center"
                                        >
                                            <Typography variant="body2">
                                                <b>
                                                    {translate(
                                                        'resources.companies.settings.overtime.seventh_consecutive_overtime'
                                                    )}
                                                </b>{' '}
                                            </Typography>
                                            <BooleanInput
                                                sx={{ paddingLeft: 2 }}
                                                source="ot_seventh_day_full_ot"
                                                label=""
                                                helperText={false}
                                            />
                                        </Stack>
                                        <Condition
                                            when="ot_seventh_day_full_ot"
                                            is={true}
                                        >
                                            <Stack
                                                sx={{ ml: 4 }}
                                                flexDirection={'row'}
                                                justifyContent="flex-start"
                                                alignItems="center"
                                            >
                                                <Typography variant="body2">
                                                    {translate(
                                                        'resources.companies.settings.overtime.first_eight_hours_at'
                                                    )}
                                                </Typography>
                                                <StyledSelectInput
                                                    source="ot_seventh_day_first_threshold_rate"
                                                    label={false}
                                                    allowEmpty
                                                    variant="standard"
                                                    sx={{ pl: 1, pr: 1 }}
                                                    choices={RATE}
                                                />{' '}
                                                <Typography variant="body2">
                                                    {translate(
                                                        'resources.companies.settings.overtime.times_of_hourly_rate'
                                                    )}
                                                </Typography>
                                            </Stack>
                                            <Stack
                                                sx={{ ml: 4 }}
                                                flexDirection={'row'}
                                                justifyContent="flex-start"
                                                alignItems="center"
                                            >
                                                <Typography variant="body2">
                                                    {translate(
                                                        'resources.companies.settings.overtime.there_after_at'
                                                    )}
                                                </Typography>
                                                <StyledSelectInput
                                                    source="ot_seventh_day_sec_threshold_rate"
                                                    label={false}
                                                    allowEmpty
                                                    variant="standard"
                                                    sx={{ pl: 1, pr: 1 }}
                                                    choices={RATE}
                                                />{' '}
                                                <Typography variant="body2">
                                                    {translate(
                                                        'resources.companies.settings.overtime.times_of_hourly_rate'
                                                    )}
                                                </Typography>
                                            </Stack>
                                        </Condition>
                                        <Stack
                                            sx={{ ml: -2 }}
                                            flexDirection={'row'}
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >
                                            <AdditionalOtSettings />
                                        </Stack>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Card sx={{ p: 3 }}>
                                        <CardTitle
                                            title={translate(
                                                'resources.companies.settings.additional_settings.title'
                                            )}
                                        />
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                <SelectInput
                                                    label="resources.companies.fields.time_format"
                                                    source="time_format"
                                                    fullWidth
                                                    helperText={false}
                                                    choices={TIME_FORMAT}
                                                    SelectProps={{ MenuProps: { disableScrollLock: true } }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                <SelectInput
                                                    source="hours_per_day"
                                                    fullWidth
                                                    helperText={false}
                                                    choices={
                                                        HOURS_PER_DAY_SELECTION
                                                    }
                                                    SelectProps={{ MenuProps: { disableScrollLock: true } }}
                                                />
                                            </Grid>
                                            {/* <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                 <div className="add-setting-percentage">
                                                <FormControl className='mui-select-responsive' fullWidth>
                                                    <Select
                                                    labelId="mui-custom-select-label"
                                                    id="mui-custom-select"
                                                    value={bonusType}
                                                    onChange={(e)=>handleChangeSplitType(e, formProps?.form)}
                                                    label="Default Bonus Split"
                                                    >
                                                    <MenuItem value={'by_hours'}>
                                                        <div className='custom-select-label'>Hours</div>
                                                        <div className='custom-select-value'>Equal Rate x hrs</div>
                                                    </MenuItem>
                                                    <MenuItem value={'by_wage'}>
                                                        <div className='custom-select-label'>Hours & Wage</div>
                                                        <div className='custom-select-value'>Equal Wage Incr. x hrs</div>
                                                    </MenuItem>
                                                    <MenuItem value={'by_percentage'}>
                                                        <div className='custom-select-label'>Percentage</div>
                                                        <div className='custom-select-value'>Manually set percentage</div>
                                                    </MenuItem>
                                                    </Select>
                                                </FormControl>
                                                </div>
                                            </Grid> */}
                                            {/* <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                <SelectInput
                                                    source="default_budget_type"
                                                    fullWidth
                                                    choices={BudgetChoices}
                                                    SelectProps={{ MenuProps: { disableScrollLock: true } }}
                                                />
                                            </Grid> */}
                                            <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                <SelectInput
                                                    source="hours_format"
                                                    fullWidth
                                                    choices={HOURS_FORMAT}
                                                    SelectProps={{ MenuProps: { disableScrollLock: true } }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                <SelectInput
                                                    source="timezone"
                                                    fullWidth
                                                    helperText={false}
                                                    choices={
                                                        TIMEZONES
                                                    }
                                                    SelectProps={{ MenuProps: { disableScrollLock: true } }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Card sx={{ p: 3 }}>
                                        <CardTitle
                                            title={translate(
                                                'resources.companies.settings.worker_view.title'
                                            )}
                                        />
                                        <Grid container spacing={2}>
                                            <Grid item lg={12} xs={12}>
                                                {(identity?.company
                                                    ?.allow_zapier_api ||
                                                    (!identity?.company
                                                        ?.is_integrated_company && identity?.company?.allow_import_from_file)) && (
                                                    <Stack
                                                        flexDirection={'row'}
                                                        alignItems="center"
                                                    >
                                                        <Typography variant="body2">
                                                            {translate(
                                                                'resources.companies.fields.allow_all_workers_to_add_time'
                                                            )}
                                                        </Typography>
                                                        <BooleanInput
                                                            sx={{
                                                                paddingTop: 2,
                                                                paddingLeft: 2,
                                                            }}
                                                            source="allow_all_workers_to_add_time"
                                                            label=""
                                                            helperText={false}
                                                            disabled={data?.connectors?.length > NUMBER.ZERO}
                                                        />
                                                    </Stack>
                                                )}
                                                <Stack
                                                    flexDirection={'row'}
                                                    alignItems="center"
                                                >
                                                    <Typography variant="body2">
                                                        {translate(
                                                            'resources.companies.fields.hide_bonuses_from_other_workers'
                                                        )}{' '}
                                                    </Typography>
                                                    <BooleanInput
                                                        sx={{ paddingLeft: 2 }}
                                                        source="hide_bonuses_from_other_workers"
                                                        label=""
                                                        helperText={false}
                                                    />
                                                </Stack>
                                                <Stack
                                                    flexDirection={'row'}
                                                    alignItems="center"
                                                >
                                                    <Typography variant="body2">
                                                        {translate(
                                                            'resources.companies.fields.hide_leadpay_bonus_from_workers'
                                                        )}{' '}
                                                    </Typography>
                                                    <BooleanInput
                                                        sx={{ paddingLeft: 2 }}
                                                        source="hide_leadpay_bonus_from_workers"
                                                        label=""
                                                        helperText={false}
                                                    />
                                                </Stack>
                                                <Stack
                                                    flexDirection={'row'}
                                                    alignItems="center"
                                                >
                                                    <Typography variant="body2">
                                                        {translate(
                                                            'resources.companies.fields.hide_company_pool'
                                                        )}{' '}
                                                    </Typography>
                                                    <BooleanInput
                                                        sx={{ paddingLeft: 2 }}
                                                        source="hide_company_pool"
                                                        label=""
                                                        helperText={false}
                                                    />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>

                              {/* Visit Assigned Configuration HTML */}
                            {scheduleVisits?.length > 0 &&
                              <Grid item xs={12} md={12} lg={12}>
                                <Card sx={{ p: 3 }}>
                                  <CardTitle
                                    title='Visit Assigned Configuration'
                                  />
                                  <Grid container spacing={2}>
                                    <Grid item lg={12} xs={12}>
                                      <Stack
                                        className="setting-overtime-box-card-stack"
                                        flexDirection={'row'}
                                        alignItems="center"
                                        flexWrap={'wrap'}
                                      >
                                        <Typography variant="body2">Auto-assign workers to ProPays with scheduled visits</Typography>
                                        <StyledSelectInput
                                          source='schedule_visit_config'
                                          label={false}
                                          variant="standard"
                                          className='select-field-visit'
                                          sx={{ pl: 1, pr: 1, mt: 0, minWidth: '64px' }}
                                          choices={
                                            Array.from({ length: 31 }, (_, i) => i)
                                              .map(i => ({
                                                id: `{"assign_before":${i}}`,
                                                name: i.toString(),
                                              }))
                                          }
                                        />
                                        <Typography variant="body2">
                                          days in advance
                                        </Typography>
                                      </Stack>
                                    </Grid>
                                  </Grid>
                                </Card>
                              </Grid>
                            }


                            </Grid>
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Grid container spacing={3}>

                                <Grid item xs={12} lg={12} className='period-grid'>
                                     <Card sx={{ p: 3 }}>
                                        <CardTitle
                                            title={translate(
                                                'resources.companies.settings.period.title'
                                            )}
                                        />
                                        <Grid container spacing={2}>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <SelectInput
                                                    label="resources.companies.settings.period.payroll_cycyle"
                                                    source="period_schedule"
                                                    fullWidth
                                                    allowEmpty
                                                    helperText={false}
                                                    choices={SCHEDULE}
                                                    SelectProps={{ MenuProps: { disableScrollLock: true } }}
                                                />
                                            </Grid>
                                            <FormDataConsumer>
                                                {({ formData, ...rest }) =>
                                                    (formData.period_schedule === 'weekly' || formData.period_schedule === 'bi-weekly') ? (
                                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                            <div className='select-period-head'>Select period start day:</div>
                                                    <div className='select-period'>
                                                        {WEEKDAY.map(payrollDay=>{
                                                            return (<span className={`select-period-items ${periodWeekDay === payrollDay.id ? 'active-select-period':''}`} key={payrollDay.id} onClick={()=>{
                                                                setPeriodWeekDay(payrollDay.id);
                                                                form.change('payroll_week_day',payrollDay.id);
                                                            }
                                                            }>{payrollDay.prefix}</span>)
                                                        })}
                                                    </div>
                                                    </Grid>):null
                                                }
                                            </FormDataConsumer>

                                            {/* <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                <DatePickerInput
                                                    source="last_closing_date"
                                                    fullWidth
                                                    validate={
                                                        !record.has_periods &&
                                                        required()
                                                    }
                                                    helperText={
                                                        record.has_closed_period &&
                                                        'resources.companies.settings.period.after_period_closed_cannot_change'
                                                    }
                                                    maxDate={maxDate}
                                                    disabled={
                                                        record.has_closed_period ||
                                                        false
                                                    }
                                                />
                                            </Grid> */}
                                            {getIntegratedNonIntegrated(
                                                identity
                                            ) && (
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <SelectInput
                                                        source="add_time_interface"
                                                        fullWidth
                                                        helperText={false}
                                                        choices={
                                                            ADD_TIME_INTERFACE
                                                        }
                                                        SelectProps={{ MenuProps: { disableScrollLock: true } }}
                                                    />
                                                </Grid>
                                            )}
                                            {/* {(isExternallyAttendanceImported && connectorList.length > NUMBER.ZERO)?<Grid item lg={12} md={12} sm={12} xs={12}>
                                            <div className='statement-propay-row allow-boolean-setting'>Allow manual input of time <BooleanInput
                                            source="allow_import_from_file"
                                            label=""
                                            helperText={false}
                                            className="custom-switch"
                                            />
                                            </div>
                                            <FormDataConsumer>
                                                {({formData})=>{
                                                    return (<>{formData.allow_import_from_file ? <div className="labour-budget-notification allow-time-notification">
                                                    {ALERT_ERROR_AUTOMATION()}
                                                    Consider any duplicate entries before manually entering or importing time
                                                </div>:<></>}</>)
                                                }}
                                            </FormDataConsumer>
                                            </Grid>:''} */}
                                            <Grid>
                                            </Grid>
                                        </Grid>

                                    </Card>
                                </Grid>

                            {connectorList.length > NUMBER.ONE && <Grid item xs={12} lg={12}>
                                        <Card
                                            className="setting-job-page cost-code-card"
                                            sx={{ p: 3 }}
                                        >
                                            <Stack
                                                flexDirection={'column'}
                                                alignItems="flex-start"
                                                position={'relative'}
                                                className='cost-code-card-stack'
                                            >
                                                <CardHeader
                                                    sx={{ paddingTop: 0 }}
                                                    title='Jobs Page'
                                                />
                                               <CardContent>
                                                   {connectorList.map((item)=>{
                                                       return(<div className='job-page-input'>
                                                           {
                                                               connectorDetail.id === item.id ? <TextField
                                                               value={connectorDetail.name}
                                                               label=""
                                                               variant="standard"
                                                               fullWidth
                                                               className='cost-code-input'
                                                               onBlur={()=>{
                                                                   connectorName[connectorDetail.id]={name:connectorDetail.name,status:item.status,service:item.service}
                                                                   setConnectorName(connectorName);
                                                                   setConnectorDetail({name:'',id:''})}}
                                                               onChange={e =>setConnectorDetail({name:e.target.value,id:connectorDetail.id})}
                                                           /> : <span className='job-page-input-label'>{connectorName[item.id]?.name || item.name || item.service}</span>
                                                           }
                                                       <Button className='job-page-input-btn' onClick={()=>setConnectorDetail({name:item.name || item.service,id:item.id})}>
                                                       {COST_CODE_EDIT_ICON()}
                                                   </Button></div>)
                                                   })}

                                               </CardContent>
                                            </Stack>
                                        </Card>
                                </Grid>}

                               {/* Propay Status Setting UI */}
                                <Grid item xs={12} lg={12}>
                                    <Card className="propay-setting-box" sx={{ p: 3 }}>
                                    <Stack
                                        flexDirection={'column'}
                                        alignItems="flex-start"
                                        position={'relative'}
                                        className='cost-code-card-stack'
                                    >
                                        <CardTitle title='ProPay Settings' />
                                        <div className='setting-description'>Would you like to add additional status?</div>
                                    </Stack>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12} sm={12}>
                                        <div className='setting-status-border'>
                                        {/* {!identity?.company?.is_new_company && <BooleanInput
                                            source="is_started_status"
                                            label="Add Started Status"
                                            helperText={false}
                                            className="custom-switch"
                                        />} */}
                                        <BooleanInput
                                            source="is_completed_status"
                                            label="Add Complete Status"
                                            helperText={false}
                                            className="custom-switch"
                                        />
                                        </div>
                                        </Grid>
                                        <FormDataConsumer>
                                        {({ formData }) => {
                                            return(<>{
                                                <Grid item xs={12} sm={12}>
                                                <div className='setting-status-progress'>
                                                    <div className='setting-description'>This is your status progress by default:</div>
                                                    <div className='setting-status pending-status'>Pending</div>
                                                   {formData.is_started_status ? <div className='setting-status started-status'>Started</div>:''}
                                                   {formData.is_completed_status ? <div className='setting-status complete-status'>Complete</div>:''}
                                                    <div className='setting-status approved-status'>Approved</div>
                                                    <div className='setting-status closed-status'>Closed</div>
                                                </div>
                                            </Grid>
                                            }</>)}}
                                            </FormDataConsumer>
                                    </Grid>
                                    {(data?.connectors?.length > 0 && showArborgoldSetting(data?.connectors)) ? <>
                                    <div className='propay-setting-job-head'>ArborGold Job Settings <sub>(optional)</sub></div>
                                    <div className='setting-job-desc'>Organize Recurring Service Type into sub-jobs per visit <BooleanInput
                                            source="create_recurring_services_as_subjobs"
                                            label=""
                                            helperText={false}
                                            className="custom-switch"
                                        />
                                     </div>
                                     <FormDataConsumer>
                                        {({ formData }) => {
                                            return(<>{
                                                formData.create_recurring_services_as_subjobs ?
                                                <>
                                                <div className="automation-job-action-row">
                                                {SELECT_JOB_ARROW()}{' '}
                                                <span className="color-text-orange">Except</span>{' '}
                                                <span className="optional-text">
                                                    (optional)
                                                </span>
                                                Except for services
                                                    <CustomMuiSelect resource={'JobService__List'} defaultVal ={record.subjob_excluded_services_ids || []} setSelected={setSelectedJobServices} selectedValue={selectedJobServices} filterQuery={{service_type: {_eq: "Recurring"}}} placement='bottomEnd' />
                                                </div>
                                                </>
                                                :''}
                                                </>)
                                            }}
                                        </FormDataConsumer>
                                        </>:''}
                                    </Card>
                                </Grid>

                            {/* <HasBackendNotConnected> */}
                            {/* Job Page Toggle Hidden */}
                                {/* <HasPermission resource="menu-jobs" action="list">
                                <Grid item xs={12} lg={12}>
                                    <Card
                                        className="setting-job-card setting-job-setting"
                                        sx={{ p: 0 }}
                                    >
                                        <HasBackendNotConnected>
                                            <HasPermission
                                                resource="menu-jobs"
                                                action="list"
                                            >
                                                <Stack
                                                    flexDirection={'row'}
                                                    alignItems="center"
                                                >
                                                    <CardHeader
                                                        sx={{ paddingTop: 0 }}
                                                        title={translate(
                                                            'resources.companies.fields.job_page'
                                                        )}
                                                    />
                                                    <BooleanInput
                                                        sx={{ paddingTop: 2 }}
                                                        source="show_job_page"
                                                        label=""
                                                        helperText={false}
                                                        onChange={newValue =>
                                                            setShowJobPage(
                                                                newValue
                                                            )
                                                        }
                                                    />
                                                </Stack>
                                            </HasPermission>
                                        </HasBackendNotConnected>
                                    </Card>
                                </Grid>

                                <Condition when="show_job_page" is={true}> */}
                                    <Grid item xs={12} lg={12}>
                                        <Card
                                            className="setting-job-card cost-code-card"
                                            sx={{ p: 3 }}
                                        >
                                            <Stack
                                                flexDirection={'column'}
                                                alignItems="flex-start"
                                                position={'relative'}
                                                className='cost-code-card-stack'
                                            >
                                                {isCostCodeEdit ?  <TextInput
                                    source="cost_code_label"
                                    label=""
                                    variant="standard"
                                    className='cost-code-input'
                                    onBlur={()=>setIsCostCodeEdit(false)}
                                    onChange={e =>setRenameCostCode(e.target.value)}
                                    validate={[minValue(2, 'Minimum Should be at least 2 characters long.'),required()]}
                                />:<CardHeader
                                                    sx={{ paddingTop: 0 }}
                                                    title={renameCostCode ? renameCostCode : identity?.company.cost_code_label}
                                                />}

                                              {!disableCostCode ? <Button onClick={() => setIsCostCodeEdit(true)}>
                                                    {COST_CODE_EDIT_ICON()}
                                                </Button>:''}
                                                <CardContent>Used to match entries when jobs have multiple ProPays</CardContent>
                                                <BooleanInput
                                                    sx={{
                                                        paddingTop: 0,
                                                        paddingLeft: 0,
                                                    }}
                                                    source="can_use_cost_code"
                                                    label=""
                                                    helperText={false}
                                                    className="custom-switch cost-code-setting-head"
                                                    disabled={disableCostCode}
                                                />
                                                {disableCostCode ? <div className="example-label-budget"> Not supported with {data.connectors.length > 0 ? data?.connectors[0]?.service_id :''}</div>:''}
                                            </Stack>
                                            {/* Hidden Cost Cost Required Toggle */}
                                            {/* <Condition
                                                when="can_use_cost_code"
                                                is={true}
                                            >
                                                <Grid item xs={12} lg={12}> */}
                                                    {/* <JobPositionDataGrid filter={{company_id:{_eq:record.id}}} show_job_page={show_job_page || has_backend}/> */}
                                                    {/* <div className="cost-code-tr">
                                                        Required
                                                        <BooleanInput
                                                            sx={{
                                                                paddingTop: 0,
                                                            }}
                                                            source="is_cost_code_required"
                                                            label=""
                                                            helperText={false}
                                                            className="custom-switch"
                                                        />
                                                    </div>
                                                </Grid>
                                            </Condition>  */}
                                        </Card>
                                    </Grid>
                                {/* </Condition>
                            </HasPermission> */}


                            <Grid item xs={12} lg={12} className='maintenance-automation-grid'>
                            </Grid>

                            {/* Statements Code HTML Start */}
                            <Grid item xs={12} lg={12}>
                                <Card
                                    className="setting-job-card cost-code-card statement-grid"
                                    sx={{ p: 3 }}
                                >
                                    <Stack
                                        flexDirection={'column'}
                                        alignItems="flex-start"
                                        position={'relative'}
                                        className='cost-code-card-stack'
                                    >
                                        <CardTitle title={translate('Bonus Statement')} />
                                        <CardContent>Group bonuses in statements as ProPays are approved</CardContent>
                                    </Stack>
                                    <Stack
                                        flexDirection={'column'}
                                        alignItems="flex-start"
                                        position={'relative'}
                                        className='automation-select-stack'
                                    >
                                    </Stack>
                                    <div className='organize-statement-select'>
                                    <SelectInput source="statement_period_schedule" fullWidth helperText={false} choices={BONUS_STATEMENT} label='Organize Statement by:' SelectProps={{ MenuProps: { disableScrollLock: true } }} />
                                    </div>
                                    <FormDataConsumer>
                                        {({ formData }) => {
                                            return(<>{
                                            (formData.statement_period_schedule === 'weekly' || formData.statement_period_schedule === 'bi-weekly') ?
                                            <>
                                            <div className='select-period-head'>Select period start day:</div>
                                            <div className='select-period'>
                                                {WEEKDAY.map(day=>{
                                                    return (<span className={`select-period-items ${bonusDay === day.id ? 'active-select-period':''}`} key={day.id}
                                                    onClick={()=>{setBonusDay(day.id);form.change('statement_period_week_day',day.id)}}>{day.prefix}</span>)
                                                })}
                                            </div>
                                            </> : ''
                                            }</>);
                                        }}
                                        </FormDataConsumer>

                                    <Grid item xs={12} lg={12}>
                                            <div className="statement-propay-row">
                                            Add negative ProPay balance to worker statements
                                                <BooleanInput
                                                    sx={{
                                                        paddingTop: 0,
                                                    }}
                                                    source="add_overage_cost_to_statement"
                                                    label=""
                                                    helperText={false}
                                                    className="custom-switch"
                                                />
                                            </div>
                                        </Grid>
                                </Card>
                            </Grid>
                            {/* Statements Code HTML End */}

                            {/* Bonus Pool Section HTML */}
                             <FormDataConsumer>
                            {({ formData: { is_bonus_pool_enabled,worker_bonus_percent, worker_pool_label, company_pool_label } }) => {
                                const workerPercentVal = (worker_bonus_percent) ? worker_bonus_percent : 0
                                const abc = workerPercentVal <= NUMBER.HUNDRED ? workerPercentVal:NUMBER.HUNDRED;
                                const companyPercent = (NUMBER.HUNDRED - abc).toFixed(2);

                                return (
                                <>
                                <Grid item xs={12} lg={12}>
                                 <Card className="setting-job-card cost-code-card bonus-pool-grid" sx={{ p: 3 }}>
                                  <Stack flexDirection={'column'} alignItems="flex-start" position={'relative'} className='cost-code-card-stack'>
                                    <CardTitle title='Bonus Pool' />
                                        <BooleanInput
                                            sx={{paddingTop: 0,paddingLeft: 0,}}
                                            source="is_bonus_pool_enabled"
                                            label=""
                                            helperText={false}
                                            className="custom-switch cost-code-setting-head"
                                        />
                                        {/* <InfoLabel className="info-label" icon="ri:error-warning-fill" height={12} >
                                        <Typography sx={{ fontSize: 10 }}>Dummy Content Bonus Pool</Typography>
                                        </InfoLabel> */}
                                        </Stack>
                                    {is_bonus_pool_enabled &&
                                    <>
                                        <div className='maintenance-sub-text'>
                                        Set the default percentage split of the budget variance between the company and workers:
                                        </div>

                                    </>}
                                    {is_bonus_pool_enabled &&
                                    <>
                                    <Stack className='bonus-pool-stack bonus-pool-stack-setting'>
                                        <NumberInput fullWidth source='worker_bonus_percent' label={`${worker_pool_label ? worker_pool_label : identity?.company?.worker_pool_label} %`}
                                        validate={[required(),maxValue(100), minValue(1)]}/>
                                            <span className='bonus-pool-slash'>/</span>
                                            <FormControl fullWidth variant="outlined" className='input-payroll-period'>
                                        <InputLabel htmlFor="worker-bonus-pool">{`${company_pool_label ? company_pool_label : identity?.company?.company_pool_label} %`}</InputLabel>
                                        <OutlinedInput
                                            id="worker-bonus-pool"
                                            type='text'
                                            label="Worker %"
                                            value={companyPercent}
                                            disabled
                                        />
                                        </FormControl>
                                    </Stack>
                                    <div className='bonus-pool-names'>
                                        <div className='auto-calculate-head'>Edit bonus pool names listed on the ProPay detail page</div>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={6}>
                                                <Stack className='bonus-pool-stack-item'>
                                                    <span className='bonus-pool-stack-label'>Worker field name</span>
                                                {isPoolFieldEdit?.worker ? <TextInput
                                                    source="worker_pool_label"
                                                    label=""
                                                    variant="standard"
                                                    className='cost-code-input'
                                                    onBlur={() => setPoolFieldEdit((prev)=>({...prev,worker: false}))}
                                                    onChange={e => setRenamePoolFieldLabel((prev)=>({...prev,worker: e.target.value}))}
                                                    validate={[validateBonuspoolApplyOn,validateCharLimit]}
                                                /> : <CardHeader
                                                    sx={{ paddingTop: 0 }}
                                                    title={renamePoolFieldLabel?.worker ? renamePoolFieldLabel?.worker : props?.record?.worker_pool_label}
                                                />}

                                                <Button onClick={() => setPoolFieldEdit((prev)=>({...prev,worker: true}))}>
                                                    {COST_CODE_EDIT_ICON()}
                                                </Button>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                            <Stack className='bonus-pool-stack-item'>
                                            <span className='bonus-pool-stack-label'>Company field name</span>
                                                {isPoolFieldEdit?.company ? <TextInput
                                                    source="company_pool_label"
                                                    label=""
                                                    variant="standard"
                                                    className='cost-code-input'
                                                    onBlur={() => setPoolFieldEdit((prev)=>({...prev, company:false}))}
                                                    onChange={e => setRenamePoolFieldLabel((prev)=>({...prev,company: e.target.value}))}
                                                    validate={[validateBonuspoolApplyOn,validateCharLimit]}
                                                /> : <CardHeader
                                                    sx={{ paddingTop: 0 }}
                                                    title={renamePoolFieldLabel?.company ? renamePoolFieldLabel?.company : props?.record?.company_pool_label}
                                                />}

                                                <Button onClick={() => setPoolFieldEdit((prev)=>({...prev, company:true}))}>
                                                    {COST_CODE_EDIT_ICON()}
                                                </Button>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </div>

                                    <div className='example-label-budget'>
                                        <span>Example:</span>
                                            <span>Budget: $2,000  -  Actual: $1,500 <br />
                                                Variance: $500 <br />
                                                Worker Bonus: 75% ($375) <br />
                                            Company Savings: 25% ($125)
                                        </span>
                                    </div>
                                    </>}
                                    {is_bonus_pool_enabled && 
                                    <div className="bonus-pool-alert">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            d="M11.9999 8.99999V12.75M2.69694 16.126C1.83094 17.626 2.91394 19.5 4.64494 19.5H19.3549C21.0849 19.5 22.1679 17.626 21.3029 16.126L13.9489 3.37799C13.0829 1.87799 10.9169 1.87799 10.0509 3.37799L2.69694 16.126ZM11.9999 15.75H12.0069V15.758H11.9999V15.75Z"
                                            stroke="#FC6E45"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                    Changes apply to new ProPays.Use bulk actions on the ProPay page to edit existing ones.
                                </div>}
                                    {/* Will remove this code once tested
                                    {is_bonus_pool_enabled && <Stack
                                                flexDirection={'column'}
                                                alignItems="flex-start"
                                                position={'relative'}
                                                className='automation-select-stack'
                                            >
                                            <div className='auto-calculate-head'>Select how you would like to apply Bonus Pool changes to ProPays?</div>
                                            <div className="automation-radios">
                                            <FunctionField
                                             render={() => (
                                                    <>
                                                        <RadioButtonGroupInput
                                                            source="bonus_pool_apply_method"
                                                            label={false}
                                                            variant="standard"
                                                            choices={bonuspoolSelection}
                                                            validate={[validateBonuspoolApplyOn]}
                                                            sx={{
                                                                '.MuiFo1rmHelperText-root': {
                                                                    display: 'none',
                                                                },
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </div>
                                        </Stack>} */}
                                </Card>
                            </Grid>
                            </>
                                )}}
                            </FormDataConsumer>
                            {/* Bonus Pool Section HTML */}
                        </Grid>
                        </Grid>
                        <Grid
                            className="company-report-accordion"
                            item
                            lg={12}
                            xs={12}
                        >
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={'+'}
                                    aria-controls="panel1a-company-content"
                                    id="panel1a-company-header"
                                >
                                    <CardHeader
                                        title={translate(
                                            'resources.companies.settings.report.title'
                                        )}
                                    />
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid item xs={12} lg={12}>
                                        <PayrollExportDataGrid
                                            filter={{
                                                company_id: { _eq: record.id },
                                            }}
                                        />
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        {/* <Grid item lg={12} xs={12}>
                            <Card sx={{ p: 3 }}>
                            </Card>
                        </Grid> */}
                        <Grid
                            item
                            lg={12}
                            xs={12}
                            className="company-setting-toolbar"
                        >
                            <StyleToolbar
                                sx={{
                                    backgroundColor: '#FFF',
                                }}
                                {...formProps}
                                mutationMode="undoable"
                                redirect="/setting"
                                invalid={formProps.invalid}
                                handleSubmit={formProps.handleSubmit}
                                saving={formProps.saving}
                                resource="company"
                            >
                                <StyledSaveButton
                                    saving={isLoading}
                                    onSave={onSave}
                                />
                            </StyleToolbar>
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
};

export const CompanySetting = (props: any) => {
    const { loaded, identity } = useGetIdentity();
    const translate = useTranslate();
    if (!loaded) {
        return null;
    }
    return (
        loaded && (
            <Edit
                component="div"
                {...props}
                resource="companies"
                id={identity?.company.id}
                hideTitle
            >
                <CompanySettingForm {...props} />
            </Edit>
        )
    );
};
