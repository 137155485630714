import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    MenuItem,
    Paper,
    SelectChangeEvent,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { handleErrorAndSuccessToast } from './AutomationListActions';
import { post } from '../resources/onboard/PaymentProvider';
import { PROPAY_BULK_ACTION_API } from '../utils/Constants/ConstantData';
import { Select } from '@mui/material';
import { TRASH_ICON } from '../utils/Constants/ClassName';
import { CRUD_UPDATE, useDataProvider, useDelete, useMutation, useNotify } from 'react-admin';
import { useSnackbar } from 'notistack';
import { NUMBER } from '../utils/Constants/MagicNumber';
import { ToastContainer } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { Tooltip } from '@mui/material';
import { PropayStatusField } from './StatusField';

const UpdateListDataDialog = ({
    open,
    handleClose,
    apiData = [],
    record,
    handleClick,
    actionName
}) => {
    const [data, setData] = React.useState([]);
    const dataProvider = useDataProvider();
    const {enqueueSnackbar} =  useSnackbar();
    const notify = useNotify();
    const queryClient = useQueryClient();

    React.useEffect(() => {
        if(apiData?.length){
            setData(apiData);
        }
    }, [apiData?.length]);

    const updateRecords = async () => {
            const payload ={
                propay_ids:data,
                update_option:'adjust_current',
                budget_operator:'set_manually'
              }
            handleClose();
            notify(`This action may take time. You will be notified soon.`,{autoHideDuration:NUMBER.ONE_THOUSAND_FIVE_HUNDRED});
            const resp = await (post(PROPAY_BULK_ACTION_API['Update Budget'], payload)) as any;
            if (resp?.bulk_sync_info_id) {
                getSyncIdInfo(resp?.bulk_sync_info_id)
            } else {
                handleErrorAndSuccessToast('error', `server error: something went wrong.`);
            }
    };

    const getSyncIdInfo = (syncId) => {
        const interval = setInterval(() => {
            dataProvider.getOne('protivBulkSyncInfo', { id: syncId }).then(async (res) => {
                if (res?.data?.status === 'success') {
                    enqueueSnackbar(`Budget updated successfully for selected ProPays (${res?.data?.success_count}).`, { variant:'success', key: 'propay', autoHideDuration: 6000 });
                    queryClient.refetchQueries();
                    if(record?.id){
                        const data:any = await dataProvider.getOne('propay',{id:record?.id});
                        handleClick({...data?.data, actionName});
                    }
                    clearInterval(interval);
                }
            })
        }, 1500);
    }

    const handleChange = (id, e, field) => {
        const inputValue = e.target.value;
        if (
            (inputValue === '' ||
            (/^\d*$/.test(inputValue) && parseInt(inputValue) >= 0)) && field !=='budget_option'
        ) {
            setData(prev => {
                const index = prev.findIndex(job => job.id === id);
                if (index !== -1) {
                    return [
                        ...prev.slice(0, index),
                        {
                            ...prev[index],
                            [field]:
                                inputValue === '' ? '' : parseInt(inputValue),
                        },
                        ...prev.slice(index + 1),
                    ];
                }
                return prev;
            });
        }else{
            setData(prev => {
                const index = prev.findIndex(job => job.id === id);
                if (index !== -1) {
                    return [
                        ...prev.slice(0, index),
                        {
                            ...prev[index],
                            [field]:
                                inputValue === '' ? '' : inputValue,
                        },
                        ...prev.slice(index + 1),
                    ];
                }
                return prev;
            });
        }
    };

    return (
        <>
        <ToastContainer />
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="revenue-leaderboard-title"
            className="common-dialog-modal budget-modal"
        >
            <DialogTitle id="alert-dialog-title">
                <Stack flexDirection={'row'} justifyContent={'flex-end'}>
                    {/* {content?.title} */}
                    <IconButton
                        sx={{paddingLeft: 2 }}
                        color="primary"
                        aria-label="budget modal"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent className='u-m-budget-d'>
            <div className="update-budget-head">
            Update missing budget for the selected ProPays {`(${data?.length})`}
            </div>
            <div className='update-budget-panel'>
            <div className='update-budget-table'>
            <TableContainer component={Paper}>
            <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className='w-n-rap'>ProPays</TableCell>
                            <TableCell className='w-n-rap'>Hrs / Amount</TableCell>
                            <TableCell className='w-n-rap' colSpan={2}>Budget</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.map(item => {
                            return (
                                <TableRow>
                                    <TableCell>
                                    <div className='p-flex-b-td'>
                                    {item?.name}
                                    <PropayStatusField record={item}/>
                                    </div>
                                    </TableCell>
                                    <TableCell>
                                    <FormControl size='small' fullWidth>
                                    <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    onChange={e =>
                                        handleChange(
                                            item?.id,
                                            e,
                                            'budget_option'
                                        )
                                    }
                                    value={item['budget_option']}
                                    displayEmpty
                                    size='small'
                                    >
                                    <MenuItem value='hours'>Hours</MenuItem>
                                    <MenuItem value='amount'>Amount</MenuItem>
                                    </Select>
                                </FormControl>
                                    </TableCell>
                                    <TableCell>
                                        {item?.budget_option ==='amount' ?
                                        <TextField
                                            size="small"
                                            onChange={e =>
                                                handleChange(
                                                    item?.id,
                                                    e,
                                                    'budget'
                                                )
                                            }
                                            value={item['budget']}
                                            defaultValue={null}
                                            id="percentage-input-goal"
                                            className="contract-input"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        $
                                                    </InputAdornment>
                                                ),
                                            }}
                                        /> :
                                        <TextField
                                            size="small"
                                            onChange={e =>
                                                handleChange(
                                                    item?.id,
                                                    e,
                                                    'budget'
                                                )
                                            }
                                            value={item['budget']}
                                            defaultValue={null}
                                            id="percentage-input-goal"
                                            className="contract-input"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        h
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        }
                                    </TableCell>
                                    <TableCell>
                                        <TrashButton record={item} setData={setData} apiData={data} handleClose={handleClose}/>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            </div>
            </div>
            </DialogContent>
            <DialogActions className="button-justified budget-update-action">
                <Button onClick={() => handleClose()} className="cancel-button">
                    Cancel
                </Button>
                {data?.filter((item)=>!item?.budget)?.length ?
                <Tooltip id='unique-tooltip' className='unique-tooltip' title="Please add budgets or delete ProPays" placement='top' arrow>
                    <Button className="update-button disabled-btn">
                        Confirm
                    </Button>
                </Tooltip> :
                    <Button
                        className="update-button"
                        disabled={data?.filter((item)=>!item?.budget)?.length ? true : false}
                        onClick={() => updateRecords()}
                    >
                        Confirm
                    </Button>
                }
            </DialogActions>
        </Dialog>
        </>
    );
};

export default UpdateListDataDialog;

const TrashButton = ({ record, setData, apiData, handleClose }) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [actionClicked, setActionClicked] = React.useState(false);
    const [mutate] = useMutation();
    const queryClient = useQueryClient();

    const handleConfirm = () => {
        setIsLoading(true);
        mutate(
            {
                type: 'update',
                resource: 'propays',
                payload: { id: record.id, action: 'cancel_propay', action_name: 'delete', data: {} },
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: (result: any, variables: any = {}) => {
                    handleErrorAndSuccessToast('success', 'You have successfully deleted ProPay.')
                    if(apiData?.length === 1){
                        queryClient.refetchQueries();
                        handleClose();
                    }
                    setIsLoading(false);
                    setData((prev)=> (prev?.filter((el)=> el?.id !== record?.id)));
                    setActionClicked(false);
                },
                onFailure: error => {
                    setIsLoading(false);
                    setActionClicked(false);
                    handleErrorAndSuccessToast('error', `${error.message}`)
                },
            }
        );
    };


    const handleClickDelete = () => {
        if (actionClicked) {
            handleConfirm()
        } else {
            setActionClicked(true);
        }
    }

    return (
        <>
        {!isLoading ?
        <Tooltip id='unique-tooltip' className='unique-tooltip' title='Delete ProPay' placement='top' arrow>
        <Button className='budget-trash' onClick={()=>handleClickDelete()}>
            {actionClicked ? 'Really?' : TRASH_ICON()}
        </Button>
        </Tooltip>
        :
        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        }
        </>
    )
}
