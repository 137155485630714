import moment from 'moment';
import { ADD_BONUSES_ICON, ADD_DEDUCTION_ICON, BIN_ICON, BONUS_POOL_ICON_02, CLOSE_FILL_ICON, CLOSE_ICON_ACTIONS, COMPLETE_PROPAY_ACTION, CURRENCY_DOLLAR_ICON, GROUP_EXPORT_ICON, MERGE_STATEMENT_ICON, PENDING_ICON, TICK_ICON_FILLED, USER_ACTIVATE_ICON, USER_DISABLE_ICON } from './ClassName';
import { NUMBER } from './MagicNumber';
import { REGEX } from './Regex';
import { VALIDATION } from './ValidationMessages';
import { label } from 'aws-amplify';

export const COMMON_DAYS =['Mon','Tue','Wed','Thu','Fri','Sat','Sun'];
export const ACTIVE_TAB = {
    DASHBOARD: '/',
    PROPAY: '/propay/propay',
    BONUSES: '/protivEmployeeBonusStatement',
    ADD_TIME: '/attendances/create',
    ATTENDANCE: '/attendances',
    COST_CODES:'/setting/costcodes'
};

export const CONSTANT_TEXTS = {
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  EMPLOYEE_NAME: 'Employee Name',
  CHECK_IN: 'Check In',
  CHECK_OUT: 'Check Out',
  DATE: 'Date',
  HOURS: 'Hours',
  JOB: 'Job',
  PAYROLL_PERIOD: 'Payroll Period'
};

export const CONSTANT_DATA ={
  regular:'regular',
  true:true,
  false:false
}

export const API_ENDPOINTS = {
  IMPORT_PROCESS_FINAL: '/api/process-final-data',
  CREATE_ATTENDANCE_IMPORT: 'api/create_attendance_import',
  COST_CODES_BULK_CREATE:'/api/costcode/bulk-create',
  UPDATE_ATTENDANCE_IMPORT:'/api/update_attendance_import',
  EMPLOYEES_MISSING_WAGE: '/api/employees',
  COMPANY_BONUS_POOL: '/api/bonus-pool/details',
  BONUS_GROUP_PROPAY_DETAIL:'/api/group_propay/earning_by_propay'
}

export const BASE64_STRING = {
  XLS: 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
  CSV: 'data:text/csv;base64,',
};

export const PROTIV_BILLING_LINK = 'https://billing.protiv.com/p/login/7sIeYUdyuedG1Y46oo';
export const PROPAY_BUDGET_HELP_LINK = 'https://help.protiv.com/how-do-i-use-the-budget-help-when-creating-a-propay';
export const PROPAY_BP_ARTICLE_LINK = 'https://help.protiv.com/configuring-bonus-pool-settings';
export const PROPAY_BS_ARTICLE_LINK = 'https://help.protiv.com/how-to-set-bonus-split-types';
export const PROPAY_LEADPAY_ARTICLE_LINK = 'https://help.protiv.com/what-is-leadpay-and-how-does-it-work';

export const PROTIV_HELP_LINK = 'https://help.protiv.com/hc/en-us/articles/14817583814413-How-do-I-import-data-Non-integrated-accounts-';

export const HELP_CENTER_URL = "https://help.protiv.com";

export const ImportStep =[
                          {label: 'Map Header', step: 0},
                          {label: 'Map Jobs', step: 1},
                          {label: 'Map Users', step: 2},
                          {label: 'Map Propays', step: 3}
                          ];

export const LIST = {
    LIST: 'List',
    CARD: 'Card',
};

export const USER_TYPE={
  worker:'worker',
  admin:'admin',
  manager:'manager',
  crew_lead: 'crew_lead',
}

export const EMAIL_REGEX = /^[a-zA-Z0-9.'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const REMOVE_TIME_IN_SMALL_BRACES = /\s*\(\d{2}:\d{2}(:\d{2})?\)\s*/g;

export const timeLogged = (hour: number) => {
    const decimalTimeString = hour.toString();
    let decimalTime = parseFloat(decimalTimeString);
    decimalTime = decimalTime * NUMBER.SIXTEY * NUMBER.SIXTEY;
    const hours = Math.floor((decimalTime / (NUMBER.SIXTEY * NUMBER.SIXTEY)));
    decimalTime = decimalTime - (hours * NUMBER.SIXTEY * NUMBER.SIXTEY);
    const minutes = Math.floor((decimalTime / NUMBER.SIXTEY));
    decimalTime = decimalTime - (minutes * NUMBER.SIXTEY);
    const totalHour = (hours < NUMBER.TEN && hours > NUMBER.ZERO) ? `0${hours}` : hours;
    const totalMinutes = minutes < NUMBER.TEN ? `0${minutes}` : minutes;
     return (`${totalHour}:${totalMinutes}`);
  };

export const isMobile = () =>{
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
    {
        return true;
     }
   else {
        return false;
         }
};

export const truncateString = (string: string) => (string.length > NUMBER.TWENTY_ONE ? `${string.substring(NUMBER.ZERO, NUMBER.TWENTY_ONE)}…` : string);
export const truncatePropayName = (string: string) => (string.length > NUMBER.FIFTY ? `${string.substring(NUMBER.ZERO, NUMBER.FIFTY)}…` : string);
export const truncatePropayListName = (string: string) => {
  return(string.length > NUMBER.FOURTY_TWO ? `${string.substring(NUMBER.ZERO, NUMBER.FOURTY_TWO)}…` : string);
}

export const getLastWeeksDate = () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() - NUMBER.SEVEN);
  };

  export const getTodaysDate = () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() - NUMBER.ZERO);
  };


  export const getLastMonthDate = () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() - NUMBER.THIRTY);
  };

  export const getStartOfCurrentYear = () => {
    const now = new Date();
    return new Date(now.getFullYear(), 0, 1); // Month is zero-based, so 0 represents January
  };

  export const getYearToDate = () => {
    return getStartOfCurrentYear(); // Return the start of the current year
  };


export const statusClass = (status: string) => {
  switch (status) {
    case 'approved': return `green-status-btn card-status-btn`;
    case 'cancelled': return `red-status-btn card-status-btn`;
    case 'started': return `started-status-btn card-status-btn`;
    case 'completed': return `completed-status-btn card-status-btn`;
    case 'paid': return `paid-status-btn card-status-btn`;
    default: return `card-status-btn`;
  }
};

  export const milestoneStatusClass = (status: string) => {
    switch (status) {
      case 'approved': return `green-status-btn card-status-btn milestone-status-btn`;
      case 'cancelled': return `grey-status-btn card-status-btn milestone-status-btn`;
      case 'started': return `started-status-btn card-status-btn milestone-status-btn`;
      case 'completed': return `completed-status-btn card-status-btn milestone-status-btn`;
      case 'paid': return `paid-status-btn card-status-btn`;
      default: return `card-status-btn milestone-status-btn`;
    }
  };

  export const capitalizeFirstLetter=(string: string)=> {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
}

export const LowercaseFirstLetter=(string: string)=> {
  return string.charAt(0).toLowerCase() + string.slice(1);
}


  export const RemoveDuplicateObject= (arr: any) => {
    const uniqueArrayOfObjects = Array.from(new Set(arr.map(a => a.jonName))).map(jonName => {
      return arr.find(a => a.jonName === jonName);
    });
    return uniqueArrayOfObjects;

  };


  export const removeDuplicatEemployeeData = (arr: any[]) => {
        const uniqueArrayOfObjects = arr.filter((item, index, self) => {
          const name = item.name.toLowerCase().replace(/\s/g, '');
          return index === self.findIndex((obj) => obj.name.toLowerCase().replace(/\s/g, '') === name);
        });
      return uniqueArrayOfObjects;
  };

  export const decimalValue = (value)=> Number.isInteger(value) ? value : isNaN(value) ? NUMBER.ZERO : parseFloat(value).toFixed(NUMBER.TWO);
  export const removeDuplicates = (arr: any) => {
    const uniqueArray = arr.reduce((accumulator, currentValue) => {
      if (accumulator.indexOf(currentValue) === -1) {
        accumulator.push(currentValue);
      }
      return accumulator;
    }, []);
      return uniqueArray;
  };

export const getRequiredColumns = (selectedColumn) => {
  const {
    isDateTimeInSameCell,
    isNameInDffrentCell,
    importType
  } = selectedColumn;

  switch (importType?.value) {
    case 'payroll_period':
      if (isNameInDffrentCell === 'Yes') {
        return [CONSTANT_TEXTS.JOB, CONSTANT_TEXTS.PAYROLL_PERIOD, CONSTANT_TEXTS.FIRST_NAME, CONSTANT_TEXTS.LAST_NAME, CONSTANT_TEXTS.HOURS];
      } else {
        return [CONSTANT_TEXTS.JOB, CONSTANT_TEXTS.PAYROLL_PERIOD, CONSTANT_TEXTS.EMPLOYEE_NAME, CONSTANT_TEXTS.HOURS];
      }
    case 'duration':
      if (isNameInDffrentCell  === 'Yes') {
        return [CONSTANT_TEXTS.JOB, CONSTANT_TEXTS.DATE, CONSTANT_TEXTS.FIRST_NAME, CONSTANT_TEXTS.LAST_NAME, CONSTANT_TEXTS.HOURS];
      } else {
        return [CONSTANT_TEXTS.JOB, CONSTANT_TEXTS.DATE, CONSTANT_TEXTS.EMPLOYEE_NAME, CONSTANT_TEXTS.HOURS];
      }
    default: // regular
      if (isNameInDffrentCell === 'Yes') {
        if(isDateTimeInSameCell === 'Yes'){
          return [CONSTANT_TEXTS.JOB, CONSTANT_TEXTS.CHECK_IN, CONSTANT_TEXTS.CHECK_OUT, CONSTANT_TEXTS.FIRST_NAME, CONSTANT_TEXTS.LAST_NAME];
        }else{
        return [CONSTANT_TEXTS.JOB, CONSTANT_TEXTS.CHECK_IN, CONSTANT_TEXTS.CHECK_OUT, CONSTANT_TEXTS.FIRST_NAME, CONSTANT_TEXTS.LAST_NAME, CONSTANT_TEXTS.DATE];
        }
      } else{
        if(isDateTimeInSameCell === 'Yes'){
          return [CONSTANT_TEXTS.JOB, CONSTANT_TEXTS.CHECK_IN, CONSTANT_TEXTS.CHECK_OUT, CONSTANT_TEXTS.EMPLOYEE_NAME];
        }else{
        return [CONSTANT_TEXTS.JOB, CONSTANT_TEXTS.CHECK_IN, CONSTANT_TEXTS.CHECK_OUT, CONSTANT_TEXTS.EMPLOYEE_NAME, CONSTANT_TEXTS.DATE];
        }
      }
  }
}

export const getColumnsFiltered = (selectedColumn, values) => {
  const {
    isDateTimeInSameCell,
    isNameInDffrentCell,
    importType
  } = selectedColumn;

  switch (importType?.value) {
    case 'payroll_period':
      values = [...values, CONSTANT_TEXTS.DATE, CONSTANT_TEXTS.CHECK_IN, CONSTANT_TEXTS.CHECK_OUT];
      if (isNameInDffrentCell === 'Yes') {
        values = [...values, CONSTANT_TEXTS.EMPLOYEE_NAME];
      } else {
        values = [...values, CONSTANT_TEXTS.FIRST_NAME, CONSTANT_TEXTS.LAST_NAME];
      }
      break;
    case 'duration':
      values = [...values, CONSTANT_TEXTS.CHECK_IN, CONSTANT_TEXTS.CHECK_OUT, CONSTANT_TEXTS.PAYROLL_PERIOD];
      if (isNameInDffrentCell === 'Yes') {
        values = [...values, CONSTANT_TEXTS.EMPLOYEE_NAME];
      } else {
        values = [...values, CONSTANT_TEXTS.FIRST_NAME, CONSTANT_TEXTS.LAST_NAME];
      }
      break;
    default: // regular
      values = [...values, CONSTANT_TEXTS.PAYROLL_PERIOD, CONSTANT_TEXTS.HOURS];
      if (isNameInDffrentCell === 'Yes') {
        values = [...values, CONSTANT_TEXTS.EMPLOYEE_NAME];
        if (isDateTimeInSameCell === 'Yes') {
          values = [...values, CONSTANT_TEXTS.DATE];
        }
      } else {
        values = [...values, CONSTANT_TEXTS.FIRST_NAME, CONSTANT_TEXTS.LAST_NAME];
        if (isDateTimeInSameCell === 'Yes') {
          values = [...values, CONSTANT_TEXTS.DATE, ];
        }
      }
  }
  return values;
}

export const filterDuplicates = (arr: any) => {
  const uniqueNames = new Set();
  // eslint-disable-next-line array-callback-return
  arr.map((item) => {
    if(uniqueNames.has(item)){
      // eslint-disable-next-line array-callback-return
      return;
    }
    const sameNameId = arr.filter((value) => (value.name === item.name && value.employee_id === item.employee_id));
    const SameNameDiffId = arr.filter((value) => (value.name === item.name && value.employee_id !== item.employee_id));
    if(SameNameDiffId.length === NUMBER.ZERO){
      const uniqueData = removeDuplicatEemployeeData(sameNameId);
      uniqueNames.add(uniqueData[NUMBER.ZERO]);
    }else{
      const modifiedAbc = sameNameId.map((item) => ({
        ...item,
        name: `${item.name} (${item.employee_id})`,
      }));
      // eslint-disable-next-line array-callback-return
      modifiedAbc.map((abcd) => {
          const index = Array.from(uniqueNames).findIndex((obj: any) => obj.employee_id === item.employee_id);
          if (index !== -1) {
            uniqueNames[index] = {...item, name: `${item.name} (${item.employee_id})` };
          } else {
            uniqueNames.add({ ...item, name: `${item.name} (${item.employee_id})` });
          }
      })
      }
  });
  const uniqueNmaesArray = Array.from(uniqueNames, (item: any) => ({ ...item }));
  return uniqueNmaesArray;
};

export const hasMinimumValuesAndDate = (data) => {
  return data.filter(obj => {
    const values = Object.values(obj).filter(value => value !== undefined);

    if (values.length >= 4) {
      return true;
    }

    return false;
  });
};



/*function to handle import step one required column selected or not validation*/
export const validateNext = (stepOne,rquiredColumns) => {
  return new Promise((resolve) => {
    // const columnsMarkedRequired = (!dateIncluded) ? [...rquiredColumns,'Date'] : rquiredColumns;
    const missingColumns = rquiredColumns?.filter(col => {
      const newObj = { ...stepOne}; // Merge dateFormat and timeFormat into stepOne
      const matchingKey = Object.keys(newObj).find(key => (newObj[key]?.label.replace(REGEX.REMOVE_ASTRIC, '') === col.replace(REGEX.REMOVE_ASTRIC, '') ||
      (key === col.replace(REGEX.REMOVE_ASTRIC, '') && newObj[key]?.label.replace(REGEX.REMOVE_ASTRIC, ''))));
      const matchingValue = newObj[matchingKey]?.value;
      return !matchingKey || !matchingValue || matchingValue.length === NUMBER.ZERO;
    });
    if (missingColumns.length > NUMBER.ZERO) {
      const message = `Missing required columns: ${missingColumns.join(', ')}`;
      resolve({ valid: false, message });
    } else {
      resolve({ valid: true, message: 'Step one is valid.' });
    }
  });
};

/*function to handle import step one required employee data validation*/
export const validateFileHaveData = (fileData, keyVal, JobKey) => {
  return new Promise((resolve, reject) => {
    if (keyVal && JobKey) {
      const systememployeeData = fileData?.map((row: any) => {
        const employeeName = keyVal.length >= NUMBER.TWO ? `${row[keyVal[NUMBER.ZERO]]} ${row[keyVal[NUMBER.ZERO]]}` : row[keyVal[NUMBER.ZERO]];
        return {
          name: employeeName,
          employee_id: employeeName
        };
      });
      const systemJobData = fileData?.map((row: any) => {
        const jobName = row[JobKey];
        return {
          name: jobName,
          employee_id: jobName
        };
      });
      if (systememployeeData.length > NUMBER.ZERO && systemJobData.length > NUMBER.ZERO ) {
        resolve({ valid: true, message: 'file is valid.' });
      } else {
        const message = `File do not contain required data to process`;
        resolve({ valid: false, message });
      }
      }else{
        resolve({ valid: false, message: 'Key missing.' });
      }
  });
};

/*function to handle csv and excel file validations */
export const validateCsvAndExcelFile = (file: File) => {
  return new Promise((resolve, reject) => {
      if (!file || file.size <= NUMBER.ZERO) {
        resolve({
          success: false,
          message: VALIDATION.INVALID_FILE
        });
      }else{
        resolve({
          success: true,
          message: VALIDATION.VALID_FILE
        });
      }
  });
};


export const findFileType = (file) => {
  if (file.name.endsWith('.csv')) {
    return 'csv'
  } else {
       return 'xlsx'
 }
};

/* This function returns an array of attendance headers based on the number of failure reasons in the input data. */
export const getDynamicHeaders = (rowData) => {
  const headers = [];
  return new Promise((resolve) => {
      /* Loop through the failure reasons in the input data and add a corresponding attendance header */
      for (let i = NUMBER.ONE; i <= rowData?.length; i++) {
        headers.push(`COLUMN_${i}`);
      }
        resolve(headers);
    });
};

/* This function returns an auto mapped and left entries.*/
export const findMatchedAndUnmatchedEntry = (dataObj) => {
  let matched = NUMBER.ZERO;
  let unMatched = NUMBER.ZERO;
  const arr = Object.values(dataObj);
  console.log(arr, 'arrarrarr')
  arr.map((item:{label:string}| undefined | string) => {
    if(item && item !== 'Create'){
      matched= matched+1
    }else{
      unMatched=unMatched+1
    }
  })
  return {matched, unMatched};
}

export const addAsteriskToLabels = (current, options) => {
  const requiredOptions = options?.filter(option => {
    // Check if option is required based on its value
    return current?.includes(option?.label);
  });

  // Loop through current array and add an asterisk to matching labels
  const updatedLabels = options?.map(obj => {
    const matchingOption = requiredOptions?.find(option => option?.label === obj?.label);
    if (matchingOption) {
      return {...obj, label:`${matchingOption?.label} *`};
    } else {
      return obj;
    }
  });
  return updatedLabels;
}

export const filterChildrenByOptions = (children, options) => {
  const optionIds = options?.map(option => option.id ? option.id : option.employeeKeyId);
  const filteredChildren =(children && children?.length > 0 ) ? children?.filter(child => optionIds.includes(child.props.data.id ? child.props.data.id : child.props.data.employeeKeyId)):[];
  return filteredChildren;
}

/* This function returns an auto mapped and left entries.*/
export const findMatchedAndUnmatchedPropay = (dataObj) => {
  let matched = NUMBER.ZERO;
  let unMatched = NUMBER.ZERO;
  dataObj.map((item:any) => {
    if(item?.propay_name){
      matched= matched+1
    }else{
      unMatched=unMatched+1
    }
  })
  return {matched, unMatched};
}

/* This function returns an auto mapped and left entries.*/
export const dynamicClassForMachtedEntries = (count, totalCount) => {
  if(count === NUMBER.ZERO ){
    return 'matched-entries-none'
  }
  if(count<totalCount){
    return 'matched-entries-some'
  } else{
    return 'matched-entries-all'
  }
}

export const handleHideShowOptions = (columnsVlaues, identity, selectedColumn) => {
  if(!identity?.company?.can_use_cost_code){
    columnsVlaues = columnsVlaues?.filter((item) => item.value !== 'cost_codes');
  }
  columnsVlaues = columnsVlaues?.filter(option => {
      if (selectedColumn?.overTime === 'Yes' && selectedColumn?.importType?.value === 'duration') {
        return true;
      } else {
        return option.value !== 'weekly_single_overtime' &&
               option.value !== 'weekly_double_overtime';
      }
    });
    return columnsVlaues;
  }

export const generateTimeOptions = () => {
    const options = [];
    const startTime = new Date();
    startTime.setHours(NUMBER.ZERO, NUMBER.ZERO, NUMBER.ZERO, NUMBER.ZERO);
    for (let i = NUMBER.ZERO; i < NUMBER.FOURTY_EIGHT; i++) {
      const time = new Date(startTime.getTime() + i * NUMBER.THIRTY * NUMBER.SIXTEY * NUMBER.ONE_THOUSAND);
      const formattedTime = time.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
      });

      options.push({
        label: formattedTime,
        value: formattedTime,
      });
    }
    return options;
  };

export const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    });

export const checkforColumnVisibility = (columnData, columnId)=> {
      const filteredObjects = columnData ? columnData.filter(obj => obj.field_id === columnId && obj.is_visible) : [];
      return filteredObjects.length > NUMBER.ZERO;
  }
export const getColumnLabel = (columnData, columnId) => {
    const filteredObjects = columnData ? columnData.filter(obj => obj.field_id === columnId) : [];
    if (filteredObjects.length > NUMBER.ZERO) {
        return filteredObjects[NUMBER.ZERO].field_id_obj.name;
    }
    return '';
}

export const PROPAY_COLUMN = {
    ACTION: 1,
    ACTUAL_AMOUNT: 2,
    ACTUAL_HOURS: 3,
    APPROVED_BONUSES: 4,
    BUDGET_AMOUNT: 5,
    BUDGET_HOURS: 6,
    COST_CODES: 7,
    END_DATE: 8,
    JOB: 9,
    MANAGER: 10,
    START_DATE: 11,
    STATUS: 12,
    STATUS_BUTTON: 13,
    TITLE: 14,
    VARIANCE_AMOUNT: 15,
    VARIANCE_HOURS: 16,
    JOB_TYPE: 100,
    SECOND_JOB:34,
    INVOICE_TYPE:166,
};
export const PROPAY_COLUMN_NAME = {
  ACTION: 'Action',
  ACTUAL_AMOUNT: 'Actual Amount',
  ACTUAL_HOURS: 'Actual Hours',
  APPROVED_BONUSES: 'Approved Bonuses',
  BUDGET_AMOUNT: 'Budget Amount',
  BUDGET_HOURS: 'Budget Hours',
  COST_CODES: 'Cost Codes',
  ENDED_ON: 'Ended On',
  JOB: 'Job',
  MANAGER: 'Manager',
  STARTED_ON: 'Started On',
  STATUS: 'Status',
  STATUS_BUTTON: 'Status Button',
  TITLE: 'Title',
  VARIANCE_AMOUNT: 'Variance Amount',
  VARIANCE_HOURS: 'Variance Hours',
  SECOND_JOB: 'Second Job',
  JOB_TYPE: 'Job Type',
  INVOICE_TYPE: 'Invoice Type',
  GROUP_PROPAY: 'GroupProPay'
};

export const dayToNumber = (day:string) =>{
    const switchObj: { [key: string]: string } = {
        'Mon': '0',
        'Tue': '1',
        'Wed': '2',
        'Thu': '3',
        'Fri': '4',
        'Sat': '5',
        'Sun': '6'
    };
    return switchObj[day] || '0';
}

export const getNotificationLisFiltered = (data, type) => {
  switch (type) {
    case 'admin':
      const adminUsers = data?.filter(user => user.user_type === 'admin').slice(0, 1)[0]?.assigned_employee_notification_ids_obj?.map((item) => ({ id: item.notification_id, is_sms_enabled: false, is_email_enabled: false, name:item.name, info_text:item?.info_text,}));
      return adminUsers;
    case 'worker':
      const workerUsers = data?.filter(user => user.user_type === 'worker').slice(0, 1)[0]?.assigned_employee_notification_ids_obj?.map((item) => ({ id: item.notification_id, is_sms_enabled: false, is_email_enabled: false,name:item.name, info_text:item?.info_text,}));
      return workerUsers;
    case 'manager':
      const managerUsers = data?.filter(user => user.user_type === 'manager').slice(0, 1)[0]?.assigned_employee_notification_ids_obj?.map((item) => ({ id: item.notification_id, is_sms_enabled: false, is_email_enabled: false,name:item.name, info_text:item?.info_text,}));
      return managerUsers;
    default:
      return [];
  }
};

export const getUserRoleAndCheckSameRole = (ids, users) => {
  return new Promise((resolve, reject) => {
    /* Extracting roles from user objects based on the provided ids */
    const roles = ids.map(id => {
      const user = users.find(u => u.id === id);
      return user ? user.user_type : null;
    });

    /* If there are no roles or only one role, all roles are the same*/
    const allRoleSame = roles.length <= NUMBER.ONE || roles.every(role => role === roles[NUMBER.ZERO]);
    const user_role = allRoleSame ? roles[NUMBER.ZERO] : null;
    resolve({ user_role, allRoleSame });
  });
}
export const AUTOMATION_FILTER_OPTIONS={status:'Status',manager:'Managers', automation_type:'Automation Type', created_date:'Created Date'};

export const FILTER_OPTIONS={propay_no_bonus:'ProPay Without Budget Only ',worker:'Workers',manager:'Managers',status:'Status',propay:'ProPays',job:'Jobs', branches: 'Branches' , division: 'Divisions',job_type: 'Job Type',invoice_type:'Invoice Type',started_on:'Started On', ended_on:'Ended On',
budget:'Budget Amount',budget_hours:'Budget Hours',variance_amount:'Variance Amount',variance_hours:'Variance Hours',hours_worked:'Hours Worked',schedule_visit:'Visit Dates',cost_code:'Cost Codes'}
export const COMPARISON_OPTIONS=[{
  value:'Greater than',
  id:'_gt'
},{
  value:'Greater than or equal to',
  id:'_gte'
},
{
  value:'Less than',
  id:'_lt'
},
{
  value:'Less than or equal to',
  id:'_lte'
},
{
  value:'Is equal to',
  id:'_eq'
},
{
  value:'Is not equal to',
  id:'_neq'
},
{
  value:'Is between',
  id:'_in'
},
]
/* For now removing 'Is not between' option since its filter query is incorrect
{
  value:'Is not between',
  id:'_nin'
}
*/

export const ATTENDANCE_FILTER_OTIONS={entries:'Entries Require Attention',worker:'Workers',status:'Status',wages:'Wage',propay:'ProPays',job:'Jobs',branches: 'Branches' , division: 'Divisions', hours:'Hours',cost_code:'Cost Codes',earning:'Earnings',period:'Payroll Period'};
export const ATTENDANCE_BULK_ACTION={changePropay:'Change Job & ProPay',changeWage:'Change Wage',delete:'Delete'}
export const PROPAY_DETAILS_BULK_ACTION={changePropay:'Change ProPay',changeWage:'Change Wage',delete:'Delete', addTime: 'Add Time'}
export const PROPAY_DETAILS_ATTENDANCE_BULK_ACTION={changeWage:'Change Wage', changePropay:'Change ProPay', addTime: 'Add Time'}
export const ATTENDANCE_BULK_OPTIONS = [
  {
      label: ATTENDANCE_BULK_ACTION.changePropay,
      value: ATTENDANCE_BULK_ACTION.changePropay,
      class: 'bulk-action-link',
  },
  {
      label:ATTENDANCE_BULK_ACTION.changeWage,
      value:ATTENDANCE_BULK_ACTION.changeWage,
      class:'bulk-action-link',
  },
  {
      label: 'Delete',
      value: 'Delete',
      class: 'delete-bulk-action-link',
      icon: BIN_ICON(),
  }
]
export const PROPAY_DETAILS_ATTENDANCE_BULK_OPTIONS = [
  {
      label: PROPAY_DETAILS_ATTENDANCE_BULK_ACTION.changePropay,
      value: PROPAY_DETAILS_ATTENDANCE_BULK_ACTION.changePropay,
      class: 'bulk-action-link',
  },
  {
      label:PROPAY_DETAILS_ATTENDANCE_BULK_ACTION.changeWage,
      value:PROPAY_DETAILS_ATTENDANCE_BULK_ACTION.changeWage,
      class:'bulk-action-link',
  },
  {
    label:PROPAY_DETAILS_ATTENDANCE_BULK_ACTION.addTime,
    value:PROPAY_DETAILS_ATTENDANCE_BULK_ACTION.addTime,
    class:'bulk-action-link',
},
]
export const PROPAY_BULK_OPTIONS =[
  {
    label: 'Update Budget',
    value: 'Update Budget',
    class: 'budget-bulk-action-link common-bulk-action-link',
    icon: CURRENCY_DOLLAR_ICON(),

  },{
    label: 'Update Bonus Pool',
    value: 'Update Bonus Pool',
    class: 'budget-bulk-action-link common-bulk-action-link',
    icon: BONUS_POOL_ICON_02(),
  },
  {
      label: 'Approve',
      value: 'Approve',
      class: 'approve-bulk-action-link common-bulk-action-link',
      icon: TICK_ICON_FILLED(),
  },
  {
      label:'Pending',
      value:'Pending',
      class:'pending-bulk-action-link common-bulk-action-link',
      icon: PENDING_ICON(),
  },
  {
    label: 'GroupProPay',
    value: 'GroupProPay',
    class: 'bulk-action-group-link',
    icon: GROUP_EXPORT_ICON(),
  },
  {
  label:'Complete',
  value:'Complete',
  class:'complete-bulk-action-link common-bulk-action-link',
  icon: COMPLETE_PROPAY_ACTION(),
  },
  {
      label: 'Cancel',
      value: 'Cancel',
      class: 'delete-bulk-action-link common-bulk-action-link',
      icon: CLOSE_ICON_ACTIONS(),
  },
  {
    label: 'Delete',
    value: 'Delete',
    class: 'delete-bulk-action-link common-bulk-action-link',
    icon: BIN_ICON(),
  }
]
export const PROPAY_BULK_ACTION_API = {
  Approve:'/api/propay/delayed_bulk_approve_propay',
  //Pending:'/api/propay/bulk_pending_propay',
  Pending:'/api/propay/delayed_bulk_pending_propay',
  Complete:'/api/propay/bulk_completed_propay',
  Delete:'/api/propay/delayed_bulk_cancel_propay',
  Cancel:'/api/propay/delayed_bulk_cancel_propay',
  'Update Budget':'/api/propay/bulk_update_budget',
  'Update Bonus Pool':'/api/propay/bulk_update_bonus_pool',
  GroupPropay:'/api/group_propay/delayed_bulk_group_propay',
  RemoveGroupPropay:'/api/group_propay/delayed_remove_propay',
  AddPropayInGroup:'/api/group_propay/delayed_add_propay'
}
export const STATEMENT_FILTER_OPTION = {worker:'Workers',created_date:'Created Date',reference:'Reference #',statement_period:'Statement Period',paid_period:'Paid Period',status:'Status',propay:'ProPays',job:'Jobs',branches: 'Branches', earnings:'Earnings',close_date:'Close Date'
}
export const BONUS_BULK_ACTION_OPTION = [{label: 'Close Statements',
value: 'Close Statements',
class: 'bulk-action-link',
icon: CLOSE_FILL_ICON(),},
{label: 'Add Bonuses',
value: 'Add Bonuses',
class: 'bulk-action-link',
icon: ADD_BONUSES_ICON(),},
{label: 'Add Deduction',
value: 'Add Deduction',
class: 'bulk-action-link',
icon: ADD_DEDUCTION_ICON(),},
{label: 'Merge Statements',
value: 'Merge Statements',
class: 'bulk-action-link',
icon: MERGE_STATEMENT_ICON(),},
{
  label:'Mark As Pending',
  value:'Mark As Pending',
  class: 'bulk-action-link bulk-action-mark-pending',
  icon:TICK_ICON_FILLED()
}
]

export const SEARCH_PLACEHOLDER = {
  propay:'Search Title, Job , Manager or Worker',
  time:'Search Name, Job or ProPay',
  bonus:'Search Name, Job or Ref #',
  team:'Search Name, Email or Mobile'
}

export const PROPAY_ACTION_OPTION = {
  updateBudget:'Update Budget',
  bonusPool:'Update Bonus Pool',
  complete:'Complete',
  pending:'Pending',
  approve:'Approve',
  delete: 'Delete',
  cancel: 'Cancel'
}

export const EMPLOYEE_FILTER_OPTION = {name:'Name',role:'Role',mobile:'Mobile',email:'Email',wage:'Wage',branches: 'Branches' , division: 'Divisions',create_date:'Create Date',status:'Status'}

export const TEAM_COLUMN = {
  NAME:'Name',
  CREATE_DATE:'Create Date',
  LAST_UPDATED: 'Last Updated',
  ROLE:'Role',
  STATUS: 'Status',
  WAGE: 'Wage',
  EMAIL:'Email',
  MOBILE: 'Mobile',
  ACTIONS:'Action'
};
export const CONNECTOR_ALLOW_COST_CODES = ['busybusy' ,'arborgold','clockshark','dataverse','exaktime','paycom','apify-lmn','apify-contractor-foreman','buildertrend'];

export const getDateTime = (dateTime)=>{
  const parsedDateTime = moment(dateTime, 'YYYY-MM-DD HH:mm:ss');
  const formattedDateTime = parsedDateTime.format('MMMM DD, YYYY [at] hh:mm A');
  return formattedDateTime
 }

 export const TEAM_BULK_ACTIONS=[{
  label:'Activate',
  value:'Activate',
  class:'user-activate-item',
  icon:USER_ACTIVATE_ICON()
 },
 {
  label:'Disable',
  value:'Disable',
  class:'user-disable-item',
  icon:USER_DISABLE_ICON()
 }]

 export const TEAM_ACTIONS = {
  activate:'Activate',
  disable:'Disable'
 }

 export const BUDGET_INFORMATION = {
   revenue:'Contract Price will calculate a fixed amount ProPay Budget.',
   labor_budget:'Labor Budget will calculate a fixed amount ProPay Budget.',
   hours_budget:'Hours Budget will calculate a fixed hours ProPay Budget.'
 }
 export const DATE_LABELS={
  propay:{
    label1:'Started On',
    label2:'Ended On'
  },
  team:{
    label1:'Created From',
    label2:'Created To'
  }
 }

// This is a temporary solution. Until the backend team fixes the issue, 
// we will set the default leaderboard configuration from the UI side 
// for a new company or any company that does not have a default leaderboard config.
export const LEADERBOARD_CONFIG = {
  visible_count: 2,
  saved_hours: {
      name: 'Saved Hours',
      group_by: 'worker',
      show_to: ['Admin'],
  },
  net_saved_hours: {
      name: 'Net Saved Hours',
      group_by: 'worker',
      show_to: ['Admin'],
  },
  hours_worked: { 
      name: 'Hours Worked', 
      show_to: ['Admin'] 
  },
  propays_done: {
      name: 'ProPays Done',
      group_by: 'worker',
      show_to: ['Admin'],
  },
  revenue_per_hour: {
      name: 'Revenue Per Hour',
      group_by: 'worker',
      show_to: ['Admin'],
  },
  revenue_produced: {
      name: 'Revenue Produced',
      group_by: 'worker',
      show_to: ['Admin'],
  },
  bonus_wage: { 
      name: 'Bonus Wage', 
      group_by: 'worker', 
      show_to: ['Admin'] 
  },
  bonuses_earned: {
      name: 'Bonuses Earned',
      group_by: 'worker',
      show_to: ['Admin'],
  },
};
