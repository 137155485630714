import { TOAST_INFO_ICON } from "./ClassName";
import { capitalizeFirstLetter } from "./ConstantData";
import { NUMBER } from "./MagicNumber";

export const truncateString = (string: string, maxLength:number, trimLength:number) => {
  return(string.length > maxLength ? `${string.substring(NUMBER.ZERO, trimLength)}…` : string);
}

/* Function to compare two arrya and find updated data only.*/
export const findUpdatedData = (oldArray, newArray) => {
    const updatedData = [];
    for (let i = NUMBER.ZERO; i < oldArray?.length; i++) {
      const oldItem = oldArray[i];
      const newItem = newArray[i];
      if (oldItem?.is_visible !== newItem?.is_visible) {
        updatedData.push(newItem);
      }
    }
    return updatedData;
}

export const createGroupError = (formData, identity, managerVal) => {
  return new Promise((resolve, reject) => {
    const errors = [];
    groupManagerRequired(formData?.manager_id, errors, managerVal);
    leadPayAmountRequired(formData, errors);
    workerSplitPercentRequired(formData, errors);
    leadpayWorkerRequired(formData, errors);
    leadpaySplitPercentRequired(formData, errors);
    bonusPoolRequired(formData, errors);
    formData?.bonus_split_type === 'by_percentage' && createWorkerDataRequired(formData?.employee_wage_ids, errors);
    leadPayDataRequired(formData, errors);
    if (errors.length > 0) {
      resolve(errors);
    } else {
      resolve(errors);
    }
  });
}

export const findFormError = (formData, identity) => {
    return new Promise((resolve, reject) => {
      const errors = [];
      jobRequired(formData, errors);
      propayNameRequired(formData?.name, errors);
      managerRequired(formData?.manager_id, errors);
      amountRequired(formData, errors);
      leadPayAmountRequired(formData, errors);
      workerSplitPercentRequired(formData, errors);
      leadpayWorkerRequired(formData, errors);
      leadpaySplitPercentRequired(formData, errors);
      bonusPoolRequired(formData, errors);
      notesRequired(formData, errors);
      workerDataRequired(formData?.employee_wage_ids, errors);
      notesDataRequired(formData, errors);
      leadPayDataRequired(formData, errors);
      if (formData?.milestone_ids?.length) {
       milestonesDataRequired(formData?.milestone_ids, formData?.budget_option, errors);
      }
      if (errors.length > 0) {
        resolve(errors);
      } else {
        resolve(errors);
      }
    });
  };

const jobRequired = (data, errorArray) => {
  if(data.hasOwnProperty('second_job_id')){
    if(data.job_id || data.second_job_id){
      return errorArray;
    }else {
      errorArray?.push('Jobs are required. Please assign any one.');
      return errorArray;
    }
  }
  if (data.job_id) {
    return errorArray;
  } else {
    errorArray?.push('Job is required. Please assign one.');
    return errorArray;
  }
}

const propayNameRequired = (data, errorArray) => {
  if (data) {
    return errorArray;
  } else {
    errorArray?.push('ProPay Title is required.');
    return errorArray;
  }
}

const leadpaySplitPercentRequired = (data, errorArray) => {
    if (data?.is_change_lead_pay) {
      const bonusSum = (data?.leadpay_employee_wage_ids || []).reduce((sum, employee) => {
        return sum + (Number(employee?.lead_pay_per) || 0);
      }, 0);
      if(bonusSum !== 100){
      errorArray?.push(errorArray?.length > 1 ? 'LeadPay amount split requires the percentage to equal to 100%':'LeadPay budget split requires the percentage to equal to 100%. Please fix.');
      }
    }
}

const leadpayWorkerRequired = (data, errorArray) => {
  if (data?.is_change_lead_pay && !data?.leadpay_employee_wage_ids?.length) {
    errorArray?.push('Leadpay member not added.');
  }
}

const managerRequired = (data, errorArray) => {
  if (!data) {
    errorArray?.push('Manager is required. Please assign one.');
  }
}

const groupManagerRequired = (data, errorArray, managerVal) => {
  if (!data && !managerVal) {
    errorArray?.push('Manager is required. Please assign one.');
  }
}


const amountRequired = (formData, errorArray) => {
  if(!formData?.milestone_ids?.length){
  if(formData?.budget_option === 'hours'){
    if(!formData?.budget_hours){
      errorArray?.push('Please add a budget it is a required field.')
    }
  }else{
    if(!formData?.amount){
      errorArray?.push('Please add a budget it is a required field.')
    }
  }
 }
}

const milestonesDataRequired = (milestoneIds,budgetOption, errorArray) => {
  if (milestoneIds && milestoneIds.length > 0) {
    milestoneIds.forEach((milestone, index) => {
      if(budgetOption === 'hours'){
        if (!milestone.name || !milestone.budget_hours) {
          errorArray?.push(milestoneIds.length > 1 ? `Milestone ${milestone?.name ? milestone ?.name : index + 1}` : `Milestone ${milestone?.name ? milestone ?.name : index + 1} is missing required fields.`);
        }
      }else{
        if (!milestone.name || !milestone.amount ) {
          errorArray?.push(milestoneIds.length > 1 ? `Milestone ${milestone?.name ? milestone ?.name : index + 1}` : `Milestone ${milestone?.name ? milestone ?.name : index + 1} is missing required fields.`);
        }
      }
    });
  }
}

const leadPayDataRequired = (data, errorArray) => {
  if (data?.is_change_lead_pay && data?.leadpay_employee_wage_ids && data?.leadpay_employee_wage_ids?.length > 0) {
    data?.leadpay_employee_wage_ids.forEach((employee, index) => {
        if (!employee?.employee_id || !employee?.lead_pay_per) {
          errorArray?.push(data?.leadpay_employee_wage_ids?.length > 1 ? `Lead pay row ${index + 1}` : `Lead pay row ${index + 1} is missing required fields.`);
        }
    });
  }
}

const leadPayAmountRequired = (data, errorArray) => {
  if (data?.is_change_lead_pay && !data?.leadpay_amount) {
    errorArray?.push('Leadpay amount is required when leadpay toggle is on.');
  }
}

const workerDataRequired = (employeeWageIds, errorArray) => {
  if (employeeWageIds && employeeWageIds.length > 0) {
    employeeWageIds.forEach((worker, index) => {
        if (!worker?.employee_id || !worker?.base_wage) {
          errorArray?.push(employeeWageIds?.length > 1 ? `Row ${index + 1} in add worker` : `Row ${index + 1} in add worker is missing required fields.`);
        }
    });
  }
}

const createWorkerDataRequired = (employeeWageIds, errorArray) => {
  if (employeeWageIds && employeeWageIds.length > 0) {
    employeeWageIds.forEach((worker, index) => {
        if (!worker?.employee_id || !worker?.bonus_per) {
          errorArray?.push(employeeWageIds?.length > 1 ? `Row ${index + 1} in add worker` : `Row ${index + 1} in add worker is missing required fields.`);
        }
    });
  }
}

const notesDataRequired = (data, errorArray) => {
  if (data?.allow_task && data?.task_ids && data?.task_ids.length > 0) {
    data?.task_ids?.forEach((worker, index) => {
        if (!worker?.name) {
          errorArray?.push(data?.task_ids?.length > 1 ? `Row ${index + 1} in add notes` : `Row ${index + 1} in add notes is missing required fields.`);
        }
    });
  }
}

const workerSplitPercentRequired = (data, errorArray) => {
  if (data?.bonus_split_type === 'by_percentage' && data?.employee_wage_ids?.length) {
    const bonusSum = (data?.employee_wage_ids || []).reduce((sum, employee) => {
      return sum + (Number(employee?.bonus_per) || 0);
    }, 0);
    const roundedBonusSum = Math.round(bonusSum * 100) / 100;
    if(roundedBonusSum !== 1){
    errorArray?.push(errorArray?.length > 1 ? 'Percentage split type requires a total percentage equal to 100%.':'Percentage split type requires a total percentage equal to 100%. Please fix.');
    }
  }
}

const bonusPoolRequired = (data, errorArray) => {
  if (data?.is_bonus_pool_enabled && !data?.worker_bonus_percent) {
    errorArray?.push(errorArray?.length > 1 ? 'Workers Bonus Pool percentage must be greater than zero.' : 'Workers Bonus Pool percentage must be greater than zero. Please fix.');
  }
}

const notesRequired = (data, errorArray) => {
  if (data?.allow_task && !data?.task_ids?.length) {
    errorArray?.push(errorArray?.length > 1 ? 'Notes are required when the notes section is enabled.':'Notes are required when the notes section is enabled. Please fix.');
  }
}

export const CustomToast = ({ errors }) => {
  const allErrors = [
    'Job',
    'ProPay Title',
    'Leadpay member not added.',
    'Manager',
    'Cost Codes',
    'budget',
    'Leadpay amount',
  ]
  const multiError = ['is missing required fields.']

  if(errors?.length > 1){
    errors.map((error) => {
      const matchingError = allErrors.find((keyword) => error.includes(keyword));

      if (matchingError) {
        const index = errors.indexOf(error);
        errors.splice(index, 1, capitalizeFirstLetter(matchingError));
      }

      return error;
    });
    return (
      <div>
    {errors.length > 0 && (
      <>
        <div>Please fix the required fields:</div>
        {errors.map((field, index) => {
          return(
          <div key={index}>{multiError.find((keyword) => field?.includes(keyword))
            ? field.replace(new RegExp(multiError.join('|'), 'g'), '')
            : field}</div>
        )})}
        <div>Once complete, you can create group a of selected ProPays.</div>
      </>
    )}
  </div>
    );
  }
  const singleError = ['Add Worker row', 'Lead pay row', 'Milestone ']
  return (
    <div>
      {errors?.length && errors.map((error, index) => (
        <div key={index}>{
          singleError.find((keyword) => error?.includes(keyword)) ? `${error} is missing required fields.` : error}</div>
      ))}
    </div>
  );
};

export const CustomLeadpayToastToast = () => {
  return (
    <div className="lead-pay-toast">
      <span className="lead-pay-toast-icon">
      {TOAST_INFO_ICON()}
      </span>
      <div className="lead-pay-toast-body">
        <h4>LeadPay is enabled. </h4>
        <p>Actions are required:</p>
        <ul>
          <li>Add bonus amount and at least one user</li>
        </ul>
      </div>
    </div>
  );
};

export const findpropayWithoutBudget = (record) => {
  switch(record?.budget_option){
      case 'hours':
          if(record?.automation_id && !record?.budget_hours && !record?.budget){
              return true;
          }else{
              return false;
          }
      default:
          if(record?.automation_id && !record?.budget && !record?.budget_hours){
              return true;
          }else{
              return false;
          }
  }
}

export const applyRedColor = (condition) => {
  return condition ? 'red-text' : '';
}

export const getFilterCount =(value)=>{
   return value < 10 ? value : `+${value-9}`
}
export const  getFormatedMessage = (str:string)=>{
  return str.replace(/(\('|\', None\)|\['|\])/g, '');
}

export const findGroupAutomationFormError = (formData, step) => {
  return new Promise((resolve, reject) => {
    const errors = [];
    switch (step) {
      case 1:
        automationNameRequired(formData?.title, errors, 'Automation Title is required.');
        automationNameRequired(formData?.crew_lead_id, errors, 'Crew lead is required.');
        groupPropayNameRequired(formData?.name_format, errors);
        break;
      case 2:
        bonusTypeRequired(formData, errors);
        leadPayAmountRequired(formData, errors);
        leadpayWorkerRequired(formData, errors);
        leadpaySplitPercentRequired(formData, errors);
        bonusPoolRequired(formData, errors);
        leadPayDataRequired(formData, errors);
        managerRequired(formData?.manager_id, errors);
        break;
      case 3:
        automationNameRequired(formData?.repeat_value, errors, 'Repeat Every Field is required.');
        repeatTypeTypeRequired(formData, errors);
        scheduleEndRequired(formData, errors);
        automationNameRequired(formData?.start_date, errors, 'Date field is required.');
        break;
      default:

        break;
    }
    resolve(errors);
  });
};

const scheduleEndRequired = (data, errorArray) => {
  if (data?.schedule_end_type) {
    if(data?.repeat_type === 'on'){
      if(data?.end_date){
        return errorArray
      }else{
        errorArray?.push('Schedule date is required.');
        return errorArray;
      }
    }else if(data?.repeat_type === 'after'){
      if(data?.after_occurrence){
        return errorArray
      }else{
        errorArray?.push('Ocurrences input is required.');
        return errorArray;
      }
    }else{
      return errorArray;
    }
  } else {
    errorArray?.push('Schedule End is required.');
    return errorArray;
  }
}

const repeatTypeTypeRequired = (data, errorArray) => {
  if (data?.repeat_type) {
    if(data?.repeat_type === 'week'){
      if(data?.repeat_week_day>=0){
        return errorArray
      }else{
        errorArray?.push('Repeat week day is required.');
        return errorArray;
      }
    }else{
      return errorArray;
    }
  } else {
    errorArray?.push('Reapeat on is required.');
    return errorArray;
  }
}

const bonusTypeRequired = (data, errorArray) => {
  if (data?.divide_bonus_by) {
    if(data?.divide_bonus_by === 'hours_worked'){
      if(data?.distribute_bonus_rate){
        return errorArray
      }else{
        errorArray?.push('Bonus split type is required.');
        return errorArray;
      }
    }else{
      return errorArray;
    }
  } else {
    errorArray?.push('Bonus distribution type is required.');
    return errorArray;
  }
}

const automationNameRequired = (data, errorArray, message) => {
  if (data) {
    return errorArray;
  } else {
    errorArray?.push(message);
    return errorArray;
  }
}

const groupPropayNameRequired = (data, errorArray) => {
  if (data?.length) {
    return errorArray;
  } else {
    errorArray?.push('Title should be is required.');
    return errorArray;
  }
}
