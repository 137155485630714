import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Grid,
    Card,
    CardContent,
    Typography,
    Theme,
    Tooltip,
    useMediaQuery,
    Menu,
    ListItemIcon,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BIN_ICON, EDIT_ICON, FILLED_PLUS_ICON } from '../../utils/Constants/ClassName';
import { useState } from 'react';
import { BulkAddBonusModal } from '../protivEmployeeBonusStatement/BulkAddBonusModal';
import { Confirm } from '../../components/Confirm';
import { useDeleteMany, useNotify } from 'react-admin';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { PROPAY_STATUS } from '../../constants';
import { USER_TYPE } from '../../utils/Constants/ConstantData';
import { MenuItem } from '@mui/material';
import { IconButton } from '@mui/material';
import { DropdownIcon } from '../../components/SvgIcons/DropdownIcon';
import { JobView } from './ResponsiveDetailView';


export const BonusResponsiveAdditionView = ({ additionalDetails,refreshData,data,identity,additionalTotal }) => {
    const [openAddModal,setOpenAddModal]=useState(false);
    const [editAdditional,setEditAdditional] = useState({isOpen:false,id:NUMBER.ZERO,data:{}});
    const [deleteAdditional,setDeleteAdditional]=useState({isOpen:false,id:NUMBER.ZERO,type:''})
    const notify = useNotify();
    const [expandedId, setExpandedId] = useState(0);
    const shouldShowAction = data?.status !== PROPAY_STATUS.PAID && identity?.user_type !== USER_TYPE.worker
    const [deleteMany] = useDeleteMany('additionalPay',
        { ids: [deleteAdditional.id] },
        {
            onSuccess: () => {
                setDeleteAdditional({isOpen:false,id:NUMBER.ZERO,type:''})
                notify(`Additional ${deleteAdditional.type} deleted.`);
                refreshData && refreshData()
            },
            onError: (error: Error) => {
                notify(error.message)
                setDeleteAdditional({isOpen:false,id:NUMBER.ZERO,type:''})
            },
            mutationMode: 'pessimistic',
        }
    );
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const getPropayName = (propayObj: any) => {
        let parent = propayObj.parent_id
            ? propayObj.parent_id_obj.display_name
            : '';
        return parent
            ? parent + ` > ${propayObj.display_name}`
            : propayObj.display_name;
    };
    const handleSummaryClick = (event) => {
        event.stopPropagation();
    };
    const handleChange = (val) => {
        setExpandedId(val);
      };

    return (
        <div className="bonus-additional">
                                <div className="bonus-additional-head">
                                    Bonuses & Deductions
                                    {(shouldShowAction || additionalDetails?.length > NUMBER.ZERO) &&  <Button className="bonus-additional-btn" onClick={()=>{setOpenAddModal(true)}}>
                                        Add
                                    </Button>}
                                </div>

                                {additionalDetails?.length > NUMBER.ZERO ?
                                <div className='bonus-additional-mt'>
                                {additionalDetails.map(row => {
             return (
                                <div className="acc-worker-card res-list-acc">
                                    <div className="acc-worker-header">
                                        <div className="acc-worker-right">
                                            <Typography
                                                variant="h4"
                                                gutterBottom
                                            >
                                                {row.propay_id ? getPropayName(row.propay_id_obj) : 'N/A'}{' '}
                                                <Button className={`${row.additional_deduction_amt ? 'overage-btn-bonus':'btn-bonus-green'}`}>
                                                {row.additional_deduction_amt ?'Deduction':'Bonus'}
                                              </Button>
                                            </Typography>
                                        </div>

                                        <div className="time-header-action"><AdditionalActions setEditAdditional={setEditAdditional} row={row} setDeleteAdditional={setDeleteAdditional}/></div>
                                    </div>

                                    <Accordion className="res-acc-inner"
                                    expanded={row.id === expandedId}
                                    onChange={(e,expanded)=>handleChange(expanded ? row.id:0)}>
                                        <AccordionSummary
                                            // aria-controls="panel1a-content"
                                            // id="worker1a-header"
                                            className="worker-table-expand"
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                className="card-view-group"
                                                onClick={handleSummaryClick}
                                            >
                                         <label>{row.additional_deduction_amt ? 'Deduction':'Bonus'}:</label>
                                          <p className="no-translate">
                                        {row.additional_deduction_amt  ? `(${formatter.format(row.additional_deduction_amt)})` : formatter.format(row.additional_bonus_amt)}
                                                </p>
                                            </Grid>

                                            <AccordionSummary
                                                aria-controls="panel2a-content"
                                                expandIcon={<ExpandMoreIcon />}
                                                id="worker2a-header"
                                                className="worker-more-dtl"
                                            >
                                                {expandedId === row.id? 'See Less' : 'See More'}
                                            </AccordionSummary>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={0}>
                                                <Grid item xs={12} md={4}>
                                                    <Card>
                                                        <CardContent>
                                                            <Grid
                                                                container
                                                                columns={{
                                                                    xs: 12,
                                                                    md: 12,
                                                                }}
                                                                spacing={1}
                                                                className="propay-grid-res"
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    className="card-view-group"
                                                                >
                                                                    <label>
                                                                        Job
                                                                    </label>
                                                                    <JobView jobData={row.propay_id_obj}/>
                                                                </Grid>

                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    className="card-view-group"
                                                                >
                                                                    <label>
                                                                        Description
                                                                    </label>
                                                                    {row.description}
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            )
                            })}
                             {openAddModal && <BulkAddBonusModal isOpen={openAddModal} refreshData={refreshData} onClose={()=>{setOpenAddModal(false)}} selectedIds={[data.id]} showOption={true} recordId={data.id}/>}
              {(editAdditional.isOpen) && <BulkAddBonusModal editData={editAdditional.data} isEdit={true} isOpen={editAdditional.isOpen} refreshData={()=>{}} onClose={()=>{setEditAdditional({isOpen:false,id:NUMBER.ZERO,data:{}})}} 
                                    selectedIds={[editAdditional.id]} bulkAction={editAdditional.data['additional_deduction_amt'] ? 'deduction':'bonus'} showOption={false} recordId={editAdditional.id}/>}
                  {deleteAdditional.isOpen && <Confirm
                    isOpen={deleteAdditional.isOpen}
                    title={`Delete ${deleteAdditional.type}`}
                    content={`Are you sure you want to delete ${deleteAdditional.type} `}
                    onConfirm={()=>{deleteMany()}}
                    onClose={() => {setDeleteAdditional({isOpen:false,id:NUMBER.ZERO,type:''})}}
                    />}
                                </div>
                                :''}
                               {additionalTotal ? <div className='p-t-bonus'>
                                <label>Additional Total</label>
                                {formatter.format(additionalTotal)}
                                </div>:''}
                            </div>
    );
};
export default BonusResponsiveAdditionView;

export const AdditionalActions = (props)=>{
    const {setEditAdditional,row,setDeleteAdditional}=props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return(
        <div
        className='card-dropdown'
    >
      <Tooltip title="">
            <IconButton
                onClick={handleClick}
                sx={{ ml: 2 }}
                aria-controls={open ? 'card-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >
                <DropdownIcon />
            </IconButton>
        </Tooltip>
    <Menu
        onClose={handleClose}
        anchorEl={anchorEl}
        id="card-menu"
         open={open}
        className='bonus-sm-menu'
    >
        <MenuItem key={'Edit'}>
        <ListItemIcon>
        <Tooltip  title="Edit"  className='additional-edit-icon' arrow placement="top"
         onClick={()=>{setEditAdditional({isOpen:true,data:row,id:row.additional_pay_ids[NUMBER.ZERO]});handleClose()}}
        >
         {EDIT_ICON()}
         </Tooltip>
         </ListItemIcon>
         Edit
        </MenuItem>
        <MenuItem className='red-menu-item'>
        <ListItemIcon>
        <Tooltip
        title="Delete"
        className='additional-deleted-icon'
        arrow
        placement="top"
        onClick={()=>{setDeleteAdditional({isOpen:true,id:row.additional_pay_ids[NUMBER.ZERO],type:row.additional_deduction_amt ? 'deduction':'bonus'});handleClose()}
        }>
        {BIN_ICON()}
        </Tooltip>
        </ListItemIcon>
        Delete
        </MenuItem>
    </Menu>
    </div>)

}
