import * as React from 'react';
import { Button, Checkbox, FormControlLabel, Menu, MenuItem, Typography ,FormGroup,Switch, Popover} from '@mui/material';
import { useState,useEffect,useRef,useMemo } from 'react';
import {
    FILTER_ICON,
    FILTER_ADD_ICON,
    CLOSE_ICON,
    BREADCRUMB_BACK_ICON,
    CHIP_CLOSE_ICON,
} from '../utils/Constants/ClassName';
import { FILTER_OPTIONS, USER_TYPE } from '../utils/Constants/ConstantData';
import { NUMBER } from '../utils/Constants/MagicNumber';
import { PROPAY_STATUS } from '../resources/propays';
import { useListContext, useTranslate, useGetList } from 'react-admin';
import CustomWorkerFilter from './CustomWorkerFilter';
import { getCustomFilterQuery } from '../components/fields';
import _ from 'lodash';
import CustomManagerFilter from './CustomManagerFilter';
import CustomJobFilter from './CustomJobFilter';
import CustomConditionalFilter from './CustomConditionalFilter';
import CustomHoursFilter from './CustomHoursFilter';
import { useGetIdentityOptimized } from '../components/identity';
import CustomCostCodeFilter from './CustomCostCodeFilter';
import CustomPropayFilter from './CustomPropayFilter';
import { CustomFilterContextProvider } from './CustomFilterContext';
import CustomStatusFilter from './CustomStatusFilter';
import { InfoLabel } from '../components/fields/InfoLabel';
import { PROPAY_CONSTANT } from '../constants';
import CustomConditionalDateFilter from './CustomConditionalDateFilter';
import CustomJobTypeFilter from './CustomJobTypeFilter';
import CustomInvoiceTypeFilter from './CustomInvoiceTypeFilter';
import CustomBranchFilter from './CustomBranchFilter';
import CustomDivisionFilter from './CustomDivisionFilter';

const CustomFilters = props => {
    const { setFilterQuery,connectorList=[],showLimitedFilter=false ,statusFilter,setStatusFilter} = props;
    const {perPage,page,filterValues,setPage,setPerPage,currentSort} = useListContext();
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorSub, setAnchorSub] = useState(null);
    const [subTitle, setSubTitle] = useState('');
    const [searchTag, setSearchTag] = useState([]);
    const [removedChip, setRemovedChip] = useState([]);
    const renderRef =  useRef(true);
      const open = Boolean(anchorEl);
    const { identity } = useGetIdentityOptimized();
    const hasJobTypeEnabled = connectorList.some(item => item?.support_options?.import_entities?.includes("job_type"))
    const hasInvoiceTypeEnabled = connectorList.some(item => item?.service === 'aspire' && item?.status === 'authorized')
    const { data: scheduleVisits } = useGetList('ScheduleVisit', {
      filter: {},
      pagination: { page: 1, perPage: 1 },
      sort: { field: 'id', order: 'DESC' },
    });
    const filterOptions = Object.values(FILTER_OPTIONS);
    const displayFilterOptions = filterOptions.filter((item) => {
      const filterIsLimited = props?.showLimitedFilter ? ['Workers','ProPays','Jobs','Started On','Ended On'].includes(item) : true;
      const isNotWorkerUserType = identity?.user_type !== USER_TYPE.worker || item !== FILTER_OPTIONS.worker;
      const canUseCostCodeOption = identity?.company?.can_use_cost_code || item !== FILTER_OPTIONS.cost_code;
      const isJobTypeFilterable = hasJobTypeEnabled || item !== FILTER_OPTIONS.job_type;
      const isInvoiceTypeFilterable = hasInvoiceTypeEnabled || item !== FILTER_OPTIONS.invoice_type;
      const isScheduleVisitFilterable = scheduleVisits?.length > NUMBER.ZERO || item !== FILTER_OPTIONS.schedule_visit;

      return filterIsLimited && isNotWorkerUserType && canUseCostCodeOption && isInvoiceTypeFilterable && isJobTypeFilterable && isScheduleVisitFilterable;
    });
    const checkNull = (value:string) => value === null;
    const getLocalStorageValues = (item:string)=>localStorage.getItem(item);
    const filterRef = useRef([]);
    const dateFilter =  getLocalStorageValues(PROPAY_CONSTANT.dateTimeFilter);


    useEffect(()=>{
        if(!showLimitedFilter){
       const prevAppliedFilters  = getLocalStorageValues(PROPAY_CONSTANT.appliedFilter);
       const selectedPerPage = getLocalStorageValues(PROPAY_CONSTANT.perPage);
       const selectedPage = getLocalStorageValues(PROPAY_CONSTANT.page);

      if(checkNull(prevAppliedFilters) && checkNull(getLocalStorageValues(PROPAY_CONSTANT.dateTimeFilter))){
        setSearchTag([{
            tag: FILTER_OPTIONS.status,
            name: 'open',
            count: NUMBER.ZERO,
            selected_ids: ['open'],
        }])
      }else{
          setSearchTag(JSON.parse(prevAppliedFilters));
      }
      !checkNull(selectedPerPage) && setPerPage(Number(selectedPerPage));
      !checkNull(selectedPage) && setPage(Number(selectedPage));
    }
      return()=>{
          if(!showLimitedFilter){
            localStorage.setItem(PROPAY_CONSTANT.perPage,filterRef?.current[NUMBER.ZERO]);
            localStorage.setItem(PROPAY_CONSTANT.page,filterRef?.current[NUMBER.ONE]);
            localStorage.setItem(PROPAY_CONSTANT.sort,filterRef?.current[NUMBER.TWO]);
          }
      }
    },[])

    useEffect(() => {
        if (searchTag.length > NUMBER.ZERO || !checkNull(getLocalStorageValues(PROPAY_CONSTANT.dateTimeFilter))) {
            setFilterQuery(getCustomFilterQuery(searchTag,connectorList,showLimitedFilter));
        } else {
            setFilterQuery({});
        }
        setRemovedChip(searchTag);
        !showLimitedFilter && localStorage.setItem(PROPAY_CONSTANT.appliedFilter,JSON.stringify(searchTag))
         setRemovedChip(searchTag);
    }, [searchTag]);

    useMemo(()=>{
     (filterValues.hasOwnProperty('from_date') && !showLimitedFilter) && localStorage.setItem(PROPAY_CONSTANT.dateTimeFilter,JSON.stringify(filterValues['from_date']));
     setFilterQuery(getCustomFilterQuery(searchTag,connectorList,showLimitedFilter));
    },[filterValues?.from_date,dateFilter])

    useEffect(()=>{
        filterRef.current  = [perPage.toString(),page.toString(),JSON.stringify(currentSort)];
    },[perPage,currentSort,page])

    useEffect(()=>{
 if(!showLimitedFilter){
    if(renderRef.current){
        renderRef.current = false;
        return;
    }
      const filterTag = searchTag.filter(item => item.tag !== FILTER_OPTIONS.status);
      const index = statusFilter ?  statusFilter.length - 1: -1;
      index >= 0 ? setSearchTag([...filterTag,{
        tag: FILTER_OPTIONS.status,
        name: statusFilter[index] === 'paid' ? 'Closed' : statusFilter[index],
        count: index > NUMBER.ZERO ? index : NUMBER.ZERO,
        selected_ids: statusFilter,
    }]):setSearchTag(filterTag)
        }
    },[statusFilter])

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleItemClick = title => {
        setAnchorSub(anchorEl);
        setAnchorEl(null);
        setSubTitle(title);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const debounceTimeout = React.useRef(null);
    const removeFilter = tag => {
        if(tag === FILTER_OPTIONS.status && setStatusFilter){
            setStatusFilter([]);
        }
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        const updateTags = removedChip.filter(el => el.tag !== tag);
        setRemovedChip(updateTags);
        debounceTimeout.current = setTimeout(() => {
            setSearchTag(updateTags);
        }, 1000);
    };
    const clearAll = () => {
        handleClose();
        setSearchTag([]);
        setStatusFilter &&  setStatusFilter([]);
    };
    const openSelectedFilter = (event,title)=>{
        if(title === FILTER_OPTIONS.propay_no_bonus){
            setAnchorEl(event.currentTarget);
            return;
        }
        setAnchorSub(event.currentTarget);
        setSubTitle(title);
    }
    const onSwitchToggle = (value)=>{
        const updateFilter = searchTag.filter(el=>el.tag !== FILTER_OPTIONS.propay_no_bonus)
        if(value){
           setSearchTag([...updateFilter,{tag: FILTER_OPTIONS.propay_no_bonus,name: 'YES',count: NUMBER.ZERO,selected_ids: ''}]);
        }else{
           setSearchTag(updateFilter);
        }
        handleClose();
    }
    const isChecked = ()=>{
        const currentTags = searchTag?.filter(el => el.tag === FILTER_OPTIONS.propay_no_bonus);
        return currentTags.length > NUMBER.ZERO
    }
    return (
        <>
        <CustomFilterContextProvider>
            <div className="propay-column-item filter-add-item">
                {FILTER_ICON()}
                Filter:
                <>
                    {removedChip.length > NUMBER.ZERO
                        ? removedChip.map(el => {
                              return (
                                  <>
                                    <div className="filter-chip">
                                      <Button
                                          className="filter-chip-button"
                                          key={el.name}
                                          onClick={(event) => openSelectedFilter(event,el.tag)}
                                      >
                                          <strong>{el.tag}:</strong> {el.name}{' '}
                                          {el.count > NUMBER.ZERO ? (
                                              <span className="filter-chip-plus">
                                                  +{el.count}
                                              </span>
                                          ) : (
                                              ''
                                          )}{' '}
                                      </Button>
                                      <Button
                                              onClick={() =>
                                                  removeFilter(el.tag)
                                              }
                                          >
                                              {CHIP_CLOSE_ICON()}
                                      </Button>
                                      </div>
                                  </>
                              );
                          })
                        : ''}
                    {removedChip.length > NUMBER.TWO && (
                        <Button
                            className="clear-filter-all"
                            onClick={() => clearAll()}
                        >
                            Clear All
                        </Button>
                    )}
                </>
                {displayFilterOptions.length !== removedChip.length && <Button
                    className="filter-add-btn"
                    onClick={handleClick}
                    aria-controls={open ? 'card-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    {FILTER_ADD_ICON()}
                    Add
                </Button>}
            </div>
            <Menu
                anchorEl={anchorEl}
                id="filter-menu"
                className="filter-dropdown"
                open={open}
                onClose={handleClose}
                disableScrollLock={true}
                PaperProps={{
                    elevation: NUMBER.ZERO,
                    sx: {
                        overflow: 'visible',
                        borderWidth: '1px 0px 0px 1px',
                        mt: 1.5,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: -1,
                            left: NUMBER.FIFTEEN,
                            width: NUMBER.FIFTEEN,
                            height: NUMBER.FIFTEEN,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: NUMBER.ZERO,
                            borderWidth: '1px 0px 0px 1px',
                            borderColor: '#B8C0C6',
                            borderStyle: 'solid',
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
            >
                {' '}
                <div className="filter-head">
                    Filters
                    <button className="close-filter" onClick={handleClose}>
                        {CLOSE_ICON()}
                    </button>
                </div>
                <div className="filter-parent-scroll filter-custom-scroll">
                    {displayFilterOptions.map(item => {
                        return (
                            <>
                            { item !== FILTER_OPTIONS.propay_no_bonus ? <MenuItem
                                key={item}
                                onClick={() => handleItemClick(item)}
                            >
                                {item}
                                <button className="filter-arrow">Arrow</button>
                            </MenuItem>:
                              <MenuItem>{item}
                              <InfoLabel sx={{ height: 10 }} icon='ri:error-warning-fill' height={12}>
                              <Typography sx={{ fontSize: 10 }}>
                              Toggle will show all jobs with ProPays that are not yet matched.
                              </Typography>
                            </InfoLabel>
                            <FormGroup className="custom-switch">
                                <Switch onChange={(e, val) => onSwitchToggle(val)} checked={isChecked()}/>
                            </FormGroup>
                            </MenuItem>
                             }
                            </>
                            // <MenuItem
                            //     key={item}
                            //     onClick={() => handleItemClick(item)}
                            // >
                            //     {item}
                            //     <button className="filter-arrow">Arrow</button>
                            // </MenuItem>
                        );
                    })}
                </div>
            </Menu>
            <SubMultiFilter
                anchorSub={anchorSub}
                showLimitedFilter={false}
                setSearchTag={setSearchTag}
                searchTag={searchTag}
                setAnchorSub={setAnchorSub}
                subTitle={subTitle}
                setAnchorEl={setAnchorEl}
                identity={identity}
                removeFilter={removeFilter}
                setStatusFilter={setStatusFilter}
            />
            </CustomFilterContextProvider>
        </>
    );
};

export default  React.memo(CustomFilters);

export const SubMultiFilter = props => {
    const {
        anchorSub,
        setAnchorSub,
        subTitle,
        setAnchorEl,
        setSearchTag,
        searchTag,
        identity,
        removeFilter,
        showLimitedFilter,
        setStatusFilter
    } = props;
    const isOpen = Boolean(anchorSub);
    const hoursOption = [FILTER_OPTIONS.hours_worked,FILTER_OPTIONS.budget_hours, FILTER_OPTIONS.variance_hours]

    const closeSubFilter = () => {
        setAnchorSub(null);
    };

    const handleBack = () => {
        setAnchorEl(anchorSub);
        setAnchorSub(null);
    };

    const updateSearchTag = (newTags, element) => {
      const emptyTags = ['Status','ProPays','Workers','Manager','Jobs','Cost Codes'];
        const SelectedTag = element[NUMBER.ZERO];
        if(SelectedTag.tag === FILTER_OPTIONS.status && setStatusFilter){
            setStatusFilter(SelectedTag.selected_ids)
        }
        if(emptyTags.includes(SelectedTag.tag) && !SelectedTag.selected_ids.length){
            removeFilter(SelectedTag.tag);
            return;
        }
        if (newTags.length > NUMBER.ZERO) {
            setSearchTag(_.concat(newTags, element));
        } else {
            setSearchTag(element);
        }
    };

const isPrevChecked = (val,filterOption,tempState)=> {
    const values = searchTag.length >  NUMBER.ZERO ? searchTag?.filter(element=>element.tag === filterOption):[];
    return values.length > NUMBER.ZERO ? values[NUMBER.ZERO].selected_ids.includes(val) || tempState.includes(val) : tempState.includes(val);
  }

  const checkHours = ()=> hoursOption.includes(subTitle) && identity?.company?.hours_format === 'by_time';
  const propayStatusOptions = PROPAY_STATUS.filter((item)=>{
    if ((item?.id === 'started' && !identity?.company?.is_started_status) ||
            (item?.id === 'completed' && !identity?.company?.is_completed_status)) {
            return false;
        }
        return true;
  })

    return (
        <>
            <Popover
                anchorEl={anchorSub}
                id="filter-sub-menu"
                className="filter-dropdown"
                open={isOpen}
                onClose={closeSubFilter}
                disableScrollLock={true}
                PaperProps={{
                    elevation: NUMBER.ZERO,
                    style: {
                        maxHeight: '100%',
                      },
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: NUMBER.ZERO,
                            left: NUMBER.FIFTEEN,
                            width: NUMBER.FIFTEEN,
                            height: NUMBER.FIFTEEN,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: NUMBER.ZERO,
                            borderWidth: '1px 0px 0px 1px',
                            borderColor: '#B8C0C6',
                            borderStyle: 'solid',
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
            >
                <MenuItem className="filter-menu-item">
                    <div className="filter-breadcrumbs">
                        <button
                            className="breadcrumb-back"
                            onClick={() => handleBack()}
                        >
                            {BREADCRUMB_BACK_ICON()}
                        </button>
                        <span className="breadcrumb-text">
                            <span onClick={() => handleBack()}>Filters</span> / <strong>{subTitle}</strong>
                        </span>
                        <Button
                            className="close-filter"
                            onClick={() => closeSubFilter()}
                        >
                            {CLOSE_ICON()}
                        </Button>
                    </div>
                </MenuItem>
                {!showLimitedFilter && subTitle === FILTER_OPTIONS.status ? <CustomStatusFilter setSearchTag={setSearchTag}
                        searchTag={searchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                        isPrevChecked={isPrevChecked}
                        statusOptions={propayStatusOptions}
                        subTitle={subTitle}
                      />:''}
                {!showLimitedFilter && (subTitle === FILTER_OPTIONS.budget || subTitle === FILTER_OPTIONS.variance_amount || (hoursOption.includes(subTitle) && identity?.company?.hours_format === 'by_decimal')) ? (
                    <CustomConditionalFilter
                        setSearchTag={setSearchTag}
                        searchTag={searchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                        filterOption={subTitle}
                    />
                ) : (
                    ''
                )}
                {subTitle === FILTER_OPTIONS.worker ? (
                    <CustomWorkerFilter
                        setSearchTag={setSearchTag}
                        searchTag={searchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                        isPrevChecked={isPrevChecked}
                    />
                ) : (
                    ''
                )}
              {subTitle === FILTER_OPTIONS.job_type ? (
                <CustomJobTypeFilter
                  setSearchTag={setSearchTag}
                  searchTag={searchTag}
                  closeSubFilter={closeSubFilter}
                  updateSearchTag={updateSearchTag}
                  isPrevChecked={isPrevChecked}
                  identity={identity}
                />
              ) : (
                  ''
              )}

            {subTitle === FILTER_OPTIONS.invoice_type ? (
                <CustomInvoiceTypeFilter
                  setSearchTag={setSearchTag}
                  searchTag={searchTag}
                  closeSubFilter={closeSubFilter}
                  updateSearchTag={updateSearchTag}
                  isPrevChecked={isPrevChecked}
                  identity={identity}
                />
              ) : (
                  ''
              )}

                {subTitle === FILTER_OPTIONS.started_on ? (
                    <CustomConditionalDateFilter
                        searchTag={searchTag}
                        setSearchTag={setSearchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                        filterOption={subTitle}
                    />
                ) : (
                    ''
                )}
                {subTitle === FILTER_OPTIONS.schedule_visit ? (
                  <CustomConditionalDateFilter
                    searchTag={searchTag}
                    setSearchTag={setSearchTag}
                    closeSubFilter={closeSubFilter}
                    updateSearchTag={updateSearchTag}
                    filterOption={subTitle}
                  />
                ) : (
                  ''
                )}
                {subTitle === FILTER_OPTIONS.ended_on ? (
                    <CustomConditionalDateFilter
                        searchTag={searchTag}
                        setSearchTag={setSearchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                        filterOption={subTitle}
                />
                ) : (
                    ''
                )}

                {!showLimitedFilter && subTitle === FILTER_OPTIONS.cost_code ? (
                    <CustomCostCodeFilter
                        setSearchTag={setSearchTag}
                        searchTag={searchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                    />
                ) : (
                    ''
                )}
                {!showLimitedFilter && subTitle === FILTER_OPTIONS.manager ? (
                    <CustomManagerFilter
                        setSearchTag={setSearchTag}
                        searchTag={searchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                        isPrevChecked={isPrevChecked}
                    />
                ) : (
                    ''
                )}
                {subTitle === FILTER_OPTIONS.job ? (
                    <CustomJobFilter
                        setSearchTag={setSearchTag}
                        searchTag={searchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                        isPrevChecked={isPrevChecked}
                    />
                ) : (
                    ''
                )}
                {!showLimitedFilter && checkHours() ? (
                    <CustomHoursFilter
                        setSearchTag={setSearchTag}
                        searchTag={searchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                        filterOption={subTitle}
                    />
                ) : (
                    ''
                )}
                {subTitle === FILTER_OPTIONS.propay ? (
                    <CustomPropayFilter
                        setSearchTag={setSearchTag}
                        searchTag={searchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                        isPrevChecked={isPrevChecked}
                    />
                ) : (
                    ''
                )}
                 { subTitle === FILTER_OPTIONS.branches && (
                    <CustomBranchFilter
                        setSearchTag={setSearchTag}
                        searchTag={searchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                    />
                ) }
                { subTitle === FILTER_OPTIONS.division && (
                    <CustomDivisionFilter
                        setSearchTag={setSearchTag}
                        searchTag={searchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                    />
                )}
            </Popover>
        </>
    );
};