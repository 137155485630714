import * as React from 'react';
import { Button, Checkbox, FormControlLabel, Menu, MenuItem, Typography, FormGroup,Switch, Popover } from '@mui/material';
import { useState,useEffect,useRef,useMemo } from 'react';
import {
    FILTER_ICON,
    FILTER_ADD_ICON,
    CLOSE_ICON,
    BREADCRUMB_BACK_ICON,
    CHIP_CLOSE_ICON,
} from '../../utils/Constants/ClassName';
import { useGetIdentityOptimized } from '../../components/identity';
import { EMPLOYEE_FILTER_OPTION } from '../../utils/Constants/ConstantData';
import { useListContext } from 'react-admin';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import CustomSubFilterList from '../../layout/CustomSubFilterList';
import _ from 'lodash';
import CustomConditionalFilter from '../../layout/CustomConditionalFilter';
import CustomStatusFilter from '../../layout/CustomStatusFilter';
import CustomDateFilter from '../../layout/CustomDateFilter';
import { getTeamCustomFilterQuery } from '../../components/fields';
import CustomStaticDataFilter from '../../layout/CustomStaticDataFilter';
import CustomGroupDataFilter from '../../layout/CustomGroupDataFilter';
import CustomBranchFilter from '../../layout/CustomBranchFilter';
import CustomDivisionFilter from '../../layout/CustomDivisionFilter';

export const RoleOptions = [{id:'admin',name:'Admin'},{id:'manager',name:'Manager'},{id:'crew_lead',name:'Crew Lead'},{id:'worker',name:'Worker'}]
export const StatusOptions = [{id:'active',name:'Active',parent_id:'',has_children:true},{id:'invited',name:'Invite Sent',parent_id:'active',has_children:false},{id:'draft',name:'Not yet Invited',parent_id:'active',has_children:false},{id:'in_active',name:'Inactive',parent_id:'',has_children:false}]
   

const CustomEmployeeFilters = props => {
    const { setEmployeeFilter,defaultFilter } = props;
    const {perPage,page,filterValues,setPage,setPerPage,currentSort} = useListContext();
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorSub, setAnchorSub] = useState(null);
    const [subTitle, setSubTitle] = useState('');
    const [searchTag, setSearchTag] = useState([]);
    const [removedChip, setRemovedChip] = useState([]);
    const open = Boolean(anchorEl);
    const { identity } = useGetIdentityOptimized();
    const filterOptions = Object.values(EMPLOYEE_FILTER_OPTION);
    const checkNull = (value:string) => value === null || value === undefined;
    const getLocalStorageValues = (item:string)=>localStorage.getItem(item);
    const filterRef = useRef([]);
    const dateFilter = getLocalStorageValues('teamFromDateFilter')

    useEffect(()=>{
       const prevAppliedFilters  = getLocalStorageValues('teamAppliedFilters');
       const appliedFilter = JSON.parse(prevAppliedFilters);
       const prevSetting =  JSON.parse(getLocalStorageValues('teamPageSetting'))

       if(checkNull(prevAppliedFilters)){
        setSearchTag([{
            tag: EMPLOYEE_FILTER_OPTION.status,
            name: 'Active',
            count: NUMBER.ZERO,
            selected_ids: [
                "invited",
                "draft",
                "active"
              ]
        }])
       } else {
        setSearchTag(appliedFilter);
       };
    
      if(!checkNull(prevSetting)){
        setPerPage(Number(prevSetting.perPage));
        setPage(Number(prevSetting.page))
      }
      return()=>{
        const savedFilter = {
            perPage:filterRef?.current[NUMBER.ZERO],
            page:filterRef?.current[NUMBER.ONE],
            sort:filterRef?.current[NUMBER.TWO],
        }
            localStorage.setItem('teamPageSetting',JSON.stringify(savedFilter));
      }
    },[])

    useEffect(() => {
        setEmployeeFilter({...defaultFilter,...getTeamCustomFilterQuery(searchTag)});
        localStorage.setItem('teamAppliedFilters',JSON.stringify(searchTag));
        setRemovedChip(searchTag);
    }, [searchTag]);

    useEffect(()=>{
        filterRef.current  = [perPage.toString(),page.toString(),JSON.stringify(currentSort)];
    },[perPage,currentSort,page])

    useMemo(()=>{
        filterValues.hasOwnProperty('create_date') && localStorage.setItem('teamFromDateFilter',JSON.stringify(filterValues['create_date']));
        setEmployeeFilter(getTeamCustomFilterQuery(searchTag));
       },[filterValues?.create_date,dateFilter])

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleItemClick = title => {
        setAnchorSub(anchorEl);
        setAnchorEl(null);
        setSubTitle(title);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const debounceTimeout = React.useRef(null);
    const removeFilter = tag => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        const updateTags = removedChip.filter(el => el.tag !== tag);
        setRemovedChip(updateTags);
        debounceTimeout.current = setTimeout(() => {
            setSearchTag(updateTags);
        }, 1000);
    };
    const clearAll = () => {
        handleClose();
        setSearchTag([]);
    };
    const openSelectedFilter = (event,title)=>{
        setAnchorSub(event.currentTarget);
        setSubTitle(title);
    }

    return (
        <>
   
            <div className="propay-column-item filter-add-item">
                {FILTER_ICON()}
                Filter:
                <>
                    {removedChip.length > NUMBER.ZERO
                        ? removedChip.map(el => {
                              return (
                                  <>
                                    <div className="filter-chip">
                                      <Button
                                          className="filter-chip-button"
                                          key={el.name}
                                          onClick={(event) => openSelectedFilter(event,el.tag)}
                                      >
                                          <strong>{el.tag}:</strong> {el.name}{' '}
                                          {el.count > NUMBER.ZERO ? (
                                              <span className="filter-chip-plus">
                                                  +{el.count}
                                              </span>
                                          ) : (
                                              ''
                                          )}{' '}
                                      </Button>
                                      <Button
                                              onClick={() =>
                                                  removeFilter(el.tag)
                                              }
                                          >
                                              {CHIP_CLOSE_ICON()}
                                      </Button>
                                      </div>
                                  </>
                              );
                          })
                        : ''}
                    {removedChip.length > NUMBER.TWO && (
                        <Button
                            className="clear-filter-all"
                            onClick={() => clearAll()}
                        >
                            Clear All
                        </Button>
                    )}
                </>
                {filterOptions.length !== removedChip.length && <Button
                    className="filter-add-btn"
                    onClick={handleClick}
                    aria-controls={open ? 'card-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    {FILTER_ADD_ICON()}
                    Add
                </Button>}
            </div>
            <Menu
                anchorEl={anchorEl}
                id="filter-menu"
                className="filter-dropdown"
                open={open}
                onClose={handleClose}
                disableScrollLock={true}
                PaperProps={{
                    elevation: NUMBER.ZERO,
                    sx: {
                        overflow: 'visible',
                        borderWidth: '1px 0px 0px 1px',
                        mt: 1.5,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: -1,
                            left: NUMBER.FIFTEEN,
                            width: NUMBER.FIFTEEN,
                            height: NUMBER.FIFTEEN,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: NUMBER.ZERO,
                            borderWidth: '1px 0px 0px 1px',
                            borderColor: '#B8C0C6',
                            borderStyle: 'solid',
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
            >
                {' '}
                <div className="filter-head">
                    Filters
                    <button className="close-filter" onClick={handleClose}>
                        {CLOSE_ICON()}
                    </button>
                </div>
                <div className="filter-parent-scroll filter-custom-scroll">
                    {filterOptions.map(item => {
                        return (
                             <MenuItem
                                key={item}
                                onClick={() => handleItemClick(item)}
                            >
                                {item}
                                <button className="filter-arrow">Arrow</button>
                            </MenuItem>

                        );
                    })}

                </div>
            </Menu>
             <SubMultiFilter
                anchorSub={anchorSub}
                setSearchTag={setSearchTag}
                searchTag={searchTag}
                setAnchorSub={setAnchorSub}
                subTitle={subTitle}
                setAnchorEl={setAnchorEl}
                identity={identity}
                removeFilter={removeFilter}
            /> 
        </>
    );
};

export default  React.memo(CustomEmployeeFilters);

export const SubMultiFilter = props => {
    const {
        anchorSub,
        setAnchorSub,
        subTitle,
        setAnchorEl,
        setSearchTag,
        searchTag,
        identity,
        removeFilter
    } = props;
    const isOpen = Boolean(anchorSub);
    const statusFilter = searchTag.length > NUMBER.ZERO && searchTag?.filter(element=>element.tag === EMPLOYEE_FILTER_OPTION.status);
    const prevSelected = statusFilter.length > NUMBER.ZERO ? statusFilter[0].selected_ids : [];
    const [status, setStatus] = useState(prevSelected);
    const closeSubFilter = () => {
        setAnchorSub(null);
        setStatus([])
    };
    useEffect(()=>{
        setStatus(prevSelected)
    },[prevSelected.length])

    const handleBack = () => {
        setAnchorEl(anchorSub);
        setAnchorSub(null);
    };

    
    const updateSearchTag = (newTags, element) => {
      const emptyTags = ['Status','ProPay','Worker','Manager','Job','Cost Codes'];
        const SelectedTag = element[NUMBER.ZERO];
        if(emptyTags.includes(SelectedTag.tag) && !SelectedTag.selected_ids.length){
            removeFilter(SelectedTag.tag);
            return;
        }
        if (newTags.length > NUMBER.ZERO) {
            setSearchTag(_.concat(newTags, element));
        } else {
            setSearchTag(element);
        }
    };


const isPrevChecked = (val,filterOption,tempState)=> {
    const values = searchTag.length >  NUMBER.ZERO ? searchTag?.filter(element=>element.tag === filterOption):[];
    return values.length > NUMBER.ZERO ? values[NUMBER.ZERO].selected_ids.includes(val) || tempState.includes(val) : tempState.includes(val);
  }

    return (
        <>
            <Popover
                anchorEl={anchorSub}
                id="filter-sub-menu"
                className="filter-dropdown"
                open={isOpen}
                onClose={closeSubFilter}
                disableScrollLock={true}
                PaperProps={{
                    elevation: NUMBER.ZERO,
                    style: {
                        maxHeight: '100%',
                      },
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: NUMBER.ZERO,
                            left: NUMBER.FIFTEEN,
                            width: NUMBER.FIFTEEN,
                            height: NUMBER.FIFTEEN,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: NUMBER.ZERO,
                            borderWidth: '1px 0px 0px 1px',
                            borderColor: '#B8C0C6',
                            borderStyle: 'solid',
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
            >
                <MenuItem className="filter-menu-item">
                    <div className="filter-breadcrumbs">
                        <button
                            className="breadcrumb-back"
                            onClick={() => handleBack()}
                        >
                            {BREADCRUMB_BACK_ICON()}
                        </button>
                        <span className="breadcrumb-text">
                            <span onClick={() => handleBack()}>Filters</span> / <strong>{subTitle}</strong>
                        </span>
                        <Button
                            className="close-filter"
                            onClick={() => closeSubFilter()}
                        >
                            {CLOSE_ICON()}
                        </Button>
                    </div> 
                </MenuItem>
                {subTitle === EMPLOYEE_FILTER_OPTION.name ? (
                    <CustomSubFilterList 
                    setSearchTag={setSearchTag}
                    searchTag={searchTag}
                    closeSubFilter={closeSubFilter}
                    updateSearchTag={updateSearchTag}
                    isPrevChecked={isPrevChecked}
                    statusOptions={StatusOptions}
                    resource='Employee__FilterList'
                    resourceFilterQuery={{active: {_eq: true}, show_in_list: {_eq: true}}}
                    searchKey='display_name'
                    subTitle={subTitle}
                  />
                ) : (
                    ''
                )}
                {subTitle === EMPLOYEE_FILTER_OPTION.email ? (
                    <CustomSubFilterList 
                    setSearchTag={setSearchTag}
                    searchTag={searchTag}
                    closeSubFilter={closeSubFilter}
                    updateSearchTag={updateSearchTag}
                    isPrevChecked={isPrevChecked}
                    statusOptions={StatusOptions}
                    resource='Employee__EmailFilterList'
                    resourceFilterQuery={{active: {_eq: true}, show_in_list: {_eq: true},email: {_is_null: false, _neq: ""}}}
                    searchKey='email'
                    subTitle={subTitle}
                  />
                ) : (
                    ''
                )}
                {subTitle === EMPLOYEE_FILTER_OPTION.mobile ? (
                    <CustomSubFilterList 
                    setSearchTag={setSearchTag}
                    searchTag={searchTag}
                    closeSubFilter={closeSubFilter}
                    updateSearchTag={updateSearchTag}
                    isPrevChecked={isPrevChecked}
                    statusOptions={StatusOptions}
                    resource='Employee__EmailFilterList'
                    resourceFilterQuery={{active: {_eq: true}, show_in_list: {_eq: true},mobile_number: {_is_null: false, _neq: ""}}}
                    searchKey='mobile_number'
                    subTitle={subTitle}
                  />
                ) : (
                    ''
                )}
                {subTitle === EMPLOYEE_FILTER_OPTION.wage ? (
                    <CustomConditionalFilter
                    setSearchTag={setSearchTag}
                    searchTag={searchTag}
                    closeSubFilter={closeSubFilter}
                    updateSearchTag={updateSearchTag}
                    filterOption={subTitle}
                />
                ) : (
                    ''
                )}
                 {subTitle === EMPLOYEE_FILTER_OPTION.create_date ? (
                    <CustomDateFilter
                    searchTag={searchTag}
                    setSearchTag={setSearchTag}
                    closeSubFilter={closeSubFilter}
                    updateSearchTag={updateSearchTag}
                    selectedTag={subTitle}
                />
                ) : (
                    ''
                )}
                {(subTitle === EMPLOYEE_FILTER_OPTION.status) ? <CustomGroupDataFilter setSearchTag={setSearchTag}
                        searchTag={searchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                        isPrevChecked={isPrevChecked}
                        subTitle={subTitle}
                        listOptions={StatusOptions}
                      />:''}
                      {(subTitle === EMPLOYEE_FILTER_OPTION.role) ? <CustomStatusFilter setSearchTag={setSearchTag}
                        searchTag={searchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                        isPrevChecked={isPrevChecked}
                        subTitle={subTitle}
                        statusOptions={RoleOptions}
                      />:''}
                      { subTitle === EMPLOYEE_FILTER_OPTION.branches && (
                    <CustomBranchFilter
                        setSearchTag={setSearchTag}
                        searchTag={searchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                    />
                        )}
                      { subTitle === EMPLOYEE_FILTER_OPTION.division && (
                    <CustomDivisionFilter
                        setSearchTag={setSearchTag}
                        searchTag={searchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                    />
                        )}
            </Popover>
        </>
    );
};
