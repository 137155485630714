/* eslint-disable @typescript-eslint/no-unused-vars */
import { styled, useTheme } from '@mui/material/styles';
import  { useEffect, useRef, useState } from 'react';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, IconButton, Stack, Menu, useMediaQuery, Theme, Toolbar, MenuItem, ListItemIcon } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import {
    AutocompleteArrayInput,
 FunctionField,MenuItemLink, ReferenceInput, ResourceContextProvider, SelectInput, useDataProvider, useListContext, useNotify, useTranslate, useUnselectAll,
} from 'react-admin';
import { DefaultDatagrid } from '../../components/fields';
import { Datagrid } from '../../components/datagrid';
import { AutocompleteInput } from '../../components/fields/inputs';
import { useIdentityContext } from '../../components/identity';
import { useGetBaseLocationForCurrentRoute } from '../../hooks/useGetBaseLocationForCurrentRoute';
import { CreateButton } from '../../layout/CreateButton';
import { List } from '../../layout/List';
import { FilterGusser, ResponsiveFilterGusser } from '../../layout/ResponsiveFilter';
import { HasBackendNotConnected } from '../company/company';
import {  DownloadBonusOtReport, HasPermission, PayrollReport } from '../payrolls/Payrolls';
import ReferenceArrayInput from '../../components/fields/ReferenceArrayInput';
import { Helmet } from 'react-helmet-async';
import { ExportButton } from 'react-admin';
import { ADD_BONUSES_ICON, ADD_DEDUCTION_ICON, ARROW_READ_LESS, ARROW_READ_MORE, ARROW_UP_02, BONUS_EXPORT_VIEW_ICON, BONUS_REPORT_ICON, CLOSE_FILL_ICON, CLOSE_ICON, EDIT_ICON_02, EXPORT_ICON, MERGE_STATEMENT_ICON, NO_PAID_PERIOD_CALENDER_ICON, PAYROLL_EXPORT_ICON, PAYROLL_REPORT_ICON, TICK_ICON_FILLED, TIME_ICON_02, VISIT_CALENDER_ICON } from '../../utils/Constants/ClassName';
import { PayBonus } from '../payrolls/BonusPayrolls';
import BonusStatementDetails from '../payrolls/BonusStatementDetails';
import { BonusReportExporter } from '../payrolls/BonusReportExporter';
import usePayrollReport from '../payrolls/usePayrollReport';
import { CONSTANT_DATA, formatter, USER_TYPE } from '../../utils/Constants/ConstantData';
import BonusStatementAction from './BonusStatementAction';
import Empty from '../../layout/Empty';
import { PROPAY_STATUS } from '../../constants';
import { useNavigate } from 'react-router-dom';
import { CustomBonusSearchInput } from '../attendances/AttendanceFilterModal';
import { utils, writeFile } from 'xlsx';
import StatusField from '../../layout/StatusField';
import BonusExpandedView from '../payrolls/BonusStatementDetails';
import CustomBonusFilter from '../../layout/CustomBonusFilter';
import { DateRangeInputFilter } from '../../components/fields/DateRangeInputFilter';
import StatementActionList from './StatementActionList';
import MobileViewFiter from './MobileViewFiter';
import DialogForm from '../../components/DialogForm';
import { DeactivatedIcon, NotificationIcon } from '../employees/Employee';
import { DateFormat } from '../../components/fields/DateFormat';
import BonusStatementResponsive from './BonusStatementResponsive';
import BonusShimmer from '../payrolls/BonusShimmer';
import { BonusStatementModalDetail } from './BonusStatementModalDetail';
import BonusIndicators from './BonusIndicators';
import { NoStatementPeriod } from '../../utils/Constants/CommonView';
import { BonusListAction } from './BonusListActions';
const StyledAutocompleteInputLarge = styled(AutocompleteInput)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        'min-width': 'unset',
    },
    [theme.breakpoints.up('sm')]: {
        'min-width': '200px',
    }
}));

const StyledReferenceArrayInput = styled(ReferenceArrayInput)({
    minWidth: '150px',
});

const StyledSelectInput = styled(SelectInput)({
    'min-width': '150px',
});

export const ATTENDANCE_STATUS = [
    { id: 'pending', name: 'resources.attendances.choices.status.pending' },
    { id: 'paid', name: 'Closed'},
];

export const BonusFilter = [
    <CustomBonusSearchInput
    className="filter-search"
    source="name._ilike"
    label="resources.propays.search"
    size="medium"
    alwaysOn
    alwaysOnMobile
    />,
    <ReferenceInput
        size='medium'
        source='statement_period_id._eq'
        reference='ProtivStatementPeriod__DropdownList'
        label='Statement Period'
        alwaysOn
        alwaysOnMobile
    >
        <StyledAutocompleteInputLarge source='name' />
    </ReferenceInput>,
     <ReferenceInput
     size='medium'
     source="paid_period_id._in"
     reference="Period__DropdownList"
     label='Paid Period'
     alwaysOn
     alwaysOnMobile
     sort={{ field: 'start_date', order: 'DESC' }}
     perPage={NUMBER.HUNDRED}
     >
     <StyledAutocompleteInputLarge source='name' />
    </ReferenceInput>,
    <StyledReferenceArrayInput
        size='medium'
        filter={{active: {_eq: true}}}
        source='employee_id._in'
        reference='Employee__DropdownList'
        label='Worker'
        alwaysOn
    >
        <AutocompleteArrayInput source='name'/>
    </StyledReferenceArrayInput>,
    <StyledSelectInput
    size='medium'
    source='status._eq'
    label='Status'
    choices={ATTENDANCE_STATUS}
    alwaysOn
    />,
    // <ReferenceInput
    //     source='propay_id._eq'
    //     reference='propays'
    //     label={'ProPay'}
    //     size='medium'
    //     alwaysOn
    // >
    // <StyledAutocompleteInputLarge />
    // </ReferenceInput>,
];
export const BonusFilterOptions = [
    <CustomBonusSearchInput
    className="filter-search"
    source="name._ilike"
    label="resources.propays.search"
    size="medium"
    alwaysOn
    alwaysOnMobile
    name='bonus'
    />,
    <DateRangeInputFilter
    className="date-input-filter"
    source="statement_period_id_obj.start_date"
    pageType='bonus'
    alwaysOn
/>
];
export const CloseStatementsMenu = (props) => {
    const { total,filterValues,currentSort } = useListContext();
    const {identity,setBulkAction,currentFilter,isSmall} = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate()
    const dataProvider = useDataProvider();
    const notify = useNotify()
    const [showBonusReport,setShowBonusReport] = useState(false);
    const [showPayrollReport,setShowPayrollReport] = useState(false);
    useEffect(()=>{},[showBonusReport,showPayrollReport])
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const closeMenu = () => {
      setAnchorEl(null);
    };
    const navigateTo =(url)=>{
        navigate(url)
    }
    function capitalizeFirstLetter(text: string) {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    const handleExportView = async() => {
        if(!total){
            notify('No data to export')
            return;
        }
        Object.assign(currentFilter, filterValues);
        const Heading = [["Ref #", "Name", "Statement Period","Paid Period", "Close Date", "Earning","Status"],
          ];
          const listData =await dataProvider.getList('ProtivEmployeeBonusStatement__ExportList', {
            sort: currentSort,
            pagination: { page: NUMBER.ONE, perPage: total },
            filter: currentFilter})

            const dataToDowload = listData.data.map(item=>{
                return {
                    'num':item.number,
                    'name':item.employee_id_obj.display_name,
                    'statement':item.statement_period_id_obj.display_name,
                    'paid_period':item.paid_period ? item.paid_period_id_obj.display_name:'',
                    'close_date': item.closed_statement_date === false ? '' : item.closed_statement_date,
                    'earning':item.total_earning,
                    'status':item.status === 'paid' ? 'Closed' : capitalizeFirstLetter(item.status)}
            })
        const ws = utils.json_to_sheet(dataToDowload);
        for (let i = 2; i <= dataToDowload.length + 1; i++) { 
         ws[`E${i}`].z = '"$"#,##0.00';
        } 
        const wb = utils.book_new();
        utils.sheet_add_aoa(ws, Heading);
        utils.book_append_sheet(wb, ws, 'List');
        writeFile(wb, 'Export.xlsx');
      };
    return (
        <div>
            <Button
                id="bonus-action-menu"
                className='filter-actions-button'
                aria-controls={open ? 'bonus-action-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <svg width="4" height="18" viewBox="0 0 4 18" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 4.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4zM2 11a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 6.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" fill-rule="evenodd" />
                </svg>
            </Button>
            <Menu
                id="bonus-action-menu"
                anchorEl={anchorEl}
                open={open}
                className='bonus-action-menu'
                onClose={closeMenu}
                disableScrollLock={true}
                MenuListProps={{
                    'aria-labelledby': 'bonus-action-menu',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >

                <HasPermission resource='payrolls' action='export_payroll'>
                <MenuItem onClick={()=>{setShowBonusReport(true)}}>
                    <ListItemIcon className='bonus-icon-stroke'>
                    {BONUS_REPORT_ICON()}
                    </ListItemIcon>
                    Bonus Report
                </MenuItem>

                <MenuItem onClick={()=>setShowPayrollReport(true)}>
                    <ListItemIcon className='bonus-icon-fill'>
                        {PAYROLL_REPORT_ICON()}
                    </ListItemIcon>
                    Payroll Report
                </MenuItem>
                </HasPermission>
                <MenuItem onClick={()=>{
                    handleExportView()
                    closeMenu();}}>
                    <ListItemIcon className='bonus-icon-fill'>
                        {BONUS_EXPORT_VIEW_ICON()}
                    </ListItemIcon>
                    Export View
                </MenuItem>

                {(filterValues?.status?._eq !== 'paid' && total && identity?.user_type !== USER_TYPE.worker && !isSmall) ?
                <>
                <MenuItem className='bulk-action-divider' disableRipple>Bulk Actions:</MenuItem>
                <MenuItem onClick={()=>{setBulkAction('Close');closeMenu()}}>
                <ListItemIcon className='bonus-icon-fill'>
                        {CLOSE_FILL_ICON()}
                    </ListItemIcon> Close Statements
                </MenuItem>
                <MenuItem onClick={()=>{setBulkAction('Add Bonuses');closeMenu()}}>
                    <ListItemIcon className='add-icon-bonus'>
                        {ADD_BONUSES_ICON()}
                    </ListItemIcon>
                    Add Bonuses
                </MenuItem>
                <MenuItem onClick={()=>{setBulkAction('Add Deduction');closeMenu()}}>
                    <ListItemIcon className='add-icon-bonus'>
                        {ADD_DEDUCTION_ICON()}
                    </ListItemIcon>
                    Add Deduction
                </MenuItem>
                <MenuItem onClick={()=>{setBulkAction('Merge');closeMenu()}}>
                    <ListItemIcon className='bonus-icon-fill'>
                        {MERGE_STATEMENT_ICON()}
                    </ListItemIcon>
                    Merge Statements
                </MenuItem>
                <MenuItem className='mark-pending-action' onClick={()=>{setBulkAction('Mark As Pending');closeMenu()}}>
                    <ListItemIcon className='bonus-icon-fill'>
                        {TICK_ICON_FILLED()}
                    </ListItemIcon>
                    Mark As Pending
                </MenuItem>
                </>:''}

        </Menu>
        <DialogForm open={showBonusReport || showPayrollReport} title={'Generate Report'} onClose={() => { setShowBonusReport(false); setShowPayrollReport(false); closeMenu() }}>
          {showBonusReport ? <DownloadBonusOtReport onClose={() => setShowBonusReport(false)} /> : showPayrollReport && <PayrollReport onClose={() => { setShowPayrollReport(false) }} />}
        </DialogForm>
        </div>
    );
  }
export const BonusAttendanceList = (props: any) => {
    const identities = useIdentityContext();
    const [bonusFilterQuery,setBonusFilterQuery]=useState({})
    const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
);
const [bulkAction,setBulkAction]= useState('');
const [hideAction,setHideAction]= useState(true);
const [bonusFilter,setBonusFilter]=useState({})
const [isLoading,setIsLoading]=useState(true);
const [showDetail,setShowDetail]=useState({open:false,id:0});
const [bonusStatusFilter,setBonusStatusFilter]=useState([])
const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    });

useEffect(() => {
    document.body.classList.add("bonus-body");
    return () => {
    document.body.classList.remove("bonus-body");
    }
}, [])


    return (
     <ResourceContextProvider value="protivEmployeeBonusStatement">
        <div className='bonus-list-page'>
        <div className='time-filter-toolbar bonus-MuiPaper-root'>
            {isSmall ?
            <>
            {isLoading ? <BonusShimmer />:''}
            </>
            :''}
            <List
            title={'Bonus Statements'}
            empty={false}
            hideTitle={isSmall}
            resource='protivEmployeeBonusStatement'
            className='attendance-page bonus-data-grid-list'
            filters={isSmall ? <MobileViewFiter setBonusFilter={setBonusFilter} bonusFilter={bonusFilter}/> :<FilterGusser filters={BonusFilterOptions}/>}
            actions={false}
            disableSyncWithLocation={true}
            filterDefaultValues={{parent_id:{_is_null:true}}}
            filter={isSmall ? bonusFilter:bonusFilterQuery}
        >
            <>
            {isSmall ?
            <>
            <div className='bonus-res-header'>
            <div className='bonus-res-head'>
            Bonuses
            </div>
            <CloseStatementsMenu identity={identities} setBulkAction={setBulkAction} isSmall={isSmall} currentFilter={isSmall ? bonusFilter:bonusFilterQuery}/>
            <div className='bonus-res-paragraph'>
            Income earned from approved ProPays
            </div>
            </div>
            </>
            :''}

            {!isSmall ?
            <>
            <div className='propay-column-row'>
            <CustomBonusFilter setBonusFilterQuery={setBonusFilterQuery} bonusFilterQuery={bonusFilterQuery} bonusStatusFilter={bonusStatusFilter} setBonusStatusFilter={setBonusStatusFilter}/>
            <div className='bulk-actions-bonuses'>
                <div className='bulk-actions-right'>
                <span className='bulk-actions-text'>Actions:</span>
                <CloseStatementsMenu identity={identities} setBulkAction={setBulkAction} currentFilter={isSmall ? bonusFilter:bonusFilterQuery}/>
                {/* Will be adding functionality in future
                <Button className="bonus-action-edit">
                {EDIT_ICON_02()}
                 </Button> */}
                </div>
            </div>
            </div>
            <div className='bonus-ind-list'>
            <BonusIndicators identity={identities} filterQuery={bonusFilterQuery} bonusStatusFilter={bonusStatusFilter} setBonusStatusFilter={setBonusStatusFilter}/>
            </div>
            </>
            :''}
            
            {(bulkAction && hideAction) && <div className='close-statement-toolbar'>
                <div className='close-bulk-action-text'>
               <IconButton onClick={()=>{setBulkAction('');setHideAction(true)}} size="medium" className='cross-statement'>
                    <CLOSE_ICON />
                </IconButton>
                Select statements you wish to {bulkAction.toLowerCase()}
                <Button className='bulk-approve-button' disabled>{bulkAction} {bulkAction.includes('Close') ? 'Statements':''}</Button>
               </div>
               </div>}
               
            {
                isSmall ?  <BonusStatementResponsive identity={identities} setBonusFilter={setBonusFilter} bonusFilter={bonusFilter} setIsLoading={setIsLoading}/>:
            <div className='bonus-listing-new-scroll'>
            <Datagrid
            bulkActionButtons={bulkAction ? <BonusStatementAction setHideAction={setHideAction} hideAction={hideAction} setBulkAction={setBulkAction} bulkAction={bulkAction}/>: <StatementActionList />}
            optimized={true}
            stickyHeader
            isRowSelectable={() => identities?.user_type !== USER_TYPE.worker}
           
        >
            <FunctionField
                 label='Ref #'
                 source='number'
                 sortable
                 className='no-translate reference-td-th'
                 render={(record: any) => {
                     return (
                         <Stack direction='row' className='ref-stack-no' spacing={1}>
                            {record.number}
                         </Stack>)
                 }}
             />

             <FunctionField
                 source='employee_id'
                 sortable
                 label='Name'
                 className='no-translate'
                 render={(record: any) => {
                     return (
                         <Stack direction='row' className='bonus-worker-name-stack b-n-title' spacing={1} onClick={()=>setShowDetail({open:true,id:record.id})}>
                            {record.employee_id_obj.display_name}
                            <span>{record.employee_id_obj?.is_disable_all_notification ? <NotificationIcon /> :''}</span>
                            <span>{!record.employee_id_obj.active ? <DeactivatedIcon />:''}</span>
                         </Stack>)
                 }}
             />
             <FunctionField
                 source='statement_period_id'
                 label='Statement Period'
                 sortable
                 className='no-translate'
                 render={(record: any) => {
                     return (
                         <Stack direction='row' className='bonus-worker-name-stack b-s-p-stack' spacing={1}>
                            <span className='b-s-p-icon'>{VISIT_CALENDER_ICON()}</span>
                            {record.statement_period_id ? record.statement_period_id_obj.display_name:''}
                         </Stack>)
                 }}
             />
              <FunctionField
                 label='Paid Period'
                 source='paid_period_id'
                 sortable
                 className='no-translate'
                 render={(record: any) => {
                     return (
                         <Stack direction='row' className='bonus-worker-name-stack b-s-p-stack' spacing={1}>
                           
                            {record.paid_period_id ?  <span className='b-s-p-icon'>{VISIT_CALENDER_ICON()} {record.paid_period_id_obj.display_name}</span>  :<NoStatementPeriod isPending={false} />}
                         </Stack>
                        )
                 }}
             />

               <FunctionField
                 label='Close Date'
                 source='closed_statement_date'
                 render={record=><Stack direction='row' className='bonus-worker-name-stack b-s-p-stack' spacing={1}>{record.closed_statement_date ? <DateFormat date={record.closed_statement_date} /> :<NoStatementPeriod isPending={true}/>}</Stack> }
             />

             <FunctionField
                 label='Total Bonuses'
                 source='total_earning'
                 className='no-translate'
                 sortable
                 render={(record: any) => {
                     return (
                         <Stack direction='row' className='bonus-worker-name-stack' spacing={1}>
                            {formatter.format(record.total_earning)}
                         </Stack>
                        )
                 }}
             />
             <FunctionField
                 label='Status'
                 sortable
                 source='status'
                 className='no-translate'
                 render={(record: any) => {
                    return (<StatusField record={record} />)
                 }}
             />
               <FunctionField
                 label={'Actions'}
                 className='no-translate'
                 render={(record: any) => {
                    return (<BonusListAction  identity={identities} record={record} setShowDetail={setShowDetail}/>)
                 }}
             />
         </Datagrid>
        {showDetail.open ?  <BonusStatementModalDetail  showDetail={showDetail} handleClose={()=>setShowDetail({open:false,id:0})} identity={identities} bonusFilterQuery={bonusFilterQuery}/>:''}
         </div>
            }
                </>
                </List>
            <Helmet>
            <title>Bonuses</title>
        </Helmet>
        </div>
        </div>
    </ResourceContextProvider>
    )
};
