import { useContext, useEffect, useState } from 'react';
import {
    CRUD_UPDATE, useDataProvider, useMutation, useNotify,
    useRecordContext
} from 'react-admin';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { Confirm } from '../../components/Confirm';
import { ConfirmModal } from '../../components/ConfirmModal';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { POST } from '../../services/HttpService';
import { CustomFilterContext } from '../CustomFilterContext';
import { PROPAY_BULK_ACTION_API } from '../../utils/Constants/ConstantData';
import { useSnackbar } from 'notistack';
import { GENERAL_INFO } from '../../constants';

/* handling propay cancel action in this component */
const PropayCancelAction = (props:any) => {
    const {cancelPropay, cancelClose, route,isMilestone} = props;
    const navigate = useNavigate();
    const [OpenConfiromDialog, setOpenConfiromDialog] = useState(false);
    const [cancelAlertDialog, setCancelAlertDialog] = useState(false);
    const propayContext = useContext(CustomFilterContext);
    const dataProvider = useDataProvider();
    const {enqueueSnackbar} =  useSnackbar();
    const record = useRecordContext(props);
    const onSuccess = ()=> {
        queryClient.refetchQueries();
        route && navigate(-NUMBER.ONE);
    };

    const [mutate, { loading }] = useMutation();
    const queryClient = useQueryClient();
    const notify = useNotify();

    const handleCancelGroupPropay = ()=>{
        const payload={
            propay_ids:[record?.id],
            action_name:'cancel'
        }
      POST(PROPAY_BULK_ACTION_API.Cancel,payload).then((response:any)=>{
        setOpenConfiromDialog(false);
        getStatus(response?.bulk_sync_info_id);
        }).catch(error=>{
            notify(`Failure ! ${error.message}`,{ type: 'warning' })
        })
        notify(GENERAL_INFO.soonNotifiied);
    }

    const getStatus = (syncId)=>{
        const interval = setInterval(() => {
                  if(syncId){
                  dataProvider.getOne('protivBulkSyncInfo', { id: syncId }).then((res)=>{
                      if(res?.data?.status === 'success' && syncId){
                          if(record?.budget || record?.budget_hours){
                            setCancelAlertDialog(true);
                          }                
                          const result = JSON.parse(res?.data?.result);
                          propayContext?.setBulkActionResponse(result);
                          propayContext?.setShowActionAlert(true);
                          let message =`${res?.data?.success_count}/${res?.data?.success_count+res?.data?.failure_count} ProPay was cancelled.`;
                          propayContext?.setAlertMessage(message);
                          enqueueSnackbar(message,{variant:'success',key:'propay',autoHideDuration:6000});
                          clearInterval(interval);
                      }
                  })
              }
              }, 2000);
      }

    useEffect(() => {
        if(cancelPropay) {
                setOpenConfiromDialog(true);
        }
    },[cancelPropay]);

    const handleCancelNormalPropay = () => {
        mutate(
            {
                type: 'update',
                resource: 'propays',
                payload: { id: record.id, action: 'cancel_propay', action_name:'cancel', data: {} },
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: (result: any, variables: any = {}) => {
                    notify(`You have Successfully Cancelled ${isMilestone ? 'Milestone': record?.is_group_propay ? 'GroupProPay' : 'Propay'} .`, { type: 'info' });
                    if(record?.budget || record?.budget_hours){
                        setCancelAlertDialog(true);
                    }
                    setOpenConfiromDialog(false);
                },
                onFailure: error => {
                    notify(error.message, { type: 'warning' });
                },
            }
        );
    };

    const handleConfirm = () => {
        if(record?.is_group_propay){
            handleCancelGroupPropay();
        }else{
            handleCancelNormalPropay();
        }
    }

    const handleClose= () => {
        setOpenConfiromDialog(false);
        cancelClose(false);
    };

    const handleCancel = () => {
        cancelClose(false);
        mutate(
            {
                type: 'update',
                resource: 'propays',
                payload: {id: record.id, action:'actionSendPropayCancelledWorkerSms', data: {} }
            },
            {
              mutationMode: 'pessimistic',
              action: CRUD_UPDATE,
              onSuccess: (data: any, variables: any = {}) => {
                    onSuccess();
              },
              onFailure: (error) => {
                notify(`Failure ! ${error.message}`);
              },
            }
          );
    };
    return (
            <>
                <Confirm
                    isOpen={OpenConfiromDialog}
                    loading={loading}
                    title={`Cancel ${isMilestone ? 'Milestone': record?.is_group_propay ? 'GroupProPay' : 'Propay'} - ${record?.name}`}
                    content={`Are you sure you want to cancel this ${isMilestone ? 'Milestone': record?.is_group_propay ? 'GroupProPay' : 'Propay'}?`}
                    onConfirm={handleConfirm}
                    onClose={() => handleClose()}
                    />
                <ConfirmModal
                  isOpen={cancelAlertDialog}
                  loading={loading}
                  title={`Cancel ${isMilestone ? 'Milestone': record?.is_group_propay ? 'GroupProPay' : 'Propay'}`}
                  content={`Would you like to notify ${isMilestone ? 'Milestone':'Propay'} cancelled alert to all assigned workers?`}
                  onClose={() => {
                    onSuccess();
                    setCancelAlertDialog(false);
                  } }
                  onConfirm={handleCancel}
                  ButtonOneText={'No'}
                  buttonTwoText={'Yes'}
                    />
            </>
    );
};
export default PropayCancelAction;
