import { Alert, AlertTitle, Box, Button, Card, Grid, Hidden, Skeleton, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useDataProvider, useGetList, useListContext, useMutation, useNotify, useTranslate } from 'react-admin';
import { Helmet } from 'react-helmet-async';
import { useQueryClient, useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { useGetIdentityOptimized, usePermissionsOptimized } from '../components/identity';
import PageAlerts, { PageAlertContextProvider } from '../components/page-alerts/PageAlerts';
import CardListingDashboard from '../layout/CardListingDashboard';
import ThemeWrapper from '../layout/ThemeWrapper';
import { ListGroupBase } from '../ra-list-grouping/ListGroupBase';
import PaymentProvider, { get, post } from '../resources/onboard/PaymentProvider';
import { HasPermission } from '../resources/payrolls/Payrolls';
import { NUMBER } from '../utils/Constants/MagicNumber';
import BonusDetails from './BonusDetails';
import DialogFormDashboard from './DialogDashboard';
import PropayCalculator from './PropayCalculator';
import DashboardCardListingResponsive from '../layout/DashboardCardListingResponsive';
import BonusCalculatorDashboard from './BonusCalculatorDashboard';
import { ConfirmModal } from '../components/ConfirmModal';
import DialogForm from '../components/DialogForm';
import { EmployeeUpdateForm } from '../resources/employees/Employee';
import { ResetTvRounded } from '@mui/icons-material';
import BonusPoolDetails from './BonusPoolDetails';
import { API_ENDPOINTS } from '../utils/Constants/ConstantData';
import { useLocation } from 'react-router-dom';
import { getDuplicateConnectorList } from '../components/fields';
import { canAccess } from '../ra-rbac';
import { HeapAddUserProperties } from '../Heap';
import OverviewBonuses from './OverviewBonuses';
import { ToastContainer } from 'react-toastify';
import DownloadNewApp from '../resources/propays/DownloadNewApp';
import moment from 'moment';


const StyledGrid = styled(Grid)(({ theme }) => ({
    transition: theme.transitions.create(['all'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.enteringScreen,
    }),
    '.MuiFormControl-root.propayName': {
        marginTop: 0,
    },
    '&.add-time-container': {
        width: '100%',
        '& .MuiBox-root': {
            width: '100%'
        },
        '& .RaCreate-card': { width: '100%' }
    }
}));

export const DashboardGridItem = (props: any) => {
    return (
        <StyledGrid item xs={12} md={6} lg={4} {...props}>
            {props.children}
        </StyledGrid>
    );
};

export const ListGroup = (props: any) => {
    return (
        <ListGroupBase {...props}>
            <GroupedRecrod {...props} />
        </ListGroupBase>
    );
};

export const GroupedRecrod = (props: any) => {
    const { data, isLoading } = useListContext(props);
    if (isLoading) {
        return <CardLoader/>;
    }
    const record = data && data[0];
    return React.cloneElement(props.children, { record: record });
};

const StyledCard = styled(Card)({
    width:'80%',
    borderRadius:80,
});
export const CardLoader = () => {
    return (<DashboardGridItem>
        <Hidden smUp>
            <Stack width="100%" justifyContent="center" alignItems="center">
                <StyledCard>
                    <Skeleton variant="rectangular" height={150} />
                </StyledCard>
            </Stack>
        </Hidden>

        <Hidden smDown>
            <Card>
                <Skeleton variant="rectangular" height={150} />
            </Card>
        </Hidden>
    </DashboardGridItem>)
};
const SyledBox = styled(Box)(({theme}) => ({
    [theme.breakpoints.down('sm')]: {
        backgroundColor: theme.palette.primary.main
    }
}));
const OnboardingAlert = () => {
    const [onboardingData, setOnboardingData] = useState({force_onboarding:false,onboarding_state:'completed'});
    const translate = useTranslate();
    const notifiy = useNotify();

    useEffect(() => {
        const OnboardingApiCall = async () => {
          try {
            const response = await PaymentProvider.onboardingInfo();
            setOnboardingData(response);
          } catch (error) {
            notifiy('Something went wrong in onBoarding Api.');
          }
        };
        OnboardingApiCall();
      }, [notifiy]);

    return (
        <>
            {onboardingData && onboardingData?.force_onboarding && onboardingData?.onboarding_state !== 'completed' &&
                <Alert sx={{ mb: 1 }} severity="error">
                    <AlertTitle> {translate('dashboard.onboarding')} </AlertTitle>
                    {translate('dashboard.onboarding_info')} <Button component={Link} to='/onboard'><strong> {translate('dashboard.check_it_out')} </strong></Button>
                </Alert>
            }
        </>
    );
};

export const TrialRemainingDays = () => {
    const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const notify = useNotify();
    const navigate = useNavigate();
    const [isCancelLoading, setCancelLoading] = useState(false)
    const {data:onboardingData, isLoading } = useQuery('onboardingInfo', PaymentProvider.onboardingInfo);

    React.useEffect(()=>{
        if(isLoading || !onboardingData || (onboardingData && onboardingData.trial_remaining_days == 0)){
            return
        }else{
            document.body.classList.add("trial-day-language");
        }
        return () => {
            document.body.classList.remove("trial-day-language");
        }
    },[isLoading,onboardingData?.trial_remaining_days]);

    if(isLoading || !onboardingData || (onboardingData && onboardingData.trial_remaining_days == 0)){
        return null
    }


    const handleCancelSubscription = () => {
        setConfirmDialogOpen(true)
    }
    const handleClose = () => {
        setConfirmDialogOpen(false)
    }
    const handleConfirm =async () => {
        try {
            setCancelLoading(true);
            const result = await PaymentProvider.cancelSubscription();
            setCancelLoading(false);
            if(result.status == 'success'){
                notify("Subscription cancelled successfully");
                handleClose();
                navigate("/subscription/canceled");
            } else {
                notify(`Failed to cancel subscription`);
            }

        } catch (error) {
            setCancelLoading(false);
            notify(`Failed with error: ${error.error}`);
        }
    }

    return (<Stack direction='row' justifyContent='flex-start' alignItems={'center'}  spacing={1}>
            <Typography variant='h6'  color='primary' sx={{  }}>
                {onboardingData?.trial_remaining_days}
            </Typography>
            <Typography variant='body2'  color='primary' sx={{ mx: 1 }}>
                trial days left
            </Typography>
            <Button title='Cancel Subscription' onClick={handleCancelSubscription}>Cancel</Button>
            <ConfirmModal
                isOpen={isConfirmDialogOpen}
                loading={isCancelLoading}
                title='Cancel Subscription'
                content='All users of your company will not able to use system. Are you sure want to cancel subscription?'
                onClose={handleClose}
                onConfirm={handleConfirm}
            />
        </Stack>)
};

const employeeFilter = {base_wage: {_eq: 0},active: {_eq: true}};

const EmployeeWageUpdate = ({dialogRefs, employeesData}) => {
    return(
        <>
        <EmployeeUpdateForm
            onEditSuccess={() => {
                dialogRefs.current.close();
            }}
            handleClose={dialogRefs?.current?.close}
            emptyFields={employeesData?.data?.map((item)=>({id:item?.employee_id,user_type:item?.assigned_as ? item?.assigned_as :item?.user_type,name:item?.name,email:(item?.email && item?.email !== 'false') ? item?.email :'',
            base_wage:item?.base_wage ? item?.base_wage :null,mobile_number:(item?.mobile_number && item?.mobile_number !== 'false') ? item?.mobile_number :null}))}
            hideMobile={true}
            isLoading={employeesData?.isLoading}
            isDashboard={true}
            subText=''
        />
        </>
    );
};


const getHeapEmployeeData = async (employee_id) => {
    const dataObj = {
        jsonrpc: '2.0'
    };
      const res = await post(
        '/api/employee/'+employee_id,
        dataObj
      ) as any;
      if (res && res.error) {
        return Promise.reject(res);
      } else {
        return Promise.resolve(res);
    };
}

const prepareHeapEmployeeData = (employeeData)=>{
    return {
        "First Name":employeeData.first_name,
        "Last Name":employeeData.last_name,
        'User Type':employeeData.user_type,
        "Company Name":employeeData.company_name,
        "Email":employeeData.email,
        "Mobile Number":employeeData.mobile_number,
        "Propay User": employeeData.is_propay_user ? 'YES' : 'No',
        "Status": employeeData.status,
        "Create Date": employeeData.create_date,
        "Latest Connection Date & Time": employeeData.last_connection_date
    }
}

const Dashboard = () => {
    const translate = useTranslate();
    const { identity } = useGetIdentityOptimized();
    const dataProvider = useDataProvider();
    const [response, setResponse] = useState({propay:{data:[],total:NUMBER.ZERO},userFields:{data:[],total:NUMBER.ZERO}})
    const location = useLocation();
    const {permissions} = usePermissionsOptimized();
    const isConnectorIntegrated = canAccess({permissions,resource: 'allow-integrations',action: '*',});
    const [connectorList,setConnectorList]=useState([])
    const [hasMobileApp, setHasMobileApp] = useState<any>();
    const [triggerEffect, setTriggerEffect] = useState(false);
    const [loadingNotifiedBefore, setLoadingNotifiedBefore] = useState(true);
    const [loadingHasMobileApp, setLoadingHasMobileApp] = useState(true);
    const [open, setOpen] = React.useState(false);
    const [notifiedBefore, setNotifiedBefore] = React.useState([]);
    const [mutate] = useMutation();

    useEffect(() => {
        const dashboardApiCalls = async () => {
            const activePropay = await dataProvider.getList('Propay__DropdownList',{ pagination: { page: NUMBER.ONE, perPage: NUMBER.ONE },
                                        sort: { field: 'create_date', order: 'DESC' }, filter:{status:{_eq:'open'}} });
            const userFields = await dataProvider.getList('userFields',{pagination: { page: 1, perPage: 50 },
                                        sort:{field: 'field_id', order: 'ASC'},filter:{model: {_eq: 'protiv.propay'}}});
            setResponse({propay:{data: activePropay?.data,total: activePropay?.total},userFields:{data: userFields?.data,total: userFields?.total}})
        }
        dashboardApiCalls();
    }, [dataProvider]);

    useEffect(()=>{
        if(isConnectorIntegrated){
            const getConnectorList = async ()=>{
                const data = await getDuplicateConnectorList();
                setConnectorList(!data.length ? []: data)
            }
            getConnectorList();
        }
    },[isConnectorIntegrated])

    useEffect(() => {
        if (identity?.employee_id){
            const employee = async () => {
                const response = await getHeapEmployeeData(identity.employee_id)
                if(response.status === 'success'){
                    const employeeData = response.employeeData
                    const heap_identity = employeeData.first_name + employeeData.last_name + "_" + (identity.employee_id)
                    var heapEmployeeData = prepareHeapEmployeeData(employeeData)
                    HeapAddUserProperties({...heapEmployeeData,Id:heap_identity})
                }
            }
            employee();
            }
        }, [location,identity?.employee_id]);

    const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const dialogRefs: any = React.useRef();
    const [employeesData, setEmployeesData] = useState<any>([]);

    const openWageModal = async (check)=>{
        const finalRequest = {
            jsonrpc: '2.0',
            params: {assigned_as:check ? 'both':'worker'},
        };
        const employeeResponse = await post(API_ENDPOINTS.EMPLOYEES_MISSING_WAGE, finalRequest) as any;
         const employeeData = {data:employeeResponse};
        if(employeeData?.data?.length){
            setEmployeesData(employeeData);
            setTimeout(() => {
                employeeData?.data?.length && dialogRefs?.current?.open();
                const newUrl = `${window?.location?.pathname}`;
                window.history.pushState({}, '', newUrl);
            }, NUMBER.TWO_THOUSAND);
        }else{
            setEmployeesData([]);
            const newUrl = `${window?.location?.pathname}`;
            window.history.pushState({}, '', newUrl);
        }
    }
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const addBaseWage = queryParams.get('add_wage');
        const addMobileNumber = queryParams.get('mobile_number');
        if (identity?.user_type && addBaseWage === 'true' && identity?.user_type !== 'worker') {
            openWageModal(false);
        }
        if (identity?.user_type && addMobileNumber === 'true' && identity?.user_type !== 'worker') {
            openWageModal(true);
        }

        if((addBaseWage === 'true' || addMobileNumber === 'true') && identity?.user_type === 'worker'){
            const newUrl = `${window?.location?.pathname}`;
            window.history.pushState({}, '', newUrl);
        }
    }, [identity?.user_type]);

    useEffect(() => {
        document.body.classList.add("responsive-body");
        return () => {
        document.body.classList.remove("responsive-body");
        }
    }, [])
    useEffect(() => {
        if (identity?.employee_id) {
            const hasGotNotifiedBefore = async () => {
                const data: any = await dataProvider.getList(
                    'EmployeeAppActivityTracker__userNotifiedQuery',
                    {
                        pagination: { page: 1, perPage: 1 },
                        sort: { field: 'id', order: 'ASC' },
                        filter: {
                            employee_id: { _eq: Number(identity?.employee_id) },
                        },
                    }
                );
                setNotifiedBefore(data?.data);
                setLoadingNotifiedBefore(false);
            };
            hasGotNotifiedBefore();
        }
    }, [identity?.employee_id, dataProvider, triggerEffect]);

    useEffect(() => {
        if (identity?.employee_id) {
            const hasMobileAppFlagCheck = async () => {
                const data: any = await dataProvider.getList(
                    'Employee__hasMobileAppQuery',
                    {
                        pagination: { page: 1, perPage: 1 },
                        sort: { field: 'id', order: 'ASC' },
                        filter: {
                            employee_id: { _eq: Number(identity?.employee_id) },
                            attribute_name: {_eq: "mobile_app_installed"}
                        },
                    }
                );
                setHasMobileApp(data?.data);
                setLoadingHasMobileApp(false);
            };
            hasMobileAppFlagCheck();
        }
    }, [dataProvider, identity?.employee_id]);

    useEffect(() => {
        if (!loadingNotifiedBefore && !loadingHasMobileApp) {
            const lastActivity = notifiedBefore?.[0]?.last_datetime_activity;
            const daysDifference = moment()?.diff(moment(lastActivity), 'days');
            // Case 1 Ensures that isSmall is true.
            // Case 2  Ensures that hasMobileApp is false.
            if (isSmall && hasMobileApp?.length === 0) {
                if (notifiedBefore.length === 0) {
                    // Case 3: If notifiedBefore is empty, open the modal
                    setOpen(true);
                } else if (daysDifference > 30) {
                    // Case 4: If notifiedBefore is not empty but daysDifference > 30, open the modal
                    setOpen(true);
                }
            }
        }
    }, [
        loadingNotifiedBefore,
        loadingHasMobileApp,
        hasMobileApp,
        isSmall,
        notifiedBefore,
    ]);

    return (
        <>
        <ToastContainer/>
        <DialogForm
            title="Missing User Data"
            ref={dialogRefs}
            wageClass={'add-wage-dialog'}
            iconBootun={true}
        >
        <EmployeeWageUpdate dialogRefs={dialogRefs} employeesData={employeesData}/>
        </DialogForm>

            <OverviewBonuses />
            <DownloadNewApp
                open={open}
                setOpen={setOpen}
                notifiedBefore={notifiedBefore}
                onRefresh={() => setTriggerEffect(prev => !prev)}
            />
            <ThemeWrapper>
            <PageAlertContextProvider>
                <SyledBox className='dashboard-outer'>
                    <HasPermission resource="allow-onboarding" action="list">
                        <OnboardingAlert />
                    </HasPermission>
                    <PageAlerts/>

                    {/* <BonusPoolDetails identity={identity}/> */}

                    {/* {response?.propay?.total > NUMBER.ZERO && <Grid container spacing={3} className="grid-dashboard-cont">
                        {isSmall ? <DashboardCardListingResponsive connectorList={connectorList} total={response?.propay?.total} options={response?.userFields?.data} identity={identity}/>
                         : <CardListingDashboard connectorList={connectorList} total={response?.propay?.total} options={response?.userFields?.data} identity={identity}/>}
                     </Grid>} */}
                </SyledBox>
            </PageAlertContextProvider>
            </ThemeWrapper>
            <Helmet>
            <title>Dashboard</title>
        </Helmet>
        </>
    );
};

export default Dashboard;
