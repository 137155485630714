import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { PROTIV_ICON } from '../../utils/Constants/ClassName';
import AppStoreImage from '../../assets/download-app-store.svg';
import GooglePlayStoreImage from '../../assets/google-play-store.svg';
import { CRUD_CREATE, CRUD_UPDATE, useMutation } from 'react-admin';
import moment from 'moment';
import { useIdentityContext } from '../../components/identity';

const DownloadNewApp = (props: any) => {
    const { open, setOpen, notifiedBefore, onRefresh } = props;
    const [mutate] = useMutation();
    const identity: any = useIdentityContext();
    const lastActivity = notifiedBefore?.[0]?.last_datetime_activity;
    const daysDifference = moment().diff(moment(lastActivity), 'days');
    const handleClose = () => {
        setOpen(false);
        const currentDate = moment().format('YYYY-MM-DD HH:mm:ss');
        const { employee_id } = identity;
        const basePayload = { last_datetime_activity: currentDate };
        const notificationId = notifiedBefore?.[0]?.id;
        if (identity?.employee_id) {
            //create new data if no data found
            if (notifiedBefore.length === 0) {
                mutate(
                    {
                        type: 'create',
                        resource: 'EmployeeAppActivityTracker',
                        payload: {
                            data: {
                                activity_type:
                                    'notification_mobile_app_available',
                                employee_id,
                                ...basePayload,
                            },
                        },
                    },
                    {
                        mutationMode: 'pessimistic',
                        action: CRUD_CREATE,
                        onSuccess: () => {
                            onRefresh();
                        },
                    }
                );
            } else if (daysDifference > 30) {
                const newData = {
                    id: notificationId,
                    ...basePayload,
                };
                mutate(
                    {
                        type: 'update',
                        resource: 'EmployeeAppActivityTracker',
                        payload: {
                            id: notificationId,
                            data: newData,
                            previousData: { id: notificationId },
                        },
                    },
                    {
                        mutationMode: 'pessimistic',
                        action: CRUD_UPDATE,
                        onSuccess: () => {
                            onRefresh();
                        },
                    }
                );
            }
        }
    };
    const [platform, setPlatform] = useState('');
    const [downloadLink, setDownloadLink] = useState('');

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor;

        if (/android/i.test(userAgent)) {
            setPlatform('android');
            setDownloadLink(
                'https://play.google.com/store/apps/details?id=com.protiv'
            );
        } else if (/iPad|iPhone|iPod/.test(userAgent)) {
            setPlatform('ios');
            setDownloadLink('https://apps.apple.com/in/app/protiv-app/id6475716657');
        }
    }, []);

    return (
        <>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                className="download-app-modal"
            >
                <DialogTitle>
                <Stack
                        flexDirection={'row'}
                        justifyContent={'flex-end'}
                        className='download-app-stack'
                >
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: NUMBER.EIGHT,
                            top: NUMBER.EIGHT,
                            color: theme =>
                                theme.palette.grey[NUMBER.FIVE_HUNDRED],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
                </DialogTitle>
                <DialogContent>
                    <div className="download-app-cont">
                        <div className="download-app-logo">{PROTIV_ICON()}</div>
                        <div className="download-app-head">
                            Download <br />
                            Our Mobile App
                        </div>
                        <div className="download-app-img">
                            <a
                                href={downloadLink}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Button className="download-click">
                                    Click here
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            d="M19.5 13.5L12 21M12 21L4.50002 13.5M12 21L12 3"
                                            stroke="#485D63"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </Button>
                                {platform === 'ios' && (
                                    <img src={AppStoreImage} alt="protiv" />
                                )}
                                {platform === 'android' && (
                                    <img
                                        src={GooglePlayStoreImage}
                                        alt="protiv"
                                    />
                                )}
                            </a>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default DownloadNewApp;