import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useListContext, useUnselectAll,useNotify } from 'react-admin';
import { BIN_ICON, COMPLETE_PROPAY_ACTION, GROUP_EXPORT_ICON, PENDING_ICON, TICK_ICON_FILLED,BONUS_POOL_ICON_02,CURRENCY_DOLLAR_ICON, CLOSE_ICON_ACTIONS } from '../../utils/Constants/ClassName';
import { PropayBulkActionModal } from './PropayBulkActionModal';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { useIdentityContext } from '../../components/identity';
import dataProvider from '../../dataProvider';
import { AlertModal, GroupApproveModal } from './PropayTab';
import { TextField } from '@mui/material';
import { post } from '../onboard/PaymentProvider';
import { PROPAY_ACTION_OPTION } from '../../utils/Constants/ConstantData';
import {BulkUpdateBudgetModal,BulkUpdateBonusPoolModal} from './BulkUpdateBudget';

const PropayActions = (props)=>{
const {bulkAction,setHideAction,setBulkAction,hideAction,setShowGroupPropay, setAlertModal, alertModal, groupName, setGroupName, setSelectedListData, successFlag, setSuccessFlag,connectorList=[]} = props;
const { selectedIds = [], data }=useListContext();
const [showActionModal,setShowActionModal]=useState(false);
const [checkMilestones, setCheckMilestones] = useState(false);
const [showMessage, setShowMessage] = useState([]);
const [selectedData, setSelectedData] = useState([]);
const [showBudgetModal,setShowBudgetModal]=useState(false);
const unselectAll = useUnselectAll();
useEffect(()=>{
    if(selectedIds.length > NUMBER.ZERO){
        setHideAction(false);
    }
    if(!selectedIds.length && !hideAction){
        setBulkAction('');
    }
},[selectedIds])

useEffect(() => {
    if(successFlag) {
        unselectAll('propays');
        setSuccessFlag(false);
    }
},[successFlag])

useEffect(() => {
    if(data) {
        const selectedItems = data.filter(propay => selectedIds.includes(propay?.id));
        setSelectedData([...selectedItems])
        setSelectedListData([...selectedItems]);
        const hasMilestones = selectedItems.some(obj => obj.has_milestones === true);
        setCheckMilestones(hasMilestones);
    }
},[data, selectedIds])

    return (
        <>
        <div className='close-statement-toolbar'>
        <div className='group-notes-div'>
        <span>{selectedIds.length} ProPays Selected </span>
        {bulkAction === 'GroupProPay' && <div className='group-notes-text'>
        <strong>Note:</strong> To add the selected ProPays to an existing group, please go to the group page.
        </div>}
        </div>
        {bulkAction === 'GroupProPay' && <div className='bulk-group-fields'>
            <span className='group-name-label'>Group Name:</span>
            <TextField onChange={(e) => setGroupName(e.target.value)} id="group-add-name" placeholder='Add Name' variant="outlined" className='add-name-bulk-input' />
        </div>}
        <ActionButton selectedData={selectedData} showMessage={showMessage} setShowMessage={setShowMessage} selectedIds={selectedIds} groupName={groupName} setGroupName={setGroupName} setAlertModal={setAlertModal} checkMilestones={checkMilestones} setShowGroupPropay={setShowGroupPropay} bulkAction={bulkAction} setShowActionModal={[PROPAY_ACTION_OPTION.updateBudget,PROPAY_ACTION_OPTION.bonusPool].includes(bulkAction) ?  setShowBudgetModal:setShowActionModal}/>
        </div>

        <AlertModal setShowGroupPropay={setShowGroupPropay} showMessage={showMessage} selectedData={selectedData} isOpen={alertModal} onClose={() => setAlertModal(false)} />

        <PropayBulkActionModal isOpen={showActionModal}
        action={bulkAction} selectedIds={selectedIds} onClose={()=>setShowActionModal(false)} unselectAll={unselectAll} />
       <BulkUpdateBudgetModal connectorList={connectorList} selectedData={selectedData} selectedIds={selectedIds} close={()=>setShowBudgetModal(false)} openModal={showBudgetModal && bulkAction === PROPAY_ACTION_OPTION.updateBudget} unselectAll={unselectAll}/>
       <BulkUpdateBonusPoolModal selectedIds={selectedIds} close={()=>setShowBudgetModal(false)} unselectAll={unselectAll} openModal={showBudgetModal && bulkAction === PROPAY_ACTION_OPTION.bonusPool} />
        </>
    );
};

export default  PropayActions;

const ActionButton = ({setShowActionModal, setShowMessage, showMessage, selectedData, bulkAction, setShowGroupPropay, checkMilestones, setAlertModal, groupName, setGroupName, selectedIds}) => {
    const notify = useNotify();
    const handleBulkAction =()=>{
    setShowActionModal(true);
  }

  const getErrorMessage = (message) => {
    let errorMessages = [];

    const messagesMap = {
        "group_parent_propay": "Existing GroupProPay unsupported from ProPay list.",
        "grouped_propay": "Grouped ProPay issue",
        "propay_with_milestones": "Milestones Unsupported",
        "milestones": "Milestones unsupported",
        "approved_propay": "Approved ProPay Unsupported",
        "paid_propay": "Completed ProPay Unsupported"
    };

    for (const key in message) {
        if (Array.isArray(message[key]) && message[key].length > 0) {
            message[key].forEach(item => {
                errorMessages.push({
                    message: item,
                    name: messagesMap[key] || "Unknown issue"
                });
            });
        }
    }

    setShowMessage(errorMessages);
}

  function hasValues(obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (Array.isArray(obj[key]) && obj[key].length > 0) {
                return true;
            }
        }
    }
    return false;
}

  const handleGroupPropay = () => {
    const data = {
        propay_ids: [...selectedIds]
    }
    post('/api/validate/group_propays', data).then((response:any) => {
        getErrorMessage(response?.group_propay_dict)
        if(hasValues(response?.group_propay_dict) || selectedIds.length < 2) {
            setAlertModal(true)
        } else {
            setShowGroupPropay(true)
        }
    }).catch(error=>{
        notify(`Failure ! ${error.message}`);
    })
  }

    const identity = useIdentityContext();
    switch(bulkAction){
        case PROPAY_ACTION_OPTION.approve:{
            return <Button className={'bulk-approve-button'} onClick={()=>handleBulkAction()}>{TICK_ICON_FILLED()} {bulkAction}</Button>
        }
        case PROPAY_ACTION_OPTION.delete:{
            return <Button className={'bulk-approve-button'} onClick={()=>handleBulkAction()}>{BIN_ICON()} {bulkAction}</Button>
        }
        case PROPAY_ACTION_OPTION.cancel:{
            return <Button className={'bulk-approve-button'} onClick={()=>handleBulkAction()}>{CLOSE_ICON_ACTIONS()} {bulkAction}</Button>
        }
        case PROPAY_ACTION_OPTION.pending:{
            return <Button className={'bulk-pending-button'} onClick={()=>handleBulkAction()}>{PENDING_ICON()} {(identity?.company?.is_started_status && bulkAction === 'Pending') ? `${bulkAction} or Start` : bulkAction}</Button>
        }
        case PROPAY_ACTION_OPTION.complete:{
            return <Button className={'complete-button'} onClick={()=>handleBulkAction()}>{COMPLETE_PROPAY_ACTION()} {bulkAction}</Button>
        }
        case 'GroupProPay': {
            return <Button disabled={groupName.trim() === ''} className={'toolbar-orange-btn'} onClick={() => handleGroupPropay()}>{GROUP_EXPORT_ICON()} {bulkAction}</Button>
        }
            case PROPAY_ACTION_OPTION.updateBudget:{
            return <Button className={'update-budget-button'} onClick={()=>handleBulkAction()}>{CURRENCY_DOLLAR_ICON()} {bulkAction}</Button>
        }
        case PROPAY_ACTION_OPTION.bonusPool:{
            return <Button className={'update-budget-button'} onClick={()=>handleBulkAction()}>{BONUS_POOL_ICON_02()} {bulkAction}</Button>
        }
        default:{
            return <Button className={'toolbar-orange-btn'} onClick={()=>handleBulkAction()}>{GROUP_EXPORT_ICON()} {bulkAction}</Button>
        }
    }
}
