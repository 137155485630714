import { useEffect, useRef, useState } from 'react';
import { BooleanInput, FormDataConsumer, useTranslate, } from 'react-admin';
import { InputPicker, Loader, SelectPicker } from 'rsuite';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { Button, Input, Typography } from '@mui/material';
import { COST_CODE_EDIT_ICON, PLUS_ICON_DROPDOWN, USER_ICON_DROPDOWN } from '../../utils/Constants/ClassName';
import { EditJobForm, JobDialog } from '../../resources/jobs/job';
import { EmployeeDisplayNameField } from '../../resources/employees/Employee';
import { useSimpleFormIteratorItem } from '../ArrayInput/useSimpleFormIteratorItem';
import uniqueId from "lodash/uniqueId"

const CustomReferenceInputReactSelect = (props) => {
    const {setFilter,choices,propay_id, recordId} = props;
    const [attendence, setAttendance] = useState(null);
    const [clearId,setClearId] = useState('');
    const data = choices
    ?.filter((propay) => !propay.parent_id)
    .flatMap((propay) => {
      const childOptions = propay.milestone_ids?.map((milestone) => ({
        label: milestone.name,
        value: milestone.id,
        propay_id: propay.id,
        class: 'rsuite-menu-child ' + propay.className || '',
        status:milestone?.status,
        role: `${propay.name}?????${propay.id}`,
        is_restricted:milestone.is_restricted,
        id:recordId
      }));

      if (childOptions && childOptions?.length > NUMBER.ZERO) {
        return [...childOptions];
      } else {
        return {
          label: propay.name,
          value: propay.id,
          propay_id: propay.id,
          status:propay?.status,
          class: 'rsuite-menu-parent',
          id:recordId
        };
      }
    });
      const handleSearch = async (value) => {
          setFilter(value);
      };

const handlePropayChange = (val, item) => {
  setClearId('');
  setAttendance(item.value);
  props?.updateAttendance(item.id,item.value, false);
}
const handlePropayClear = (val) => {
  setClearId(propay_id)
  setAttendance(null);
  props?.updateAttendance(props.recordId,null, false);
  setFilter("");
}


  const renderValue = (value, item) => {
    if (!item) return null;
    const role = item.role ? item.role?.split('?????')[0] : '';
    const name = item?.role ? `${role} > ${item.label}` : item.label;
    return name;
};

const renderGroupItem = (label, item) => {
  const name = label?.split('?????')[0];
  return (
    <div className={`rs-picker-option`}>
      <span>{name}</span>
    </div>
  );
};


useEffect(()=>{
  if (data.length > 0) {
  const val = data.filter((item)=> item.value === propay_id);
  if(val?.length && clearId !== propay_id){
    setAttendance(val[0].value);
    renderValue('', val[0]?.label);
  }
}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data]);

  const renderMenuItem = (label, item) => {
    return (
      <div className={`rs-picker-option ${item.class}`}>
        <span>{label}</span>
      </div>
    );
  };
  const filteredData = data.filter((option) => option.label !== null && option.label !== undefined && (['pending', 'started', 'completed', 'approved']?.includes(option?.status)) && !option.is_restricted );

  return (
    <InputPicker
      groupBy="role"
      value={attendence}
      placeholder="Milestone"
      className={props?.classToAdd ? props?.classToAdd : ''}
      renderMenuGroup={renderGroupItem}
      data={filteredData}
      onSearch={handleSearch}
      onSelect={(value, item) => handlePropayChange(value, item)}
      onClean={(event) => handlePropayClear(event)}
      renderValue={renderValue}
      renderMenuItem={renderMenuItem}
      placement="autoVerticalStart"
      data-title='Milestone Name'
    />
  );
};

export default CustomReferenceInputReactSelect;

export const CustomReferenceSelectInputWithFooter = (props) => {
const { index } = useSimpleFormIteratorItem();
const componentId = uniqueId('menu-list-')
const translate = useTranslate()
const {
    setFilter,
    choices,
    isLoading,
    newId,
    recordId,
    input,
    buttonTwoClick,
    buttonOneClick,
    isFetching,
    searchPlaceholder,
    source,
    forEmployee = false,
    hideInActive,
    showEditIcon,
} = props;
const [loadOnScroll, setLoadOnScroll]= useState(false);
const [dropdownOpen, setDropdownOpen]= useState(false);
const [dropdownTouched, setDropdownTouched]= useState(false);
const [searchText, setSearchText] = useState('');
const totatRecords = useRef(0);
const loadingState = useRef({perPage:10,loading:false, fetching:false });
const [data, setData]= useState([]);
const body = document.body;
const [selectedId,setSelectedId]=useState(null);
const [isEdit,setIsEdit]=useState(false);
const showEdit = typeof showEditIcon === "boolean" ? showEditIcon : source.toLowerCase().includes('job');

useEffect(()=>{
  totatRecords.current = props?.possibleValues?.total ? props?.possibleValues?.total : totatRecords.current;
  loadingState.current = {perPage: props?.perPage,loading:isLoading, fetching:isFetching }
},[isLoading, isFetching, props?.possibleValues?.total, props?.perPage])


const getLabel = (propay: any) => {
    const nameField = source.toLowerCase().includes('job') ? 'full_name':'display_name'

    if (forEmployee) {
      const label = propay?.active || hideInActive ? propay[nameField] :`${propay[nameField]} (Inactive)`
      return <EmployeeDisplayNameField record={{ ...propay, display_name: label }} />
    }

    if (propay.revenue) return `${propay[nameField]} ($${(propay?.revenue*1).toFixed(2)})`

    return propay?.hasOwnProperty(nameField) ? propay[nameField] : ''
  }

  useEffect(()=>{
    if(!isLoading){
      const listData =
      choices?.flatMap(propay => ({
      ...propay,
      label: getLabel(propay),
      value: propay.id,
      job_id: propay.id,
      status: propay?.status,
      class: 'rsuite-menu-parent ' + propay.className || '',
      id: recordId ? recordId : propay.id,
      name: getLabel(propay),
      revenue: propay.revenue,
      })) || [];
      setData(listData);
    }
  },[choices, choices.length, isLoading])


  const filteredData = data.filter(propay => !!propay.label);

  useEffect(()=>{
    if(!isLoading && loadOnScroll && choices?.length !== props?.possibleValues?.total){
      addScrollListener();
    }
  },[isLoading, loadOnScroll])

  const handleSearch = async (value) => {
    setFilter(value);
    setSearchText(value);
  };

  const handlePropayChange = (val, item) => {
    setSelectedId(item.value)
    props?.onValueChange && props?.onValueChange(item.value, props?.formProps?.form ?? item);
    input?.onChange(item.value);

  }
  const handlePropayClear = (val) => {
    setSelectedId(null)
    input?.onChange(null);
    setDropdownTouched(true);
    setFilter("");
  }

  const EditAction = ({value})=>{
    return( <div className='create-job-edit-icon' onClick={(e)=> {
      e?.stopPropagation();
      setIsEdit(true);
      setSelectedId(value);
      }}>
    {COST_CODE_EDIT_ICON()}
</div>)
  }

  const renderValue = (value, item) => {
    if (!item) return null;
    const role = item.role ? item.role?.split('?????')[0] : '';
    const name = item?.role ? `${role} > ${item.label}` : item.label;
    return <>{item.label} {showEdit? <EditAction value={value}/>:''}</>;
  };

  const renderGroupItem = (label, item) => {
    const name = label?.split('?????')[0];
    return (
      <div className={`rs-picker-option`}>
        <span>{name}</span>
      </div>
    );
  };


  useEffect(() => {
    if (data.length > 0) {
      const val = data.filter((item) => item.value === newId);
      if (val?.length) {
        input?.onChange(val[0].value);
        props?.onValueChange && props?.onValueChange(val[0].value, props?.formProps?.form);
        renderValue('', val[0]?.label);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const renderMenuItem = (label, item) => {
    return (
      <div className={`rs-picker-option ${item.class}`}>
        <span>{label}</span>
      </div>
    );
  };

  const addScrollListener = () => {
    const container =  document.querySelector('.rs-picker-menu');
    if (container) {
      const scrollableElement = container.querySelector('.rs-picker-select-menu-items');
      if (scrollableElement) {
        scrollableElement.addEventListener('scroll', handleScroll);
      }
    }
  };

  const removeScrollListener = () => {
    const container = document.getElementById(componentId);
    if (container) {
      const scrollableElement = container.querySelector('.rs-picker-select-menu-items');
      if (scrollableElement) {
        scrollableElement.removeEventListener('scroll', ()=>{});
      }
    }
  };

  const handleDropdownOpen = () => {
    setLoadOnScroll(true);
    setDropdownOpen(true);
    body.classList.add('rsuite-dropdown-overlap')
  };

  const handleDropdownClose = () => {
    //removeScrollListener();
    setSearchText('');
    setFilter("");
    body.classList.remove('rsuite-dropdown-overlap')
    setLoadOnScroll(false);
    setDropdownOpen(false);
    setDropdownTouched(true);
  };

  const handleScroll = (e) => {
    const container = e.target;
      if (container.scrollHeight - container.scrollTop <= container.clientHeight + 2) {
        if(!loadingState.current.loading && !loadingState.current.fetching && loadingState.current.perPage < totatRecords.current){
          props?.setPerPage((prevPage) => prevPage + 10);
        }
    }
  };
  const FixedLoader = () => (
    <Loader
      content="Loading..."
      style={{
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        bottom: '0',
        background: '#fff',
        width: '100%',
        padding: '4px 0'
      }}
    />
  );

  const renderMenu = menu => {
    return (
      <>
        {menu}
        {isLoading && <FixedLoader />}
      </>
    );
  };

  const showLabel = () => {
    if(props?.hideLabel){
      return '';
    }else {
      return props?.placeholder ? props?.placeholder : "Job *";
    }
  }
  const handleEdit = (updatedData)=>{
    setIsEdit(false);
    props?.setPerPage((prevPage) => prevPage + 10);
    props?.onValueChange && props?.onValueChange(updatedData.id, props?.formProps?.form);
    input?.onChange(updatedData.id);
  }
  return (
    <>
            <SelectPicker
            disabled={props?.disabled || (props?.disableFirst && index === 0)}
            classPrefix='propay-select-picker'
            menuClassName='propay-select-dropdown'
            value={input?.value}
            label={(input?.value || dropdownOpen) ? showLabel() : ''}
            className={(dropdownTouched && !input?.value && translate(props?.meta?.error) || (props?.meta?.touched && !input?.value)) ? 'error-border-select-picker' : ''}
            renderMenuGroup={renderGroupItem}
            data={filteredData}
            onOpen={handleDropdownOpen}
            onClose={handleDropdownClose}
            searchable={false}
            appearance={dropdownOpen ? ' ' : showLabel()}
            placeholder={props.placeholder}
            renderExtraFooter={() => (
              <>
              <div className={`${filteredData?.length ? '':'cost-code-toggle'}`}>
              <div className='propay-filter-search tag-picker-propay-search'>
              <Input className='propay-search' value={searchText} placeholder={searchPlaceholder} type='text' onChange={(e)=>handleSearch(e?.target?.value)}/>
              </div>
                {props?.buttonText ? <div className='btn-group-no-data'>
                  {props?.showUnderAllJobs && <div className={`cost-code-tag-picker ${filteredData?.length ? '' : 'no-job-tag-relative'}`}>
                      <BooleanInput
                          sx={{ paddingTop: 2 }}
                          source="show_to_all"
                          // onChange={(e) => handleCreateCode(e)}
                          label='Toggle on will show this propay under all jobs.'
                          helperText={false}
                          className="custom-switch"
                      />
                      </div>}
                  <Button onClick={() => buttonOneClick()} className='create-manager-btn'>{PLUS_ICON_DROPDOWN()} {props?.buttonText}</Button>
                  {props?.buttonTextTwo && <Button onClick={()=>buttonTwoClick()} className='create-manager-btn'>{USER_ICON_DROPDOWN()} {props?.buttonTextTwo} </Button>}
                </div> : ''}
                </div>
              </>
            )}
            renderMenu={renderMenu}
            onSelect={(value, item) => handlePropayChange(value, item)}
            onClean={(event) => handlePropayClear(event)}
            renderValue={renderValue}
            renderMenuItem={renderMenuItem}
            placement='bottomStart'
            menuMaxHeight={400}
          />

    {(dropdownTouched && !input?.value && translate(props?.meta?.error) || (props?.meta?.touched && !input?.value)) && <span className='error-required-text'>{translate(props?.meta?.error)}</span>}
   {(isEdit) ? <JobDialog contentProps={{ maxHeight:325, height: window.innerHeight / NUMBER.TWO}} onCancel={()=>setIsEdit(false)} component={<EditJobForm record={{id:selectedId}} onEdit={(data)=>{handleEdit(data)}}/>} record={{id:selectedId}} title='Edit Job'/> :''}
    </>
  );
};
