import { Button, Input } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { TagPicker, Tag, Loader } from 'rsuite';
import { useIdentityContext } from '../components/identity';
import { useDataProvider } from 'react-admin';

const MultiSelectInputWithInfiniteScroll = (props) => {
    const {setSelected,selectedValue,val, setPrevious, fieldName, handleChangeFormFields, selectedUser, editMode,resource,filterQuery={},placement='bottomStart'} = props;
    const dataProvider = useDataProvider();
    const [data, setData] = useState<any>({ data: [], total: 0 });
    const [selectedValues, setSelectedValues] = useState([]);
    const [choices, setChoices] = useState([]);
    const [loadOnScroll, setLoadOnScroll] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const selectedData = useRef([]);
    const apiResponse = useRef({data:[], total:0, isLoading: false});
    const debounceTimeout = React.useRef(null);
    const body = document.body;

    const handleApiCall = async (pagination, search?:string) => {
        apiResponse.current = {...apiResponse.current, isLoading: true};
        const apiData = await dataProvider.getList(resource, {
            pagination: pagination,
            sort: { field: 'id', order: 'ASC' },
            filter: {q:(search === '' || search)? search : searchText, id: { _nin: selectedValue },...filterQuery },
        });
        apiResponse.current = {data:apiData?.data, total: apiData?.total, isLoading: false};
        const filteredApiData = apiData.data.filter(item => !selectedData.current.some(selectedItem => selectedItem.id === item.id));
        setData({data:[...selectedData.current, ...filteredApiData], total:apiData?.total});
    };

    useEffect(() => {
        const pagination = { page: 1, perPage: 20 };
        setTimeout(()=>handleApiCall(pagination),500);
    }, []);

    useEffect(() => {
        const ApiCallForSeletedValues = async () => {
            const apiData = await dataProvider.getList(resource, {
                pagination: { page: 1, perPage: 100 },
                sort: { field: 'id', order: 'ASC' },
                filter: { id:{_in:selectedValue},...filterQuery},
            });
            selectedData.current = apiData?.data;
            const filteredApiData = apiData.data.filter(item => !data.data.some(selectedItem => selectedItem.id === item.id));
            setData(prev => ({ data: [...filteredApiData, ...prev?.data], total: prev?.total }));
        }
        if(selectedValue?.length){
            ApiCallForSeletedValues();
            setSelectedValues(selectedValue);
        }else{
            selectedData.current = [];
        }
    }, [editMode, selectedValue?.length]);

    useEffect(() => {
        if (!apiResponse?.current?.isLoading) {
          const datas = data.data?.flatMap(item => {
            return {
              label: item?.display_name,
              value: item.id,
              status: item?.status,
              class: 'rsuite-menu-parent',
            };
          });
            if(selectedUser?.value){
                const filteredEmployees = datas.filter(emp => emp?.value !== selectedUser?.value);
                setChoices([...filteredEmployees, selectedUser])
            }else{
                setChoices(datas);
            }
        }
    }, [data?.data?.length, apiResponse?.current?.isLoading, selectedUser?.value]);

    return (
        <MultiSelectInputUi {...props} choices={choices} data={data} handleApiCall={handleApiCall} apiResponse={apiResponse} selectedValues={selectedValues} setSelectedValues={setSelectedValues}/>
    );
};

export default MultiSelectInputWithInfiniteScroll;

export const MultiSelectInputWithInfiniteScrollControllerApi = ({setAspireFieldsChange, ...props}) => {
    const [data, setData] = useState({ data: [], total: 0 });
    const [choices, setChoices] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const initialSelectedValues = useRef([]);
    const selectedData = useRef([]);
    const apiResponse = useRef({ data: [], total: 0, isLoading: false });
    const identity = useIdentityContext();
    const [isLoading, setIsLoading] = useState(true);
  
    const handleApiCall = async (pagination, search = '') => {
      setIsLoading(true);
      apiResponse.current.isLoading = true;
    
      try {
        const response = await fetch('/api/protiv/import-milestones/milestones-candidates/list-settings', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            jsonrpc: '2.0',
            params: { entity_name: 'job_type', company_id: identity?.company_id },
          }),
        });
        const json = await response.json();
        const apiData = json?.result?.settings || [];
    
        const selected = apiData.filter(item => item.is_selected).map(item => item.entity_record_id);

        if (initialSelectedValues.current.length === 0) {
          initialSelectedValues.current = selected;
        }
    
        const filteredApiData = apiData.filter(item => 
          !selectedData.current.some(selectedItem => selectedItem.entity_record_id === item.entity_record_id) &&
          item.entity_record_name.toLowerCase().includes(search.toLowerCase())
        );
    
        setSelectedValues(prevSelectedValues => [
          ...prevSelectedValues,
          ...selected.filter(id => !prevSelectedValues.includes(id))
        ]);
    
        setData(prev => ({ data: [...selectedData.current, ...filteredApiData], total: apiData.length }));
        apiResponse.current = { data: apiData, total: apiData.length, isLoading: false };
      } catch (error) {
        console.error('Error fetching data:', error);
        apiResponse.current.isLoading = false;
      } finally {
        setIsLoading(false);
      }
    };
  
    useEffect(() => {
      const fetchData = async () => {
        const pagination = { page: 1, perPage: 20 };
        await handleApiCall(pagination);
      };
      fetchData();
    }, [identity?.company_id]);
  
    useEffect(() => {
      if (!isLoading && data.data.length > 0 && !apiResponse.current.isLoading) {
        const datas = data.data.map(item => ({
          label: item.entity_record_name,
          value: item.entity_record_id,
          status: item.is_selected || selectedValues.includes(item.entity_record_id),
          class: 'rsuite-menu-parent',
        }));
        setChoices(datas);
      }
    }, [data.data, apiResponse.current.isLoading, isLoading, selectedValues]);
  
    return (
      <MultiSelectInputUi {...props} choices={choices} data={data} handleApiCall={handleApiCall} apiResponse={apiResponse} selectedValues={selectedValues} setSelectedValues={setSelectedValues} initialSelectedValues={initialSelectedValues.current}
      setAspireFieldsChange={setAspireFieldsChange}/>
    );
  };
  
  const MultiSelectInputUi = (props) => {
    const {
      setSelected, selectedValues, setSelectedValues, setPrevious,
      fieldName, handleChangeFormFields, choices, data, handleApiCall,
      filterQuery = {}, placement = 'bottomStart', initialSelectedValues, setAspireFieldsChange
    } = props;
    const [loadOnScroll, setLoadOnScroll] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const searchRef = useRef('');
    const debounceTimeout = useRef(null);
  
    useEffect(() => {
      if (!props.apiResponse?.current?.isLoading && loadOnScroll && choices?.length !== data?.total) {
        addScrollListener();
      }
      if (props.apiResponse?.current?.isLoading) {
        removeScrollListener();
      }
    }, [props.apiResponse?.current?.isLoading, loadOnScroll]);
  
    const handleSearch = async (value) => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      setSearchText(value);
      searchRef.current = value;
      debounceTimeout.current = setTimeout(() => {
        handleApiCall({ page: 1, perPage: 20 }, value);
      }, 300);
    };
  
    const handleChange = (val) => {
      if (setPrevious) {
        handleChangeFormFields(fieldName, val);
      } else {
        setSelected(val);
      }
      setSelectedValues(val);
      const isChanged = JSON.stringify(val.sort()) !== JSON.stringify(initialSelectedValues?.sort());
      if (setAspireFieldsChange) {
        setAspireFieldsChange(isChanged);
      }
    };
  
    const handleClear = () => {
      if (setPrevious) {
          handleChangeFormFields(fieldName, []);
      } else {
          setSelected([]);
      }
      setSelectedValues([]);
      const isChanged = JSON.stringify([]) !== JSON.stringify(initialSelectedValues?.sort());
      if (setAspireFieldsChange) {
        setAspireFieldsChange(isChanged);
      }
  };
  
    const renderGroupItem = (label) => <div className="rs-picker-option"><span>{label}</span></div>;
    const renderMenuItem = (label, item) => <div className={`rs-picker-option ${item.class}`}><span>{label}</span></div>;
  
    const filteredData = choices.filter(option => option.label !== null && option.label !== undefined);
  
    const addScrollListener = () => {
      const container = document.querySelector('.tag-picker-cost-code-menu');
      if (container) {
        const scrollableElement = container.querySelector('.rs-picker-check-menu-items');
        if (scrollableElement) {
          scrollableElement.addEventListener('scroll', handleScroll);
        }
      }
    };
  
    const removeScrollListener = () => {
      const container = document.querySelector('.tag-picker-cost-code-menu');
      if (container) {
        const scrollableElement = container.querySelector('.rs-picker-check-menu-items');
        if (scrollableElement) {
          scrollableElement.removeEventListener('scroll', () => { });
        }
      }
    };
  
    const handleDropdownOpen = () => {
      setLoadOnScroll(true);
      setDropdownOpen(true);
    };
  
    const handleDropdownClose = () => {
      removeScrollListener();
      setSearchText('');
      setLoadOnScroll(false);
      setDropdownOpen(false);
    };
  
    const handleScroll = (e) => {
      const container = e.target;
      if (container.scrollHeight - container.scrollTop <= container.clientHeight + 1) {
        
        if (searchRef.current !== "") {
          return;
        }
        if (!props.apiResponse?.current?.isLoading && props.apiResponse?.current?.data?.length < props.apiResponse?.current?.total) {
          const pagination = { page: 1, perPage: 20 * (Math.floor(props.apiResponse?.current?.data?.length / 20) + 1) };
          handleApiCall(pagination, searchRef.current);
        }
      }
    };
  
    const FixedLoader = () => (
      <Loader
        content="Loading..."
        style={{
          display: 'flex',
          justifyContent: 'center',
          position: 'absolute',
          bottom: '0',
          background: '#fff',
          width: '100%',
          padding: '4px 0',
        }}
      />
    );
  
    const renderMenu = (menu) => (
      <>
        {menu}
        {props.apiResponse?.current?.isLoading && <FixedLoader />}
      </>
    );
  
    const handleTagRemove = (tag, e) => {
      const newArray = selectedValues.filter(item => item !== e);
      if (setPrevious) {
        handleChangeFormFields(fieldName, newArray);
      } else {
        setSelected(newArray);
      }
      setSelectedValues(newArray);
      const isChanged = JSON.stringify(newArray.sort()) !== JSON.stringify(initialSelectedValues.sort());
      setAspireFieldsChange(isChanged);
    };
  
    const mountRef = useRef();
  
    return (
      <div className="automation-multi-select" ref={mountRef}>
        <TagPicker
          placeholder={dropdownOpen ? ' ' : props?.label}
          menuClassName="propay-select-dropdown tag-picker-cost-code-menu automation-suite-select no-translate"
          className="automation-multi-select-picker"
          value={selectedValues}
          container={() => mountRef.current}
          renderMenuGroup={renderGroupItem}
          data={filteredData}
          searchable={false}
          onOpen={handleDropdownOpen}
          onClose={handleDropdownClose}
          renderMenu={renderMenu}
          placement={placement}
          renderExtraFooter={() => (
            <div>
              <div className="propay-filter-search tag-picker-propay-search">
                <Input
                  className="propay-search"
                  value={searchText}
                  placeholder="Search"
                  type="text"
                  onChange={(e) => handleSearch(e?.target?.value)}
                />
              </div>
            </div>
          )}
          onSelect={(value) => handleChange(value)}
          onClean={handleClear}
          renderValue={(values, items) => {
            if (items && items.length > 1) {
              const firstTag = items[0];
              const remainingCount = items.length - 1;
              return (
                <>
                  <Tag closable onClose={() => handleTagRemove('', firstTag?.value)}>
                    {firstTag?.label}
                  </Tag>
                  <Button className="plus-automation-btn">{` +${remainingCount}`}</Button>
                </>
              );
            } else if (items && items.length === 1) {
              return <Tag closable onClose={() => handleTagRemove('', items[0]?.value)}>{items[0]?.label}</Tag>;
            } else {
              return null;
            }
          }}
          renderMenuItem={renderMenuItem}
          menuStyle={{ maxHeight: '300px', overflowY: 'auto' }}
        />
      </div>
    );
  };

