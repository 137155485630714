import {
    TableContainer,
    TableCell,
    Table,
    TableBody,
    TableHead,
    TableRow,
    Tooltip,
    Button,
    TableFooter,
    Box,
} from '@mui/material';
import moment from 'moment';
import { convertNumber } from '../../components/fields';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import {
    VOID_ICON,
    CHANGE_PERIOD_ICON,
    DELETE_ICON,
    REVERSE_ICON,
    EDIT_ICON,
    FILLED_PLUS_ICON,
    BIN_ICON,
    ARROW_UP_02,
} from '../../utils/Constants/ClassName';
import { BonusEarningModal } from './BonusEarningModal';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { CRUD_UPDATE, useDeleteMany, useMutation, useNotify } from 'react-admin';
import { useQueryClient } from 'react-query';
import { PROPAY_STATUS } from '../../constants';
import { USER_TYPE,API_ENDPOINTS } from '../../utils/Constants/ConstantData';
import { BulkAddBonusModal } from '../protivEmployeeBonusStatement/BulkAddBonusModal';
import { Confirm } from '../../components/Confirm';
import { GroupPropayEarningModal } from './GroupPropayEarningModal';
import { POST } from '../../services/HttpService';
import { InfoLabel } from '../../components/fields/InfoLabel';
import { Typography } from '@mui/material';

export  const isLeadPay = (ids, propayId) => ids.filter(it => it.propay_id === propayId).length > NUMBER.ZERO;
export const getPropayName = (propayObj: any) => {
    let parent = propayObj.parent_id
        ? propayObj.parent_id_obj.display_name
        : '';
    return parent
        ? parent + ` > ${propayObj.display_name}`
        : propayObj.display_name;
};
const BonusDetailView = props => {
    const {
        identity,
        setShowVoidModal,
        setShowChangeModal,
        data,
        total,
        detailData,
        isVoidSection,
        setVoidPropayId,
        setBonusRecordId,
        setPropayName,
        setShowReverseModal,
        setIsOverage,
        additionalDetails,
        refreshData,
        showAdditional,
        statementTotal=0,
        voidTotal=0,
        //additionalTotal=0,
        propayTotal=0

    } = props;
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    const [openEarningModal, setOpenEarningModal] = useState(false);
    const [openAddModal, setAddModal] = useState(false);
    const [showEditModal,setShowEditModal]= useState(false);
    const [editDetails,setEditDetails]= useState({data:{},id:0});
    const [deleteDetails,setDeleteDetails]=useState({isOpen:false,id:0,type:''})
    const [groupDetail,setGroupDetail]=useState({show:false,id:0,name:''})
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [mutate] = useMutation();
    const [propayList, setPropayList] = useState([]);
    const showActions = data.status !== PROPAY_STATUS.PAID && identity?.user_type !== USER_TYPE.worker;
    const [deleteMany] = useDeleteMany(
        'additionalPay',
        { ids: [deleteDetails.id] },
        {
            onSuccess: () => {
                setDeleteDetails({isOpen:false,id:0,type:''})
                notify(`Additional ${deleteDetails.type} deleted.`);
                refreshData()
            },
            onError: (error: Error) => {
                notify(error.message)
                setDeleteDetails({isOpen:false,id:0,type:''})
            },
            mutationMode: 'pessimistic',
        }
    );
    const notify = useNotify();
    const getTotalSum = (key: string) => {
        let sum =
            total > NUMBER.ZERO
                ? detailData?.reduce((value, currentValue) => {
                      return value + Number(currentValue.wage_id_obj[key]);
                  }, NUMBER.ZERO)
                : NUMBER.ZERO;
        return sum;
    };
    const totalHours = getTotalSum('hours');
    const savedHours = getTotalSum('saved_hours');
    const [selectedRow, setSelectedRow] = useState(null);
    const getSum =(data,key)=>{
        let sum =
        data?.length > NUMBER.ZERO
            ? data?.reduce((value, currentValue) => {
                  return value + Number(currentValue[key]);
              }, NUMBER.ZERO)
            : NUMBER.ZERO;
    return sum;
    }
    const bonusTotal = getSum(detailData,'bonus_earning')  > NUMBER.ZERO ? formatter.format(getSum(detailData,'bonus_earning') - getSum(detailData,'deficit_amount')): `-${formatter.format(getSum(detailData,'deficit_amount'))}` ;
    const additionalTotal = getSum(additionalDetails,'additional_bonus_amt')  > NUMBER.ZERO ? formatter.format(getSum(additionalDetails,'additional_bonus_amt') - getSum(additionalDetails,'additional_deduction_amt')): `(${formatter.format(getSum(additionalDetails,'additional_deduction_amt'))})` ;
    const netSavedHours = total > NUMBER.ZERO
   ? detailData?.reduce((value, currentValue) => {
         return value + Number((currentValue.hours/currentValue.propay_id_obj.hours)*(currentValue.propay_id_obj.estimated_hours - currentValue.propay_id_obj.hours) );
     }, NUMBER.ZERO)
   : NUMBER.ZERO;
    useEffect(() => {}, [selectedRow,editDetails,propayList]);
    useEffect(() => {
        const getPropayList = () => {
            POST(API_ENDPOINTS.BONUS_GROUP_PROPAY_DETAIL, {
                propay_statement_id: groupDetail.id,
            }).then((res: any) => {
                if (res?.status === 'success') {
                    setPropayList(res?.statement_earning_by_propay);
                } else {
                    setPropayList([]);
                }
            });
        };
        groupDetail.show && getPropayList();
    }, [groupDetail.show]);

   
    const dateFormat = date => moment(date).format('MMM DD, YYYY');
    const gotoPropayDetail = propayId => navigate(`/show/${propayId}/propay`);

    const getWagePercent = () => {
        let cl =
            ((data.total_increased_wage - data.normal_wage) /
                data.normal_wage) *
            NUMBER.HUNDRED;
        return cl.toFixed(NUMBER.TWO);
    };

  


    return (
        <>
            {(!isVoidSection && (detailData.length > NUMBER.ZERO || additionalDetails.length > NUMBER.ZERO)) ? (
                <div className="bonuses-box-row b-box-modal-row">

                    <Tooltip id="unique-tooltip" title="The positive balance of the current statement is considered bonuses" placement="top" arrow>
                    <div className="bonuses-box-item active-bonus-item">
                        <span>Total Bonus</span>
                        {formatter.format(
                            data.total_earning > NUMBER.ZERO
                                ? data.total_earning
                                : NUMBER.ZERO
                        )}
                    </div>
                    </Tooltip>

                    <Tooltip id='unique-tooltip' title='The "From" value shows your base wage, while the "To" value is the wage, including bonuses' placement="top" arrow>
                    <div className="bonuses-box-item">
                        <span>Wage From & To</span>
                        {formatter.format(data.normal_wage)} to{' '}
                        {formatter.format(
                            data.total_increased_wage
                                ? data.total_increased_wage
                                : data.normal_wage
                        )}
                    </div>
                    </Tooltip>

                    <Tooltip id='unique-tooltip' title='Bonuses earned but not yet approved for a job' placement="top" arrow>
                    <div className="bonuses-box-item">
                        <span>Reserve</span>
                        {formatter.format(data?.reserve_amount)}
                    </div>
                    </Tooltip>

                    <Tooltip id='unique-tooltip' title='The difference in hours between estimated and actual job time, only for jobs under budget.' placement="top" arrow>
                    <div className="bonuses-box-item">
                        <span>Saved Hours</span>
                        {convertNumber(savedHours, identity)} hrs.
                    </div>
                    </Tooltip>

                    <Tooltip id='unique-tooltip' title='The difference in hours between estimated and actual job time for jobs under and over budget.' placement="top" arrow>
                    <div className="bonuses-box-item">
                        <span>Net Saved Hours</span>
                        <div className={`b-box-item-flex ${netSavedHours < 0 ? 'red-text':''}`}>
                        {convertNumber(netSavedHours, identity)} hrs.
                        </div>
                    </div>
                    </Tooltip>

                    <Tooltip id='unique-tooltip' title='The increase in rate and percentage compared to your base wage.' placement="top" arrow>
                    <div className="bonuses-box-item bonuses-arrow-item">
                        <span>Wage Increase</span>
                        <div className='b-box-item-flex'>
                        {data.total_increased_wage > NUMBER.ZERO ? (
                            <>
                                {formatter.format(
                                    data.total_increased_wage - data.normal_wage
                                )}{' '}
                                <small>({getWagePercent()}%)</small>
                                {ARROW_UP_02()}
                            </>
                        ) : (
                            '$0.00'
                        )}
                        </div>
                    </div>
                    </Tooltip>
                </div>
            ) : (
                ''
            )}
            {(statementTotal  && !isVoidSection) ? <div className={`t-s-alert ${statementTotal < 0 ? 't-s-danger':'t-s-success'}`}>
                                <label>Statement Total
                                <InfoLabel
                                className="remove-bonus-tooltip"
                                sx={{ height: 20 }}
                                icon="ri:error-warning-fill"
                                height={20}
                            >
                                <Typography className="bonus-tooltip-cs">
                                Statement Total = ProPay Total + Additional Total
                                </Typography>
                                </InfoLabel>
                                </label>
                                {formatter.format(statementTotal)}
                                </div>:''}


            {(total > NUMBER.ZERO ||  additionalDetails.length > NUMBER.ZERO) ? (
                <div className='bonuses-table-wrapper'>
                   {detailData.length > NUMBER.ZERO &&
                   <>
                   {!isVoidSection ?<div className='bonus-propays-head'>ProPays</div>:''}
                   <TableContainer className="bonuses-table-paper bonus-propay-table">
                        <Table
                            aria-label="Bonuses Worker table"
                            className="bonuses-mui-table RaDatagrid-table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Propay</TableCell>
                                    <TableCell>Dates (Start ~ End)</TableCell>
                                    <TableCell>Hours</TableCell>
                                    <TableCell>Amount</TableCell>
                                    {data.status !== PROPAY_STATUS.PAID &&
                                        identity?.user_type !==
                                            USER_TYPE.worker && (
                                            <TableCell>
                                                Actions
                                            </TableCell>
                                        )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {detailData?.length > NUMBER.ZERO &&
                                    detailData?.map(row => (
                                        <>
                                            <TableRow
                                                key={row.employee_id}
                                                sx={{
                                                    '&:last-child td, &:last-child th': {
                                                        border: 0,
                                                    },
                                                }}
                                                className='bonus-void-tr'
                                            >
                                                <TableCell>
                                                    <div
                                                        className="bonus-propay-name bonus-cursor-pointer"
                                                        onClick={() =>
                                                            gotoPropayDetail(
                                                                row
                                                                    .propay_id_obj
                                                                    .parent_id
                                                                    ? row
                                                                          .propay_id_obj
                                                                          .parent_id
                                                                    : row.propay_id
                                                            )
                                                        }
                                                    >
                                                        {getPropayName(
                                                            row.propay_id_obj
                                                        )}{' '}
                                                    </div>
                                                    <div>

                                                    {row?.propay_id_obj?.is_group_propay &&
                                                    <div className='bonus-group-flex'>
                                                    <Button className='propay-grouped-btn'>Grouped</Button>
                                                    {groupBonusIds(row?.bonus_ids_obj) && !row?.lead_pay_ids.length ? <Button className='bonus-group-more' onClick={()=>setGroupDetail({show:true,id:row.id,name:getPropayName(row.propay_id_obj)})}>{groupBonusIds(row?.bonus_ids_obj)}</Button>:row?.lead_pay_ids.length > 0 ? <GroupLeadpay/>:''}
                                                    </div>
                                                    }

                                                    </div>
                                                    <div className="bonus-job-name">
                                                        {row.propay_id_obj
                                                            .job_id_obj.id
                                                            ? row.propay_id_obj
                                                                  .job_id_obj
                                                                  .full_name
                                                            : ''}
                                                    </div>
                                                    {row.deficit_amount ? (
                                                        <Button className="overage-btn-bonus">
                                                            Overage
                                                        </Button>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {isLeadPay(
                                                        row.lead_pay_ids_obj,
                                                        row.propay_id_obj
                                                            .parent_id ||
                                                            row.propay_id
                                                    ) ? (
                                                        <Button className="overage-btn-bonus">
                                                            LeadPay
                                                        </Button>
                                                    ) : (
                                                        ''
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {dateFormat(
                                                        row.propay_id_obj
                                                            .from_date
                                                    )}{' '}
                                                    ~{' '}
                                                    {dateFormat(
                                                        row.propay_id_obj
                                                            .to_date
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {convertNumber(
                                                        row.hours,
                                                        identity
                                                    )}{' '}
                                                </TableCell>
                                                <TableCell
                                                    onClick={() => {
                                                        setOpenEarningModal(
                                                            true
                                                        );
                                                        setSelectedRow(row);
                                                    }}
                                                >
                                                    {!isLeadPay(
                                                        row.lead_pay_ids_obj,
                                                        row.propay_id_obj
                                                            .parent_id ||
                                                            row.propay_id
                                                    ) ? (
                                                        <div
                                                            className={`${
                                                                row.deficit_amount
                                                                    ? 'red-text bonus-propay-name bonus-cursor-pointer'
                                                                    : 'bonus-propay-name bonus-cursor-pointer'
                                                            }`}
                                                        >
                                                            {row.deficit_amount
                                                                ? '-'
                                                                : ''}
                                                            {formatter.format(
                                                                row.deficit_amount
                                                                    ? row.deficit_amount
                                                                    : row.bonus_earning
                                                            )}
                                                            {!identity?.company
                                                                ?.remove_ot_diff &&
                                                            row.bonus_ot_diff_amt >
                                                                NUMBER.ZERO ? (
                                                                <Tooltip
                                                                    title={`Overtime: ${formatter.format(
                                                                        row.bonus_ot_diff_amt
                                                                    )}`}
                                                                >
                                                                    <span className="ot-earning-btn">
                                                                        OT
                                                                    </span>
                                                                </Tooltip>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <span className='bonus-propay-name bonus-cursor-pointer'>
                                                            {formatter.format(
                                                                row.bonus_earning
                                                            )}
                                                        </span>
                                                    )}
                                                </TableCell>
                                                {data.status !== PROPAY_STATUS.PAID &&
                                        identity?.user_type !==
                                            USER_TYPE.worker && (
                                                <TableCell>
                                                    {data.status !==
                                                        PROPAY_STATUS.PAID &&
                                                        identity?.user_type !==
                                                            USER_TYPE.worker && (
                                                            <div className="bonus-action-icons">
                                                                {!isVoidSection && (
                                                                    <>
                                                                        <Tooltip
                                                                            title="Void"
                                                                            placement="top"
                                                                            arrow
                                                                            onClick={() => {
                                                                                setShowVoidModal(
                                                                                    true
                                                                                );
                                                                                setBonusRecordId(
                                                                                    row.id
                                                                                );
                                                                                setIsOverage(
                                                                                    row.deficit_amount
                                                                                );
                                                                                setVoidPropayId(
                                                                                    row.propay_id
                                                                                );
                                                                                setPropayName(
                                                                                    getPropayName(
                                                                                        row.propay_id_obj
                                                                                    )
                                                                                );
                                                                            }}
                                                                        >
                                                                            {VOID_ICON()}
                                                                        </Tooltip>
                                                                        <Tooltip
                                                                            title="Change Statement Period"
                                                                            arrow
                                                                            onClick={() => {
                                                                                setShowChangeModal(
                                                                                    true
                                                                                );
                                                                                setBonusRecordId(
                                                                                    row.id
                                                                                );
                                                                            }}
                                                                            placement="top"
                                                                        >
                                                                            {CHANGE_PERIOD_ICON()}
                                                                        </Tooltip>
                                                                    </>
                                                                )}
                                                                {isVoidSection && (
                                                                    <Tooltip
                                                                        title="Reverse"
                                                                        arrow
                                                                        placement="top"
                                                                        onClick={() => {
                                                                            setShowReverseModal(
                                                                                true
                                                                            );
                                                                            setBonusRecordId(
                                                                                row.id
                                                                            );
                                                                            setVoidPropayId(
                                                                                row.propay_id
                                                                            );
                                                                        }}
                                                                    >
                                                                        {REVERSE_ICON()}
                                                                    </Tooltip>
                                                                )}
                                                            </div>
                                                        )}
                                                </TableCell>
                                                 )}
                                                
                                              {(groupDetail.show && groupDetail.id) ? <GroupPropayEarningModal identity={identity} propayList={propayList} close={()=>setGroupDetail({show:false,id:0,name:''})} openModal={groupDetail.show} grpName={groupDetail.name}/>:''}
                                            </TableRow>
                                            {isVoidSection ? (
                                                <TableRow className='desc-void-tr'>
                                                    <TableCell colSpan={5}>
                                                        <div className="void-manager-desc">
                                                            <strong>
                                                                Description:
                                                            </strong>{' '}
                                                            {row.description}
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                ''
                                            )}
                                        </>
                                    ))}
                            </TableBody>
                            <TableFooter>
                                {total > NUMBER.ONE  && (
                                    <TableRow>
                                        <TableCell>{isVoidSection ? 'Void':'ProPay'} Total</TableCell>
                                        <TableCell>{''}</TableCell>
                                        <TableCell>
                                            <>{!isVoidSection ? convertNumber(
                                                totalHours,
                                                identity
                                            ):''}</>
                                        </TableCell>
                                        <TableCell>
                                            <span className={`${bonusTotal.includes('-') ? 'red-text':''}`}>{bonusTotal}</span> 
                                        </TableCell>
                                        <TableCell>{''}</TableCell>
                                    </TableRow>
                                )}
                            </TableFooter>
                            {openEarningModal && (
                                                    <BonusEarningModal
                                                        isOpen={
                                                            openEarningModal
                                                        }
                                                        isOverage={
                                                            selectedRow?.deficit_amount
                                                        }
                                                        bonusDetailData={
                                                            selectedRow?.deficit_amount
                                                                ? selectedRow?.overage_ids_obj
                                                                : [...selectedRow?.bonus_ids_obj,...selectedRow?.lead_pay_ids_obj]
                                                        }
                                                        onClose={() => {
                                                            setOpenEarningModal(
                                                                false
                                                            );
                                                            setSelectedRow(
                                                                null
                                                            );
                                                        }}
                                                        data={selectedRow}
                                                    />
                                                )}
                        </Table>
                    </TableContainer></>}

                     {(showAdditional && !isVoidSection  ) ?
                     <>
                     <div className='b-a-header-row'>
                                   {(additionalDetails?.length > NUMBER.ZERO || showActions) ? <div>Bonuses & Deductions</div>:''}
                                     {(showActions) ? <Button className='additional-add-btn' onClick={()=>{setAddModal(true)}}>Add</Button>:''}
                    </div>

                     <TableContainer className={`bonuses-table-paper bonus-additional-table ${!showActions ? 'st-paid-action-hidden':'' }`}>
                        <Table
                            aria-label="Bonuses Additional Table"
                            className="bonuses-mui-table RaDatagrid-table"
                        >
                            <TableHead>
                               {additionalDetails?.length > NUMBER.ZERO && <TableRow>
                                    <TableCell>ProPay</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Amount</TableCell>
                                    {(showActions) &&<TableCell>&nbsp;</TableCell>}
                                </TableRow>}
                            </TableHead>
                            <TableBody>
                            {additionalDetails?.length > NUMBER.ZERO && additionalDetails?.map((row,index)=> (
                                        <>
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell>
                                        <div
                                            className={`${row.propay_id ? 'bonus-propay-name bonus-cursor-pointer':'' }`}
                                            onClick={() =>
                                                row.propay_id && gotoPropayDetail(
                                                    row.propay_id_obj.parent_id
                                                        ? row.propay_id_obj
                                                              .parent_id
                                                        : row.propay_id
                                                )
                                            }
                                        >
                                            {row.propay_id ? getPropayName(row.propay_id_obj):'N/A'}{' '}
                                        </div>
                                        <div className="bonus-job-name">
                                            {row.propay_id_obj.job_id_obj.id
                                                ? row.propay_id_obj.job_id_obj
                                                      .full_name
                                                : ''}
                                        </div>
                                        {row.additional_deduction_amt ? (
                                            <Button className="overage-btn-bonus">
                                                Deduction
                                            </Button>
                                        ) : (row.additional_bonus_amt && <Button className="btn-bonus-green">
                                        Bonus
                                    </Button>)}

                                    </TableCell>
                                    <TableCell>
                                        {row.description}
                                    </TableCell>
                                    <TableCell>
                                    {row.additional_deduction_amt ? `(${formatter.format(row.additional_deduction_amt)})`: formatter.format(row.additional_bonus_amt)}
                                    </TableCell>
                                    {(showActions) &&<TableCell>
                                        <div className='bonus-action-icons'>
                                        <Tooltip title="Edit" placement="top" arrow className='additional-edit-icon' onClick={()=>{setShowEditModal(true);
                                            setEditDetails({data:row,id:row.additional_pay_ids[0]})
                                            }}>{EDIT_ICON()}</Tooltip>
                                            <Tooltip title="Delete" placement="top" arrow className='additional-deleted-icon' onClick={()=>{setDeleteDetails({isOpen:true,id:row.additional_pay_ids[0],type:row.additional_deduction_amt ? 'deduction':'bonus'})}}>{BIN_ICON()}</Tooltip>
                                        </div>
                                    </TableCell>}
                                </TableRow>
                                </>
                                    ))}
                            </TableBody>
                            <TableFooter>
                                {additionalDetails?.length > NUMBER.ONE && !isVoidSection && (
                                    <TableRow>
                                        <TableCell>Additional Total</TableCell>
                                        <TableCell>&nbsp;</TableCell>
                                        <TableCell>{additionalTotal}</TableCell>
                                        {(showActions) &&<TableCell>&nbsp;</TableCell>}
                                    </TableRow>
                                )}
                            </TableFooter>
                            {(openAddModal) && <BulkAddBonusModal isOpen={openAddModal} refreshData={refreshData} onClose={()=>{setAddModal(false)}} selectedIds={[data.id]} showOption={true} recordId={data.id}/>}
                            {(showEditModal && editDetails.id) && <BulkAddBonusModal editData={editDetails.data} isEdit={true} isOpen={showEditModal} refreshData={refreshData} onClose={()=>{setShowEditModal(false)}}
                                    selectedIds={[editDetails.id]} bulkAction={editDetails.data['additional_deduction_amt'] ? 'deduction':'bonus'} showOption={false} recordId={editDetails.id}/>}
                       {deleteDetails.isOpen && <Confirm
                                                 isOpen={deleteDetails.isOpen}
                                                 title={`Delete ${deleteDetails.type}`}
                                                 content={`Are you sure you want to delete ${deleteDetails.type} `}
                                                 onConfirm={()=>{deleteMany()}}
                                                 onClose={() => {setDeleteDetails({isOpen:false,id:0,type:''})}}
                                                 />}
                        </Table>
                    </TableContainer>
                    </>
                    :''}
                </div>
            ) : ''}
        </>
    );
};
export default BonusDetailView;
export const groupBonusIds = (data)=>{
    const groupIds = data.map(item => item.propay_id)
    const result =_.uniq(groupIds)
    return result?.length
   }
export const GroupLeadpay = ()=>{
       return(<Tooltip id='group_tooltip_id' title={'LeadPay Bonus for the GroupProPay'} placement="top" arrow><span className='bonus-lp-cls'>{' '}LP</span></Tooltip>)
   }
   